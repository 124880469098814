/* eslint no-underscore-dangle: 0 */
/* eslint max-len: 0 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DataTable from '../Table';

import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import popSchedule from '../../utils/api/pop-schedule';
import TopContent from '../../shared/component/TopContent';
import ModalBox from '../../shared/component/ModalBox';
import ViewDetails from './components/ViewDetails';
import { changeDateTimeFormat, checkPermission, convertSecondsToHoursAndMinutes } from '../../utils/helpers';

import {
  fetchPOPScheduleList,
  setFilter,
  setTableFilter,
} from '../../redux/slices/POPScheduleSlice';
import exportCSV from '../Global/exportCSV';
import Button from '../../shared/component/Button';
import height from '../../utils/size-variables';

function POPAnalytics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    popData,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.popSchedule);

  const [createPopDetailsModal, setCreatePopDetailsModal] = useState({
    title: '',
    content: 0,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    orientation: false,
  });

  const getPop = (counts, filters = {}) => {
    if (status === 'succeeded') {
      dispatch(fetchPOPScheduleList({ page: counts, limit: 20, ...filters }));
    }
  };
  const handleScroll = () => {
    getPop(pageCount + 1, filter);
  };

  const closeModal = () => {
    setCreatePopDetailsModal({
      title: '',
      content: 0,
    });
  };

  const onButtonClick = (popScheduleDetails) => {
    setCreatePopDetailsModal({
      title: popScheduleDetails?.scheduleName,
      content: <ViewDetails details={popScheduleDetails?.sequentialData} />,
    });
  };

  // Function to handle export CSV file
  const exportData = () => {
    const columnHeaders = [
      'deviceName', 'scheduleName', 'scheduleStartDate', 'scheduleEndDate', 'deviceOff',
      'outOfSync', 'matchingSchedule', 'completionPercentage', 'deviceTime', 'actualSchedule'];
    // API call for export CSV
    popSchedule.exportToCsv()
      .then((res) => {
        const rows = res?.data?.results.map((val) => {
          const temp = {
            ...val?._source,
            scheduleStartDate: changeDateTimeFormat(val?._source?.scheduleStart),
            scheduleEndDate: changeDateTimeFormat(val?._source?.scheduleEnd),
            deviceOff: convertSecondsToHoursAndMinutes(val._source.totalDeviceOff),
            outOfSync: convertSecondsToHoursAndMinutes(val._source.outOfSync),
            matchingSchedule: convertSecondsToHoursAndMinutes(val._source.matchingSchedule),
            completionPercentage: `${val._source.percentage}%`,
            deviceTime: '',
            actualSchedule: '',
          };

          const sequentialData = JSON.parse(val?._source?.sequentialData);
          for (let i = 0; i < sequentialData.length; i += 1) {
            temp.deviceTime
              += `${changeDateTimeFormat(sequentialData?.[i]?.startsAt)} to ${changeDateTimeFormat(sequentialData?.[i]?.stopsAt)}\n`;
          }

          for (let i = 0; i < sequentialData.length; i += 1) {
            temp.actualSchedule
              += `${sequentialData?.[i]?.status}\n`;
          }

          return temp;
        });

        exportCSV(columnHeaders, rows);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'deviceName',
      enableSorting: false,
    },
    {
      header: t('scheduleName'),
      accessorKey: 'scheduleName',
      enableSorting: false,
    },
    {
      header: t('scheduleStarts'),
      accessorKey: 'scheduleStarts',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => row.original.scheduleStartDate,
    },
    {
      header: t('scheduleEnds'),
      accessorKey: 'scheduleEnds',
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => row.original.scheduleEndDate,
    },
    {
      header: t('deviceOff'),
      accessorKey: 'deviceOff',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('outOfSync'),
      accessorKey: 'outOfSync',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('matchingSchedule'),
      accessorKey: 'matchingSchedule',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('completionPercentage'),
      accessorKey: 'completionPercentage',
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: '',
      accessorKey: 'view',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      Cell: ({ row }) => (
        <Button
          id="view-detail"
          label={t('viewDetail')}
          click={() => onButtonClick(row?.original)}
          classes="default-button button-outline-success btn-sx btn-text-success"
        />
      ),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getPop(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getPop(1, {});
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('popDevice')}
          buttonClass="success-button mycontent-createbutton"
          button={false}
          click={() => { }}
        />
        <ModalBox
          status={createPopDetailsModal.content !== 0}
          closeModal={closeModal}
          modalView={createPopDetailsModal}
          notification={false}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area  mydata-table">
              <DataTable
                className="my-data-table"
                header={columns}
                value={popData}
                status={status}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={tableFilter}
                isFirstRender={isFirstRender}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.onlyTableHeight}
                exportButton={checkPermission('exportPOPDevice')}
                exportData={exportData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

POPAnalytics.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};
POPAnalytics.defaultProps = {
  row: {},
};

export default POPAnalytics;
