import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import arrowLeftIcon from '../../../assets/icons/arrow-left.png';

function ViewImage() {
  const navigate = useNavigate();
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const handleBackClick = () => {
    navigate('/admin/templates');
  };
  return (
    <>
      <Box
        sx={
          {
            width: '30px', height: '30px', margin: '20px', cursor: 'pointer',
          }
        }
        onClick={() => handleBackClick()}
      >
        <img
          src={arrowLeftIcon}
          alt="left-arrow"
        />
      </Box>

      <Box sx={{ width: '100vw', height: '100vh' }}>
        <img
          src={templateData.signedUrl ? templateData.signedUrl : templateData?.template_image_url}
          alt="template-pic"
        />
      </Box>
    </>

  );
}

export default ViewImage;
