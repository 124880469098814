import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';

import close from '../../../assets/icons/close-circle.png';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';

import {
  fetchSnapshotDisplayGroupList,
  setFilter,
  setMuiFilterData,
} from '../../../redux/slices/SnapshotDisplayGroupSlice';
import height from '../../../utils/size-variables';

function DevicesGroupsList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    rowSelection,
    setRowSelection,
  } = props;

  const {
    displayGroup,
    pageCount,
    totalPageCount,
    status,
    filter,
    muiFilterData,
    totalDataCount,
  } = useSelector((state) => state.snapshotDisplayGroup);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [columnVisibility, setColumnVisibility] = useState({});

  const [list, viewList] = useState(false);

  const getDevicesGroup = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchSnapshotDisplayGroupList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getDevicesGroup(pageCount, filter);
  };

  const columns = [
    {
      header: t('groupName'),
      accessorKey: 'name',
    },
    {
      header: t('totalCount'),
      accessorKey: 'count',
      filterFn: 'commonFIlterFn',
      enableSorting: false,
      muiFilterTextFieldProps: { type: 'number' },
      Cell: ({ row }) => row.original.devices_list.length,
    },
  ];

  const closeModel = () => {
    viewList(false);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setMuiFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDevicesGroup(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(muiFilterData).length === 0) {
      getDevicesGroup(1);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(rowSelection).length > 0) {
      viewList(true);
    } else {
      viewList(false);
    }
  }, [rowSelection]);

  return (
    <div className="snapshot-display-group">
      <div className="snapshot-display-group-table">
        <DataTable
          header={columns}
          value={displayGroup}
          onFilterOrSortingChange={onFilterOrSortingChange}
          reduxColumnFiltersSorting={muiFilterData}
          status={status}
          totalPageCount={totalPageCount}
          pageCount={pageCount}
          scrollData={handleScroll}
          totalDataCount={totalDataCount}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
          height={height.formTableHeight}
          enableRowSelection
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          isFirstRender={isFirstRender}
          toolbarLeft={(
            <TableHeaderSelectCount
              select={Object.keys(rowSelection).length}
              total={totalDataCount}
            />
          )}
        />
      </div>
      {
        list && (
          <div className="snapshot-display-group-popup">
            <div className="close-icon">
              <div className="icon" onClick={closeModel} role="presentation">
                <img alt="close" src={close} />
              </div>
            </div>
            {
              displayGroup.filter((val) => Object.keys(rowSelection).includes(val.id)).map((val) => (
                <div className="snapshot-display-group-list">
                  <div className="snapshot-display-group-title">{val.name}</div>
                  <ul className="snapshot-display-group-device-list">
                    {
                      val.devices.map((val1) => (
                        <li className="snapshot-display-group-device-item">{val1.name}</li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}
DevicesGroupsList.propTypes = {
  rowSelection: PropTypes.objectOf().isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default DevicesGroupsList;
