/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '../../../shared/component/Button';

import plans from '../../../utils/api/plans';
import { currencySymbol, useRecaptcha } from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import { TickIcon } from '../../../shared/component/svgIcon';

function PlansPricing(props) {
  const { VerifyRecaptcha } = useRecaptcha();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleChangePaymentMode, backModal } = props;
  const availableTenures = [
    {
      label: 'Monthly',
      count: 1,
    },
    {
      label: 'Semi-Annual',
      count: 6,
    },
    {
      label: 'Annual',
      count: 12,
    },
  ];

  const [plansData, setPlansData] = useState([
    {
      features: ['', '', '', ''],
    },
    {
      features: ['', '', '', ''],
    },
    {
      features: ['', '', '', ''],
    },
    {
      features: ['', '', '', ''],
    },
  ]);
  const [selectedTenure, setSelectedTenure] = useState(1);
  const [loadingPlansData, setLoadingPlansData] = useState(true);
  const [buttonLoading, setButtonLoading] = useState({});

  const handleTenureChange = (tenure) => {
    setSelectedTenure(tenure);
  };

  const handleButtonClick = (planId, tenure) => {
    setButtonLoading((prevState) => ({
      ...prevState,
      [planId]: true, // Set loading to true for the clicked plan
    }));
    handleChangePaymentMode(planId, tenure, setButtonLoading);
  };

  const getPlans = async () => {
    const recaptchaToken = await VerifyRecaptcha('get-plans');
    if (recaptchaToken) {
      plans.getPlans({ recaptchaToken })
        .then((res) => {
          setPlansData(res?.data);
          setLoadingPlansData(false);
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
          backModal(false);
        });
    }
  };

  const handleContactRedirect = () => {
    window.location.href = 'https://wejha.com/contact';
  };
  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="plans-pricing-wrap">
      <div className="switch-tenure-wrap">
        {availableTenures.map((tenure) => (
          <div
            className="each-tenure"
            role="presentation"
            onClick={() => handleTenureChange(tenure.count)}
          >
            <div
              className="tenure-active"
              style={{
                '--active-width': selectedTenure === tenure.count ? '80%' : '0%',
                '--active-height': selectedTenure === tenure.count ? '80%' : '0%',
              }}
            />
            <p
              style={{
                '--tenure-color': selectedTenure === tenure.count ? '#30A84B' : '#5C646D',
              }}
            >
              {t(tenure.label)}
            </p>
          </div>
        ))}
      </div>
      <div className="plans-wrap">
        {plansData?.map((plan) => (
          <div className="each-plan">
            <div className="plan-container">
              {/* <div className="plan-details">
                {loadingPlansData
                  ? <Skeleton variant="text" sx={{ fontSize: '20px', width: '50%' }} />
                  : <h1>{plan?.name}</h1>}
                {loadingPlansData
                  ? <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                  : <p>{plan?.description}</p>}
                {loadingPlansData
                  ? <Skeleton variant="text" sx={{ fontSize: '28px', margin: '25px 0' }} />
                  : (
                    <div className="price">
                      <span>{currencySymbol(plan?.price_per_device?.[0]?.currency || 'USD')}</span>
                      <span>
                        {
                          (() => {
                            let finalPrice;
                            if (plan && plan?.price_per_device && plan?.price_per_device[0]) {
                              plan?.price_per_device?.[0]?.price_in_months?.forEach((month) => {
                                if (month.month === selectedTenure) {
                                  finalPrice = month?.price;
                                }
                              });
                            }
                            return finalPrice;
                          })()
                        }
                      </span>
                      <span>
                        {t('/device')}
                      </span>
                    </div>
                  )}
              </div> */}
              <div className="plan-details">
                {loadingPlansData ? (
                  <>
                    <Skeleton variant="text" sx={{ fontSize: '20px', width: '50%' }} />
                    <Skeleton variant="text" sx={{ fontSize: '14px' }} />
                    <Skeleton variant="text" sx={{ fontSize: '28px', margin: '25px 0' }} />
                  </>
                ) : (
                  <>
                    {/* <h1>{plan?.name}</h1> */}
                    <h1>{plan?.name ? plan.name.charAt(0).toUpperCase() + plan.name.slice(1) : ''}</h1>
                    <p>{plan?.description}</p>
                    {plan?.name.toLowerCase() !== 'enterprise' && (
                      <div className="price">
                        <span>{currencySymbol(plan?.price_per_device?.[0]?.currency || 'USD')}</span>
                        <span>
                          {(() => {
                            let finalPrice;
                            if (plan && plan?.price_per_device && plan?.price_per_device[0]) {
                              plan?.price_per_device?.[0]?.price_in_months?.forEach((month) => {
                                if (month.month === selectedTenure) {
                                  finalPrice = month?.price;
                                }
                              });
                            }
                            return finalPrice;
                          })()}
                        </span>
                        <span>{t('/device')}</span>
                      </div>
                    )}
                  </>
                )}
                {plan.name === 'enterprise'
                  && (
                    <div className="description-button" style={{ paddingBottom: '20px' }}>
                      <Button
                        label={t('Contact us')}
                        classes="success-button"
                        click={handleContactRedirect}
                      />
                    </div>
                  )}
              </div>
              <div className="features-wrap">
                {loadingPlansData
                  ? <Skeleton variant="text" sx={{ fontSize: '18px', margin: '10px 0' }} />
                  : <h2>{t('allFeaturesOf')} {plan?.name}</h2>}
                <div className="features-container">
                  {plan?.features?.map((feature) => (
                    <div className="each-feature">
                      <span><TickIcon /></span>
                      {loadingPlansData
                        ? <Skeleton variant="text" sx={{ fontSize: '18px', width: '75%' }} />
                        : <p>{feature}</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {loadingPlansData && <Skeleton variant="rectangular" sx={{ height: '35px', margin: '10px 0 0' }} />}
            {/* {(!plan?.trial_plan && !loadingPlansData)
              && (
                <Button
                  label={plan?.disable_tenure === selectedTenure
                    ? t('currentPlan')
                    : t('selectPlan')}
                  disabled={plan?.disable_tenure === selectedTenure}
                  classes={buttonLoading[plan?._id]
                    ? 'success-button loading-btn'
                    : 'success-button'}
                  click={() => handleButtonClick(plan?._id, selectedTenure)}
                  loading={buttonLoading[plan?._id]}
                />
              )} */}
            {(!plan?.trial_plan && !loadingPlansData && plan?.name !== 'enterprise') && (
              <Button
                label={plan?.disable_tenure === selectedTenure
                  ? t('currentPlan')
                  : t('selectPlan')}
                disabled={plan?.disable_tenure === selectedTenure}
                classes={buttonLoading[plan?._id]
                  ? 'success-button loading-btn'
                  : 'success-button'}
                click={() => handleButtonClick(plan?._id, selectedTenure)}
                loading={buttonLoading[plan?._id]}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

PlansPricing.propTypes = {
  handleChangePaymentMode: PropTypes.func.isRequired,
  backModal: PropTypes.func.isRequired,
};

export default PlansPricing;
