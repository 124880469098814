import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

// import permissions from '../../../assets/json/permissions.json';
import rolesApi from '../../../utils/api/roles';

import {
  createNewRole,
  fetchPermissionList,
  fetchRolesListAll,
} from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../shared/component/form/Validation';

function RolesAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.roles);

  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [disable, setDisable] = useState(true);
  const [all, setAll] = useState(false);

  const [value, setValue] = useState({});
  const [select, selectData] = useState([]);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const addPlaylist = () => {
    navigate('../');
  };

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 50, value?.name)) {
      setUploading(true);
      setDisable(true);
      rolesApi.addRole(value)
        .then((res) => {
          dispatch(createNewRole(res?.data));
          setUploading(false);
          setDisable(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            dispatch(fetchRolesListAll());
            navigate('../');
          }, 3000);
        })
        .catch((error) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  const handleChange = (event) => {
    if (!select.includes(event.target.value)) {
      selectData([...select, event.target.value]);
      setValue({ ...value, permissions: [...select, event.target.value] });
    } else {
      const index = select.indexOf(event.target.value);
      if (index > -1) {
        select.splice(index, 1);
      }
      selectData([...select]);
      setValue({ ...value, permissions: [...select] });
    }
  };

  const handleChangeAll = () => {
    if (!all) {
      const allValue = permissions.map((val) => val.children.map((val1) => val1.value))
        .reduceRight((accumulator, currentValue) => accumulator.concat(currentValue));
      selectData([...allValue]);
      setValue({ ...value, permissions: [...allValue] });
      setAll(true);
    } else {
      selectData([]);
      setValue({ ...value, permissions: [] });
      setAll(false);
    }
  };

  const inputHandel = (name, changValue) => {
    setValue({ ...value, [name]: changValue });
  };

  useEffect(() => {
    if (value?.name && value?.permissions?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('roleCreatedSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('roles'), t('createNewRoles')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('role')}
                  type="text"
                  name="name"
                  required
                  value={value.name}
                  change={inputHandel}
                  error={!nameValidation(1, 100, value?.name) && submitButtonClicked}
                  errorMessage={t('roleNameValidation')}
                />
              </div>
              <div className="my-content-permissions">
                <div className="my-content-permissions-list">
                  <h4 className="title">{t('permissions')}</h4>
                  <div className="permission-list">
                    <div className="permission-lis-session">
                      <div className="my-content-permissions-all-check">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              size="small"
                              sx={{
                                color: '#30A84B',
                                '&.Mui-checked': {
                                  color: '#30A84B',
                                },
                              }}
                              onChange={handleChangeAll}
                            />
                          )}
                          label="Select ALL"
                        />
                      </div>
                      {
                        permissions.map((values) => values?.children.length > 0 && (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">{values.name}</FormLabel>
                            <FormGroup
                              aria-label="position"
                              row
                              sx={{
                                gap: 7,
                                rowGap: 2,
                              }}
                            >
                              {
                                values?.children.map((e) => (
                                  <FormControlLabel
                                    value={e.value}
                                    control={(
                                      <Checkbox
                                        size="small"
                                        sx={{
                                          color: '#30A84B',
                                          '&.Mui-checked': {
                                            color: '#30A84B',
                                          },
                                        }}
                                        onChange={handleChange}
                                        checked={select.includes(e.value)}
                                      />
                                    )}
                                    label={e.name}
                                  />
                                ))
                              }
                            </FormGroup>
                          </FormControl>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={addPlaylist} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={uploading ? t('creating') : t('create')}
                  disabled={disable}
                  click={submit}
                  classes={uploading ? 'success-button loading-btn' : 'success-button'}
                  loading={uploading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesAdd;
