import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import subscription from '../../../utils/api/subscription';
import { currencySymbol, useRecaptcha } from '../../../utils/helpers';
import SelectBox from '../../../shared/component/form/SelectBox';
import { Input, InputDate, InputInnerSymbol } from '../../../shared/component/form/Input';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function StorageChangeSummary(props) {
  const dispatch = useDispatch();
  const { VerifyRecaptcha } = useRecaptcha();
  const {
    storageChangeSuccess,
    backModal,
    storageInfo,
    currency,
    paymentData,
  } = props;
  const [payment, setPayment] = useState(paymentData);
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');

  const paymentOption = [
    {
      label: 'Net banking',
    },
    {
      label: 'UPI',
    },
    {
      label: 'Other',
    },
  ];
  const { t } = useTranslation();

  const submitDevice = async () => {
    const recaptchaToken = await VerifyRecaptcha('Storage-change-summary');
    setDisable(true);
    setButtonStatus('loading');
    const request = {
      transaction_id: payment?.transaction_id,
      transaction_date: payment?.transaction_date,
      transaction_type: payment?.transaction_type?.label,
      transaction_amount: payment?.totalPrice,
      storage: storageInfo.totalStorage,
      recaptchaToken,
      payment_mode: 'offline',
    };
    subscription.storageIncreaseCount(request)
      .then(() => {
        setDisable(false);
        setButtonStatus('');
        storageChangeSuccess('storage');
      })
      .catch((err) => {
        setDisable(false);
        setButtonStatus('');
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  // const getSummaryData = () => {
  //   const request = {
  //     storage: storageInfo.totalStorage,
  //   };
  //   subscription.storageIncreaseCountSummary(request).then((response) => {
  //     setPayment((prevValue) => ({
  //       ...prevValue,
  //       planName: response?.data?.plan_name,
  //       unitPrice: response?.data?.unit_price,
  //       storageCount: response?.data?.quantity,
  //       totalPrice: response?.data?.price,
  //       paymentSummary: response?.data?.payment_summary,
  //       actualPrice: response?.data?.actual_price,
  //     }));
  //   }).catch((error) => {
  //     dispatch(setErrorNotification(error?.response?.data));
  //     backModal();
  //   });
  // };

  const handleChange = (name, changeValue) => {
    setPayment((prevValue) => ({
      ...prevValue,
      [name]: changeValue,
    }));
  };

  // useEffect(() => {
  //   getSummaryData();
  // }, []);

  useEffect(() => {
    if (payment?.totalPrice <= 0) {
      setDisable(false);
    } else if (payment?.transaction_id && payment?.transaction_date && payment.transaction_type) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payment]);
  return (
    <div className="device-change-summary">
      <div className="header">
        {/* <div className="test-title">{t('account')}: {planInformation.account}</div> */}
        <div className="test-title">{t('planName')}: {payment.planName}</div>
      </div>
      <div className="device-change-content">
        <div className="sass-summary">
          <table>
            <tr>
              <td colSpan="4">
                <div className="table-header">
                  <span>{t('saasSummary')}</span>
                  <div className="success-link" onClick={backModal} role="presentation">{t('editStorage')}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{t('description')}</th>
              <th>{t('unitPrice')}</th>
              <th>{t('quantity')}</th>
              <th>{t('totalPrice')}</th>
            </tr>
            <tr>
              <td>{t('addOnStorage')}</td>
              <td>{currencySymbol(currency, payment.unitPrice)}</td>
              <td>{payment.storageCount > 0 && '+ '}{payment.storageCount} {t('gb')}</td>
              <td>{currencySymbol(currency, payment?.actualPrice?.toFixed(2))}</td>
            </tr>
          </table>
        </div>
      </div>
      {
        payment?.paymentSummary?.debitAmount > 0 && (
          <div className="payment-summary">
            <div className="title">{t('paymentSummary')}</div>
            <div className="content">
              <p>{t('totalAmount')}</p>
              <snap className="success-text">
                {currencySymbol(currency, payment?.actualPrice?.toFixed(2))}
              </snap>
            </div>
            <div className="content">
              <p>{t('amountAddWalletMessage')}</p>
              <snap className="text-danger">
                -{currencySymbol(currency, payment?.paymentSummary?.debitAmount?.toFixed(2))}
              </snap>
            </div>
            <div className="content bt-dot">
              <p>{t('amountToBePaid')}</p>
              <snap>
                {currencySymbol(currency, payment?.totalPrice?.toFixed(2))}
              </snap>
            </div>
          </div>
        )
      }
      {
        payment?.paymentSummary?.creditAmount > 0 && (
          <div className="payment-summary">
            <div className="title">{t('paymentSummary')}</div>
            <div className="content">
              <p>{t('wallet')}</p>
              <snap className="text-success">
                +{currencySymbol(currency, payment?.paymentSummary?.creditAmount?.toFixed(2))}
              </snap>
            </div>
          </div>
        )
      }
      {
        payment?.totalPrice > 0 && (
          <div className="payment-form">
            <div className="payment-info-form">
              <h2>{t('transactionDetails')}</h2>
              <div className="single-row">
                <Input
                  type="text"
                  label={t('transactionID')}
                  required
                  name="transaction_id"
                  value={payment?.transaction_id}
                  change={handleChange}
                  placeholder={t('enterTransactionID')}
                />
                <InputDate
                  label={t('transactionDate')}
                  name="transaction_date"
                  required
                  value={payment?.transaction_date}
                  change={handleChange}
                  disableFuture
                />
                <SelectBox
                  label={t('transactionMode')}
                  name="transaction_type"
                  required
                  value={payment.transaction_type}
                  onchange={handleChange}
                  lists={paymentOption}
                  id="select-account"
                  placeholder={t('selectTransactionMode')}
                />
                <InputInnerSymbol
                  type="number"
                  label={t('transactionAmount')}
                  required
                  value={payment?.totalPrice}
                  placeholder="0"
                  symbol={currencySymbol(currency)}
                  min={0}
                  readonly
                />
              </div>
            </div>
          </div>
        )
      }
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button" style={{ width: '5vw' }}>
          <Button label={t('back')} click={backModal} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submitDevice}
            classes={buttonStatus === 'loading'
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonStatus === 'loading'}
          />
        </div>
      </div>
    </div>
  );
}

StorageChangeSummary.propTypes = {
  storageChangeSuccess: PropTypes.func.isRequired,
  backModal: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  storageInfo: PropTypes.shape({
    totalStorage: PropTypes.number,
  }).isRequired,
  paymentData: PropTypes.objectOf().isRequired,
};

export default StorageChangeSummary;
