import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import playlist from '../../../utils/api/playlist';
import {
  addPlaylist,
  createPlaylist,
  updatePlaylist,
} from '../../../redux/slices/PlaylistSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ContentView from '../../MyContent/components/ContentView';
// import { EditIcon } from '../../../shared/component/svgIcon';
// import IconButton from '../../../shared/component/IconButton';
import height from '../../../utils/size-variables';

function PlayListAddView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newPlaylist } = useSelector((state) => state.playlist);
  const [tableValue, setTableValues] = useState([]);

  const [disable, setDisable] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [modalView, setModalView] = useState({});

  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const viewContent = (singleContent) => {
    setNotification(false);
    setModalView({
      title: 'Preview',
      content: <ContentView singleContent={singleContent} />,
    });
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('contentName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original?.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type?.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original?.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => row.original?.file_type_to_show,
    },
    // {
    //   header: t('author'),
    //   accessorKey: 'user',
    //   filterFn: 'commonFIlterFn',
    //   Cell: ({ row }) => row.original.author,
    // },
    // {
    //   header: t('Update Date'),
    //   accessorKey: 'createdAt',
    //   accessorFn: (originalRow) => new Date(originalRow.updatedAt),
    //   filterVariant: 'date-range',
    //   enableColumnFilter: false,
    //   Cell: ({ row }) => row.original.updateDate,
    // },
    // {
    //   header: t('fileSize'),
    //   accessorKey: 'file_size',
    //   enableColumnFilter: false,
    // },
    {
      header: t('startTransition'),
      accessorKey: 'start_transition',
    },
    {
      header: t('endTransition'),
      accessorKey: 'end_transition',
    },
    // {
    //   header: '',
    //   accessorKey: 'action',
    //   Cell: () => (
    //     <IconButton
    //       label={t('editTransition')}
    //       icon={<EditIcon />}
    //       classes="success-button-outline round-button"
    //     />
    //   ),
    // },
  ];

  const editPlaylist = () => {
    if (newPlaylist?.id) {
      navigate(`../edit/${newPlaylist?.id}`);
    } else {
      navigate('../add', { state: { page: 'add-view' } });
    }
  };

  const closeModalView = () => {
    setModalView({});
  };

  const submit = () => {
    setDisable(true);
    setNotification(true);
    if (newPlaylist?.id) {
      const playlistData = {
        id: newPlaylist?.id,
        name: newPlaylist?.name,
        description: newPlaylist?.description ? newPlaylist?.description : '',
        contents: tableValue.map((each) => each?.id),
      };
      setUploading(true);
      playlist.updatePlaylist({ ...playlistData }).then((res) => {
        dispatch(updatePlaylist(res?.data));
        setUploading(false);
        setModalView({
          title: '',
          content: <Success message={t('playlistUpdatedSuccess')} />,
        });
        setNotification(true);
        setTimeout(() => {
          setModalView({});
          navigate('../');
          dispatch(addPlaylist({}));
          setDisable(false);
        }, 3000);
      }).catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
    } else {
      const playlistData = {
        name: newPlaylist?.name,
        description: newPlaylist?.description || undefined,
        contents: tableValue.map((each) => each?.id),
      };
      setUploading(true);
      playlist.addPlaylist({ ...playlistData })
        .then((res) => {
          dispatch(createPlaylist(res?.data));
          setUploading(false);
          setModalView({
            title: '',
            content: <Success message={t('playlistCreatedSuccess')} />,
          });
          setNotification(true);
          setTimeout(() => {
            setModalView({});
            navigate('../');
            setDisable(false);
            dispatch(addPlaylist({}));
          }, 3000);
        })
        .catch((error) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (Object.keys(newPlaylist).length === 0) {
      navigate('../add');
    } else {
      setTableValues([...newPlaylist.selectedContentDetails]);
    }
  }, []);

  return (
    <div className="content">
      <ModalBox
        status={Object.keys(modalView)?.length > 0}
        closeModal={closeModalView}
        notification={notification}
        modalView={modalView}
      />
      <div className="main-content">
        <TopContent
          label={[t('playlist'), t('createNewPlaylist')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={editPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('playlistName')}
                  type="text"
                  name="playlist"
                  required={false}
                  readonly
                  value={newPlaylist?.name}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  required={false}
                  readonly
                  value={newPlaylist?.description}
                />
              </div>
              <Table
                header={columns}
                value={tableValue}
                status="succeed"
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                totalDataCount=""
                onFilterOrSortingChange={() => { }}
                isFirstRender
                rowSelection={contentSelection}
                setRowSelection={setContentSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={false}
                height={height.formTableHeight}
                disableFilter
                disableSorting
                disableFullScreenToggle
                enableRowOrdering
                setValue={setTableValues}
              />
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label="Back" click={editPlaylist} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label="Continue"
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
PlayListAddView.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      name: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      updateDate: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
PlayListAddView.defaultProps = {
  row: null,
};

export default PlayListAddView;
