import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getRoles: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/roles`, { params });
  },
  getRolesAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/roles/rolesList`, { params });
  },
  getPermissions: (data = {}) => get(`${process.env.REACT_APP_API_URL}cms/v1/roles/permissions`, { data }),
  getSingleRole: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/roles/${data}`, {}),
  addRole: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/roles`, body);
  },
  updateRole: (data = {}) => {
    const params = {
      name: data.name,
      permissions: data.permissions,
    };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/roles/${data.id}`, params);
  },
  deleteRole: (data) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/roles/${data}`, {}),
};
