/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ColorPicker from 'react-best-gradient-color-picker';
import TopContent from '../../../../shared/component/TopContent';
import ModalBox from '../../../../shared/component/ModalBox';
import { Input, InputRightInnerIcon } from '../../../../shared/component/form/Input';
import Button from '../../../../shared/component/Button';
import { nameValidation } from '../../../../shared/component/form/Validation';
import locationIcon from '../../../../assets/icons/location.png';
import Location from '../../../Location/components/Location';
import { createWidgetStoreLocally } from '../../../../redux/slices/WidgetTempSlice';
import { weatherApi } from '../shared';

function ClockEditForm() {
  const { t } = useTranslation();
  const selectedSingleSavedWidget = useSelector((states) => states.widgets.selectedSingleSavedWidget);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState(selectedSingleSavedWidget || {
    text_color: '#000000',
    background_color: '#FFFFFF',
  });
  const [notification, setNotification] = useState(false);
  const [disable, setDisable] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [modelContent, setModelContent] = useState(true);
  const [colorPickerOpen, setColorPickerOpen] = useState(null);

  const handleColorPickerClick = (pickerType) => {
    setColorPickerOpen(pickerType === colorPickerOpen ? null : pickerType);
  };

  const navigateBack = () => {
    navigate('/admin/apps/saved-widgets');
  };

  // Function to changes values on user form
  const handleChange = (name, changeValue) => {
    setFormValue({ ...formValue, [name]: changeValue });
  };

  const handlePreview = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, formValue?.name)) {
      dispatch(createWidgetStoreLocally(formValue));
      navigate(
        '/admin/apps/clock-image-page',
        {
          state: {
            ...formValue,
            html_url: selectedSingleSavedWidget?.html_url,
            script_url: selectedSingleSavedWidget?.widget_id?.script_url,
            widgetEditId: selectedSingleSavedWidget?._id,
          },
        },
      );
    }
  };

  const setLatLng = async (data) => {
    try {
      const weatherData = await weatherApi(data?.lat, data?.lng);
      setFormValue((prevValue) => ({
        ...prevValue,
        latitude: data.lat,
        longitude: data.lng,
        time_zone: weatherData.timezone,
        location: weatherData.name,
      }));
      setNotification(false);
    } catch (error) {
      setNotification(error.message);
    }
  };

  const openMap = () => {
    setNotification(true);
    setModelContent({
      title: 'Map Location',
      content: (
        <Location
          setLatLng={setLatLng}
          close={setNotification}
          data={{ lat: formValue.latitude, lng: formValue.longitude }}
        />
      ),
    });
  };

  useEffect(() => {
    if (
      formValue.name
      && formValue?.time_zone
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formValue]);

  return (
    <div className="widget-addform content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modelContent}
      />
      <div className="main-content">
        <TopContent
          label={[t('apps'), t('clock'), state?.name]}
          button={false}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form">
                <p style={{ fontWeight: '600' }}>{t('clockDetails')}</p>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('widgetName')}
                    type="text"
                    name="name"
                    required
                    value={formValue?.name}
                    change={handleChange}
                    placeholder={t('enterWidgetName')}
                    error={!nameValidation(1, 100, formValue?.name) && submitButtonClicked}
                    errorMessage={t('nameValidation')}
                  />
                  <InputRightInnerIcon
                    style={{ gap: '10px' }}
                    icon={locationIcon}
                    label={t('location')}
                    type="text"
                    name="location"
                    required
                    value={formValue?.location}
                    click={openMap}
                    placeholder={t('enterLocation')}
                  />
                </div>
                <div className="single-row">
                  <div className="wrapper-color-picker">
                    <p>{t('backgroundColor')}</p>
                    <div className="color-picker-flex">
                      <div
                        className="color-picker-style"
                        style={{
                          background: formValue.background_color,
                          width: '24px',
                          height: '24px',
                        }}
                        role="none"
                        onClick={() => handleColorPickerClick('backgroundcolor')}
                      />
                      <div>{formValue.background_color}</div>
                    </div>
                    {colorPickerOpen === 'backgroundcolor'
                      && (
                        <div className="color-picker">
                          <ColorPicker
                            value={formValue.background_color}
                            width={240}
                            height={100}
                            onChange={(color) => handleChange('background_color', color)}
                          />
                          <p
                            className="closeButton"
                            onClick={() => handleColorPickerClick('backgroundcolor')}
                            role="none"
                          >
                            {t('close')}
                          </p>
                        </div>
                      )}
                  </div>
                  <div className="wrapper-color-picker">
                    <p>{t('textColor')}</p>
                    <div className="color-picker-flex">
                      <div
                        className="color-picker-style"
                        style={{
                          background: formValue.text_color,
                          width: '24px',
                          height: '24px',
                        }}
                        role="none"
                        onClick={() => handleColorPickerClick('textcolor')}
                      />
                      <div>{formValue.text_color}</div>
                    </div>
                    {colorPickerOpen === 'textcolor'
                      && (
                        <div className="color-picker">
                          <ColorPicker
                            value={formValue.text_color}
                            width={240}
                            height={100}
                            onChange={(color) => handleChange('text_color', color)}
                          />
                          <p
                            className="closeButton"
                            onClick={() => handleColorPickerClick('textcolor')}
                            role="none"
                          >
                            {t('close')}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={navigateBack} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      disabled={disable}
                      label={t('preview')}
                      click={handlePreview}
                      classes="success-button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClockEditForm;
