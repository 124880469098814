import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getAvailableContentLayout: () => get(`${process.env.REACT_APP_API_URL}cms/v1/content-layouts`),
  getAvailableContentLayoutById: (id) => get(`${process.env.REACT_APP_API_URL}cms/v1/content-layouts/${id}`),
  addSavedLayout: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/my-content-layouts`, body, true);
  },
  updateSavedLayout: (id, data = {}) => patch(
    `${process.env.REACT_APP_API_URL}cms/v1/my-content-layouts/${id}`,
    data,
    true,
  ),
  getSavedContentLayout: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/my-content-layouts`, { params });
  },
  getSavedContentLayoutById: (id) => get(`${process.env.REACT_APP_API_URL}cms/v1/my-content-layouts/${id}`),
  deleteSavedContentLayout: (id) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/my-content-layouts/${id}`),

  // This needs to be in utils/widgets
  getSavedWidgets: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/my-widgets/`, { params });
  },
};
