import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SelectBox from '../../../shared/component/form/SelectBox';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import aiInstance from '../../../utils/api/ai-instance';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { instanceTransferStatus } from '../../../redux/slices/AiAnalyticsSlice';

function TransferCredit(props) {
  const [value, setValue] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { instanceList } = useSelector((state) => state?.AiAnalytics);
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch();

  const handleFunction = (name, changeValue) => {
    setValue({
      ...value,
      [name]: changeValue,
    });
  };
  const handleClose = () => {
    props?.close(false);
  };

  const submit = () => {
    setDisable(true);
    setUploading(true);
    const req = {
      from_instance_id: value?.transferFrom?.id,
      to_instance_id: value?.transferTo?.id,
      transfer_hours: value?.transferHours,
    };
    aiInstance.addTransfer(req)
      .then((res) => {
        if (res) {
          setUploading(false);
          setNotification(true);
          dispatch(instanceTransferStatus('success'));
          setTimeout(() => {
            setNotification(false);
            handleClose();
          }, 2000);
        }
      })
      .catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  useEffect(() => {
    if (
      value?.transferFrom
      && value?.transferTo
      && value?.transferHours > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    setSelectedOptions([value?.transferFrom]);
  }, [value]);
  const filteredList = instanceList.filter(
    (instance) => !selectedOptions.some((selected) => selected?.id === instance?.id),
  );

  return (
    <div className="transfer-credit">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={{ content: <Success message={t('creditTransferSuccess')} /> }}
      />
      <div className="form">
        <SelectBox
          label={t('transferFrom')}
          name="transferFrom"
          lists={instanceList}
          value={value?.transferFrom}
          onchange={handleFunction}
          selectedOptions={selectedOptions}
          placeholder={t('select')}
          id="transferFrom"
          required
        />
        <SelectBox
          label={t('transferTo')}
          name="transferTo"
          lists={filteredList}
          value={value?.transferTo}
          onchange={handleFunction}
          placeholder={t('select')}
          id="transferTo"
          required
        />
        <Input
          icon={' '}
          label={t('transferHours')}
          type="number"
          name="transferHours"
          required
          value={value?.transferHours}
          change={handleFunction}
          placeholder={t('0 h')}
        />
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={uploading ? t('loading') : t('confirm')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TransferCredit.propTypes = {
  close: PropTypes.node.isRequired,
};

export default TransferCredit;
