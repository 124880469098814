import React from 'react';
import PropTypes from 'prop-types';

function CircularProgressBar(props) {
  const { percentage } = props;
  const circleWidth = 55;
  const radius = 23;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <div className="skill">
      <div className="outer">
        <div className="inner">
          <div className="svg-wrap">
            <svg
              width={circleWidth}
              height={circleWidth}
              viewBox={`0 0 ${circleWidth} ${circleWidth}`}
            >
              <circle
                cx={circleWidth / 2}
                cy={circleWidth / 2}
                strokeWidth="8px"
                r={radius}
                className="circle-background"
              />
              <circle
                cx={circleWidth / 2}
                cy={circleWidth / 2}
                strokeWidth="8px"
                r={radius}
                className="circle-progress"
                style={{
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                }}
                transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
              />
              <text x="50%" y="57%" textAnchor="middle" className="percent-text">
                {percentage || 0}%
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

CircularProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default CircularProgressBar;
