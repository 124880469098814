import React from 'react';
import PropTypes from 'prop-types';

function DotLoader(props) {
  return (
    <div
      className="loading"
      style={{
        '--dot-active-color': props?.dotActiveColor,
        '--dot-blur-color': props?.dotBlurColor,
        '--dot-size': props?.dotSize,
      }}
    >
      <div className="loading-dot" />
      <div className="loading-dot" />
      <div className="loading-dot" />
    </div>
  );
}

DotLoader.propTypes = {
  dotActiveColor: PropTypes.string,
  dotBlurColor: PropTypes.string,
  dotSize: PropTypes.string,
};

DotLoader.defaultProps = {
  dotActiveColor: '#FFFFFF',
  dotBlurColor: '#FFFFFF4e',
  dotSize: '6px',
};

export default DotLoader;
