import React from 'react';
import PropTypes from 'prop-types';
import IFrameComponent from '../../../../../shared/component/IFrameComponent';

function ClockPreview(props) {
  const {
    selectedWidget,
    gridName,
  } = props;

  return (
    <div
      className="widget-preview-wrap"
    >
      <IFrameComponent
        htmlUrl={selectedWidget?.html_url}
        jsUrl={selectedWidget?.widget_id?.script_url}
        title={`clock_${gridName}`}
        replacements={[
          ['latitude', selectedWidget?.latitude],
          ['longitude', selectedWidget?.longitude],
          ['background_color', selectedWidget?.background_color],
          ['text_color', selectedWidget?.text_color],
        ]}
      />
    </div>
  );
}

ClockPreview.propTypes = {
  selectedWidget: PropTypes.objectOf().isRequired,
  gridName: PropTypes.string.isRequired,
};

export default ClockPreview;
