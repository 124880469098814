import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import TopContent from '../../shared/component/TopContent';
import SaveWidget from './components/SaveWidget';
import WidgetCards from './components/WidgetCards';

function Widget() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const contentLayoutTabs = [
    {
      id: 1,
      label: t('widgets'),
      key: 'widgets',
    },
    {
      id: 2,
      label: t('saved'),
      key: 'saved-widgets',
    },
  ];

  const handleSwitchTab = (key) => {
    navigate(`../${key}`);
  };

  return (
    <div className="content-layout widget-view">
      <TopContent
        label={t('apps')}
      />
      <div className="content-layout-wrap widget-wrapper">
        <div className="content-layout-tabs">
          {contentLayoutTabs?.map((tab) => (
            <div
              role="presentation"
              onClick={() => handleSwitchTab(tab?.key)}
              className="each-tab"
            >
              <h1
                style={{ '--active-tab-color': tab?.key === params?.tab ? '#03A84B' : '#717880' }}
              >
                {tab?.label}
              </h1>
              <div
                className="active-tab-border"
                style={{ '--active-tab-width': tab?.key === params?.tab ? '95%' : '0%' }}
              />
            </div>
          ))}
        </div>
        <div className="content-layout-container widget-content">
          {params?.tab === 'widgets' && <WidgetCards />}
          {params?.tab === 'saved-widgets' && <SaveWidget />}
        </div>
      </div>
    </div>
  );
}

export default Widget;
