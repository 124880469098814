import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { fetchDisplaySignageList } from '../../../redux/slices/DisplaySignageSlice';
import { getTimeDifference } from '../../../utils/helpers';
import {
  LatestUpdateIcon,
  MoreMenuIcon,
} from '../../../shared/component/svgIcon';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import height from '../../../utils/size-variables';

function CardView(props) {
  const { toolbarLeft, actions } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    displaySignage,
    pageCount,
    totalPageCount,
    filter,
    status,
  } = useSelector((state) => state.display);

  const getDisplaySignage = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchDisplaySignageList({ page: counts, limit: 20, ...search }));
    }
  };
  const handleScroll = () => {
    getDisplaySignage(pageCount + 1, filter);
  };
  return (
    <div className="content-main-container">
      <div className="grid-view-header">
        {toolbarLeft}
      </div>
      <div className="grid-view-container">
        <InfiniteScroll
          dataLength={displaySignage.length}
          next={handleScroll}
          height={height.onlyTableHeight}
          hasMore={pageCount < totalPageCount}
          loader={status === 'loading' && (
            <h4 className="text-center">
              <Box sx={{ display: 'flex' }}>
                <CircularProgress color="success" />
              </Box>
            </h4>
          )}
        >
          <div className="card-container">
            {displaySignage?.map((displayChange) => (
              <div className="card-view">
                <div className="card-header">
                  <span>{displayChange.name}</span>
                  <span
                    className={displayChange.lastPollTime ? 'status-button' : 'inactive-button'}
                  >
                    {displayChange.lastPollTime ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className="device-information">
                  <div className="item">
                    <span className="item-label">{t('storeInfo')}</span>
                    <span>{displayChange.store_name}</span>
                  </div>
                  <div className="item">
                    <span className="item-label">{t('deviceOS')}</span>
                    <span>{displayChange.hardware}</span>
                  </div>
                  <div className="item">
                    <span className="item-label">{t('playerVersion')}</span>
                    <span>{displayChange.player_app_version}</span>
                  </div>
                  <div className="item">
                    <span className="item-label">{t('resolution')}</span>
                    <span>{displayChange.resolution}</span>
                  </div>
                  <div className="item">
                    <span className="item-label">{t('orientation')}</span>
                    <span>{displayChange.orientation}</span>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="footer-content">
                    <div className="footer-image">
                      <LatestUpdateIcon />
                    </div>
                    <p>Last Updated {getTimeDifference(displayChange.lastPollAt)}</p>
                    <div className="more-btn">
                      <DropDownMenu
                        action={actions}
                        icon={<MoreMenuIcon />}
                        value={displayChange}
                        dropdown="tableForm"
                        tooltip=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
CardView.propTypes = {
  toolbarLeft: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
};
CardView.defaultProps = {
  toolbarLeft: '',
  actions: [],
};
export default CardView;
