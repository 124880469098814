import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  fetchTransactionList,
  setTransactionFilter,
  setTransactionTableFilter,
} from '../../../redux/slices/SubscriptionSlice';
import DataTable from '../../Table';
import height from '../../../utils/size-variables';

function TransactionHistory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    transaction,
    pageCountTransaction,
    totalPageCountTransaction,
    status,
    filterTransaction,
    tableFilterTransaction,
    totalDataCountTransaction,
  } = useSelector((state) => state.subscription);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getSubscriptions = (counts, filters = {}) => {
    if (status === 'succeed') {
      dispatch(fetchTransactionList({ page: counts, limit: 20, ...filters }));
    }
  };

  const handleScroll = () => {
    getSubscriptions(pageCountTransaction + 1, filterTransaction);
  };

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('transactionID'),
      accessorKey: 'transaction_id',
    },
    {
      header: t('subscriptionId'),
      accessorKey: 'order_id',
    },
    {
      header: t('transactionDate'),
      accessorKey: 'createdAt',
      accessorFn: (originalRow) => new Date(originalRow.createdAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.request_date,
    },
    {
      header: t('type'),
      accessorKey: 'type',
    },
    {
      header: t('price'),
      accessorKey: 'total_price',
      Cell: ({ row }) => row.original.price,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTransactionTableFilter(reduxColumnFiltersSorting));
    dispatch(setTransactionFilter(filteredValues));
    getSubscriptions(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    getSubscriptions(1);
  }, []);
  return (
    <div>
      <DataTable
        header={columns}
        value={transaction}
        status={status}
        totalPageCount={totalPageCountTransaction}
        onFilterOrSortingChange={onFilterOrSortingChange}
        reduxColumnFiltersSorting={tableFilterTransaction}
        isFirstRender={isFirstRender}
        pageCount={pageCountTransaction}
        scrollData={handleScroll}
        totalDataCount={totalDataCountTransaction}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        enable={Object.keys(filterTransaction).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
        height={height.halfTableHeight}
      />
    </div>
  );
}

export default TransactionHistory;
