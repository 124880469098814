import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';

import {
  fetchSnapshotDisplaySignageList,
  setFilter,
  setMuiFilterData,
} from '../../../redux/slices/SnapshotDisplaySignageSlice';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';
import { mimString } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function DevicesList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    rowSelection,
    setRowSelection,
  } = props;

  const {
    displaySignage,
    pageCount,
    totalPageCount,
    status,
    filter,
    muiFilterData,
    totalDataCount,
  } = useSelector((state) => state.snapshotDisplay);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({});

  const getDevices = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchSnapshotDisplaySignageList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getDevices(pageCount + 1, filter);
  };

  const columns = [
    {
      header: t('storeInfo'),
      accessorKey: 'store',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.store.storeName,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOs'),
      accessorKey: 'hardware',
      Cell: ({ row }) => mimString(row.original.hardware),
    },
    {
      header: t('playerVersion'),
      accessorKey: 'player_app_version',
      Cell: ({ row }) => mimString(row.original.player_app_version),
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
      Cell: ({ row }) => mimString(row.original.orientation),
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setMuiFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDevices(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(muiFilterData).length === 0) {
      getDevices(1);
    }
  }, []);

  return (
    <DataTable
      header={columns}
      value={displaySignage}
      status={status}
      onFilterOrSortingChange={onFilterOrSortingChange}
      reduxColumnFiltersSorting={muiFilterData}
      totalPageCount={totalPageCount}
      pageCount={pageCount}
      scrollData={handleScroll}
      totalDataCount={totalDataCount}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
      height={height.formTableHeight}
      enableRowSelection
      columnVisibility={columnVisibility}
      isFirstRender={isFirstRender}
      setColumnVisibility={setColumnVisibility}
      toolbarLeft={(
        <TableHeaderSelectCount
          select={Object.keys(rowSelection).length}
          total={totalDataCount}
        />
      )}
    />
  );
}
DevicesList.propTypes = {
  rowSelection: PropTypes.objectOf().isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default DevicesList;
