import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import DataTable from '../../Table';
import height from '../../../utils/size-variables';
import {
  fetchRentalHistory,
  setTableFilter,
  setFilter,
} from '../../../redux/slices/RentalHistorySlice';

function RentalHistory() {
  const dispatch = useDispatch();

  const {
    rentalHistory,
    pageCount,
    totalPageCount,
    filter,
    tableFilter,
    totalDataCount,
    status,
  } = useSelector((state) => state.rentalHistory);

  console.log(status);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const getRentalHistory = (counts, filters = {}) => {
    dispatch(fetchRentalHistory({ page: counts, limit: 20, ...filters }));
  };

  const handleScroll = () => {
    getRentalHistory(pageCount + 1, filter);
  };

  const columns = [
    {
      header: 'S.No',
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: 'Order Id',
      accessorKey: 'order_id',
    },
    {
      header: 'Date',
      accessorKey: 'transaction_id',
    },
    {
      header: 'Total Devices',
      accessorKey: 'total_device',
      Cell: ({ row }) => row.original.total_device,
    },
    {
      header: 'Price',
      accessorKey: 'price',
      Cell: ({ row }) => row.original.price,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getRentalHistory(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getRentalHistory(1);
    }
  }, []);
  return (
    <div>
      <DataTable
        header={columns}
        value={rentalHistory}
        status={status}
        totalPageCount={totalPageCount}
        onFilterOrSortingChange={onFilterOrSortingChange}
        reduxColumnFiltersSorting={tableFilter}
        isFirstRender={isFirstRender}
        pageCount={pageCount}
        scrollData={handleScroll}
        totalDataCount={totalDataCount}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
        height={height.halfTableHeight}
      />
    </div>
  );
}

export default RentalHistory;
