import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import { BlackCloseButton } from '../../../shared/component/svgIcon';

function ViewStoreImage(props) {
  const { t } = useTranslation();
  const buttonView = false;
  const {
    storeImage,
    setThumbnail,
    indexKey,
    closeModal,
  } = props;
  return (
    <div className="view-store-image">
      <div className="button-thumbnail">
        {buttonView && (
          <Button
            label={t('setAsThumbnail')}
            click={() => setThumbnail(indexKey)}
            classes="success-button"
          />
        )}
        <div onClick={closeModal} role="presentation" className="close-button"><BlackCloseButton /></div>
      </div>
      <img src={storeImage.viewUrl} alt="store_image" />
    </div>
  );
}

ViewStoreImage.propTypes = {
  storeImage: PropTypes.shape({
    viewUrl: PropTypes.string,
    setDefaultThumb: PropTypes.bool,
  }),
  setThumbnail: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  indexKey: PropTypes.string.isRequired,
};

ViewStoreImage.defaultProps = {
  storeImage: {},
};
export default ViewStoreImage;
