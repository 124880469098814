/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import storeLocation from '../../utils/api/store-location';
import { changeDateTimeFormat } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchSnapshotStoreLocationList = createAsyncThunk(
  'storeLocation/fetchSnapshotStoreLocation',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocations(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        area_city: `${val?.area}, ${val?.city}`,
        state_country: `${val?.state}, ${val?.country}`,
        createDate: changeDateTimeFormat(val.createdAt),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  storeLocation: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  muiFilterData: {},
};

export const snapshotStoreLocationSlice = createSlice({
  name: 'snapshotStoreLocation',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setMuiFilterData: (state, action) => {
      state.muiFilterData = action.payload;
    },
    resetSnapshotStoreLocationState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSnapshotStoreLocationList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSnapshotStoreLocationList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.storeLocation = datas;
          } else {
            state.storeLocation.push(...datas);
          }
        }
      })
      .addCase(fetchSnapshotStoreLocationList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setMuiFilterData,
  resetSnapshotStoreLocationState,
} = snapshotStoreLocationSlice.actions;

export default snapshotStoreLocationSlice.reducer;
