import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

function CategoryEdit(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props?.setEditModal(false);
  };
  return (
    <div className="edit-category">
      <div className="form">
        <Input
          icon={' '}
          label={t('category')}
          type="text"
          name="name"
          required
          value={props?.editCategory}
          change={props?.handleChangeEditCategory}
          placeholder={t('enterCategoryName')}
          error={props?.editCategory?.length > 25}
          errorMessage={t('categoryshouldhaveonly25letters')}
        />
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={t('update')}
              click={props?.submit}
              classes="success-button"
              disabled={props?.editCategory === ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CategoryEdit.propTypes = {
  setEditModal: PropTypes.node.isRequired,
  editCategory: PropTypes.string.isRequired,
  handleChangeEditCategory: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

export default CategoryEdit;
