import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../../../shared/component/CircularProgress';

function ContentStatus(props) {
  const { t } = useTranslation();
  const { totalDevice, InstanceActiveDevice } = props;
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (InstanceActiveDevice > 0) {
      const calculatedPercentage = Math.round((InstanceActiveDevice / totalDevice) * 100);
      setPercentage(calculatedPercentage);
    } else {
      setPercentage(0);
    }
  }, [InstanceActiveDevice, totalDevice]);

  return (
    <div className="content-status">
      <div className="progress storage-bar">
        <CircularProgressBar percentage={percentage || 0} />
        <div className="progress-text">
          <h2>{t('aiDevice')}</h2>
          <h3>{InstanceActiveDevice || '0'} / {totalDevice || 0} Devices
          </h3>
        </div>
      </div>
    </div>
  );
}
ContentStatus.propTypes = {
  totalDevice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  InstanceActiveDevice: PropTypes.string.isRequired,
};

export default ContentStatus;
