import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'recharts';

function YAxisLeftTick({ y, payload: { value } }) {
  return (
    <Text x={0} y={y} textAnchor="start" verticalAnchor="middle" style={{ fontSize: '13px' }}>
      {value}
    </Text>
  );
}

YAxisLeftTick.propTypes = {
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
};

YAxisLeftTick.defaultProps = {
  y: 0,
};

export default YAxisLeftTick;
