/* eslint-disable */
import React, { useEffect } from 'react';

function Chatbot() {
    useEffect(() => {
        const host = "https://app.heygen.com";
        const url = host + "/guest/streaming-embed?share=eyJxdWFsaXR5IjoiaGlnaCIsImF2YXRhck5hbWUiOiJqb3NoX2xpdGUzXzIwMjMwNzE0Iiwidm9p%0D%0AY2UiOnsidm9pY2VfaWQiOiJkMGRiOTM4YzYxYzU0YTJiYmExOTdlMzZkZjQwMzc0ZiJ9LCJwcmV2%0D%0AaWV3SW1nIjoiaHR0cHM6Ly9maWxlcy5oZXlnZW4uYWkvYXZhdGFyL3YzLzEwMDYzYzc0M2YxMTQ3%0D%0AMjJhYjY1MzhiMzU5MDVjNTFjXzMwMTMvcHJldmlld190YXJnZXQud2VicCIsIm5lZWRSZW1vdmVC%0D%0AYWNrZ3JvdW5kIjpmYWxzZSwidXNlcm5hbWUiOiIxMWRmOTM3Y2E2ZDM0NzE3YWU3NjBhNzY0NTI3%0D%0AOWNkOCJ9&inIFrame=1";
        const clientWidth = document.body.clientWidth;

        const wrapDiv = document.createElement("div");
        wrapDiv.id = "heygen-streaming-embed";
        const container = document.createElement("div");
        container.id = "heygen-streaming-container";

        const stylesheet = document.createElement("style");
        stylesheet.innerHTML = `
          #heygen-streaming-embed {
            z-index: 9999;
            position: fixed;
            width: 100%;
            height: 100vh;
            border: 2px solid #fff;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
            transition: all linear 0.1s;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
          }
          #heygen-streaming-embed.show {
            opacity: 1;
            visibility: visible;
          }
          #heygen-streaming-embed.expand {
            ${clientWidth < 540 ? "height: 100vh; width: 100%; left: 50%; transform: translateX(-50%);" : "height: 100vh; width: 100%;"}
            border: 0;
            border-radius: 8px;
          }
          #heygen-streaming-container {
            width: 100%;
            height: 100vh;
          }
          #heygen-streaming-container iframe {
            width: 100%;
            height: 100vh;
            border: 0;
          }
        `;

        const iframe = document.createElement("iframe");
        iframe.allowFullscreen = false;
        iframe.title = "Streaming Embed";
        iframe.role = "dialog";
        iframe.src = url;

        let visible = false;
        let initial = false;

        window.addEventListener("message", (e) => {
            if (e.origin === host && e.data && e.data.type && "streaming-embed" === e.data.type) {
                if ("init" === e.data.action) {
                    initial = true;
                    wrapDiv.classList.toggle("show", initial);
                } else if ("show" === e.data.action) {
                    visible = true;
                    wrapDiv.classList.toggle("expand", visible);
                } else if ("hide" === e.data.action) {
                    visible = false;
                    wrapDiv.classList.toggle("expand", visible);
                }
            }
        });

        container.appendChild(iframe);
        wrapDiv.appendChild(stylesheet);
        wrapDiv.appendChild(container);
        document.body.appendChild(wrapDiv);

        return () => {
            document.body.removeChild(wrapDiv);
        };
    }, []);

    return null;
}

export default Chatbot;
