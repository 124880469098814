/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getTimeDifference,
  getResolutionCategory,
} from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';
import schedule from '../../utils/api/schedule';

export const fetchDisplaySignageList = createAsyncThunk(
  'displaySignage/fetchDisplaySignage',
  async (data, { dispatch }) => {
    const response = await schedule.getDevices(data).then((response1) => {
      const displaySignageData = response1.data.results.map((val) => ({
        ...val,
        row_selection: Boolean(val?.orientation_flag && val?.version_flag),
        store_info: `${val?.store?.storeName}, ${val?.store?.city}`,
        store_name: `${val?.store?.storeName}`,
        store_location: `${val?.store?.city}`,
        store: { ...val.store, id: val.store._id },
        resolution: val?.display_width_pixels
          && getResolutionCategory(val?.display_width_pixels, val?.display_height_pixels),
        screen_size: val?.screen_size ? `${val?.screen_size}''` : '',
        signage_information: [{
          name: 'Brand',
          value: val?.brand,
        },
        {
          name: 'Device Type',
          value: val?.device_type,
        },
        {
          name: 'Orientation',
          value: val?.orientation,
        }],
        id: val._id,
        lastPollTime: (new Date() - new Date(val?.lastPollAt)) <= 30 * 60 * 1000,
        timeDifference: val?.lastPollAt ? ` 
          Active ${getTimeDifference(val?.lastPollAt)}
        ` : 'Not found',
      }));
      return { ...response1?.data, results: displaySignageData, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  displaySignage: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  error: {},
  filter: {},
  tableFilter: {},
};

export const scheduleDisplaySignageSlice = createSlice({
  name: 'scheduleDisplaySignage',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetScheduleDisplaySignageState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDisplaySignageList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplaySignageList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const datas = action?.payload?.results;
          // if (datas.length > 0) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action?.payload?.page === 1) {
            state.displaySignage = datas;
          } else {
            state.displaySignage.push(...datas);
          }
        }
      });
  },
});

export const {
  singleDisplaySignage,
  setFilter,
  setTableFilter,
  resetScheduleDisplaySignageState,
} = scheduleDisplaySignageSlice.actions;

export default scheduleDisplaySignageSlice.reducer;
