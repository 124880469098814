/* eslint no-underscore-dangle: 0 */
import React, { useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import { CloseIconWithoutBorder, LayoutEditIcon } from '../../../../shared/component/svgIcon';
import galleryImage from '../../../../assets/images/no-image.png';
import youtubeIcon from '../../../../assets/images/youtube-icon.png';
import pdfIcon from '../../../../assets/images/pdf-icon.png';
import urlIcon from '../../../../assets/images/url-image.png';
import playlistIcon from '../../../../assets/images/playlist-icon.png';
import ModalBox from '../../../../shared/component/ModalBox';
import ContentRearrange from './ContentRearrange';
import WeatherPreview from './Widgets/WeatherPreview';
import ClockPreview from './Widgets/ClockPreview';
import RSSPreview from './Widgets/RSSPreview';

function DroppableContainer(props) {
  const {
    layout,
    gridCount,
    dropOverContainer,
    setAvailableLayouts,
  } = props;
  const { setNodeRef } = useDroppable({
    id: layout._id,
  });

  const [openRearrangePopup, setOpenRearrangePopup] = useState(false);

  const handleRemoveContent = (indexNum) => {
    setAvailableLayouts((prevValue) => prevValue.map((eachLayout) => {
      if (eachLayout._id === layout._id) {
        return {
          ...eachLayout,
          contents: eachLayout.contents.filter((_, index) => index !== indexNum),
        };
      }
      return eachLayout;
    }));
  };

  return (
    <div
      className="droppable-wrap"
      style={{
        gridColumnStart: layout?.grid_column_start,
        gridColumnEnd: layout?.grid_column_end,
        gridRowStart: layout?.grid_row_start,
        gridRowEnd: layout?.grid_row_end,
      }}
    >
      <ModalBox
        status={openRearrangePopup}
        closeModal={() => setOpenRearrangePopup(false)}
        modalView={{
          title: layout?.name,
          content: <ContentRearrange
            layout={layout}
            setAvailableLayouts={setAvailableLayouts}
            closeModal={() => setOpenRearrangePopup(false)}
          />,
        }}
        notification={false}
      />
      {!(layout?.file_types?.[0] === 'weather'
        || layout?.file_types?.[0] === 'clock'
        || layout?.file_types?.[0] === 'rss')
        ? (
          <div
            ref={setNodeRef}
            className="droppable-container"
            style={{
              '--droppable-border': dropOverContainer === layout?._id
                ? '2px solid #0A7BCC'
                : '1px solid #FFFFFF',
            }}
          >
            <div className="droppable-container-header">
              <div className="header-content">
                <h5>{layout?.name}</h5>
                <div className="accepted-types-container">
                  <span>Content Type:</span>
                  {layout?.file_types?.map((type, key) => (
                    <span>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                      {key !== (layout.file_types.length - 1) && ','}
                    </span>
                  ))}
                </div>
              </div>
              {layout?.contents?.length > 1
                && (
                  <div
                    className="header-edit-wrap"
                    role="presentation"
                    onClick={() => setOpenRearrangePopup(true)}
                  >
                    <LayoutEditIcon />
                    <span>Edit</span>
                  </div>
                )}
            </div>
            {layout?.contents?.length > 0
              ? (
                <div className="dropped-contents-wrap">
                  {layout?.contents?.map((content, index) => (
                    <div className="dropped-content">
                      {
                        (() => {
                          let contentToDisplay;
                          if (content?.file_type?.includes('image') || content?.file_type?.includes('template')) {
                            contentToDisplay = (
                              <img
                                src={content?.thumbnailSignedUrls?.px_600x400 || content?.signedUrl}
                                alt=""
                              />
                            );
                          } else if (content?.file_type?.includes('video')) {
                            contentToDisplay = (
                              <video
                                src={content?.signedUrl}
                                controls
                                poster={content?.thumbnailSignedUrls?.px_600x400}
                                preload="none"
                              >
                                <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                              </video>
                            );
                          } else if (content?.file_type?.includes('youtube')) {
                            contentToDisplay = (
                              <div className="preview-with-icon">
                                <img src={youtubeIcon} alt="" className="preview-icon" />
                                <span>{content?.name}</span>
                              </div>
                            );
                          } else if (content?.file_type?.includes('pdf')) {
                            contentToDisplay = (
                              <div className="preview-with-icon">
                                <img src={pdfIcon} alt="" className="preview-icon" />
                                <span>{content?.name}</span>
                              </div>
                            );
                          } else if (content?.file_type === 'url') {
                            contentToDisplay = (
                              <div className="preview-with-icon">
                                <img src={urlIcon} alt="" className="preview-icon" />
                                <span>{content?.name}</span>
                              </div>
                            );
                          } else if (content?.file_type?.includes('playlist')) {
                            contentToDisplay = (
                              <div className="preview-with-icon">
                                <img src={playlistIcon} alt="" className="preview-icon" />
                                <span>{content?.name}</span>
                              </div>
                            );
                          }
                          return contentToDisplay;
                        })()
                      }
                      <div
                        className="close-icon"
                        role="presentation"
                        onClick={() => handleRemoveContent(index)}
                      >
                        <CloseIconWithoutBorder
                          height="16"
                          width="16"
                          color="#000000"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="dropped-gallery">
                  <img src={galleryImage} alt="" className="gallery-image" />
                  <span>Content {gridCount + 1}</span>
                </div>
              )}
          </div>
        ) : (
          <div className="widget-wrap">
            <h5>{layout?.name}</h5>
            {
              (() => {
                let widgetPreview;
                if (layout?.file_types?.[0] === 'weather') {
                  widgetPreview = (
                    <WeatherPreview
                      gridName={layout?.partition_id}
                      selectedWidget={layout?.selectedWidget}
                    />
                  );
                } else if (layout?.file_types?.[0] === 'clock') {
                  widgetPreview = (
                    <ClockPreview
                      gridName={layout?.partition_id}
                      selectedWidget={layout?.selectedWidget}
                    />
                  );
                } else if (layout?.file_types?.[0] === 'rss') {
                  widgetPreview = (
                    <RSSPreview
                      gridName={layout?.partition_id}
                      selectedWidget={layout?.selectedWidget}
                    />
                  );
                }
                return widgetPreview;
              })()
            }
          </div>
        )}
    </div>
  );
}

DroppableContainer.propTypes = {
  gridCount: PropTypes.number.isRequired,
  dropOverContainer: PropTypes.string.isRequired,
  setAvailableLayouts: PropTypes.node.isRequired,
  layout: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    partition_id: PropTypes.string.isRequired,
    grid_column_start: PropTypes.number.isRequired,
    grid_column_end: PropTypes.number.isRequired,
    grid_row_start: PropTypes.number.isRequired,
    grid_row_end: PropTypes.number.isRequired,
    contents: PropTypes.arrayOf().isRequired,
    file_types: PropTypes.arrayOf().isRequired,
    selectedWidget: PropTypes.objectOf(),
  }).isRequired,
};

export default DroppableContainer;
