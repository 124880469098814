import {
  get,
  patch,
  post,
} from './base/index';

export default {
  getPreferenceList: () => get(`${process.env.REACT_APP_API_URL}cms/v1/company-preference`),
  addPreferenceList: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/company-preference/`, data, true),
  updatePreference: (data = {}) => patch(`${process.env.REACT_APP_API_URL}cms/v1/company-preference/`, { ...data }),
  deletePreference: (data = {}) => {
    const body = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/company-preference/remove-image`, body);
  },
};
