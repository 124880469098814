import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function IFrameComponent(props) {
  const {
    htmlUrl,
    jsUrl,
    replacements,
    title,
  } = props;

  const [htmlContent, setHtmlContent] = useState('');
  const [scriptContent, setScriptContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        // Fetch HTML
        const response = await fetch(htmlUrl);
        let html = await response.text();
        // Fetch Script
        const scriptResponse = await fetch(jsUrl);
        let script = await scriptResponse.text();

        replacements.forEach(([oldStr, newStr]) => {
          html = html.replace(new RegExp(oldStr, 'g'), newStr);
          script = script.replace(new RegExp(oldStr, 'g'), newStr);
        });
        setHtmlContent(html);
        setScriptContent(script);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    if (htmlContent && scriptContent) {
      const iframe = document.getElementById(title);
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(htmlContent);
      iframeDoc.write(`<script>${scriptContent}</script>`);
      iframeDoc.close();
    }
  }, [htmlContent, scriptContent]);

  return <iframe title={title} id={title} style={{ width: '100%', height: '100%', border: 'none' }} />;
}

IFrameComponent.propTypes = {
  htmlUrl: PropTypes.string.isRequired,
  jsUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  replacements: PropTypes.arrayOf().isRequired,
};

IFrameComponent.defaultProps = {
  title: '',
};

export default IFrameComponent;
