import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from './Button';

function DeleteModal(props) {
  const { t } = useTranslation();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // height: '30%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.2px solid #bab8b8',
    boxShadow: 24,
    borderRadius: '10px',
    padding: '34px',
    overflowY: 'scroll',
  };
  return (
    <Modal
      open={props?.status}
      onClose={() => props?.closeModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal-box">
          <div className="modal-header-title">
            <h1 className="modal-title">{props?.title}</h1>
            <p className="modal-body-content">{props?.subTitle}</p>
            <p className="modal-body-content">{props?.subTitle1}</p>
          </div>
          <div className="modal-delete-buttons">
            <Button
              id="cancel"
              classes="default-button"
              click={() => props?.closeModal(false)}
              label={!props?.buttonCancel ? t('back') : props?.buttonCancel}
            />
            <Button
              id={props?.buttonLabel}
              classes="danger-button"
              click={props?.okFunction}
              label={props?.buttonLabel}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

DeleteModal.propTypes = {
  status: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  okFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle1: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonCancel: PropTypes.string,
};
DeleteModal.defaultProps = {
  subTitle: '',
  status: false,
  subTitle1: '',
  buttonCancel: '',
};

export default DeleteModal;
