import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Avatar, Typography } from '@mui/material';

export default function DropDownMenu(props) {
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusCheck = (
    id,
    status,
  ) => (props?.statusCheck ? props?.statusCheck(id, status) : false);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={props?.tooltip}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {props?.icon}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.25))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: currentLanguageCode === 'ar' ? 'none' : '14px',
              left: currentLanguageCode === 'ar' ? '14px' : 'none',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props?.userProfile?.name
          && (
            <>
              <MenuItem
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 2,
                  cursor: 'default',
                  pointerEvents: 'none !important',
                  maxWidth: '270px',
                  // overflow: 'hidden',
                }}
              >
                <Avatar sx={{ width: 32, height: 31 }} src={props?.userProfile?.icon} />
                <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Typography
                    color="#212B36"
                    sx={{ fontWeight: '500' }}
                  >
                    {props?.userProfile?.name}
                  </Typography>
                  <Typography
                    color="#717880"
                    sx={{
                      fontWeight: '400',
                      whiteSpace: 'nowrap !important',
                      overflow: 'hidden !important',
                      textOverflow: 'ellipsis !important',
                    }}
                  >
                    {props?.userProfile?.email}
                  </Typography>
                </div>
              </MenuItem>
              <Divider sx={{ m: '0 !important' }} />
            </>
          )}
        {props?.action?.map((item, index) => {
          let value;
          if (props?.dropdown === 'tableForm') {
            value = (
              <div key={item.id || index}>
                <MenuItem
                  onClick={() => item?.function(item?.sendFullDetails ? props?.value : props?.value?.id)}
                  disabled={item?.disabled || statusCheck(item?.id, props?.value?.status)}
                >
                  <p className="menu-text" id={item?.id}>{item?.name}</p>
                </MenuItem>
                {props?.divider && <Divider />}
              </div>
            );
          } else if (props?.dropdown === 'dropdown') {
            value = (
              <div key={item.id || index}>
                {item.key === 'logout' && <Divider />}
                <MenuItem
                  key={item.id || index}
                  onClick={() => item?.function(item?.code)}
                  disabled={item?.disabled || statusCheck(item?.name, props?.value?.status)}
                  sx={{ padding: props?.customPadding ? '12px 16px' : '' }}
                >
                  <p className="menu-text" style={{ color: item.key === 'logout' ? '#E8212E' : '' }}>{item?.name}</p>
                </MenuItem>
                {props?.divider && <Divider />}
              </div>
            );
          }
          return value;
        })}
      </Menu>
    </div>
  );
}
DropDownMenu.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.string.isRequired,
  action: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
      function: PropTypes.func.isRequired,
      sendFullDetails: PropTypes.bool,
      disabled: PropTypes.bool,
      // icon: PropTypes.string,
      // email: PropTypes.string,
    }),
  ).isRequired,
  userProfile: PropTypes.objectOf,
  dropdown: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string,
    template_content: PropTypes.arrayOf,
  }),
  statusCheck: PropTypes.func,
  divider: PropTypes.bool,
  customPadding: PropTypes.bool,
};
DropDownMenu.defaultProps = {
  value: {
    id: '',
    status: '',
  },
  tooltip: '',
  divider: false,
  statusCheck: () => false,
  userProfile: {},
  customPadding: false,
};
