/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import contentLayout from '../../utils/api/content-layout';
import { setErrorNotification } from './NotificationSlice';

export const fetchSavedContentLayout = createAsyncThunk(
  'savedContentLayout/fetchSavedContentLayout',
  async (data, { dispatch }) => {
    const response = await contentLayout.getSavedContentLayout(data)
      .then((res) => ({
        ...res?.data,
        results: res?.data?.results?.map((eachLayout) => ({
          ...eachLayout,
          id: eachLayout?._id,
        })),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  savedContentLayout: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeed',
  filter: {},
  tableFilter: {},
};

export const savedContentLayoutSlice = createSlice({
  name: 'savedContentLayout',
  initialState,
  reducers: {
    // Reducer to add the new content to top of the content list
    createSavedLayout: (state, action) => {
      const newSavedLayout = action.payload; // savedContentLayout to be added at the top
      const savedLayoutList = [
        {
          ...newSavedLayout,
          id: newSavedLayout._id,
        },
        ...state.savedContentLayout, // Existing savedContentLayout
      ];
      state.savedContentLayout = savedLayoutList; // update the savedContentLayout list
    },
    // Reducer to update the savedLayout by ID.
    updateSavedLayout: (state, action) => {
      const updatedSavedLayout = action.payload; // savedLayout to be added at the top
      const savedLayoutList = state.savedContentLayout; // Assign the existing savedLayout to another variable
      // Find the index of the updated savedLayout by ID
      const index = savedLayoutList.findIndex((layout) => layout._id === updatedSavedLayout._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        savedLayoutList[index] = {
          ...updatedSavedLayout,
          id: updatedSavedLayout._id,
        };
      }
      state.savedContentLayout = savedLayoutList; // Finally update the content list
    },
    deleteSavedLayout: (state, action) => {
      const savedLayoutList = state.savedContentLayout;

      // Find the index to delete by ID
      const index = savedLayoutList.findIndex((savedLayout) => savedLayout.id === action.payload);
      // If the index found, remove that index from list of savedContentLayout
      if (index !== -1) {
        savedLayoutList.splice(index, 1);
      }
      state.savedContentLayout = savedLayoutList; // Update the savedContentLayout state after deleted
    },
    resetSavedContentLayoutSlice: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSavedContentLayout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSavedContentLayout.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.savedContentLayout = fetchedData;
          } else {
            const finalData = [...state.savedContentLayout];
            // Add fetchedData to finalData, but only if the id is not already present
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.savedContentLayout = finalData;
          }
        }
        state.status = 'succeed';
      })
      .addCase(fetchSavedContentLayout.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  createSavedLayout,
  updateSavedLayout,
  deleteSavedLayout,
  resetSavedContentLayoutSlice,
} = savedContentLayoutSlice.actions;

export default savedContentLayoutSlice.reducer;
