import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ImageCarousel(props) {
  const { images } = props;
  const [position, setPosition] = useState(0);
  const changeImage = (type) => {
    if (type === 'sub' && position - 1 >= 0) {
      setPosition((prevValue) => prevValue - 1);
    }
    if (type === 'add' && position + 1 < images.length) {
      setPosition((prevValue) => prevValue + 1);
    }
  };
  return (
    <div className="image-carousel-session">
      <div className="image-carousel">
        <img src={images[position].signedUrl} alt="store_image" />
      </div>
      {
        images.length > 1 && (
          <div className="button-group">
            <div>
              {
                position > 0 && (
                  <div
                    className="left-button"
                    onClick={() => changeImage('sub')}
                    role="presentation"
                  >
                    {'<'}
                  </div>
                )
              }
            </div>
            <div>
              {
                position < images.length - 1 && (
                  <div
                    className="right-button"
                    onClick={() => changeImage('add')}
                    role="presentation"
                  >
                    {'>'}
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  );
}

ImageCarousel.propTypes = {
  images: PropTypes.string.isRequired,
};

export default ImageCarousel;
