import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ModalBox from '../../../shared/component/ModalBox';

import TopContent from '../../../shared/component/TopContent';
import ContentView from '../../../shared/component/ContentView';

import calender from '../../../assets/icons/calendar.png';
import info from '../../../assets/icons/info.png';
import time from '../../../assets/icons/timer.png';
import video from '../../../assets/icons/video-square.png';
import image from '../../../assets/icons/gallery.png';

import {
  fetchSingleSchedule,
  fetchSingleSchedules,
} from '../../../redux/slices/ScheduleSlice';
import HeatMapView from '../../../shared/component/HeatMapView';
import { checkFeatures, checkPermission } from '../../../utils/helpers';

function ScheduleView() {
  const { t } = useTranslation();
  const heatMap = false;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { schedules, newSchedule } = useSelector((state) => state.schedules);

  const [open, setOpen] = useState(false);
  const [modalView, setModalView] = useState({ content: '', title: '' });

  const addSchedule = () => {
    navigate(`../edit/${params?.id}?type=edit`);
  };

  const editFunction = (data) => {
    const viewData = [
      {
        name: t('contentName'),
        value: data?.name,
      },
      {
        name: t('contentType'),
        value: data?.file_type,
      },
      {
        name: t('orientation'),
        value: data?.orientation,
      },
      {
        name: t('totalSize'),
        value: data?.file_size,
      },
      {
        name: t('totalDuration'),
        value: data?.duration,
      },
    ];
    setOpen(true);
    setModalView({ content: <ContentView data={viewData} />, title: data?.name });
  };

  const viewDetails = (data) => {
    const viewData = [
      {
        name: t('deviceName'),
        value: data?.name,
      },
      {
        name: t('deviceType'),
        value: data?.device_type,
      },
      {
        name: t('hardware'),
        value: data?.hardware,
      },
      {
        name: t('devicePixels'),
        value: `${data?.display_width_pixels} X ${data?.display_height_pixels}`,
      },
      {
        name: t('orientation'),
        value: data?.orientation,
      },
    ];
    setOpen(true);
    setModalView({ content: <ContentView data={viewData} />, title: data?.name });
  };

  const viewHeatMap = () => {
    setOpen(true);
    setModalView({ content: <HeatMapView data={newSchedule} />, title: 'Device Available Days' });
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (schedules.length === 0) {
      dispatch(fetchSingleSchedule(params?.id));
    } else {
      dispatch(fetchSingleSchedules(params?.id));
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={[t('schedule'), newSchedule?.name]}
          button={
            checkPermission('editSchedule')
            && checkFeatures('schedule', 'edit')
            && newSchedule?.status !== 'completed'
            && newSchedule?.status !== 'cancelled'
          }
          buttonLabel={t('editSchedule')}
          buttonClass="default-button button-outline-success-1"
          click={addSchedule}
        />
        <ModalBox status={open} modalView={modalView} closeModal={closeModal} />
        <div className="schedule-view">
          <div className="schedule-content-area">
            <div className="schedule-details">
              <div className="schedule-details-list">{t('scheduleName')} : {newSchedule?.name}</div>
              <div className="schedule-details-list">
                <div className="image"><img alt="image_icon" src={calender} /></div>
                <div className="date-text">
                  {newSchedule?.scheduleDate}
                  {heatMap && (
                    <div className="image_info" role="presentation" onClick={viewHeatMap}>
                      <img alt="image_icon" src={info} />
                    </div>
                  )}
                </div>
              </div>
              <div className="schedule-details-list">
                <div className="image"><img alt="image_icon" src={time} /></div>
                {newSchedule?.scheduledTime} ({newSchedule?.time_zone})
              </div>
              <div className="schedule-details-list">{t('scheduleDuration')} : {newSchedule?.hour}</div>
            </div>
          </div>
          <div className="schedule-content">
            <div className="my-content">
              <div className="my-content-area">
                <div className="contents-header">
                  <h3>{t('contents')}</h3>
                  <div className="tag">
                    <img alt="icon" src={image} />
                    {newSchedule && newSchedule.image_count} /
                    <img alt="icon" src={video} />
                    {newSchedule && newSchedule.video_count}
                  </div>
                </div>
                <div className="contents-body">
                  <ul>
                    {newSchedule && newSchedule?.contents_to_view && newSchedule?.contents_to_view?.map((val) => (
                      <li role="presentation" onClick={() => editFunction(val)}>
                        <img alt="icon" src={val.file_type === 'video/mp4' ? video : image} />{val.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <hr />
                <div className="contents-header">
                  <h3>{t('playlist')}</h3>
                  <div className="tag">
                    {newSchedule?.playlists_to_view?.length}
                  </div>
                </div>
                <div className="contents-body">
                  <ul className="full-box">
                    {newSchedule?.playlists_to_view?.map((val) => (
                      <li className="full-box-list">{val.name}</li>
                    ))}
                  </ul>
                </div>
                <div className="contents-header">
                  <h3>{t('widgets')}</h3>
                  <div className="tag">
                    {newSchedule && newSchedule?.widgets_to_view && newSchedule?.widgets_to_view?.length}
                  </div>
                </div>
                <div className="contents-body">
                  <ul className="full-box">
                    {newSchedule && newSchedule?.widgets_to_view && newSchedule?.widgets_to_view?.map((val) => (
                      // <Link to={`/admin/apps/rssfeed-template/edit-rssfeed/${val.id}`}>
                      <li className="full-box-list">{val.name}</li>
                      // </Link>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="my-content">
              <div className="my-content-area">
                <div className="contents-header">
                  <h3>{t('devices')}</h3>
                  <div className="tag">
                    {newSchedule && newSchedule?.devices_list && newSchedule?.devices_list?.length}
                  </div>
                </div>
                <div className="contents-body">
                  <ul>
                    {newSchedule && newSchedule?.devices_list && newSchedule?.devices_list?.map((val) => (
                      <li role="presentation" onClick={() => viewDetails(val)}>{val.name}</li>
                    ))}
                  </ul>
                </div>
                <hr />
                <div className="contents-header">
                  <h3>{t('deviceGroups')}</h3>
                  <div className="tag">
                    {newSchedule && newSchedule?.device_groups_list && newSchedule?.device_groups_list.length}
                  </div>
                </div>
                <div className="contents-body">
                  <ul className="full-box">
                    {newSchedule && newSchedule?.device_groups_list && newSchedule?.device_groups_list.map((val) => (
                      <Link to={`/admin/display-groups/view/${val.id}`}>
                        <li className="full-box-list">{val.name}</li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleView;
