/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import templates from '../../utils/api/templates';
import { setErrorNotification } from './NotificationSlice';

export const savedTemplateList = createAsyncThunk(
  'savedTemplates',
  async (data, { dispatch }) => {
    const response = await templates.getSavedTemplates(data)
      .then((res) => ({ ...res.data, code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  allTemplates: [],
  templateContent: {},
  savedTemplateData: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  signedUrl: [],
  tabView: 'tab-1',
  contentUploadType: 'create',
  content: {},
  defaultTemplate: {},
};

export const templateSlice = createSlice({
  name: 'allTemplates',
  initialState,
  reducers: {
    getAllTemplates: (state, action) => {
      state.allTemplates = action.payload;
    },
    getCurrentTemplate: (state, action) => {
      state.templateContent = action.payload;
    },
    updateCurrentTemplate: (state, action) => {
      state.templateContent = action.payload;
    },
    defaultTemplateData: (state, action) => {
      state.defaultTemplate = action.payload;
    },
    // Reducer to update the template by ID.
    createOrUpdateTemplate: (state, action) => {
      const updateTemplate = action.payload; // template to be added at the top
      const templateList = state.savedTemplateData; // Assign the existing template to another variable
      // Find the index of the updated content by ID
      const index = templateList.findIndex((template) => template._id === updateTemplate._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        templateList.splice(index, 1);
      }
      const newTemplateList = [
        updateTemplate,
        ...state.savedTemplateData,
      ];
      state.savedTemplateData = newTemplateList;
    },

    updateSavedTemplate: (state, action) => {
      state.savedTemplateData = action.payload;
    },

    getSignedUrl: (state, action) => {
      state.signedUrl = action.payload;
    },
    resetSignedUrl: (state) => {
      state.signedUrl = [];
    },
    resetTemplateState: (state) => {
      Object.assign(state, initialState);
    },
    setPage: (state, action) => {
      state.tabView = action.payload;
    },
    contentUpload: (state, action) => {
      state.contentUploadType = action.payload;
    },
    setSingleContent: (state, action) => {
      state.content = action.payload;
    },
    clearPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    resetTemplateContent: (state) => {
      state.content = {};
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(savedTemplateList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(savedTemplateList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          let finalData = [];
          if (action.payload.page === 1) {
            finalData = fetchedData;
          } else {
            finalData = [...state.savedTemplateData, ...fetchedData];
          }
          // Add fetchedData to finalData, but only if the id is not already present
          state.savedTemplateData = finalData;
        }
        state.status = 'succeeded';
      })
      .addCase(savedTemplateList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  getCurrentTemplate,
  updateCurrentTemplate,
  getAllTemplates,
  getTemplates,
  createOrUpdateTemplate,
  getSignedUrl,
  resetSignedUrl,
  resetTemplateState,
  setPage,
  updateSavedTemplate,
  contentUpload,
  setSingleContent,
  defaultTemplateData,
  clearPageCount,
  resetTemplateContent,
} = templateSlice.actions;

export default templateSlice.reducer;
