import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import DataTable from '../../Table';

function PreviewStore(props) {
  const { t } = useTranslation();
  const [tableValue, setTableValue] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = [
    {
      header: t('storeName'),
      accessorKey: 'name',
    },
    {
      header: t('areaAndCity'),
      accessorKey: 'area_city',
    },
    {
      header: t('state'),
      accessorKey: 'state',
    },
    {
      header: t('country'),
      accessorKey: 'country',
    },
  ];

  const back = () => {
    props?.back(props?.data);
  };

  const submit = () => {
    props?.closeModal(false);
    props?.selectData('location', props?.data);
  };
  useEffect(() => {
    setTableValue(props?.data?.stores_list);
  }, [props?.data]);

  return (
    <div className="contents-list">
      <div className="table-response">
        <DataTable
          header={columns}
          value={tableValue}
          status="succeed"
          onFilterOrSortingChange={() => { }}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount=""
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setSorting={setSorting}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          rowSelection={contentSelection}
          setRowSelection={setContentSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          sorting={sorting}
          enable={false}
          height="50vh"
          disableFilter
          disableSorting
          disableFullScreen
          disableFullScreenToggle
        // enableRowOrdering
        // setValue={setValue}
        />
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('continue')} click={submit} classes="success-button" />
        </div>
      </div>
    </div>
  );
}

PreviewStore.propTypes = {
  data: PropTypes.objectOf().isRequired,
  back: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectData: PropTypes.func.isRequired,
};

export default PreviewStore;
