/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
}
  from '@mui/material';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import arrowLeftIcon from '../../../assets/icons/arrow-left.png';
import Button from '../../../shared/component/Button';
import templateDelIcon from '../../../assets/images/Templates/template-del-icon.png';
import DeleteModal from '../../../shared/component/DeleteModal';
import {
  CategoryIcon,
  CategoryActiveIcon,
  HideIcon,
  SettingsActiveIcon,
  SettingsIcon,
  ShowIcon,
  TemplateEditIcon,
} from '../../../shared/component/svgIcon';
import ModalBox from '../../../shared/component/ModalBox';
import CategoryEdit from './CategoryEdit';
import TitleEdit from './TitleEdit';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import StyleRenderPage from './StyleRenderPage';
import SaveAndTagsPopup from './SaveAndTagsPopup';

function TableTemplateEditor(props) {
  const [data, setData] = useState([]);
  // const [switchPreview, setSwitchPreview] = useState(false);
  const [index, setIndex] = useState(0);
  const [showStyle, setShowStyle] = useState(false);
  const [disable, setDisable] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemsIndex, setItemsIndex] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [editMode, setEditMode] = useState({});
  const [cellValue, setCellValue] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [editCategory, setEditCategory] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [editSubTitle, setEditSubTitle] = useState('');
  const [submitDisable, setSubmitDisable] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const inputCategory = useRef(null);
  const tableRefs = useRef(null);

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            overflow: 'visible',
            width: '100%',
            '&.Mui-selected': {
              // backgroundColor: theme.palette.secondary.main,
              color: '#212B36 !important',
              // borderRadius: "25px"
            },
          },
        },
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'none',
        borderRadius: '0px',
      },
    },
  });

  let parsingLocalData;
  useEffect(() => {
    if (props?.templateData && Object.keys(props?.templateData).length > 0) {
      localStorage.setItem('templateEditData', JSON.stringify(props?.templateData));
    }
    const localData = localStorage.getItem('templateEditData');

    if (localData) {
      parsingLocalData = JSON.parse(localData);

      if (Object.keys(props?.templateData).length === 0) {
        setData([parsingLocalData]);
      } else {
        setData([{ ...props?.templateData }]);
      }
    } else if (props?.templateData && Object.keys(props?.templateData).length > 0) {
      setData([{ ...props?.templateData }]);
    }

    if (!checkPermission('getTemplatesList') || !checkFeatures('templates', 'create')) {
      setDisable(true);
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  useEffect(() => () => localStorage.removeItem('templateEditData'), []);

  const onEditTitle = () => {
    setTitleModal(true);
    const newData = JSON.parse(JSON.stringify(data));
    const titleName = newData?.[0]?.template_title;
    const subTitleName = newData?.[0]?.template_sub_title;
    setEditTitle(titleName);
    setEditSubTitle(subTitleName);
  };

  const handleTitleChange = (name, changeValue) => {
    setEditTitle(changeValue);
  };

  const handleSubTitleChange = (name, changeValue) => {
    setEditSubTitle(changeValue);
  };
  const submitTitle = () => {
    const templateNumber = data?.[0].templateNumber;
    let isValid = false;
    if (templateNumber === 1 || templateNumber === 2) {
      isValid = editTitle.length <= 20;
    } else {
      isValid = editTitle.length <= 20 && editSubTitle.length <= 25;
    }
    if (isValid) {
      const newData = JSON.parse(JSON.stringify(data));
      newData[0].template_title = editTitle;
      newData[0].template_sub_title = editSubTitle;
      setData(newData);
      setTitleModal(false);
    }
  };

  const handleFileNameChange = (dataIndex, event) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[dataIndex].template_name = event.target.value;
    setData(newData);
  };

  const handleAddCategory = () => {
    setAddCategory(true);
    inputCategory?.current?.focus();
  };

  useEffect(() => {
    if (addCategory) {
      inputCategory.current?.focus();
    }
  }, [addCategory]);

  const handleCategoryChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleBlurCategory = () => {
    if (categoryName.length <= 25) {
      const newData = JSON.parse(JSON.stringify(data));
      // Create a shallow copy of the items array
      const updatedItems = [...(newData?.[0]?.template_contents?.items ?? [])];
      // Push a new item to the copied array
      if (categoryName) {
        // // Update the state with the new data
        const newItem = {
          category: categoryName,
          details: [{ id: 0, name: '', desc: '' }],
          pricing: [{ p1: '', p2: '', p3: '' }],
        };
        updatedItems?.push(newItem);
        // Update the template_contents.items with the updatedItems array
        newData[0].template_contents.items = updatedItems;
        setData(newData); // Update the state with the new data
        // setAddedCategory(true);
        setIndex(updatedItems.length - 1);
      }
      setAddCategory(false);
      setCategoryName('');
    }
  };

  const onHideCategory = (value) => {
    // setHideCategory((prev) => !prev);
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    const newItems = [...(newData?.[0]?.template_contents.items ?? [])]; // Copy the items array
    if (newItems[index]) {
      if (value === 'hide') {
        newItems[index].hide = true; // Add the hide property
        setSubmitDisable('');
      } else if (value === 'show') {
        newItems[index].hide = false; // Add the hide property
      }
      newData[0].template_contents.items = newItems; // Set the updated items array back to the data
      setData(newData); // Update the state with the new data
    }
  };

  const onEditCategory = () => {
    setEditModal(true);
    const newData = JSON.parse(JSON.stringify(data));
    const categoryEditName = newData?.[0]?.template_contents?.items?.[index].category;
    setEditCategory(categoryEditName);
  };

  const handleChangeEditCategory = (name, changeValue) => {
    setEditCategory(changeValue);
  };

  const submit = () => {
    if (editCategory.length <= 25) {
      const newData = JSON.parse(JSON.stringify(data));
      const newItems = [...(newData?.[0]?.template_contents.items ?? [])]; // Copy the items array
      newItems[index].category = editCategory;
      newData[0].template_contents.items = newItems;
      setData(newData);
      setEditModal(false);
    }
  };

  const handleCellClick = (itemIndex, field) => {
    setEditMode({ itemIndex, field });
    setCellValue(data[0]?.template_contents?.items?.[index]?.details[itemIndex][field] || '');
  };

  const handleCellBlur = (itemIndex, field) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData[0].template_contents.items[index].details[itemIndex][field] = cellValue;
    setData(newData);
    // Exit edit mode
    setEditMode({});
  };

  const handleInputChange = (event) => {
    if ((editMode?.field === 'name' && event.target.value.length <= 30)
      || (editMode?.field === 'desc' && event.target.value.length <= 100)) {
      setCellValue(event.target.value);
    }
  };

  useEffect(() => {
    if (tableRefs.current) {
      tableRefs.current.focus();
    }
  }, [editMode]);

  const handlePriceCellClick = (itemIndex, field) => {
    setEditMode({ itemIndex, field });
    setCellValue(data[0]?.template_contents?.items?.[index]?.pricing[itemIndex][field] || '');
  };

  const handlePriceCellBlur = (itemIndex, field) => {
    // Save the new value to the state
    const newData = JSON.parse(JSON.stringify(data));
    newData[0].template_contents.items[index].pricing[itemIndex][field] = cellValue;
    setData(newData);
    // Exit edit mode
    setEditMode({});
  };

  const handlePriceInputChange = (e) => {
    setCellValue(e.target.value);
  };

  const handleAddItem = () => {
    const newData = JSON.parse(JSON.stringify(data)); // Deep copy of the data array
    const newItem = { ...newData?.[0]?.template_contents?.items?.[index] };
    // Create deep copies of the details and pricing arrays within the newItem
    const newDetails = newItem?.details?.map((detail) => ({ ...detail }));
    const newPricing = newItem?.pricing?.map((price) => ({ ...price }));
    // Push the corresponding detail and pricing to the new arrays
    newDetails.push({ id: newDetails.length, name: '', desc: '' });
    newPricing.push({ p1: '', p2: '', p3: '' });
    // Create a deep copy of the newItem object with the updated arrays
    const duplicatedItem = {
      ...newItem,
      details: newDetails,
      pricing: newPricing,
    };

    const updatedData = newData?.map((item, i) => {
      if (i === 0) {
        return {
          ...item,
          template_contents: {
            ...item?.template_contents,
            items: item?.template_contents?.items?.map((innerItem, j) => {
              if (j === index) {
                return duplicatedItem;
              }
              return innerItem;
            }),
          },
        };
      }
      return item;
    });
    setData(updatedData);
  };

  const handleDeleteItem = (itemIndex) => {
    setItemsIndex(itemIndex);
    setOpenDeleteModal(true);
  };

  const deleteCategoryItem = () => {
    // Create a copy of the data array
    const newData = JSON.parse(JSON.stringify(data));
    // Create a copy of the items array within the element
    const newItems = [...newData?.[0]?.template_contents?.items ?? []];
    // Ensure that `index` is properly defined, representing the index of the item you want to delete
    // Create a deep copy of the item object
    const newItem = { ...newItems?.[index] };
    // Create a copy of the details array within the item
    const newDetails = [...newItem?.details ?? []];
    // Remove the details at the specified index
    newDetails?.splice(itemsIndex, 1);
    // Create a copy of the pricing array within the item
    const newPricing = [...newItem?.pricing ?? []];
    // Remove the pricing at the specified index
    newPricing?.splice(itemsIndex, 1);
    // Update the item object with the new details and pricing
    newItem.details = newDetails;
    newItem.pricing = newPricing;
    // Update the items array within the element with the modified item
    newItems[index] = newItem;
    // Update the element's items with the new array
    newData[0].template_contents.items = newItems;
    // Update the state with the new data
    setData(newData);
    setOpenDeleteModal(false);
  };

  const handleCategoryClick = (catIndex) => {
    setIndex(catIndex);
    setEditMode({});
  };
  const handleSaveTemplate = () => {
    if (data?.[0]?.template_name.length <= 25) {
      const templateNumber = data?.[0]?.template_number;
      const getHideCategory = data?.[0]?.template_contents?.items?.filter((dataHide) => !dataHide.hide);
      if (templateNumber === 2 && getHideCategory?.length > 1) {
        setSubmitDisable('1');
      } else if (templateNumber === 3 && getHideCategory?.length > 2) {
        setSubmitDisable('2');
      } else if (templateNumber === 4 && getHideCategory?.length > 3) {
        setSubmitDisable('3');
      } else if (templateNumber === 9 && getHideCategory?.length > 5) {
        setSubmitDisable('5');
      } else if (templateNumber === 10 && getHideCategory?.length > 3) {
        setSubmitDisable('3');
      } else {
        setOpenModal(true);
      }
    }
  };

  const handleStyleSettings = () => {
    setShowStyle(true);
    dispatch(updateCurrentTemplate(...data));
  };
  const handleCategorySettings = () => {
    dispatch(updateCurrentTemplate(...data));
    setShowStyle(false);
  };
  const handleArrowClick = () => {
    const confirmChange = window.confirm('Are you sure? Changes may not be saved.');
    if (confirmChange) {
      dispatch(updateCurrentTemplate(...data));
      navigate('/admin/templates');
    }
  };
  useEffect(() => {
    if (data?.[0]?.template_name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [data?.[0]?.template_name]);
  const hideDescription = data?.[0]?.template_number === 3 || data?.[0]?.template_number === 4;
  const isDescriptionNotApplicable = data?.[0]?.template_contents?.items?.[index]?.is_description_not_applicable;
  const templateNumber = data?.[0]?.template_number;
  return (
    <div>
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={{
          title: t('saveTemplate'),
          content: <SaveAndTagsPopup
            setOpenModal={setOpenModal}
            content={data}
          />,
        }}
      />
      <DeleteModal
        status={openDeleteModal}
        closeModal={setOpenDeleteModal}
        okFunction={deleteCategoryItem}
        buttonLabel={t('yes')}
        buttonCancel={t('no')}
        title={t('confirmation')}
        subTitle=<span>{t('areYouSureYouWantToDeleteThisCategoryItem?')}</span>
      />
      <ModalBox
        status={editModal}
        closeModal={setEditModal}
        modalView={{
          title: t('editcategory'),
          content: <CategoryEdit
            setEditModal={setEditModal}
            editCategory={editCategory}
            handleChangeEditCategory={handleChangeEditCategory}
            submit={submit}
          />,
        }}
      />
      <ModalBox
        status={titleModal}
        closeModal={setTitleModal}
        modalView={{
          title: t('editMenuTitles'),
          content: <TitleEdit
            setTitleModal={setTitleModal}
            editTitle={editTitle}
            handleTitleChange={handleTitleChange}
            editSubTitle={editSubTitle}
            handleSubTitleChange={handleSubTitleChange}
            submitTitle={submitTitle}
            templateNumber={templateNumber}
          />,
        }}
      />
      {data?.map((ele, eleIndex) => (
        <div className="template-table" key={ele?._id}>
          <div className="top-nav">
            <div className="header-title-wrapper">
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => handleArrowClick()}
                role="presentation"
              >
                <img
                  src={arrowLeftIcon}
                  alt="left-arrow"
                />
              </div>
              <div className="save-file">
                <div className="save-file-form">
                  <input
                    name="save-file-name"
                    className="save-title-input"
                    value={ele?.template_name}
                    onChange={(event) => handleFileNameChange(eleIndex, event)}
                    placeholder="file Name"
                    id="save-file-name"
                    autoComplete="off"
                    ref={inputRef}
                  />
                  {ele?.template_name?.length > 25
                    && (<span className="text-danger">{t('fileNameshouldhaveonly25letters')}</span>)}
                </div>
                <div className="edit-icon" onClick={() => inputRef.current.focus()} role="none">
                  <TemplateEditIcon />
                </div>
              </div>
            </div>

            <div className="right-side">
              <div className="wrapper">
                <div className="header-button">
                  <Button
                    label={t('save & preview')}
                    click={handleSaveTemplate}
                    disabled={disable}
                    classes="success-button"
                  />
                </div>
              </div>
              {submitDisable
                && (
                  <div className="error-text">
                    <span className="text-danger">
                      {t('thistemplateCanShowOnly')} {submitDisable} {t('categoryPleasehideOthers')}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="menu-body">
            <div className="side-menu">
              <div
                className={showStyle ? 'side-menu-title' : 'side-menu-title active'}
                onClick={handleCategorySettings}
                role="presentation"
              >
                {showStyle ? (
                  <CategoryIcon />
                ) : (
                  <CategoryActiveIcon />
                )}
                <h5>{t('category')}</h5>
              </div>
              <div
                className={!showStyle ? 'side-menu-title' : 'side-menu-title active'}
                onClick={handleStyleSettings}
                role="presentation"
              >
                {!showStyle ? (
                  <SettingsIcon />
                ) : (
                  <SettingsActiveIcon />
                )}
                <h5>{t('settings')}</h5>
              </div>

            </div>
            {!showStyle
              && (
                <div className="category-table">
                  <div className="template-main-title">
                    <span>{ele?.template_title}</span>
                    <div className="edit-icon" onClick={onEditTitle} role="none">
                      <TemplateEditIcon />
                    </div>
                  </div>
                  <div className="tabs-container">
                    <ThemeProvider theme={theme}>
                      <Tabs
                        value={index}
                        onChange={(event, newValue) => handleCategoryClick(newValue)}
                        aria-label="secondary tabs example"
                        sx={{
                          '& .MuiTab-root': {
                            textTransform: 'capitalize',
                            fontSize: '14px',
                          },
                          '& .Mui-selected': {
                            color: '#212B36 !important',
                          },
                          '& .MuiTabs-indicator': {
                            backgroundColor: '#212B36',
                          },
                        }}
                      >
                        {ele?.template_contents?.items?.map((val, catIndex) => (
                          <Tab value={catIndex} label={val?.category} />
                        ))}
                      </Tabs>
                    </ThemeProvider>
                    {addCategory && (
                      <div className="add-category">
                        <input
                          name="category-name"
                          className="category-input"
                          value={categoryName}
                          onChange={handleCategoryChange}
                          onBlur={handleBlurCategory}
                          placeholder=""
                          id="category-name"
                          autoComplete="off"
                          ref={inputCategory}
                        />
                        {categoryName.length > 25
                          && (<span className="text-danger">{t('categoryshouldhaveonly25letters')}</span>
                          )}
                      </div>
                    )}
                    <span
                      className="add-btn"
                      onClick={handleAddCategory}
                      role="presentation"
                    >
                      {t('+addcategory')}
                    </span>
                  </div>
                  <div className="categories">
                    <div className="inner-category" onClick={onEditCategory} role="presentation">
                      <TemplateEditIcon />
                      <span>{t('editcategory')}</span>
                    </div>
                    {!ele?.template_contents?.items?.[index]?.hide ? (
                      <div className="inner-category" onClick={() => onHideCategory('hide')} role="presentation">
                        <HideIcon />
                        <span>{t('hidecategory')}</span>
                      </div>
                    ) : (
                      <div className="inner-category" onClick={() => onHideCategory('show')} role="presentation">
                        <ShowIcon />
                        <span>{t('showcategory')}</span>
                      </div>
                    )}
                  </div>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ color: '#9b9b9b' }}>{t('itemName')}</TableCell>
                          {
                            (() => {
                              let showMultiPrice;
                              if (data?.[0]?.template_number === 1) {
                                showMultiPrice = (
                                  <>
                                    {/* <TableCell style={{ color: '#9b9b9b' }}>{t('availability')}</TableCell> */}
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('price1')}</TableCell>
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('price2')}</TableCell>
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('price3')}</TableCell>
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('actions')}</TableCell>
                                  </>
                                );
                              } else {
                                showMultiPrice = (
                                  <>
                                    {/* <TableCell style={{ color: '#9b9b9b' }}>{t('availability')}</TableCell> */}
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('price')}</TableCell>
                                    <TableCell style={{ color: '#9b9b9b' }}>{t('actions')}</TableCell>
                                  </>
                                );
                              }
                              return showMultiPrice;
                            })()
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.[0]?.template_number !== 1
                          && ele?.template_contents?.items?.[index]?.details?.map((item, itemIndex) => (
                            <TableRow
                              key={item?.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {!ele?.template_contents?.items?.[index]?.hide
                                && (
                                  <>
                                    <TableCell width={700}>
                                      <div className="tableCells">
                                        {editMode.itemIndex === itemIndex
                                          && editMode.field === 'name'
                                          ? (
                                            <div className="tableCell-form">
                                              <input
                                                type="text"
                                                className="input-box"
                                                value={cellValue}
                                                onChange={handleInputChange}
                                                onBlur={() => handleCellBlur(itemIndex, 'name')}
                                                ref={tableRefs}
                                              />
                                              {cellValue?.length >= 30
                                                && (
                                                  <span className="text-danger">
                                                    {t('itemnameshouldhaveonly30letters')}
                                                  </span>
                                                )}
                                            </div>
                                          ) : (
                                            <div
                                              role="presentation"
                                              onClick={() => handleCellClick(itemIndex, 'name')}
                                            >
                                              {item?.name
                                                ? (
                                                  <span>{item?.name}</span>
                                                ) : (
                                                  <span>{t('addItem')}</span>
                                                )}
                                            </div>
                                          )}
                                      </div>
                                      <div
                                        className="tableCells"
                                        style={{
                                          fontStyle: 'italic',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {editMode.itemIndex === itemIndex
                                          && editMode?.field === 'desc'
                                          ? (
                                            <div className="tableCell-form">
                                              <input
                                                type="text"
                                                className="input-box"
                                                value={cellValue}
                                                onChange={handleInputChange}
                                                onBlur={() => handleCellBlur(itemIndex, 'desc')}
                                                ref={tableRefs}
                                              />
                                              {cellValue?.length >= 100
                                                && (
                                                  <span style={{ fontStyle: 'normal' }} className="text-danger">
                                                    {t('descriptionShouldHaveOnly100Letters')}
                                                  </span>
                                                )}
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => handleCellClick(itemIndex, 'desc')}
                                              role="presentation"
                                            >
                                              {
                                                (() => {
                                                  let description;
                                                  if (item?.desc !== '') {
                                                    description = <span>{item.desc}</span>;
                                                  } else if (!isDescriptionNotApplicable
                                                    && !hideDescription
                                                  ) {
                                                    description = (
                                                      <span style={{ fontStyle: 'normal' }}>{t('addDescription')}</span>
                                                    );
                                                  }
                                                  return description;
                                                })()
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                    <TableCell width={150}>
                                      <div className="tableCells">
                                        {editMode.itemIndex === itemIndex
                                          && editMode.field === 'p1'
                                          ? (
                                            <div className="tableCell-form">
                                              <input
                                                type="text"
                                                className="input-box"
                                                value={cellValue}
                                                onChange={handlePriceInputChange}
                                                onBlur={() => handlePriceCellBlur(itemIndex, 'p1')}
                                                ref={tableRefs}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => handlePriceCellClick(itemIndex, 'p1')}
                                              role="presentation"
                                            >
                                              {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p1 ? (
                                                <span>
                                                  {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p1}
                                                </span>
                                              ) : (
                                                <span>{t('addPrice')}</span>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                    <TableCell width={200}>
                                      <div className="action-btn">
                                        {ele?.template_contents?.items?.[index]?.details?.length > 1
                                          ? (
                                            <div
                                              className="delete-icon"
                                              onClick={() => handleDeleteItem(itemIndex)}
                                              role="presentation"
                                            >
                                              <img
                                                src={templateDelIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          )
                                          : (
                                            <div
                                              className="delete-icon"
                                              role="presentation"
                                            >
                                              <img
                                                src={templateDelIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                  </>
                                )}
                            </TableRow>
                          ))}
                        {data?.[0]?.template_number === 1
                          && ele?.template_contents?.items?.[index]?.details?.map((item, itemIndex) => (
                            <TableRow
                              key={item?.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              {!ele?.template_contents?.items?.[index]?.hide
                                && (
                                  <>
                                    <TableCell width={700}>
                                      <div className="tableCells">
                                        {editMode.itemIndex === itemIndex
                                          && editMode.field === 'name'
                                          ? (
                                            <div className="tableCell-form">
                                              <input
                                                type="text"
                                                className="input-box"
                                                value={cellValue}
                                                onChange={handleInputChange}
                                                onBlur={() => handleCellBlur(itemIndex, 'name')}
                                                ref={tableRefs}
                                              />
                                              {cellValue?.length >= 30
                                                && (
                                                  <span className="text-danger">
                                                    {t('itemnameshouldhaveonly30letters')}
                                                  </span>
                                                )}
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => handleCellClick(itemIndex, 'name')}
                                              role="presentation"
                                            >
                                              {item?.name ? (
                                                <span>{item?.name}</span>
                                              ) : (
                                                <span>{t('addItem')}</span>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                      <div
                                        className="tableCells"
                                        style={{
                                          fontStyle: 'italic',
                                          fontSize: '14px',
                                        }}
                                      >
                                        {editMode.itemIndex === itemIndex
                                          && editMode.field === 'desc'
                                          ? (
                                            <div className="tableCell-form">
                                              <input
                                                type="text"
                                                className="input-box"
                                                value={cellValue}
                                                onChange={handleInputChange}
                                                onBlur={() => handleCellBlur(itemIndex, 'desc')}
                                                ref={tableRefs}
                                              />
                                              {cellValue?.length >= 100
                                                && (
                                                  <span className="text-danger">
                                                    {t('descriptionShouldHaveOnly100Letters')}
                                                  </span>
                                                )}
                                            </div>
                                          ) : (
                                            <div
                                              onClick={() => handleCellClick(itemIndex, 'desc')}
                                              role="presentation"
                                            >
                                              {item?.desc
                                                ? (
                                                  <span>{item?.desc}</span>
                                                ) : (
                                                  <span style={{ fontStyle: 'normal' }}>{t('addDescription')}</span>
                                                )}
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                    <TableCell width={150}>
                                      <div className="tableCells">
                                        {editMode?.itemIndex === itemIndex && editMode?.field === 'p1' ? (
                                          <div className="tableCell-form">
                                            <input
                                              type="text"
                                              className="input-box"
                                              value={cellValue}
                                              onChange={handlePriceInputChange}
                                              onBlur={() => handlePriceCellBlur(itemIndex, 'p1')}
                                              ref={tableRefs}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => handlePriceCellClick(itemIndex, 'p1')}
                                            role="presentation"
                                          >
                                            {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p1 ? (
                                              <span>
                                                {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p1}
                                              </span>
                                            ) : (
                                              <span>{t('addPrice')}</span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell width={150}>
                                      <div className="tableCells">
                                        {editMode.itemIndex === itemIndex && editMode.field === 'p2' ? (
                                          <div className="tableCell-form">
                                            <input
                                              type="text"
                                              className="input-box"
                                              value={cellValue}
                                              onChange={handlePriceInputChange}
                                              onBlur={() => handlePriceCellBlur(itemIndex, 'p2')}
                                              ref={tableRefs}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => handlePriceCellClick(itemIndex, 'p2')}
                                            role="presentation"
                                          >
                                            {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p2 ? (
                                              <span>
                                                {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p2}
                                              </span>
                                            ) : (
                                              <span>{t('addPrice')}</span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell width={150}>
                                      <div className="tableCells">
                                        {editMode?.itemIndex === itemIndex && editMode?.field === 'p3' ? (
                                          <div className="tableCell-form">
                                            <input
                                              type="text"
                                              className="input-box"
                                              value={cellValue}
                                              onChange={handlePriceInputChange}
                                              onBlur={() => handlePriceCellBlur(itemIndex, 'p3')}
                                              ref={tableRefs}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => handlePriceCellClick(itemIndex, 'p3')}
                                            role="presentation"
                                          >
                                            {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p3 ? (
                                              <span>
                                                {ele?.template_contents?.items?.[index]?.pricing?.[itemIndex]?.p3}
                                              </span>
                                            ) : (
                                              <span>{t('addPrice')}</span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell width={200}>
                                      <div className="action-btn">
                                        {ele?.template_contents?.items?.[index]?.details?.length > 1
                                          ? (
                                            <div
                                              className="delete-icon"
                                              onClick={() => handleDeleteItem(itemIndex)}
                                              role="presentation"
                                            >
                                              <img
                                                src={templateDelIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          )
                                          : (
                                            <div
                                              className="delete-icon"
                                              role="presentation"
                                            >
                                              <img
                                                src={templateDelIcon}
                                                alt="delete"
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                  </>
                                )}
                            </TableRow>
                          ))}
                        {!ele?.template_contents?.items?.[index]?.hide && (
                          <TableRow>
                            <TableCell>
                              <div>
                                <span
                                  className="addItem-btn"
                                  onClick={handleAddItem}
                                  role="presentation"
                                >
                                  {t('+addItem')}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            {showStyle && <StyleRenderPage setData={setData} />}
          </div>
        </div>
      ))};
    </div>
  );
}

TableTemplateEditor.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
};

export default TableTemplateEditor;
