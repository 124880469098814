import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared/component/Button';
import { Input } from '../../../../shared/component/form/Input';

function OtpVerification(props) {
  const { t } = useTranslation();
  const [otp, setOtp] = useState(props?.otp || '');
  const handleChange = (name, changeValue) => {
    setOtp(changeValue);
  };
  const submit = () => {
    props?.deleteConfirmation(props?.email, otp);
  };

  const back = () => {
    props?.back(props?.email);
  };

  return (
    <div className="deleteAccount">
      <div className="request-content">
        <span className="description">
          {`${t('enterTheOTPWeSenTto')} ${props?.email}`}
        </span>
      </div>
      <Input
        icon={' '}
        label={t('code')}
        type="text"
        name="code"
        value={otp}
        change={handleChange}
        placeholder=""
      />
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('back')}
            click={back}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('verifyOtp')}
            click={submit}
            classes="success-button"
            disabled={otp === ''}
          />
        </div>
      </div>
    </div>
  );
}

OtpVerification.propTypes = {
  email: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  deleteConfirmation: PropTypes.func.isRequired,
  otp: PropTypes.string,
};

OtpVerification.defaultProps = {
  otp: '',
};

export default OtpVerification;
