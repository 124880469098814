/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import 'moment-timezone/builds/moment-timezone-with-data';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import TopContent from '../../../shared/component/TopContent';
import ModalBox from '../../../shared/component/ModalBox';
import CalendarSchedulesAdd from './CalendarSchedulesAdd';
import CalendarScheduleEdit from './CalendarScheduleEdit';
import { fetchCalendarScheduleList } from '../../../redux/slices/ScheduleSlice';
import {
  ListMenuIcon,
  CalendarIcon,
  ActiveListMenu,
  ActiveCalendarIcon,
} from '../../../shared/component/svgIcon';
import Toggle from './ToggleButton';
import { checkPermission } from '../../../utils/helpers';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);
function CustomToolbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    label,
    onNavigate,
    onView,
    view,
  } = props;
  const tabPages = [
    {
      key: location?.state?.specialSchedule ? 'calendar-schedule' : 'schedules',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('getSchedule'),
    },
    {
      key: 'calendar-schedule',
      icon: <CalendarIcon />,
      activeIcon: <ActiveCalendarIcon />,
      enable: checkPermission('getCalendarSchedule'),
    },
  ];
  const scheduleList = (url) => {
    navigate(url);
  };
  return (
    <div className="calendar-toolbar">
      <div className="calendar-toggle-menu">
        <Toggle button1={scheduleList} active="calendar-schedule" tabPages={tabPages} />
      </div>
      <div className="toolbar-left">
        <button className="Today-button" onClick={() => onNavigate('TODAY')} type="button">
          {t('today')}
        </button>
      </div>
      <div className="toolbar-center">
        <button className="prev-button" onClick={() => onNavigate('PREV')} type="button">
          <div className="icon"> &#60;</div>
        </button>
        <div className="date-label">{label}</div>
        <button className="next-button" onClick={() => onNavigate('NEXT')} type="button">
          <div className="icon"> &#62;</div>
        </button>
      </div>
      <div className="toolbar-right">
        <button
          className={view === 'week' ? 'week-button active' : 'week-button'}
          onClick={() => onView('week')}
          type="button"
        >
          {t('week')}
        </button>
        <button
          className={view === 'day' ? 'day-button active' : 'day-button'}
          onClick={() => onView('day')}
          type="button"
        >
          {t('day')}
        </button>
      </div>
    </div>
  );
}
function CustomEvent(props) {
  const { event, title } = props;

  const startDate = new Date(event?.start_utc_time);
  const startTime = startDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  const endDate = new Date(event?.end_utc_time);
  const endTime = endDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <div>
      <div className="event-title">{title} <br /></div>
      <div className="event-time">
        {startTime}
        <strong> - </strong>
        {endTime}
      </div>
    </div>
  );
}
function BigCalender() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { calendarSchedules, week } = useSelector((state) => state.schedules);
  const [type] = useState(location?.state?.specialSchedule || false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '0.2px solid #bab8b8',
    boxShadow: 24,
    borderRadius: '6px',
    p: 3,
    overflowY: 'scroll',
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [weekDate, setWeekDate] = useState({
    startOfWeek: moment().startOf('week').format('YYYY-MM-DD'),
    endOfWeek: moment().endOf('week').format('YYYY-MM-DD'),
  });
  const [createContentModal, setCreateContentModal] = useState({
    title: '',
    content: 0,
  });
  const handleNavigate = (date, views) => {
    const startOfWeek = moment(date).startOf(views).format('YYYY-MM-DD');
    const endOfWeek = moment(date).endOf(views).format('YYYY-MM-DD');
    setWeekDate({ startOfWeek, endOfWeek });
  };
  const getData = () => {
    const oldDate = week.find((val) => val.start_date <= weekDate.startOfWeek && val.end_date >= weekDate.endOfWeek);
    if (!oldDate) {
      dispatch(fetchCalendarScheduleList({
        start_date: weekDate.startOfWeek,
        end_date: weekDate.endOfWeek,
      }));
    }
  };
  const handleEventClick = (event) => {
    if (checkPermission('addSchedule')) {
      const currentDate = new Date();
      const { start, end } = event;
      if (start >= currentDate) {
        const selectedStartDate = moment(start).format('YYYY-MM-DD');
        const selectedEndDate = moment(end).format('YYYY-MM-DD');
        const selectedTime = moment(start).format('HH:mm');
        const selectedTimeend = moment(end).format('HH:mm');
        setIsModalOpen(true);
        setCreateContentModal({
          content: <CalendarSchedulesAdd
            selectedDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            selectedTime={selectedTime}
            selectedTimeend={selectedTimeend}
            closeModal={setIsModalOpen}
            back={setIsModalOpen}
            Click={isModalOpen}
          />,
          title: t('newSchedule'),
        });
      }
    }
  };
  const handleClick = (event) => {
    if (checkPermission('editSchedule')) {
      setIsModalOpen(true);
      setCreateContentModal({
        title: event.title,
        content: <CalendarScheduleEdit
          Click={isModalOpen}
          back={setIsModalOpen}
          event={event}
        />,
      });
    }
  };
  const onEventResize = (data) => {
    if (checkPermission('editSchedule')) {
      const currentDate = new Date();
      const { start, end, event } = data;
      if (start >= currentDate && !event?.repeat?.repeat) {
        const selectedStartDate = moment(start).format('YYYY-MM-DD');
        const selectedEndDate = moment(end).format('YYYY-MM-DD');
        const selectedTime = moment(start).format('HH:mm');
        const selectedTimeend = moment(end).format('HH:mm');

        setIsModalOpen(true);
        setCreateContentModal({
          title: event.title,
          content: <CalendarScheduleEdit
            selectedDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            selectedTime={selectedTime}
            selectedTimeend={selectedTimeend}
            Click={isModalOpen}
            back={setIsModalOpen}
            event={event}
          />,
        });
      }
    }
  };
  const onEventDrop = (data) => {
    if (checkPermission('editSchedule')) {
      const currentDate = new Date();
      const { start, end, event } = data;
      if (start >= currentDate && !event?.repeat?.repeat) {
        const selectedStartDate = moment(start).format('YYYY-MM-DD');
        const selectedEndDate = moment(end).format('YYYY-MM-DD');
        const selectedTime = moment(start).format('HH:mm');
        const selectedTimeend = moment(end).format('HH:mm');
        setIsModalOpen(true);
        setCreateContentModal({
          title: event.title,
          content: <CalendarScheduleEdit
            selectedDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            selectedTime={selectedTime}
            selectedTimeend={selectedTimeend}
            Click={isModalOpen}
            back={setIsModalOpen}
            event={event}
          />,
        });
      }
    }
  };
  const eventPropGetter = (event) => {
    if (event.special) {
      return {
        className: 'special-event',
      };
    }
    return {};
  };

  useEffect(() => {
    getData();
  }, [weekDate]);
  return (
    <div className="content">
      <TopContent
        label={t('schedule')}
      />
      <ModalBox
        sx={style}
        status={isModalOpen}
        modalView={createContentModal}
        closeModal={() => setIsModalOpen(false)}
      />
      <div className="calendar">
        <div
          style={{
            height: '75vh',
            padding: '0px 0px 2px 0px',
            borderRadius: '8px',
            boxShadow: '0px 0px 9px #f0f0f0',
          }}
        >
          <DragAndDropCalendar
            localizer={localizer}
            defaultDate={moment().toDate()}
            onSelectSlot={handleEventClick}
            onSelectEvent={handleClick}
            events={calendarSchedules?.filter((val) => val.special === type)}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventPropGetter}
            resizable
            selectable
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onNavigate={handleNavigate}
            defaultView="week"
            components={{
              toolbar: CustomToolbar,
              event: CustomEvent,
            }}
          />

        </div>
      </div>
    </div>
  );
}
CustomToolbar.propTypes = {
  label: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
CustomEvent.propTypes = {
  event: PropTypes.objectOf().isRequired,
  title: PropTypes.string.isRequired,
};

export default BigCalender;
