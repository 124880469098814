import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { SmallRefreshIcon, ViewIcon } from '../../../shared/component/svgIcon';

function ReportsByStay(props) {
  const { t } = useTranslation();
  const stareTimeData = Object.values(props?.value?.stareTime || {});
  const stayTimeData = Object.values(props?.value?.stayTime || {});

  const series = [{
    name: 'Stare Time',
    data: stareTimeData,
    color: '#6EC284',
  }, {
    name: 'Stay Time',
    data: stayTimeData,
    color: '#1C75BC',
  }];
  const generateTodayHourlyCategories = () => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Set time to midnight
    const categories = [];

    for (let i = 0; i < 24; i += 1) {
      const hour = new Date(today);
      hour.setUTCHours(i, 0, 0, 0);
      categories.push(hour.toISOString());
    }

    return categories;
  };
  const options = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: true, // we can set this to false to hide the toolbar completely
        tools: {
          download: false, // Disables the download icon
          selection: false, // Disables the selection icon
          zoom: false, // Disables the zoom icon
          zoomin: false, // Disables the zoom in icon
          zoomout: false, // Disables the zoom out icon
          pan: false, // Disables the pan icon
          reset: false, // Disables the reset icon
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    xaxis: {
      type: 'datetime',
      categories: generateTodayHourlyCategories(),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
        },
      },
      tickAmount: 8,
      min: 0,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    grid: {
      borderColor: '#CACACA',
      strokeDashArray: 8, // This makes the background lines dotted
    },
  };
  return (
    <div className="report-by-gender">
      <div className="report-header">
        <div className="header-left">
          <h3 className="title">{t('reportsByAverageTime')}</h3>
          <ViewIcon className="status-view-icon" />
        </div>
      </div>
      <div className="time-chart-wrap">
        <ReactApexChart options={options} series={series} type="area" height={250} />
      </div>
      <div className="last-time">
        <SmallRefreshIcon />
        <span className="last-time-message">{props?.time}</span>
      </div>
    </div>
  );
}
ReportsByStay.propTypes = {
  value: PropTypes.shape({
    stareTime: PropTypes.objectOf(),
    stayTime: PropTypes.objectOf(),
  }),
  time: PropTypes.string,
};
ReportsByStay.defaultProps = {
  value: {
    stareTime: '',
    stayTime: '',
  },
  time: '',
};
export default ReportsByStay;
