/* eslint no-underscore-dangle: 0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';
import rotateRight from '../../../assets/icons/rotate-right.png';
import closeCircle from '../../../assets/icons/close-circle.png';
import DotLoader from '../../../shared/component/Loader';

function ListRow(props) {
  const {
    deviceInfo,
    signedUrl,
    startRetryTimer,
    expiryTimestamp,
    stopRetryTimer,
    maxRetryCount,
    retryCount,
    viewImage,
    retryCaptureSnapshot,
    snapshotId,
  } = props;
  const { t } = useTranslation();
  const {
    seconds,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => stopRetryTimer(deviceInfo._id) });

  useEffect(() => {
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  return (
    <div className="row">
      <div className="image">
        {
          signedUrl
            ? (
              <div
                onClick={() => viewImage(signedUrl)}
                role="presentation"
              >
                <img src={signedUrl} alt="snapshot_image" />
              </div>
            )
            : (
              <div style={{ height: '10vh' }}>
                {
                  (startRetryTimer && (retryCount < maxRetryCount)) ? (
                    <div className="generate-image">
                      <DotLoader
                        dotActiveColor="#30A84B"
                        dotBlurColor="#ACDCB7"
                        dotSize="10px"
                      />
                      <div className="button-text">Retry in {seconds} sec</div>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {
                        retryCount >= maxRetryCount ? (
                          <div className="generate-image">
                            <div className="button-image"><img src={closeCircle} alt="snapshot_image" /></div>
                            <div className="button-text">{t('errorMessage')}</div>
                          </div>
                        ) : (
                          <div
                            className="generate-image"
                            onClick={() => retryCaptureSnapshot(snapshotId)}
                            role="presentation"
                          >
                            <div className="button-image"><img src={rotateRight} alt="snapshot_image" /></div>
                            <div className="button-text">{t('clickHereToRequestSnapshotAgain')}</div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
        }
      </div>
      <div className="col">
        <div className="title">{t('deviceName')}</div>
        <div className="value">{deviceInfo?.name}</div>
      </div>
      <div className="col">
        <div className="title">{t('orientation')}</div>
        <div className="value">{deviceInfo?.orientation}</div>
      </div>
      <div className="col">
        <div className="title">{t('resolution')}</div>
        <div className="value">
          {deviceInfo?.display_width_pixels
            && `${Math.trunc(deviceInfo?.display_width_pixels)}
            * ${Math.trunc(deviceInfo?.display_height_pixels)}`}
        </div>
      </div>
      <div className="col">
        <div className="title">{t('displaySize')}</div>
        <div className="value">{deviceInfo?.screen_size}</div>
      </div>
      <div className="col"> </div>
    </div>
  );
}
ListRow.propTypes = {
  deviceInfo: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    display_width_pixels: PropTypes.number.isRequired,
    display_height_pixels: PropTypes.number.isRequired,
    screen_size: PropTypes.string.isRequired,
    imageSize: PropTypes.string.isRequired,
  }).isRequired,
  signedUrl: PropTypes.string,
  startRetryTimer: PropTypes.bool,
  expiryTimestamp: PropTypes.instanceOf(),
  stopRetryTimer: PropTypes.func,
  maxRetryCount: PropTypes.number,
  retryCount: PropTypes.number,
  viewImage: PropTypes.func,
  retryCaptureSnapshot: PropTypes.func,
  snapshotId: PropTypes.string,
};
ListRow.defaultProps = {
  signedUrl: null,
  startRetryTimer: null,
  expiryTimestamp: null,
  stopRetryTimer: null,
  maxRetryCount: null,
  retryCount: null,
  viewImage: null,
  retryCaptureSnapshot: null,
  snapshotId: null,
};

export default ListRow;
