import {
  get,
  post,
  patch,
} from './base/index';

export default {
  buyAiInstanceOnline: (data) => post(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/buy-instance`, { ...data }),
  getAIDevices: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/devices`, { params });
  },
  updateAIDevices: (data) => patch(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/devices`, { ...data }),
  availableInstances: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/available-instances`),
  genderCount: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/dashboard/total-gender`, { params });
  },
  deviceList: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/device-list`),
  getAgeCount: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/dashboard/age-group`, { params });
  },
  getTimeCount: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/dashboard/stay-start/total-count`, { params });
  },
  getGenderReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/report/time-gender-list`, { params });
  },
  getAgeReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/report/age-gender-list`, { params });
  },
  getStareStayReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/report/stare-stay-time`, { params });
  },
  getGenderReportList: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/reports/gender-list`, { params });
  },
  getAgedReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/reports/age-list`, { params });
  },
  getStaredStayReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/reports/stay-start-list`, { params });
  },
  instanceCount: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/active-instance-list`),
  creditManagementList: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/list-available-instances`, { params });
  },
  addTransfer: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/transfer`, { ...data }),
  renewalInstanceOnline: (data) => post(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/renew`, { ...data }),
  deviceUsageReport: (data) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance/ai-instance-device-usage`, { params });
  },
  aiInstanceUsageReport: (data = {}) => {
    const params = { ai_instance_id: data.aiInstanceId };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/ai-instance//usage-by-ai-instance`, { params });
  },
};
