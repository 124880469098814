import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import img1 from '../../../assets/images/dashboard/img1.png';
import img2 from '../../../assets/images/dashboard/img2.png';
import img3 from '../../../assets/images/dashboard/img3.png';
import img4 from '../../../assets/images/dashboard/img4.png';

function Count(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (e, url) => {
    e.preventDefault();
    navigate(`/admin${url}`);
  };

  const countData = [
    {
      title: t('totalStores'),
      count: props?.count.store,
      image: img1,
      url: '/location',
    },
    {
      title: t('totalDevices'),
      count: props?.count.device,
      image: img2,
      url: '/display-signage',
    },
    {
      title: t('totalContents'),
      count: props?.count.contents,
      image: img3,
      url: '/my-content',
    },
    {
      title: t('totalSchedules'),
      // Change users count
      count: props?.count.schedule,
      image: img4,
      url: '/schedules',
    },
  ];
  return (
    <div className="dashboard-card-count">
      {countData.map((item) => (
        <div className="each-count" key={item.title}>
          <div className="count-top">
            <div className="count-header">
              <h4>{item.title}</h4>
              <h1>{item.count}</h1>
            </div>
            <img src={item.image} alt="icon" className="count-img" />
          </div>
          <div className="count-bottom">
            <p>{t('viewDetails')}</p>
            <button onClick={(e) => handleClick(e, item.url)} label="button" type="submit">
              <IoIosArrowRoundForward />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

Count.propTypes = {
  count: PropTypes.shape({
    store: PropTypes.number,
    device: PropTypes.number,
    contents: PropTypes.number,
    schedule: PropTypes.number,
  }).isRequired,
};

export default Count;
