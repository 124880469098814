import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ViewIcon } from '../../../shared/component/svgIcon';

function BuyInstanceStatus(props) {
  const { t } = useTranslation();
  // const toShow = props?.usedStorage;
  // const [percentage, setPercentage] = useState(0);
  // useEffect(() => {
  //   setPercentage(Math.round((Number(props?.companyUsedStorage) / Number(props?.subscriptionStorage)) * 100));
  // }, [props]);

  return (
    <div className="buy-instance-status">
      <div className="progress">
        <div className="progress-text">
          <div className="text-wrapper">
            <h2>{props?.title}</h2>
            <Tooltip
              title={
                <p className="tooltip-text">{t('purchase200hours')}</p>
              }
            >
              <div>
                <ViewIcon className="status-view-icon" />
              </div>
              {/* <InfoOutlinedIcon className="info-icon" /> */}
            </Tooltip>
          </div>
          <h3>{props?.subTitle}</h3>
        </div>
        <div className="icon-container">
          {props?.icon}
        </div>
      </div>
    </div>
  );
}
BuyInstanceStatus.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default BuyInstanceStatus;
