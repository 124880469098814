import posItEasyLogo from '../../../assets/images/Templates/posIteasyLogo.png';
import odooLogo from '../../../assets/images/Templates/odoo-logo.png';

const integrations = [
  {
    logoImage: posItEasyLogo,
    title: 'POSitEasy',
    api: 'posTemplate.addPosToken(posToken)',
  },
  {
    logoImage: odooLogo,
    title: 'ODOO',
    api: 'posTemplate.addPosToken(posToken)',
  },
];

export default integrations;
