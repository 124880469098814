import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from '../../Table';

function NewContent(props) {
  const { t } = useTranslation();
  const { details } = props;

  const columns = [
    {
      header: t('startsAt'),
      accessorKey: 'startsAt',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: t('stopsAt'),
      accessorKey: 'stopsAt',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: t('status'),
      accessorKey: 'status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: t('duration'),
      accessorKey: 'duration',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
  ];

  return (
    <DataTable
      className="my-data-table"
      header={columns}
      value={details}
      onFilterOrSortingChange={() => { }}
      rowSelection={[]}
      setRowSelection={() => { }}
      sorting={[]}
      enable={false}
      height="50vh"
      disableFullScreen
      disableFilter
      disableFilterButton
      disableShowHideButton
      disableToggleButton
    />
  );
}

NewContent.propTypes = {
  details: PropTypes.arrayOf.isRequired,
};

export default NewContent;
