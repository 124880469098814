/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import displaySignage from '../../utils/api/devices';
import {
  changeTimeFormat,
  getTimeDifference,
  getResolutionCategory,
} from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

// Function to convert the device details into display format...
const formatDisplayData = (device) => {
  console.log(new Date(), new Date(device?.lastPollAt));
  return {
    ...device,
    store_info: `${device?.store?.storeName}, ${device?.store?.city}`,
    store_name: `${device?.store?.storeName}`,
    store_location: `${device?.store?.city}`,
    store: { ...device.store, id: device.store._id },
    resolution: device?.display_width_pixels
      && getResolutionCategory(device?.display_width_pixels, device?.display_height_pixels),
    screen_size: device?.screen_size ? `${device?.screen_size}''` : '',
    signage_information: [{
      name: 'Brand',
      deviceue: device?.brand,
    },
    {
      name: 'Device Type',
      deviceue: device?.device_type,
    },
    {
      name: 'Orientation',
      deviceue: device?.orientation,
    }],
    id: device._id,
    lastPollTime: (new Date() - new Date(device?.lastPollAt)) <= 30 * 60 * 1000,
    timeDifference: device?.lastPollAt ? ` 
      Active ${getTimeDifference(device?.lastPollAt)}
    ` : 'Not found',
  };
};

// Function to convert the device details into display format...
const formatSingleDisplayData = (device) => ({
  ...device,
  id: device._id,
  store_info: `${device?.store?.name}, ${device?.store?.city}`,
  store_name: `${device?.store?.name}`,
  store_location: `${device?.store?.city}`,
  store: { ...device.store, id: device.store._id },
  resolution: device?.display_width_pixels
    && getResolutionCategory(device?.display_width_pixels, device?.display_height_pixels),
  screen_size: device?.screen_size ? `${device?.screen_size}''` : '',
  lastPollTime: (new Date() - new Date(device?.lastPollAt)) <= 30 * 60 * 1000,
  timeDifference: device?.lastPollAt ? ` 
      Active ${getTimeDifference(device?.lastPollAt)}
    ` : 'Not found',
});

export const fetchDisplaySignageList = createAsyncThunk(
  'displaySignage/fetchDisplaySignage',
  async (data, { dispatch }) => {
    const response = await displaySignage.getDevices(data).then((response1) => {
      const displaySignages = response1.data.results.map((device) => (formatDisplayData(device)));
      return { ...response1?.data, results: displaySignages, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchDisplaySingleSignage = createAsyncThunk(
  'displaySignage/fetchSingleDisplaySignage',
  async (data, { dispatch }) => {
    const response = await displaySignage.getSingleDevice(data)
      .then((res) => ({ ...formatDisplayData(res?.data), code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchDeviceScheduleList = createAsyncThunk(
  'displaySignage/fetchDeviceScheduleList',
  async (data, { dispatch }) => {
    const response = await displaySignage.getDeviceSchedules(data)
      .then((response1) => {
        const tableData = response1?.data?.map((val) => {
          const now = `${val?.start_only_date} ${val?.start_time}`;
          const then = `${val?.end_only_date} ${val?.end_time}`;
          return {
            ...val,
            id: val?._id,
            store_name: val?.schedule?.[0]?.name,
            scheduleDate: `
            ${val?.repeat ? val?.recurrences?.start_only_date : val?.start_only_date}
            to
            ${val?.repeat ? val?.recurrences?.end_only_date : val?.end_only_date}
          `,
            scheduledTime: `
            ${changeTimeFormat(now)}
            to
            ${changeTimeFormat(then)}
          `,
          };
        });
        return tableData;
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  displaySignage: [],
  deviceSchedules: [],
  pageCount: 0,
  totalPageCount: 1,
  singleDisplaySignage: {},
  totalDataCount: 0,
  status: 'succeeded',
  scheduleStatus: 'succeeded',
  filter: {},
  tableFilter: {},
  retracted: false,
};

export const displaySignageSlice = createSlice({
  name: 'displaySignage',
  initialState,
  reducers: {
    // Reducer to add the new display to top of the display list
    createNewDisplay: (state, action) => {
      const displayList = [
        formatSingleDisplayData(action.payload),
        ...state.displaySignage, // Existing displays
      ];
      state.displaySignage = displayList; // update the display list
    },
    // Reducer to update the display by ID.
    updateDisplay: (state, action) => {
      const updatedDisplay = action.payload;
      const displayList = state.displaySignage; // Assign the existing display to another variable
      // Find the index of the updated display by ID
      const index = displayList.findIndex((display) => display._id === updatedDisplay._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        displayList[index] = formatSingleDisplayData(updatedDisplay);
      }
      state.displaySignage = displayList; // Finally update the display list
    },
    // Reducer to delete the display by ID
    deleteDisplay: (state, action) => {
      const displayList = state.displaySignage;

      // Find the index to delete by ID
      const index = displayList.findIndex((display) => display.id === action.payload);
      // If the index found, remove that index from list of displays
      if (index !== -1) {
        displayList.splice(index, 1);
      }
      state.displaySignage = displayList; // Update the displays state after deleted
    },
    singleDisplaySignage: (state, action) => {
      state.singleDisplaySignage = state.displaySignage.find((val) => val.id === action.payload);
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setRetracted: (state, action) => {
      state.retracted = action.payload;
    },
    resetDisplaySignageState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDisplaySignageList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplaySignageList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action?.payload?.code === 200) {
          const fetchedData = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.displaySignage = fetchedData;
          } else {
            const finalData = state.displaySignage;
            // Add fetchedData to finalData, but only if the id is not already present
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.displaySignage = finalData;
          }
        }
        state.status = 'succeeded';
      })
      .addCase(fetchDisplaySignageList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDisplaySingleSignage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplaySingleSignage.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          state.singleDisplaySignage = action?.payload;
        }
      })
      .addCase(fetchDisplaySingleSignage.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDeviceScheduleList.pending, (state) => {
        state.scheduleStatus = 'loading';
      })
      .addCase(fetchDeviceScheduleList.fulfilled, (state, action) => {
        // Add user to the state array
        state.scheduleStatus = 'succeeded';
        state.deviceSchedules = action?.payload;
      })
      .addCase(fetchDeviceScheduleList.rejected, (state) => {
        state.scheduleStatus = 'failed';
      });
  },
});

export const {
  createNewDisplay,
  updateDisplay,
  deleteDisplay,
  singleDisplaySignage,
  setFilter,
  setTableFilter,
  setRetracted,
  resetDisplaySignageState,
} = displaySignageSlice.actions;

export default displaySignageSlice.reducer;
