import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';

function CustomLegend({ payload }) {
  return (
    <div style={{ paddingRight: '197px', marginTop: '-446px', textAlign: 'end' }}>
      {payload.map((entry) => (
        <span key={entry.value} style={{ display: 'inline-block', marginRight: '10px' }}>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              height: '12px',
              backgroundColor: entry.color,
              marginRight: '4px',
              borderRadius: '20px',
            }}
          />
          {entry.value}
        </span>
      ))}
    </div>
  );
}

CustomLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

function ReportTimes({ gender, countData, filterFunction }) {
  const { t } = useTranslation();

  const stayColor = gender === 'women' ? '#EC4899' : '#99CCFF';
  const strayColor = gender === 'women' ? '#DB2777' : '#0047AB';

  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('ageGroup'),
      accessorKey: 'ageRange',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalStayTime'),
      accessorKey: 'stay',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalStareTime'),
      accessorKey: 'stray',
      enableColumnActions: false,
      enableResizing: false,
    },
  ];

  const exportData = () => {
    const columnHeaders = ['Age Group', 'Total Stay Time (hours)', 'Total Stare Time (hours)'];
    const returnData = countData.count.map((val) => ({
      'Age Group': val?.name,
      'Total Stay Time (hours)': val?.stay,
      'Total Stare Time (hours)': val?.stray,
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };

  return (
    <div className="report-user-total-count">
      <div className="bar-chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={countData?.chartCount}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: '#717880', fontSize: 13 }} />
            <YAxis tick={{ fill: '#717880', fontSize: 13 }} />
            <Tooltip />
            <Legend content={<CustomLegend />} />
            <Bar dataKey="stay" fill={stayColor} barSize={30} name="Stay time" />
            <Bar dataKey="stray" fill={strayColor} barSize={30} name="Stray time" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData.count}
          status="success"
          onFilterOrSortingChange={filterFunction}
          rowSelection={[]}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount={10}
          exportButton
          exportData={exportData}
        />
      </div>
    </div>
  );
}

ReportTimes.propTypes = {
  gender: PropTypes.string.isRequired,
  filterFunction: PropTypes.func.isRequired,
  countData: PropTypes.objectOf(PropTypes.arrayOf),
};
ReportTimes.defaultProps = {
  countData: {
    count: [],
    chartCount: [],
  },
};

export default ReportTimes;
