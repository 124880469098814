import {
  get,
} from './base/index';

export default {
  getPopSchedule: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/pop`, { params });
  },
  exportToCsv: () => get(`${process.env.REACT_APP_API_URL}cms/v1/pop/export`),
};
