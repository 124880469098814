import {
  get,
  patch,
} from './base/index';

export default {
  getNotifications: () => get(`${process.env.REACT_APP_API_URL}cms/v1/notifications`),
  updateNotifications: () => patch(`${process.env.REACT_APP_API_URL}cms/v1/notifications`),
  getNotificationCount: () => get(`${process.env.REACT_APP_API_URL}cms/v1/notifications/get-unread-notification`),
};
