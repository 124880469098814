import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { changeDateFormat, currencySymbol, useRecaptcha } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import { ViewIcon } from '../../../shared/component/svgIcon';
import SelectBox from '../../../shared/component/form/SelectBox';
import { Input, InputDate, InputInnerSymbol } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

function PlanSwitchSummary(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { VerifyRecaptcha } = useRecaptcha();
  const {
    newPlanId,
    planSwitchTenure,
    planSwitchSummary,
    subscriptionStatus,
    planSwitchSuccess,
    backModal,
  } = props;
  const [payment, setPayment] = useState({});
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');
  // const [planSwitchSummary, setPlanSwitchSummary] = useState({});
  // const [loadingSummaryData, setLoadingSummaryData] = useState(true);
  const localData = JSON.parse(localStorage.getItem('data'));
  const paymentOption = [
    {
      label: 'Net banking',
    },
    {
      label: 'UPI',
    },
    {
      label: 'Other',
    },
  ];
  const currency = planSwitchSummary?.active_subscription?.currency || 'USD';
  const activeSubscriptionDetails = [
    {
      field: t('displays'),
      value: planSwitchSummary?.active_subscription?.display || 0,
    },
    {
      field: t('tenure'),
      value: `${planSwitchSummary?.active_subscription?.tenure || 0} months`,
      startDate: changeDateFormat(planSwitchSummary?.active_subscription?.start_date),
      endDate: changeDateFormat(planSwitchSummary?.active_subscription?.end_date),
    },
    {
      field: t('remainingDays'),
      value: planSwitchSummary?.active_subscription?.remaining_days || 0,
    },
  ];

  const planSwitchDetails = [
    {
      title: t('currentPlan'),
      planDetails: [
        {
          field: t('planName'),
          value: planSwitchSummary?.current_plan?.plan_name || '',
        },
        {
          field: t('unitPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.current_plan?.unit_price?.toFixed(2) || 0}`,
        },
        {
          field: t('totalPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.current_plan?.plan_amount?.toFixed(2) || 0}`,
        },

      ],
    },
    {
      title: t('newPlan'),
      planDetails: [
        {
          field: t('planName'),
          value: planSwitchSummary?.new_plan?.plan_name || '',
        },
        {
          field: t('unitPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.new_plan?.unit_price?.toFixed(2) || 0}`,
        },
        {
          field: t('totalPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.new_plan?.plan_amount?.toFixed(2) || 0}`,
          tooltip: <>The calculated amount is based on the <br />Remaining days × Displays × Cost per device.</>,
        },
      ],
    },
  ];

  const back = () => backModal(false);

  const submitDevice = async () => {
    const recaptchaToken = await VerifyRecaptcha('plan-change-summary');
    setDisable(true);
    setButtonStatus('loading');
    const deviceCount = localData?.user?.company?.previous_subscription_id?.device?.count;
    const request = (subscriptionStatus === 'active' || subscriptionStatus === 'suspended')
      ? {
        transaction_id: payment?.transaction_id,
        transaction_date: payment?.transaction_date,
        transaction_type: payment?.transaction_type?.label,
        transaction_amount: planSwitchSummary?.payment_summary?.amount_to_paid,
        plan: newPlanId,
        tenure: planSwitchTenure,
        recaptchaToken,
        payment_mode: 'offline',
      }
      : {
        transaction_amount: planSwitchSummary?.payment_summary?.amount_to_paid,
        plan: newPlanId,
        tenure: planSwitchTenure,
        recaptchaToken,
        device: deviceCount,
        payment_mode: 'offline',
        type: 'renewal',
      };
    subscription.planChange(request, subscriptionStatus)
      .then(() => {
        setDisable(false);
        setButtonStatus('');
        planSwitchSuccess('plan');
      })
      .catch((error) => {
        setDisable(false);
        setButtonStatus('');
        dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
      });
  };

  const handleChange = (name, changeValue) => {
    setPayment((prevValue) => ({
      ...prevValue,
      [name]: changeValue,
    }));
  };

  // useEffect(() => {
  //   setLoadingSummaryData(true);
  //   subscription.getPlanChangeSummary({
  //     new_plan_id: newPlanId,
  //     tenure: planSwitchTenure,
  //   })
  //     .then((res) => {
  //       setLoadingSummaryData(false);
  //       setPlanSwitchSummary(res?.data);
  //     })
  //     .catch((err) => {
  //       setLoadingSummaryData(false);
  //       dispatch(setErrorNotification(err?.response?.data));
  //     });
  // }, [newPlanId]);

  useEffect(() => {
    if (
      (payment?.transaction_id
        && payment?.transaction_date
        && payment.transaction_type
      ) || Number(planSwitchSummary?.payment_summary?.amount_to_paid) <= 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payment, planSwitchSummary]);
  return (
    <div className="plan-switch-summary">
      {/* {loadingSummaryData
        && (
          <div className="loader-wrap">
            <CircularProgress />
          </div>
        )} */}
      <div className="active-subscription-wrap">
        <h2 className="section-header">{t('activeSubscription')}</h2>
        <div className="active-subscription-details">
          {activeSubscriptionDetails.map((detail) => (
            <div className="subscription-detail">
              <h4>{detail.field}:</h4>
              <p>{detail?.value}</p>
              {(detail?.startDate && detail?.endDate)
                && <span>({detail?.startDate} - {detail?.endDate})</span>}
            </div>
          ))}
        </div>
      </div>
      <div className="plan-switch-details">
        {planSwitchDetails.map((plan) => (
          <div className="plan-details-wrap">
            <h2 className="section-header">{plan.title}</h2>
            <div className="plan-details">
              {plan.planDetails.map((detail) => (
                <div className={detail?.tooltip ? 'plan-detail' : 'plan-detail pr-20'}>
                  <h4>{detail.field}:</h4>
                  <p>{detail?.value}</p>
                  {detail?.tooltip
                    && (
                      <Tooltip
                        title={detail?.tooltip}
                        placement="top"
                      >
                        <span className="tooltip-container">
                          <ViewIcon />
                        </span>
                      </Tooltip>
                    )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="plan-details-wrap">
          <h2 className="section-header">{t('paymentSummary')}</h2>
          <div className="payment-summary">
            <h4>{t('amountRemainingFromExistingPlan')} :</h4>
            <div className="price pr-20">
              <p>
                {currencySymbol(currency)} {planSwitchSummary?.current_plan?.plan_remaining_amount || 0}
              </p>
            </div>
          </div>
          <div className="payment-summary">
            <h4>{t('amountAdjustedFromExistingPlan')} :</h4>
            <div className="price">
              <p>
                - {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.adjust_from_existing_plan || 0}
              </p>
              <Tooltip
                title={t('adjustedToolTipMessage')}
                placement="top"
              >
                <span className="tooltip-container">
                  <ViewIcon />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="payment-summary">
            {(() => {
              let addonHeader;
              if (Number(planSwitchSummary?.addon_summary?.addOnPrice) > 0) {
                addonHeader = (
                  <h4>{t('addonAmountToBePaid')} :</h4>
                );
              } else if (Number(planSwitchSummary?.addon_summary?.addOnPrice) < 0) {
                addonHeader = (
                  <h4>{t('addonAmountToBeAddedInWallet')} :</h4>
                );
              } else {
                addonHeader = (
                  <h4>{t('addonAmount')} :</h4>
                );
              }
              return addonHeader;
            })()}
            <div className="price pr-20">
              {(() => {
                let addOnnPrice;
                if (Number(planSwitchSummary?.addon_summary?.addOnPrice) > 0) {
                  addOnnPrice = (
                    <p>
                      {currencySymbol(currency)} {Math.abs(planSwitchSummary?.addon_summary?.addOnPrice) || 0}
                    </p>
                  );
                } else if (Number(planSwitchSummary?.addon_summary?.addOnPrice) < 0) {
                  addOnnPrice = (
                    <p>
                      + {currencySymbol(currency)} {Math.abs(planSwitchSummary?.addon_summary?.addOnPrice) || 0}
                    </p>
                  );
                } else {
                  addOnnPrice = (
                    <p>
                      {currencySymbol(currency)} 0.00
                    </p>
                  );
                }
                return addOnnPrice;
              })()}
            </div>
          </div>
          <div className="payment-summary">
            <h4>{t('wallet')} :</h4>
            <div className="price pr-20">
              {(() => {
                let walletPrice;
                if (Number(planSwitchSummary?.payment_summary?.credit_to_wallet) > 0) {
                  walletPrice = (
                    <p className="amount-credit">
                      + {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.credit_to_wallet || 0}
                    </p>
                  );
                } else if (Number(planSwitchSummary?.payment_summary?.debit_from_wallet) > 0) {
                  walletPrice = (
                    <p className="amount-debit">
                      - {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.debit_from_wallet || 0}
                    </p>
                  );
                } else {
                  walletPrice = (
                    <p>
                      {currencySymbol(currency)} 0.00
                    </p>
                  );
                }
                return walletPrice;
              })()}
            </div>
          </div>
        </div>
        <div className="final-price">
          <h4>{t('amountToBePaid')} :</h4>
          <p className="pr-20">
            {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.amount_to_paid?.toFixed(2) || 0}
          </p>
        </div>
      </div>
      {
        planSwitchSummary?.payment_summary?.amount_to_paid > 0 && (
          <div className="payment-form">
            <div className="payment-info-form">
              <h2>{t('transactionDetails')}</h2>
              <div className="single-row">
                <Input
                  type="text"
                  label={t('transactionID')}
                  required
                  name="transaction_id"
                  value={payment?.transaction_id}
                  change={handleChange}
                  placeholder={t('enterTransactionID')}
                />
                <InputDate
                  label={t('transactionDate')}
                  name="transaction_date"
                  required
                  value={payment?.transaction_date}
                  change={handleChange}
                  disableFuture
                />
                <SelectBox
                  label={t('transactionMode')}
                  name="transaction_type"
                  required
                  value={payment?.transaction_type}
                  onchange={handleChange}
                  lists={paymentOption}
                  id="select-account"
                  placeholder={t('selectTransactionMode')}
                />
                <InputInnerSymbol
                  type="number"
                  label={t('transactionAmount')}
                  required
                  value={planSwitchSummary?.payment_summary?.amount_to_paid}
                  placeholder="0"
                  symbol={currencySymbol(currency)}
                  min={0}
                  readonly
                />
              </div>
            </div>
          </div>
        )
      }
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button" style={{ width: '5vw' }}>
          <Button
            label={t('back')}
            click={back}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submitDevice}
            classes={buttonStatus === 'loading'
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonStatus === 'loading'}
          />
        </div>
      </div>
    </div>
  );
}

PlanSwitchSummary.propTypes = {
  newPlanId: PropTypes.string.isRequired,
  planSwitchTenure: PropTypes.number.isRequired,
  planSwitchSummary: PropTypes.objectOf().isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
  planSwitchSuccess: PropTypes.func.isRequired,
  backModal: PropTypes.func.isRequired,
};

export default PlanSwitchSummary;
