/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@mui/material';

import DataTable from '../Table';

import ModalBox from '../../shared/component/ModalBox';

import DeviceStatusDetails from './components/DeviceStatus';
import height from '../../utils/size-variables';
import PlayerAppUpdateModal from './components/PlayerAppUpdateModal';
import TopContent from '../../shared/component/TopContent';
import { getTimeDifference, statusColor } from '../../utils/helpers';
import { ViewIcon } from '../../shared/component/svgIcon';
import SelectBox from '../../shared/component/form/SelectBox';
import { AiSwitch } from '../../shared/component/form/Input';
import Button from '../../shared/component/Button';
import UsageReport from './components/UsageReport';
import CreditManagement from './components/CreditManagement';
import aiInstance from '../../utils/api/ai-instance';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import {
  fetchDeviceList,
  getInstanceList,
  instanceTransferStatus,
  setFilter,
  setTableFilter,
} from '../../redux/slices/AiAnalyticsSlice';

function Devices() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    aiDevices,
    pageCount,
    totalPageCount,
    totalDataCount,
    filter,
    tableFilter,
    type,
  } = useSelector((state) => state?.AiAnalytics);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [storage, setStorage] = useState({
    totalSize: 0,
    totalGB: '',
  });

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    orientation: false, // Set orientation column hidden by Default
  });
  const [updates, setUpdates] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [devices, setDevices] = useState(aiDevices);
  const [instances, setInstances] = useState([]);
  const [instanceList, setInstanceList] = useState([]);

  // // Function to handle get device list
  const getDevices = (counts, filters = {}) => {
    dispatch(fetchDeviceList({
      page: counts,
      limit: 20,
      name: filters.name,
      store: filters.store_name,
      player_app_version: filters.player_app_version,
      status: filters.status,
      sortBy: filters.sortBy,
      sortField: filters.sortField,
    }));
  };
  const handleScroll = () => {
    getDevices(pageCount + 1, filter);
  };

  const getAiInstanceList = () => {
    aiInstance.availableInstances()
      .then((res) => {
        const formatInstance = res?.data?.map((item) => ({
          id: item?._id,
          label: item?.name,
        }));
        setInstances([...formatInstance]);
        dispatch(getInstanceList(formatInstance));
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const getInstanceDetails = (data) => {
    aiInstance.creditManagementList(data)
      .then((res) => {
        setInstanceList(res?.data);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const getAllApis = () => {
    getAiInstanceList();
    getInstanceDetails({});
  };

  const filterFunction1 = (data) => {
    const request = {
      name: data.name,
      total_available_hours: data.total_available_hours,
      available_hours: data.available_hours,
      activation_date: data.updatedAt,
      sortBy: data.sortBy,
      sortField: data.sortField,
    };
    getInstanceDetails(request);
  };
  // Fetch the total & available storage of the company
  const getTotalInstance = () => {
    aiInstance.instanceCount().then((response) => {
      setStorage({
        ...storage,
        InstanceActiveDevice: response?.data?.instance_live_count,
        totalDevice: response?.data?.device_count,
        imageSize: '',
        imageCount: '',
        videoSize: '',
        videoCount: '',
      });
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
    });
  };

  const handleChangeRadio = (index, checked, deviceDetails) => {
    const DeviceStatus = {
      device_id: deviceDetails?._id,
      ai_instance_id: deviceDetails?.ai_instance?.ai_instance_id,
      ai_instance_status: checked,
    };

    if (updates.length === 0) {
      setUpdates([DeviceStatus]);
    } else {
      setUpdates((prevUpdates) => {
        const filteredUpdates = prevUpdates.filter(
          (update) => update.device_id !== DeviceStatus.device_id
            || update.ai_instance_id !== DeviceStatus.ai_instance_id,
        );
        // Add the new DeviceStatus
        return [...filteredUpdates, DeviceStatus];
      });
    }
    setDevices((prevDevices) => prevDevices.map((device, i) => {
      if (i === index) {
        return {
          ...device,
          ai_instance_status: checked, // Update the ai_instance_status field
        };
      }
      return device;
    }));
  };
  const handleFunction = (index, newValue, deviceDetails) => {
    setUpdates((prevUpdates) => [
      ...prevUpdates,
      {
        device_id: deviceDetails?._id,
        ai_instance_id: newValue?.id,
        ai_instance_status: deviceDetails?.ai_instance_status,
      },
    ]);
    const updatedDevices = devices
      .map((device, i) => (i === index
        ? {
          ...device,
          ai_instance_name: newValue?.label,
          ai_id: newValue?.id,
        } : device));
    setDevices(updatedDevices);
    /* When an option is selected in any row, it updates the selectedOptions state */
    if (newValue !== null) {
      const newSelectedOptions = [...selectedOptions, newValue];
      setSelectedOptions(newSelectedOptions);
    } else if (newValue === null) {
      const newSelectedOptions = selectedOptions.filter((option) => option.id !== deviceDetails.ai_id);
      setSelectedOptions(newSelectedOptions);
    }
    // newSelectedOptions[index] = newValue;
  };
  const submit = () => {
    setUploading(true);
    setDisable(true);

    const uniqueUpdates = updates
      .reduce((acc, current) => {
        const existingIndex = acc.findIndex((item) => item.device_id === current.device_id);
        if (existingIndex === -1) {
          acc.push(current);
        } else {
          acc[existingIndex] = current; // Replace the existing item with the latest one
        }

        return acc;
      }, []).filter((item) => item.ai_instance_id !== 'none');
    const filteredUpdates = uniqueUpdates.filter(
      (update) => !devices.some((device) => device._id === update.device_id
        && device.ai_instance?.ai_instance_id === update.ai_instance_id
        && device.ai_instance?.ai_instance_status === update.ai_instance_status
        && device.ai_instance_id !== undefined),
    );
    const updatedValue = filteredUpdates.filter((item) => item.ai_instance_id !== undefined);
    aiInstance.updateAIDevices({ devices: updatedValue })
      .then(() => {
        setUploading(false);
        setUpdates([]);
        setDisable(false);
        getTotalInstance();
        getDevices(1);
      })
      .catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  // Content table headers
  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('facility'),
      accessorKey: 'store_name',
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: (
        <div className="ai-device-player-column">
          <span>{t('playerVersion')}</span>
          <Tooltip
            placement="top-end"
            title={
              <span className="tooltip-text">{t('playerVersion1.1.5More')}</span>
            }
          >
            <div>
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      accessorKey: 'player_app_version',
      enableSorting: false,
      size: 190,
      Cell: ({ row }) => (
        <div className="ai-device-player">
          {
            (() => {
              let playerLink;
              if (row.original?.versionFlag) {
                playerLink = (
                  <span>{row.original?.player_app_version}</span>
                );
              } else {
                playerLink = (
                  <div
                    role="presentation"
                    className="low-version"
                    onClick={() => {
                      if (!openModal) {
                        setOpenModal(true);
                      }
                    }}
                  >
                    {row.original?.player_app_version}
                  </div>
                );
              }
              return playerLink;
            })()
          }
        </div>
      ),
    },
    {
      header: t('aiVersion'),
      accessorKey: 'ai_version',
      size: 220,
    },
    {
      header: t('cameraResolution'),
      accessorKey: 'camera_resolution',
      size: 220,
    },
    {
      header: t('enableAi'),
      accessorKey: 'ai_instance_status',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      // enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 110,
      Cell: ({ row }) => (
        // when we use cell it can be override the accessorKey
        <AiSwitch
          type="checkbox"
          name="enable-ai"
          checked={row?.original?.ai_instance_status}
          change={(name, checked) => handleChangeRadio(row.index, checked, row?.original)}
          id={`toggle-checkbox-${row?.index}`}
        />
      ),
    },
    {
      header: t('remainingHours'),
      accessorKey: 'available_hours',
      // enableColumnFilter: false,
      size: 220,
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center' }}>
          {row?.original?.ai_instance.available_hours
            ? <span>{row?.original?.ai_instance.available_hours} h</span>
            : <span> - </span>}
        </div>
      ),
    },
    {
      header: t('instance'),
      accessorKey: 'ai_instance_name',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      Cell: ({ row }) => (
        <SelectBox
          name="ai_instance_name"
          lists={instances}
          value={row?.original?.ai_instance_name}
          selectedOptions={selectedOptions}
          onchange={(name, newValue) => {
            handleFunction(row?.index, newValue, row?.original);
          }}
          placeholder={t('select')}
          id={`ai_instance.ai_instance_id-${row?.index}`}
          disabled={!row?.original?.ai_instance_status}
        />
      ),
    },
    {
      header: t('status'),
      accessorKey: 'status',
      enableColumnFilter: false,
      size: 130,
      Cell: ({ row }) => {
        const lastPollTime = (new Date() - new Date(row?.original?.lastPollAt)) <= 30 * 60 * 1000;
        const timeDifference = row.original.lastPollAt ? ` 
      Active ${getTimeDifference(row.original.lastPollAt)}
    ` : 'Not found';
        const value = lastPollTime ? 'Active' : 'Inactive';
        return (
          <div className="device-status">
            <Box
              component="span"
              sx={() => ({
                backgroundColor: statusColor(value).backgroundColor,
                borderRadius: '0.25rem',
                color: statusColor(value).color,
                p: '0.25rem',
              })}
            >
              <span>{t(value)}</span>
            </Box>
            {value === 'Inactive'
              && (
                <Tooltip title={timeDifference}>
                  <div className="tooltip-container">
                    <ViewIcon className="status-view-icon" />
                  </div>
                </Tooltip>
              )}
          </div>
        );
      },
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDevices(1, filteredValues);
  };

  // Get storage stats of a company when the page loads
  useEffect(() => {
    setIsFirstRender(false);
    getDevices(1);
    getTotalInstance();
  }, []);
  useEffect(() => {
    setDevices(aiDevices);
    const initialSelectedOption = aiDevices.filter((device) => device.ai_instance.ai_instance_status === true);
    if (initialSelectedOption) {
      const selectedOption = initialSelectedOption.map((item) => ({
        id: item?.ai_instance?.ai_instance_id,
        label: item?.ai_instance?.ai_instance_name,
      }));
      setSelectedOptions(selectedOption);
    }
  }, [aiDevices]);
  useEffect(() => {
    const enableSubmit = devices.some((item) => item?.ai_instance?.ai_instance_id);
    if (enableSubmit) {
      setDisable(false);
    } else {
      setDisable(false);
    }
  }, [devices]);
  useEffect(() => {
    getAllApis();
    dispatch(instanceTransferStatus(''));
  }, [type]);
  return (
    <div className="content">
      <div className="main-content">
        <ModalBox
          status={openModal}
          closeModal={setOpenModal}
          modalView={{
            title: t('playerVersionUpdate'),
            content: <PlayerAppUpdateModal
              close={setOpenModal}
            />,
          }}
        />
        <TopContent
          label={t('devices')}
        />
        <span style={{ fontSize: '14px', fontWeight: '500', marginBottom: '15px' }}>{t('deviceManagement')}</span>
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area ai-device-table">
              <DataTable
                className="my-data-table"
                reduxColumnFiltersSorting={tableFilter}
                onFilterOrSortingChange={onFilterOrSortingChange}
                header={columns}
                value={devices}
                status="success"
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                height={height.tableHeight}
                isFirstRender={isFirstRender}
                showToolbarLeft
                toolbarLeft={
                  (<DeviceStatusDetails {...storage} />)
                }
                boxShadow="true"
              />
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={uploading ? t('loading') : t('saveChanges')}
                    click={submit}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    disabled={disable}
                    loading={uploading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <UsageReport />
        <CreditManagement
          instanceList={instanceList}
          getDevices={getDevices}
          getAllApis={getAllApis}
          filterFunction={filterFunction1}
        />
      </div>
    </div>
  );
}
Devices.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      ai_instance_name: PropTypes.string,
      ai_instance_status: PropTypes.bool,
      lastPollAt: PropTypes.string.isRequired,
      player_app_version: PropTypes.string.isRequired,
      lastPollTime: PropTypes.string.isRequired,
      timeDifference: PropTypes.string.isRequired,
      instance: PropTypes.string.isRequired,
      versionFlag: PropTypes.bool.isRequired,
      ai_instance: PropTypes.shape({
        ai_instance_status: PropTypes.bool,
        ai_instance_id: PropTypes.string,
        available_hours: PropTypes.string,
        ai_instance_name: PropTypes.string,
      }),
      remaining_hours: PropTypes.string.isRequired,
    }),
  }),
};
Devices.defaultProps = {
  row: null,
};

export default Devices;
