import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { SubScriptionExpiredIcon } from './svgIcon';
import Button from './Button';
import { getDaysBetweenDates, setLocalStorageItem } from '../../utils/helpers';

function SubscriptionRenewal(props) {
  const { errorMessage, close } = props;
  const data = JSON.parse(localStorage.getItem('data'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [planEndDate, setPlanEndDate] = useState('');
  const [extraDays, setExtraDays] = useState();
  useEffect(() => {
    const subscriptionValue = data?.user?.company?.subscription;
    const date = new Date(subscriptionValue?.end_date);
    const formattedDate = date?.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    const getDiffDays = getDaysBetweenDates(subscriptionValue?.end_date, subscriptionValue?.grace_date);
    setPlanEndDate(formattedDate);
    setExtraDays(getDiffDays);
  }, [data]);
  const subscriptionCancel = () => (
    <>
      <h1 className="message-title">{t('subscriptionCancel')}</h1>
      <div className="message-description">
        <p>{t('yourSubscriptionHasCancel')}</p>
        <p>{t('toContinueEnjoyingOurServicesPleaseRenewYourSubscription')}</p>
      </div>
    </>
  );
  const subscriptionExpired = () => (
    <>
      <h1 className="message-title">{t('subscriptionExpired')}</h1>
      <div className="message-description">
        <p>{t('yourSubscriptionHasExpired')}</p>
        <p>{t('toContinueEnjoyingOurServicesPleaseRenewYourSubscription')}</p>
      </div>
    </>
  );
  const subscriptionExpiredSoon = () => (
    <>
      <h1 className="message-title">{t('subscriptionRenewal')}</h1>
      <div className="message-description">
        <p
          dangerouslySetInnerHTML={{
            __html: t('yourSubscriptionIsAboutToExpireIn', {
              expiredDays: 3,
              interpolation: { escapeValue: false },
            }),
          }}
        />
        <p>{t('toContinueEnjoyingOurServicesPleaseRenewYourSubscription')}</p>
      </div>
    </>
  );
  // using dangerouslySetInnerHTML for dynamically added date and days
  const subscriptionExtend = () => (
    <>
      <h1 className="message-title">{t('subscriptionExpired')}</h1>
      <div className="message-description">
        <p
          dangerouslySetInnerHTML={{
            __html: t('subscriptionExtendMessage', {
              expiryDate: planEndDate,
              extraDays,
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </div>
    </>
  );
  const renderErrorMessage = (message) => {
    switch (message) {
      case 'Subscription cancelled':
        return subscriptionCancel();
      case 'Subscription expired':
        return subscriptionExpired();
      case 'Subscription expires soon':
        return subscriptionExpiredSoon();
      case 'Subscription expires extended':
        return subscriptionExtend();
      default:
        return null;
    }
  };
  const submit = () => {
    close(false);
    navigate('/admin/subscription');
    setLocalStorageItem('showSubscriptionPrompt', true);
  };
  return (
    <div className="subscription-renewal">
      <SubScriptionExpiredIcon />
      {renderErrorMessage(errorMessage)}
      <div className="form-button">
        <Button
          label={errorMessage === 'Subscription expires soon' ? t('viewPlans') : t('renewNow')}
          click={submit}
          classes="success-button"
        />
      </div>
    </div>
  );
}

SubscriptionRenewal.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  close: PropTypes.node.isRequired,
};
export default SubscriptionRenewal;
