import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import sortIcon from '../../assets/icons/active-sort.png';
// import DropDown from './DropDown';

// import calender from '../../assets/icons/calendar-tick.png';
import PopupModal from './PopupModel';

import { changeDateTimeFormat } from '../../utils/helpers';
import profile from '../../assets/images/download.jpg';
import DropDownMenu from './DropDownMenu';
import { MoreMenuIcon } from './svgIcon';

function TableForm(props) {
  const datas = props?.header;
  const { t } = useTranslation();
  const noDataSpan = props?.checkBox ? (datas.length + 1) : datas.length;
  const sort = (key) => {
    props?.sort(key);
  };
  const handleChange1 = (event) => {
    if (props?.checkedValues.includes(event.target.value)) {
      const select = props?.checkedValues.filter((val) => val !== event.target.value);
      props?.checkBoxValue('delete', [...select]);
    } else {
      props?.checkBoxValue('add', event.target.value);
    }
  };

  const selectAll = (event) => {
    const permittedValues = props?.value.map((value) => value.id);
    if (event.target.checked) {
      props?.allChecked(permittedValues);
    } else {
      props?.allChecked([]);
    }
  };
  return (
    <table className="table">
      <thead>
        <tr className={props?.class}>
          {
            props?.checkBox && (
              <td aria-label="Save">
                <Checkbox
                  size="small"
                  sx={{
                    color: '#8E8E8E',
                    '&.Mui-checked': {
                      color: '#30A84B',
                    },
                  }}
                  onChange={selectAll}
                  checked={props?.selectAllValue}
                />
              </td>
            )
          }
          {
            props?.header.map((value) => (
              <td key={props?.key}>
                <div className={value.key === 'status' && 'text-center'}>{t(value.name)}
                  {
                    value.sort
                    && (
                      <div className="sort-button" role="presentation" onClick={() => sort(value.key)}>
                        <img src={sortIcon} alt="sort" />
                      </div>
                    )
                  }
                </div>
              </td>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          props?.value.map((value) => (
            <tr className={props?.class}>
              {
                props?.checkBox && (
                  <td aria-label="Save">
                    <Checkbox
                      size="small"
                      checked={props?.checkedValues && props?.checkedValues.includes(value.id)}
                      sx={{
                        color: '#8E8E8E',
                        '&.Mui-checked': {
                          color: '#30A84B',
                        },
                      }}
                      onChange={handleChange1}
                      value={value.id}
                    />
                  </td>
                )
              }
              {
                props?.header.map((key) => {
                  let val;
                  val = (
                    <td>
                      {key.tag && value.tag
                        ? (
                          <div className="coll-tag">
                            {value[key.key].substring(0, 50)}{value[key.key].length > 49 && '...'}
                            <div className="tag">{value.tag}</div>
                          </div>
                        )
                        : value[key.key] && value[key.key].toString().replace(/,/g, ', ').substring(0, 50)}{value[key.key] && value[key.key].toString().length > 49 && '...'}
                    </td>
                  );
                  if (key.avatar) {
                    val = (
                      <td>
                        <div className="coll-tag-avatar">
                          <img src={value.signedUrl ? value.signedUrl : profile} alt="profileImage" />
                          <div className="profile_name">
                            {value[key.key].substring(0, 50)}{value[key.key].length > 49 && '...'}
                            <p>{value.email}</p>
                          </div>
                        </div>
                      </td>
                    );
                  }
                  if (key.popup) {
                    val = (
                      <td aria-label="Save">
                        <PopupModal list={value[key.key]} name={key.key} />
                      </td>
                    );
                  }
                  if (key.array) {
                    val = (
                      <td className="array-col">
                        {
                          value[key.key].map((values) => (<p>{values.name} : <b>{values.value}</b></p>))
                        }
                      </td>
                    );
                  }
                  if (key.status_tag) {
                    val = (
                      <td className="array-col">
                        {
                          value[key.key] === 'Online'
                            ? (<div className="success-tag">{value[key.key]}</div>)
                            : (<div className="danger-tag">{value[key.key]}</div>)
                        }
                      </td>
                    );
                  }
                  if (key.key === 'status') {
                    let values = '';
                    switch (value[key.key]) {
                      case 'cancelled':
                        values = <div className="danger-tag">Cancel</div>;
                        break;
                      case 'inProgress':
                        values = <div className="warning-tag">Ongoing</div>;
                        break;
                      default:
                        values = <div className="success-tag">Completed</div>;
                        break;
                    }
                    val = (
                      <td>
                        <div className="coll-tag-1">{values}</div>
                      </td>
                    );
                  }
                  if (key.key === 'file_size') {
                    val = (
                      <td label="size" className="file-size-wrap">
                        <div className="file-size">
                          {value[key.key]}
                        </div>
                      </td>
                    );
                  }
                  if (key.key === 'lastPollTime') {
                    let values = '';
                    switch (value[key.key]) {
                      case false:
                        values = <div className="danger-tag">Inactive</div>;
                        break;
                      default:
                        values = <div className="success-tag">Active</div>;
                        break;
                    }
                    val = (
                      <td>
                        <div className="coll-tag-1">{values}</div>
                      </td>
                    );
                  }
                  if (key.key === 'createdAt' || key.key === 'updatedAt') {
                    val = (
                      <td className="array-col">
                        {changeDateTimeFormat(value[key.key])}
                      </td>
                    );
                  }
                  if (key.key === 'scheduleDate' || key.key === 'scheduledTime' || key.key === 'available_time') {
                    val = (
                      <td>
                        {value[key.key]}
                      </td>
                    );
                  }
                  if (key.key === 'devices_list' || key.key === 'contents_list') {
                    val = (
                      <td aria-label="Save">
                        <PopupModal list={value[key.key]} name={key.key.replace('_', ' ')} />
                      </td>
                    );
                  }
                  if (key.key === 'available_days_count') {
                    val = (
                      <td className="array-col">
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                          <div className="success-tag">{value[key.key]}</div>
                          {/* {value[key.key] > 0
                            && <DropDown action={value.available_days} value={value} icon={calender} />} */}
                        </div>
                      </td>
                    );
                  }
                  if (key.key === 'unavailable_days_count') {
                    val = (
                      <td className="array-col">
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                          <div className="danger-tag">{value[key.key]}</div>
                          {/* {value[key.key] > 0
                            && <DropDown action={value.unavailable_days} value={value} icon={calender} />} */}
                        </div>
                      </td>
                    );
                  }
                  if (key.key === 'action') {
                    val = (
                      <td className="array-col" aria-label="Save">
                        <DropDownMenu
                          action={props?.action}
                          icon={<MoreMenuIcon />}
                          value={value}
                          dropdown="tableForm"
                          tooltip=""
                        />
                      </td>
                    );
                  }
                  return val;
                })
              }
            </tr>
          ))
        }
        {
          props?.value.length === 0 && props?.loading !== 'loading'
          && (<tr><td colSpan={noDataSpan} className="no-data-found">No data found</td></tr>)
        }
      </tbody>
    </table>
  );
}

TableForm.propTypes = {
  header: PropTypes.arrayOf().isRequired,
  value: PropTypes.arrayOf().isRequired,
  action: PropTypes.arrayOf().isRequired,
  checkBox: PropTypes.bool.isRequired,
  checkBoxValue: PropTypes.func.isRequired,
  checkedValues: PropTypes.arrayOf().isRequired,
  class: PropTypes.string.isRequired,
  allChecked: PropTypes.func.isRequired,
  sort: PropTypes.func.isRequired,
  loading: PropTypes.string.isRequired,
  selectAllValue: PropTypes.bool.isRequired,
  key: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default TableForm;
