import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getLocations: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/stores`, { params });
  },
  getCityStateCountry: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/stores/country-state-city`, { params });
  },
  getLocationsAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/stores/list`, { params });
  },
  getSingleLocation: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/stores/${data}`, {}),
  addLocation: (data) => post(`${process.env.REACT_APP_API_URL}cms/v1/stores`, data),
  updateLocation: (data, storeId) => patch(`${process.env.REACT_APP_API_URL}cms/v1/stores/${storeId}`, data),
  deleteLocation: (data) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/stores/${data}`, {}),
  storeDataExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/stores/export`, { ...data }),
  // Floor map api's
  addSingleFloor: (data, id) => post(`${process.env.REACT_APP_API_URL}cms/v1/stores/${id}`, data),
  updateFloorSingleFloor: (data, id) => patch(`${process.env.REACT_APP_API_URL}cms/v1/stores/floor-map/${id}`, data),
  deleteFloorById: (id) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/stores/floor-map/${id}`),
};
