/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import deepEqual from 'deep-equal';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import profile from '../../../assets/images/download.jpg';
import SelectBox from '../../../shared/component/form/SelectBox';

import userApi from '../../../utils/api/users';
import { updateUser } from '../../../redux/slices/UsersSlice';
import { fetchRolesListAll } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fileUploadValidation } from '../../../utils/helpers';
import { nameValidation } from '../../../shared/component/form/Validation';
import FacilityList from './FacilityList';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import PreviewStore from './PreviewFacility';

function UserAdd() {
  let backClick;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const rolesData = useSelector((state) => state.roles.rolesAllList);
  const users = useSelector((state) => state.users.users);

  const dataValue = users.find((val) => val.id === params?.id);
  const [selectedRole, setSelectedRole] = useState({
    id: dataValue?.role?.id,
    name: dataValue?.role?.name,
  });
  const [value, setValue] = useState(dataValue);
  const [image, setImage] = useState(dataValue?.signedUrl);

  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [disable, setDisable] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectLocation, setSelectLocation] = useState(dataValue?.stores);
  const [modalView, setModalView] = useState({});

  const addPlaylist = () => {
    setImage('');
    localStorage.removeItem('value');
    navigate('../');
  };

  const submit = () => {
    if (nameValidation(1, 100, value?.name)
      && nameValidation(1, 100, value?.user_name)
    ) {
      setUploading(true);
      const props = {
        id: value?._id,
        name: value?.name,
        role: selectedRole?.id,
        user_name: value?.user_name,
        profile_image: value?.image,
        stores: value?.stores_id,
      };
      if (value?.stores?.length > 0) {
        props.stores = value?.stores_id;
      }
      userApi.updateUser(props)
        .then((res) => {
          dispatch(updateUser(res?.data));
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            navigate('../');
          }, 3000);
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const file = e.target?.files?.[0];
    const checkFileValid = fileUploadValidation(file, maxFileSizeInMB, 'image');
    if (checkFileValid === '') {
      setValue({ ...value, image: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  // Function to changes values on user form
  const handleChange = (name, changeValue) => {
    if (name === 'role' && changeValue) {
      setSelectedRole({
        id: changeValue.id,
        name: changeValue.label,
      });
      setValue({ ...value, role: changeValue.id });
    } else if (name !== 'role' && name !== 'location') {
      setValue({ ...value, [name]: changeValue });
    } else if (name === 'location') {
      setValue((prevState) => ({
        ...prevState,
        ...changeValue,
      }));
    }
  };

  const backModal = (data) => {
    backClick(data);
  };

  const viewData = (data) => {
    setModalView({
      title: t('facilities'),
      content: <PreviewStore
        data={data}
        selectData={handleChange}
        closeModal={setOpenModal}
        back={backModal}
      />,
    });
  };

  // function to change modal open
  const openModalFacility = (data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView({
      title: t('facilities'),
      content: <FacilityList
        selectData={viewData}
        closeModal={setOpenModal}
        values={oldData}
      />,
    });
    setOpenModal(true);
  };

  backClick = (data) => {
    openModalFacility(data);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const removeContentsTags = (name, key) => {
    const removeDetails = value.stores_list.filter((e) => e._id !== key._id);
    const removeId = value.stores_id.filter((e) => e !== key._id);
    setValue({ ...value, stores_list: removeDetails, stores_id: removeId });
  };

  useEffect(() => {
    if (dataValue) {
      setValue((prevState) => ({
        ...prevState,
        stores_id: dataValue?.stores?.map((store) => store?._id),
        stores_list: dataValue?.stores,
      }));
    }
  }, [dataValue]);
  useEffect(() => {
    if (users.length === 0) {
      navigate('../');
    }
    if (rolesData.length === 0) {
      dispatch(fetchRolesListAll());
    }
    if (
      value?.name
      && value?.role
      && value?.user_name
      && !deepEqual(value, dataValue)
      && value.stores_id.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setSelectLocation(value?.stores_list);
  }, [value]);
  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('userUpdatedSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('users'), dataValue?.name]}
          button={false}
        />
        <ModalBox
          status={openModal}
          modalView={modalView}
          closeModal={closeModal}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form-wrap">
                <div className="profile-upload" style={{ height: '100%' }}>
                  <label htmlFor="file" className="upload-click">
                    <img src={image || profile} alt="profile" />
                    {!image ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={onChangePicture}
                    style={{ display: 'none' }}
                  />
                </div>
                <div className="user-form">
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('fullName')}
                      type="text"
                      name="name"
                      required
                      value={value?.name}
                      change={handleChange}
                      placeholder={t('enterName')}
                      error={!nameValidation(1, 100, value?.name)}
                      errorMessage={t('nameValidation')}
                    />
                    <Input
                      icon={' '}
                      label={t('username')}
                      type="text"
                      name="user_name"
                      required
                      value={value?.user_name}
                      change={handleChange}
                      placeholder={t('enterUsername')}
                      error={!nameValidation(1, 100, value?.user_name)}
                      errorMessage={t('userNameValidation')}
                    />
                  </div>
                  <div className="single-row">
                    <SelectBox
                      label={t('role')}
                      name="role"
                      required
                      value={selectedRole?.name}
                      onchange={handleChange}
                      lists={rolesData}
                      id="select-roles"
                    />
                  </div>
                  <MultiSelectBox
                    label={t('store')}
                    buttonLabel={t('select')}
                    clickButton={openModalFacility}
                    required
                    name="location"
                    addData={removeContentsTags}
                    placeholder={t('selectStore')}
                    value={selectLocation}
                  />
                  <div className="form-button-group">
                    <div className="form-button">
                      <Button label={t('back')} click={addPlaylist} classes="default-button" />
                    </div>
                    <div className="form-button">
                      <Button
                        label={uploading ? t('updating') : t('update')}
                        disabled={disable}
                        click={submit}
                        classes={uploading ? 'success-button loading-btn' : 'success-button'}
                        loading={uploading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="form-button-group">
          <div className="form-button">
            <Button label="Back" click={addPlaylist} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? 'Updating' : 'Update'}
              disabled={disable}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default UserAdd;
