import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';

function CustomLegend({ payload }) {
  return (
    <div className="custom-legend">
      {payload.map((entry) => (
        <span key={entry.value} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: entry.color }}
          />
          {entry.value}
        </span>
      ))}
    </div>
  );
}

CustomLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

function ReportManWomanTimes({ countData, filterFunction }) {
  const { t } = useTranslation();

  const stayColor = '#6096FF';
  const strayColor = '#EC4899';

  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('ageGroup'),
      accessorKey: 'ageRange',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalCount'),
      accessorKey: 'total',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalMenCount'),
      accessorKey: 'male',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalWomenCount'),
      accessorKey: 'female',
      enableColumnActions: false,
      enableResizing: false,
    },
  ];

  const exportData = () => {
    const columnHeaders = ['Age Group', 'Total Count', 'Total Men Count', 'Total Women Count'];
    const returnData = countData?.count.map((val) => ({
      'Age Group': val?.ageRange,
      'Total Count': val?.total,
      'Total Men Count': val?.male,
      'Total Women Count': val?.female,
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };

  return (
    <div className="report-user-total-count">
      <div className="bar-chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={countData?.chartCount}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: '#717880', fontSize: 13 }} />
            <YAxis tick={{ fill: '#717880', fontSize: 13 }} />
            <Tooltip />
            <Legend content={<CustomLegend />} />
            <Bar dataKey="stay" fill={stayColor} barSize={30} name="Male" />
            <Bar dataKey="stray" fill={strayColor} barSize={30} name="Female" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData?.count}
          status="success"
          onFilterOrSortingChange={filterFunction}
          rowSelection={[]}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount={10}
          exportButton
          exportData={exportData}
        />
      </div>
    </div>
  );
}

ReportManWomanTimes.propTypes = {
  countData: PropTypes.objectOf(PropTypes.arrayOf),
  filterFunction: PropTypes.func.isRequired,
};
ReportManWomanTimes.defaultProps = {
  countData: {
    chartCount: [],
    count: [],
  },
};

export default ReportManWomanTimes;
