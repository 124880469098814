/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import otaUpdate from '../../utils/api/otaUpdate';
import { setErrorNotification } from './NotificationSlice';

export const fetchUpdateDevices = createAsyncThunk(
  'otaUpdate/fetchUpdateDevices',
  async (data, { dispatch }) => {
    const response = await otaUpdate.getDevices()
      .then((response1) => {
        const tableValues = response1?.data?.map((val) => ({
          ...val,
          id: val?._id,
          store_name: val?.store?.name,
          resolution: val?.display_width_pixels
            ? <span>{val?.display_width_pixels} &times; {val?.display_height_pixels}</span>
            : undefined,
          lastPollTime: (new Date() - new Date(val?.lastPollAt)) <= 30 * 60 * 1000,
        }));
        return tableValues;
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchUpdateDeviceGroups = createAsyncThunk(
  'otaUpdate/fetchUpdateDeviceGroups',
  async (data, { dispatch }) => {
    const response = await otaUpdate.getDeviceGroups()
      .then((response1) => {
        const tableValues = response1?.data?.map((val) => ({
          ...val,
          id: val?._id,
        }));
        return tableValues;
      }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchNotifications = createAsyncThunk(
  'otaUpdate/fetchNotifications',
  async (data, { dispatch }) => {
    const response = await otaUpdate.getNotifications()
      .then((response1) => response1?.data)
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  updateDevices: [],
  updateDeviceGroups: [],
  updateNotifications: [],
  status: 'succeeded',
};

export const otaUpdateSlice = createSlice({
  name: 'otaUpdate',
  initialState,
  reducers: {
    resetOtaUpdateState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUpdateDevices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUpdateDevices.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        state.updateDevices = action.payload;
      })
      .addCase(fetchUpdateDevices.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchUpdateDeviceGroups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUpdateDeviceGroups.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        state.updateDeviceGroups = action.payload;
      })
      .addCase(fetchUpdateDeviceGroups.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        state.updateNotifications = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  resetOtaUpdateState,
} = otaUpdateSlice.actions;

export default otaUpdateSlice.reducer;
