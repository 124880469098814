import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RadioButton } from '../../../../shared/component/form/Input';
import Button from '../../../../shared/component/Button';
import subscription from '../../../../utils/api/subscription';
import Success from '../../../../shared/component/Success';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';

function CancelSubscription(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reasonList = [
    {
      label: 'Too Expensive',
      key: 'Too Expensive',
    },
    {
      label: 'Not User Friendly',
      key: 'Not User Friendly',
    },
    {
      label: 'Difficult to use',
      key: 'Difficult to use',
    },
    {
      label: 'Technical Issues',
      key: 'Technical Issues',
    },
    {
      label: 'Lack of features',
      key: 'Lack of features',
    },
    {
      label: 'Poor Customer Service',
      key: 'Poor Customer Service',
    },
  ];
  const [selectedReason, setSelectedReason] = useState('');
  const [disable, setDisable] = useState(true);
  const [notification, setNotification] = useState(false);
  const handleOptionSwitch = (value) => {
    setSelectedReason(value);
    if (value) {
      setDisable(false);
    }
  };
  const back = () => {
    props?.setOpenModal(false);
  };
  const submit = () => {
    setDisable(true);
    subscription.suspendSubscription({ reason: selectedReason })
      .then((res) => {
        if (res) {
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            props?.setOpenModal(false);
            props?.getBillingDetails();
          }, 3000);
        }
      })
      .catch((err) => {
        setDisable(false);
        dispatch(setErrorNotification(err?.response?.data));
      });
  };
  return (
    <div className="cancel-subscription">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('subscriptionSuspendSuccess')} /> }}
      />
      <div className="cancel-msg">
        {t('subscriptionCancelMessage')}
      </div>
      <div className="content-body">
        <h1 className="feedback-prompt">
          {t('subscriptionCancelFeedbackPrompt')}
        </h1>
        {reasonList?.map((option) => (
          <RadioButton
            label={option?.label}
            value={option?.key}
            checked={selectedReason === option?.key}
            onChange={handleOptionSwitch}
          />
        ))}
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={back}
              classes="default-button"
            />
          </div>
          <div className="form-button">
            <Button
              label={t('submit')}
              click={submit}
              classes="danger-button"
              disabled={disable}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CancelSubscription.propTypes = {
  setOpenModal: PropTypes.node.isRequired,
  getBillingDetails: PropTypes.func.isRequired,
};

export default CancelSubscription;
