import React from 'react';
import PropTypes from 'prop-types';
import IFrameComponent from '../../../../../shared/component/IFrameComponent';

function RSSPreview(props) {
  const { selectedWidget, gridName } = props;

  return (
    <div
      className="widget-preview-wrap"
    >
      <IFrameComponent
        htmlUrl={selectedWidget?.html_url}
        jsUrl={selectedWidget?.widget_id?.script_url}
        title={`rss_${gridName}`}
        replacements={[
          ['duration', selectedWidget?.duration],
          ['rss_display_type', selectedWidget?.rss_display_type],
          ['rss_feed_url', selectedWidget?.rss_feed_url],
        ]}
      />
    </div>
  );
}

RSSPreview.propTypes = {
  selectedWidget: PropTypes.objectOf().isRequired,
  gridName: PropTypes.string.isRequired,
};

export default RSSPreview;
