import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputIncreaseDecrease } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { changeDateFormat, currencySymbol } from '../../../utils/helpers';

function AiInstance(props) {
  const {
    submitInstanceCount,
    closeModal,
    instanceAdd,
    totalInstance,
    currency,
  } = props;
  const [instanceCount, setInstanceCount] = useState(instanceAdd);
  const [disable, setDisable] = useState(true);
  const { t } = useTranslation();
  const unitPrice = 30;
  const instancePrice = instanceCount?.totalInstance > 0 ? instanceCount?.totalInstance : 0;
  const totalInstancePrice = instancePrice * unitPrice;
  const validFrom = changeDateFormat(new Date());
  const handleInputChange = (name, changeValue) => {
    setInstanceCount((prev) => ({
      ...prev,
      [name]: changeValue - totalInstance,
      totalInstance: changeValue,
    }));
  };
  const back = () => closeModal(false);
  const submit = () => submitInstanceCount(instanceCount);
  useEffect(() => {
    if (instanceCount?.totalInstance >= 0) {
      setInstanceCount((prevState) => ({
        ...prevState,
        totalInstancePrice,
        unitPrice,
        validFrom,
      }));
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [instanceCount?.totalInstance]);
  return (
    <div className="display-information">
      <div className="display-form">
        <div className="field">
          <InputIncreaseDecrease
            icon={' '}
            label={t('instance')}
            type="number"
            name="instance"
            required={false}
            value={instanceCount?.totalInstance}
            change={handleInputChange}
            placeholder="0"
            min={0}
          />
        </div>
        <div className="field-price">
          {instanceCount.totalInstance} * {currencySymbol(currency, instanceCount.unitPrice || 0)}/{t('instance')} =
          {currencySymbol(currency, instanceCount.totalInstancePrice || 0)}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('confirm')} click={submit} classes="success-button" disabled={disable} />
        </div>
      </div>
    </div>
  );
}

AiInstance.propTypes = {
  submitInstanceCount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  totalInstance: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  instanceAdd: PropTypes.shape({
    instance: PropTypes.number,
    totalInstance: PropTypes.number,
    price: PropTypes.number,
  }),
};
AiInstance.defaultProps = {
  instanceAdd: {},
};

export default AiInstance;
