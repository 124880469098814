import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UploadIcon } from '../../../shared/component/svgIcon';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fileUploadValidation } from '../../../utils/helpers';

function ImageUploadBox(props) {
  const {
    title,
    required,
    setPicture,
    multiple,
    name,
    height,
    maxFileSize,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const selectImage = () => {
    inputFile.current.click();
  };

  const onChangePicture = (e) => {
    const files = Array.from(e.target?.files); // Convert FileList to an array
    let errorMessage = '';

    if (files?.length > 5) {
      dispatch(setErrorNotification({ message: 'Maximum 5 files should be uploaded at once.' }));
    } else {
      files.forEach((file) => {
        const validationMessage = fileUploadValidation(file, maxFileSize, 'image');
        if (validationMessage) {
          errorMessage = validationMessage;
        }
      });

      if (errorMessage) {
        dispatch(setErrorNotification({ message: errorMessage }));
      } else {
        setPicture(e.target.name, multiple ? files : files[0]);
      }
    }
    inputFile.current.value = '';
  };

  return (
    <div className="file-upload-box">
      <div className="title">
        {title}
        {required && (<small className="text-danger">*</small>)}
      </div>
      <div className="file-upload-session" onClick={selectImage} role="presentation" style={{ height }}>
        <div className="upload-icon"><UploadIcon /></div>
        <div className="upload-text"><u>{t('browse')}</u> {t('toUploadImages')}</div>
        <div className="upload-subtext">{t('supportedFormats')}</div>
        <div className="upload-subtext">Max size of 2MB</div>
        <input
          type="file"
          name={name}
          ref={inputFile}
          onChange={onChangePicture}
          multiple={multiple}
          accept="image/*"
        />
      </div>
    </div>
  );
}

ImageUploadBox.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  setPicture: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.string,
  multiple: PropTypes.string,
  maxFileSize: PropTypes.number,
};

ImageUploadBox.defaultProps = {
  required: false,
  height: '300px',
  multiple: false,
  maxFileSize: 1,
};

export default ImageUploadBox;
