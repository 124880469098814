import React, { useRef, useState, useEffect } from 'react';
// import { urlSea } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  MRT_ToggleFiltersButton as ToggleFiltersButton,
  MRT_ShowHideColumnsButton as ShowHideColumnsButton,
} from 'material-react-table';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  QueryClient,
  QueryClientProvider,
  // useInfiniteQuery,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import Button from '../../shared/component/Button';
import ExportButton from './exportBtn';

function Table(props) {
  const {
    header,
    status,
    pageCount,
    totalPageCount,
    value,
    scrollData,
    // totalDataCount,
    disableFilter,
    enableRowSelection,
    enableMultiRowSelection,
    rowSelection,
    setRowSelection,
    setGlobalFilter,
    disableSorting,
    globalFilter,
    height,
    disableFullScreenToggle,
    enableRowOrdering,
    setValue,
    columnVisibility,
    setColumnVisibility,
    exportButton,
    exportData,
    newButton,
    newButtonLabel,
    newButtonClickEvent,
    keyValue,
    toolbarLeft,
    toolbarRightFrond,
    toolbarRight,
    disableTopToolbar,
    disableFullScreen,
    onFilterOrSortingChange,
    reduxColumnFiltersSorting,
    isFirstRender,
    disableShowHideButton,
    disableToggleButton,
    disableFilterButton,
    showToolbarLeft,
    showToolbarRight,
    noRecordMessage,
    groupedColumnMode,
    enableGrouping,
    enableColumnDragging,
    expanded,
    grouping = [],
  } = props?.data || {};

  const { t } = useTranslation();
  const tableContainerRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);

  const [columnFilters, setColumnFilters] = useState(reduxColumnFiltersSorting?.columnFilters || []);
  const [sorting, setSorting] = useState(reduxColumnFiltersSorting?.sorting || []);
  const [isTableRender, setTableRender] = useState(false);
  const fetchMoreOnBottomReached = (containerRefElement) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight < 20
        && status !== 'loading'
        && pageCount < totalPageCount
      ) {
        scrollData();
      }
    }
  };

  useEffect(() => {
    if (!isFirstRender && isTableRender) {
      // Data readable by Mui table
      const reduxColumnFiltersSortingLocal = {
        columnFilters,
        sorting,
      };
      // Filter to send with API
      let filteredValues = {};

      // When content sorting applied
      if (sorting.length > 0) {
        filteredValues = {
          ...filteredValues,
          sortField: sorting[0].id,
          sortBy: sorting[0].desc ? 'desc' : 'asc',
        };
      }

      // When content column filters applied
      if (columnFilters.length > 0) {
        // Create a key: value pair from the array of filter object
        const mergedColumnFilterObject = columnFilters.reduce((acc, curr) => {
          if (curr.id === 'timeStamp' || curr.id === 'scheduledTime') {
            if (curr.value?.[0] !== '' && curr?.value?.[0]) {
              acc.startDate = dayjs(curr.value?.[0]).format('YYYY-MM-DD');
            }
            if (curr.value?.[1] !== '' && curr?.value?.[1]) {
              acc.endDate = dayjs(curr.value?.[1]).format('YYYY-MM-DD');
            }
          } else {
            acc[curr.id] = curr.value || null;
          }
          return acc;
        }, {});

        filteredValues = {
          ...filteredValues,
          ...mergedColumnFilterObject,
        };
      }

      onFilterOrSortingChange(filteredValues, reduxColumnFiltersSortingLocal);
    } else {
      setTableRender(true);
    }
  }, [columnFilters, sorting]);

  const tableProps = useMaterialReactTable({
    enableTopToolbar: disableTopToolbar && false,
    enableFilters: disableFilter && false,
    enableSorting: disableSorting && false,
    enableFullScreenToggle: disableFullScreenToggle && false,
    enableColumnResizing: true,
    layoutMode: 'grid',
    columns: header,
    data: value,
    enablePagination: false,
    enableRowNumbers: false,
    enableMultiRowSelection,
    enableRowOrdering,
    enableRowVirtualization: true,
    manualFiltering: false,
    manualSorting: true,
    enableRowSelection: enableRowSelection
      ? (row) => (Object.keys(row.original)?.includes('row_selection') ? row.original.row_selection : true)
      : false, // enable row selection conditionally per row
    renderEmptyRowsFallback: () => (
      <div style={{ textAlign: 'center', paddingTop: '2rem' }}>{noRecordMessage || t('noRecordsToDisplay')}</div>
    ),
    enableGlobalFilter: false,
    enableGrouping,
    groupedColumnMode,
    initialState: {
      showColumnFilters: reduxColumnFiltersSorting?.columnFilters?.length > 0,
      showGlobalFilter: false,
      grouping,
      expanded,
      enableColumnDragging,
    },
    enableSortingRemoval: false,
    onColumnVisibilityChange: setColumnVisibility,
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: height },
      onScroll: (event) => fetchMoreOnBottomReached(event.target),
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'none',
    muiLinearProgressProps: {
      color: 'success',
    },
    renderBottomToolbarCustomActions: () => (
      <Typography>
        {/* Fetched {value?.length} of {totalDataCount}. */}
      </Typography>
    ),
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: 'pointer' },
    // }),
    muiSelectCheckboxProps: {
      // color: 'warning',
      color: 'primary',
    },
    filterFns: {
      commonFIlterFn: () => true,
    },
    state: {
      columnVisibility,
      columnFilters,
      globalFilter,
      rowSelection,
      // isLoading,
      showProgressBars: status === 'loading',
      sorting,
    },
    rowVirtualizerInstanceRef,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          value.splice(
            hoveredRow.index,
            0,
            value.splice(draggingRow.index, 1)[0],
          );
          setValue([...value]);
        }
      },
    }),
    rowVirtualizerOptions: { overscan: 4 },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    // customize top-left of the topo toolbar
    renderTopToolbarCustomActions: () => (
      <div className="table-headerr-left">{showToolbarLeft && toolbarLeft}</div>
    ),
    // customize built-in buttons in the top-right of top toolbar
    renderToolbarInternalActions: ({ table }) => (
      <div className="table-header-right">
        {
          showToolbarRight && toolbarRightFrond
        }
        {newButton && (
          <div className="csv-button-wrap">
            <Button
              label={newButtonLabel}
              classes="export-button"
              click={newButtonClickEvent}
            />
          </div>
        )}
        {exportButton && value.length > 0 && (
          <div className="csv-button-wrap">
            <Button
              label={<ExportButton />}
              classes="export-button"
              click={exportData}
            />
          </div>
        )}
        {/* along-side built-in buttons in whatever order you want them */}
        {
          !disableFilterButton && <ToggleFiltersButton table={table} />
        }
        {
          !disableShowHideButton && <ShowHideColumnsButton table={table} />
        }
        {
          !disableToggleButton && <ToggleDensePaddingButton table={table} />
        }
        {
          !disableFullScreen && <ToggleFullScreenButton table={table} />
        }
        {
          showToolbarRight && toolbarRight
        }
      </div>
    ),
  });

  return <MaterialReactTable table={tableProps} key={keyValue} />;
}

const queryClient = new QueryClient();

function DataTable(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#30A84B',
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: props?.boxShadow ? 'none' : '',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Table data={props} />
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
Table.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
    })).isRequired,
    status: PropTypes.string,
    pageCount: PropTypes.number,
    totalPageCount: PropTypes.number,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object),
    ]).isRequired,
    scrollData: PropTypes.func,
    totalDataCount: PropTypes.number,
    disableFilter: PropTypes.bool,
    setColumnFilters: PropTypes.func,
    enableRowSelection: PropTypes.bool,
    enableGrouping: PropTypes.bool,
    groupedColumnMode: PropTypes.bool,
    enableMultiRowSelection: PropTypes.bool,
    enableColumnDragging: PropTypes.bool,
    expanded: PropTypes.bool,
    rowSelection: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]).isRequired,
    setRowSelection: PropTypes.func,
    setGlobalFilter: PropTypes.func,
    disableSorting: PropTypes.bool,
    setSorting: PropTypes.func,
    grouping: PropTypes.arrayOf(PropTypes.string),
    columnFilters: PropTypes.arrayOf(PropTypes.string),
    globalFilter: PropTypes.string,
    sorting: PropTypes.arrayOf(PropTypes.string),
    enable: PropTypes.bool,
    height: PropTypes.string,
    disableFullScreenToggle: PropTypes.bool,
    enableRowOrdering: PropTypes.bool,
    setValue: PropTypes.func,
    columnVisibility: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.objectOf(PropTypes.bool),
    ]),
    setColumnVisibility: PropTypes.func,
    exportButton: PropTypes.bool,
    exportData: PropTypes.func,
    newButton: PropTypes.bool,
    newButtonLabel: PropTypes.string,
    newButtonClickEvent: PropTypes.func,
    keyValue: PropTypes.string,
    toolbarLeft: PropTypes.element,
    toolbarRightFrond: PropTypes.element,
    toolbarRight: PropTypes.element,
    disableTopToolbar: PropTypes.bool,
    disableFullScreen: PropTypes.bool,
    disableShowHideButton: PropTypes.bool,
    disableToggleButton: PropTypes.bool,
    disableFilterButton: PropTypes.bool,
    showToolbarLeft: PropTypes.bool,
    showToolbarRight: PropTypes.bool,
    noRecordMessage: PropTypes.string,
  }).isRequired,
};
DataTable.propTypes = {
  boxShadow: PropTypes.string,
};
DataTable.defaultProps = {
  boxShadow: '',
};
export default DataTable;
