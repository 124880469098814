/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Icon } from 'leaflet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import SelectBox from '../../../shared/component/form/SelectBox';
import {
  MapIcon,
  SlickNext,
  SlickPrev,
} from '../../../shared/component/svgIcon';
import markIcon from '../../../assets/images/dashboard/mark-icon.png';
import storeImage from '../../../assets/images/Group.png';
import dashboard from '../../../utils/api/dashboard';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function LocationMarker({ position, zoom }) {
  const map = useMap();

  useEffect(() => {
    map.flyTo(position, zoom);
  }, [position]);

  return null;
}

function Location() {
  const sliderRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [allStores, setAllStores] = useState([]);

  const [city, setCity] = useState('All');
  const [slideIndex, setSlideIndex] = useState(0);
  const [cities, setCities] = useState([
    {
      label: 'All',
    },
  ]);

  const [storeData, setStoreData] = useState([]);
  const [position, setPosition] = useState([20.5937, 78.9629]);
  const [zoom, setZoom] = useState();

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: 'linear',
    beforeChange: (current, next) => setSlideIndex(next),
    prevArrow: null,
    nextArrow: null,
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    const uniqueCities = [];
    if (allStores.length > 0) {
      // Marking all stores in Map
      setStoreData(
        allStores
          .filter((val) => val.latitude && val.longitude)
          .map((val) => ({
            ...val,
            position: [parseFloat(val.latitude), parseFloat(val.longitude)],
          })),
      );
      setZoom(4);

      // Taking unique cities from stores
      if (cities.length < 2) {
        allStores.forEach((store) => {
          if (!uniqueCities.includes(store.city)) {
            uniqueCities.push(store.city);
          }
        });
        setCities((prevCities) => [
          ...prevCities,
          ...uniqueCities.map((each) => ({ label: each })),
        ]);
      }
    }
  }, [allStores]);

  useEffect(() => {
    // Marking stores based on selected city
    if (allStores.length > 0 && city !== 'All') {
      setStoreData(
        allStores
          .filter((val) => val.city === city)
          .map((val) => ({
            ...val,
            position: [parseFloat(val.latitude), parseFloat(val.longitude)],
          })),
      );
      setZoom(10);
    }

    if (allStores.length > 0 && city === 'All') {
      // Marking all stores in Map
      setStoreData(
        allStores
          .filter((val) => val.latitude && val.longitude)
          .map((val) => ({
            ...val,
            store_images: val?.store_images || [],
            position: [parseFloat(val.latitude), parseFloat(val.longitude)],
          })),
      );
      setZoom(4);
    }
  }, [city]);

  useEffect(() => {
    if (storeData.length > 0) {
      setPosition(storeData[0]?.position);
    }
  }, [storeData]);

  const handleChange = (name, value) => {
    if (value) {
      setCity(value.label);
    }
  };

  const customIcon = new Icon({
    iconUrl: markIcon,
    iconSize: [35, 35],
  });

  useEffect(() => {
    dashboard.getStoreData()
      .then((res) => {
        setAllStores(res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  }, []);

  return (
    <div className="location-wrap">
      <div className="location-header">
        <h2>{t('facilities')}</h2>
        <div className="location-right">
          <div className="place">
            <SelectBox
              label=""
              name="city"
              value={city}
              onchange={handleChange}
              lists={cities}
              id="select-roles"
              placeholder="Select City"
              border={false}
              disableClearable
            />
          </div>
        </div>
      </div>
      <MapContainer
        center={{ lat: 20.5937, lng: 78.9629 }}
        zoom={7}
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {storeData?.map((store) => (
          <Marker key={store.id} position={store.position} icon={customIcon}>
            <Popup>
              <div className="store-information">
                <div className="store-image">
                  {store?.signedUrl?.length > 0
                    ? (
                      <div className="store-image-container">
                        <div
                          role="presentation"
                          onClick={handlePrev}
                          className={
                            slideIndex === 0
                              ? 'prev-btn prev-btn-inactive'
                              : 'prev-btn'
                          }
                        >
                          <span>
                            <SlickPrev />
                          </span>
                        </div>
                        <Slider {...settings} ref={sliderRef}>
                          {store?.signedUrl?.map((image) => (
                            <div className="image-container">
                              <img src={image} alt="store" />
                            </div>
                          ))}
                        </Slider>
                        <div
                          role="presentation"
                          className={
                            slideIndex === store?.signedUrl?.length - 1
                              ? 'next-btn next-btn-inactive'
                              : 'next-btn'
                          }
                          onClick={handleNext}
                        >
                          <span>
                            <SlickNext />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="store-default-image">
                        <span><img alt="store_image" src={storeImage} /></span>
                      </div>
                    )}
                </div>
                <div className="store-content">
                  <div className="store-details">
                    <div className="store-info-header">
                      <span className="header-name">{store?.name}</span>
                      <span className="device-count">{store?.store_id}</span>
                    </div>
                    <div className="store-location-details">
                      <span>
                        <MapIcon />
                      </span>
                      <span>{store?.area}</span>
                      <span>{store?.city}</span>
                      <span> - {store?.pincode}</span>
                    </div>
                  </div>
                  <div className="store-device-details">
                    <div className="device-details">
                      <span>Display</span>
                      <span className="count">{store?.device_count}</span>
                    </div>
                    <div className="device-details">
                      <span>Active</span>
                      <span className="count">{store?.active}</span>
                    </div>
                    <div className="device-details">
                      <span>Inactive</span>
                      <span className="count">{store?.inactive}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
        <LocationMarker position={position} zoom={zoom} />
      </MapContainer>
    </div>
  );
}

LocationMarker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  zoom: PropTypes.number.isRequired,
};

export default Location;
