import React from 'react';
import PropTypes from 'prop-types';
import IFrameComponent from '../../../../shared/component/IFrameComponent';

function WeatherPreview({ selectedWidget, gridName }) {
  return (
    <div
      className="widget-preview-wrap"
    >
      <IFrameComponent
        htmlUrl={selectedWidget?.html_url}
        jsUrl={selectedWidget?.widget_id?.script_url}
        title={`weather_${gridName}`}
        replacements={[
          ['latitude', selectedWidget?.latitude],
          ['longitude', selectedWidget?.longitude],
          ['temperature', selectedWidget?.temperature],
          ['background_color', selectedWidget?.background_color],
          ['text_color', selectedWidget?.text_color],
        ]}
      />
    </div>
  );
}

WeatherPreview.propTypes = {
  gridName: PropTypes.string.isRequired,
  selectedWidget: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    html_url: PropTypes.string,
    script_url: PropTypes.string,
    temperature: PropTypes.oneOf(['celsius', 'fahrenheit']),
    background_color: PropTypes.string,
    text_color: PropTypes.string,
    widget_id: PropTypes.string,
  }).isRequired,
};

export default WeatherPreview;
