/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import playlistApi from '../../utils/api/playlist';
import { convertBytes, changeDateTimeFormat, addImageType } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchPlaylistList = createAsyncThunk(
  'playlist/fetchPlaylist',
  async (data, { dispatch }) => {
    const response = await playlistApi.getPlaylists(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        author: val.createdBy?.name,
        contents_list: val.contents.map((val1) => val1.name),
        contents: val.contents.map((val1) => ({ ...val1, id: val1._id })),
        total_file_size: convertBytes(val.totalFileSize || 0),
        createDate: changeDateTimeFormat(val.createdAt),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchSinglePlaylist = createAsyncThunk(
  'playlist/fetchSinglePlaylist',
  async (data, { dispatch }) => {
    const response = await playlistApi.getSinglePlaylist(data).then((response1) => ({
      ...response1?.data,
      author: response1?.data.createdBy?.name,
      contents_list: response1?.data.contents.map((val1) => val1.name),
      contents: response1?.data.contents.map((val1) => ({
        ...val1,
        id: val1._id,
        author: val1.createdBy?.name,
        file_type_to_show: addImageType(val1?.file_type),
        file_size: convertBytes(val1.file_size || 0),
      })),
      id: response1?.data._id,
      code: 200,
    })).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  playlist: [],
  pageCount: 0,
  totalPageCount: 1,
  singlePlaylist: {},
  newPlaylist: {},
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    // Reducer to create new playlist
    createPlaylist: (state, action) => {
      const newPlaylist = action.payload; // New playlist data
      const playlistData = [
        {
          ...newPlaylist,
          author: newPlaylist.createdBy?.name,
          contents_list: newPlaylist.contents.map((content) => content.name),
          contents: newPlaylist.contents.map((content) => ({ ...content, id: content._id })),
          total_file_size: convertBytes(newPlaylist.totalFileSize || 0),
          createDate: changeDateTimeFormat(newPlaylist.createdAt),
          id: newPlaylist._id,
        }, // Add the new playlist at the top of the playlist data
        ...state.playlist,
      ];
      state.playlist = playlistData; // Update the playlist data
    },
    // Reducer to update existing playlist
    updatePlaylist: (state, action) => {
      const updatedPlaylist = action.payload; // New playlist data
      const playlistData = state.playlist; // Entire playlist data

      // Find the index to replace the data
      const index = playlistData.findIndex((playlist) => playlist._id === updatedPlaylist._id);

      // If the index found, replace the entire object
      if (index !== -1) {
        playlistData[index] = {
          ...updatedPlaylist,
          author: updatedPlaylist.createdBy?.name,
          contents_list: updatedPlaylist.contents.map((content) => content.name),
          contents: updatedPlaylist.contents.map((content) => ({ ...content, id: content._id })),
          total_file_size: convertBytes(updatedPlaylist.totalFileSize || 0),
          createDate: changeDateTimeFormat(updatedPlaylist.createdAt),
          id: updatedPlaylist._id,
        };
      }

      state.playlist = playlistData; // Update the playlist data
    },
    // Reducer to delete the playlist
    deletePlaylist: (state, action) => {
      const playlistData = state.playlist;

      // Find the index to delete for the array
      const index = playlistData.findIndex((playlist) => playlist.id === action.payload);

      // If the index found, remove the index from the array of playlist
      if (index !== -1) {
        playlistData.splice(index, 1);
      }

      state.playlist = playlistData; // Update the playlist data after delete
    },
    addPlaylist: (state, action) => {
      state.newPlaylist = action.payload;
    },
    editPlaylist: (state, action) => {
      state.newPlaylist = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetPlaylistState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPlaylistList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlaylistList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.playlist = fetchedData;
          } else {
            const finalData = state.playlist; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.playlist = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchPlaylistList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchSinglePlaylist.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSinglePlaylist.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.singlePlaylist = action.payload;
          state.newPlaylist = action.payload;
        }
      });
  },
});

export const {
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  addPlaylist,
  editPlaylist,
  setFilter,
  setTableFilter,
  resetPlaylistState,
} = playlistSlice.actions;

export default playlistSlice.reducer;
