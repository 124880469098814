/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import widgetApi from '../../utils/api/widget';
import { setErrorNotification } from './NotificationSlice';

export const fetchWidgets = createAsyncThunk(
  'widgets/fetchWidgets',
  async (data, { dispatch }) => widgetApi.getWidgets(data)
    .then((res) => ({ ...res.data, code: 200 }))
    .catch((error) => dispatch(setErrorNotification(error?.response?.data))),
);

export const fetchSavedWidget = createAsyncThunk(
  'widgets/savedWidget',
  async (data, { dispatch }) => {
    const response = await widgetApi.getWidgetTemplate(data)
      .then((response1) => (
        { ...response1.data, code: 200 }
      ))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  widgets: [],
  selectedWidget: null,
  savedWidget: [],
  savedWidgetPageCount: 0,
  savedWidgetTotalPageCount: 1,
  savedWidgetTotalDataCount: 0,
  status: 'succeeded',
  widgetStoreData: {
    text_color: '#000000',
    background_color: '#FFFFFF',
    duration: '05',
    widgetInput: [],
  },
  error: null,
};

const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    setSelectedWidget: (state, action) => {
      state.selectedWidget = action.payload;
    },
    setSingleSavedWidget: (state, action) => {
      state.selectedSingleSavedWidget = action.payload;
    },
    createWidgetStoreLocally: (state, action) => {
      state.widgetStoreData = action.payload;
    },
    deleteSavedWidget: (state, action) => {
      const roleList = state.savedWidget;

      // Find the index to delete by ID
      const index = roleList.findIndex((role) => role._id === action.payload);
      // If the index found, remove that index from list of roles
      if (index !== -1) {
        roleList.splice(index, 1);
      }
      state.savedWidget = roleList; // Update the roles state after deleted
    },
    createWidget: (state, action) => {
      const newWidget = action.payload; // widget to be added at the top
      const widgetList = [
        {
          ...newWidget,
          id: newWidget._id,
        },
        ...state.savedWidget, // Existing widgets
      ];
      state.savedWidget = widgetList; // update the widget list
    },
    // Reducer to update the saved widget by ID.
    updateWidget: (state, action) => {
      const updatedSavedWidget = action.payload;
      const savedWidgetList = state.savedWidget; // Assign the existing saved widget to another variable
      // Find the index of the updated saved widget by ID
      const index = savedWidgetList.findIndex((savedWidget) => savedWidget._id === updatedSavedWidget._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        savedWidgetList[index] = {
          ...updatedSavedWidget,
          id: updatedSavedWidget._id,
        };
      }
      state.savedWidget = savedWidgetList; // Finally update the saved widget list
    },
    resetWidgetsState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWidgets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWidgets.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.widgets = action.payload.results.map((widget) => ({
            ...widget,
            id: widget._id,
          }));
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(fetchWidgets.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchSavedWidget.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSavedWidget.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          const data = action.payload.results.map((widget) => ({
            ...widget,
            id: widget._id,
          }));
          state.savedWidgetPageCount = action.payload?.page;
          state.savedWidgetTotalPageCount = action.payload?.totalPages;
          state.savedWidgetTotalDataCount = action.payload?.totalResults;
          if (action.payload.page === 1) {
            state.savedWidget = data;
          } else {
            state.savedWidget.push(...data);
          }
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(fetchSavedWidget.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setSelectedWidget,
  setSingleSavedWidget,
  createWidget,
  deleteSavedWidget,
  getWidgets,
  createWidgetStoreLocally,
  updateWidget,
  resetWidgetsState,
} = widgetSlice.actions;

export default widgetSlice.reducer;
