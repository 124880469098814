import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from '../../Table';
import {
  getStoreLocationList,
  setStoreTableFilter,
  setStoreFilter,
  resetStoreState,
} from '../../../redux/slices/StoresSlice';
import Button from '../../../shared/component/Button';
import loginImage from '../../../assets/images/Group.png';

function FacilityList(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    storeLocationList,
    storePageCount,
    storeTotalPageCount,
    storeTotalDataCount,
    storeStatus,
    storeFilter,
    storeTableFilter,
  } = useSelector((state) => state.facilities);
  const [facilitySelection, setFacilitySelection] = useState([]);
  const [facilityColumnVisibility, setFacilityColumnVisibility] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  // Common state
  const [disable, setDisable] = useState(true);
  // State to hold selected stores
  const [selectedValues, setSelectedValues] = useState({
    stores_id: props?.values?.stores_id || [],
    stores_list: props?.values?.stores_list || [],
  });
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('storeName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          <img
            alt="thumbnail"
            src={
              row.original?.store_images?.[0]?.signedUrl || loginImage
            }
            loading="lazy"
            role="presentation"
          />
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('storeID'),
      accessorKey: 'store_id',
    },
    {
      header: t('areaAndCity'),
      accessorKey: 'city',
    },
    {
      header: t('state'),
      accessorKey: 'state',
    },
    {
      header: t('country'),
      accessorKey: 'country',
    },
  ];

  const getNextPageData = (count, search) => {
    dispatch(getStoreLocationList({ page: count, limit: 20, ...search }));
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setStoreTableFilter(reduxColumnFiltersSorting));
    dispatch(setStoreFilter(filteredValues));
    getNextPageData(1, filteredValues);
  };

  const handleRowSelection = (selectedRows) => {
    setFacilitySelection(selectedRows);
    if (Object.keys(selectedRows).length === storeLocationList.length) {
      setAllRowsSelected(true);
    } else {
      setAllRowsSelected(false);
    }
  };

  // Load more data when user scroll
  const handleScroll = () => {
    getNextPageData(storePageCount + 1, storeFilter);
  };

  // Handle back click
  const back = () => {
    props?.closeModal(false);
  };

  // Function to Handle submit
  const submit = () => {
    props?.selectData(selectedValues);
  };

  useEffect(() => {
    if (Object.keys(facilitySelection).length >= 20 || allRowsSelected) {
      const newFacilitySelection = { ...facilitySelection };
      storeLocationList.forEach((data) => {
        if (!newFacilitySelection[data?.id]) {
          newFacilitySelection[data?.id] = true;
        }
      });
      setFacilitySelection(newFacilitySelection);
    }
  }, [storeLocationList]);

  useEffect(() => {
    setIsFirstRender(false);
    getNextPageData(1, {});
    // Convert the existing select id's into Material table readable format
    if (selectedValues?.stores_id?.length > 0) {
      const newObj = {};
      selectedValues?.stores_id?.forEach((item) => {
        newObj[item] = true;
      });
      setFacilitySelection(newObj);
    }
    return () => dispatch(resetStoreState());
  }, []);
  useEffect(() => {
    // Filter and push the selected devices into selectedValues.facilities_list
    const selectedFacility = storeLocationList.filter((data) => Object.keys(facilitySelection).includes(data.id));
    setSelectedValues({
      ...selectedValues,
      stores_id: Object.keys(facilitySelection) || [],
      stores_list: selectedFacility,
    });
  }, [facilitySelection, storeLocationList]);
  // Code to handle button disable
  useEffect(() => {
    if (Object.keys(facilitySelection)?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [facilitySelection]);
  return (
    <div className="contents-list">
      <div className="table-response">
        <DataTable
          header={columns}
          value={[
            ...selectedValues.stores_list,
            ...storeLocationList.filter((item) => !selectedValues?.stores_id?.includes(item.id)),
          ]}
          status={storeStatus}
          pageCount={storePageCount}
          onFilterOrSortingChange={onFilterOrSortingChange}
          reduxColumnFiltersSorting={storeTableFilter}
          totalPageCount={storeTotalPageCount}
          isFirstRender={isFirstRender}
          totalDataCount={storeTotalDataCount}
          scrollData={handleScroll}
          rowSelection={facilitySelection}
          setRowSelection={handleRowSelection}
          columnVisibility={facilityColumnVisibility}
          setColumnVisibility={setFacilityColumnVisibility}
          enableRowSelection
          enable={storeTableFilter?.columnFilters?.length > 0}
          height="45vh"
          key="facility"
          disableFullScreen
        />
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('continue')} click={submit} classes="success-button" disabled={disable} />
        </div>
      </div>
    </div>
  );
}

FacilityList.propTypes = {
  selectData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.objectOf(),
  }),
  values: PropTypes.shape({
    stores_id: PropTypes.arrayOf().isRequired,
    stores_list: PropTypes.arrayOf().isRequired,
  }).isRequired,
};
FacilityList.defaultProps = {
  row: null,
};
export default FacilityList;
