import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

function TitleEdit(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props?.setTitleModal(false);
  };

  const disableButton = props?.templateNumber === 1 || props?.templateNumber === 2
    ? (props?.editTitle === '') : (props?.editTitle === '' || props?.editSubTitle === '');
  return (
    <div className="edit-category">
      <div className="form">
        <Input
          icon={' '}
          label={t('menuTitle')}
          type="text"
          name="name"
          required
          value={props?.editTitle}
          change={props?.handleTitleChange}
          placeholder={t('enterTitle')}
          error={props?.editTitle?.length > 20}
          errorMessage={t('titleshouldbe20orlessthanorequalto20letters')}
        />
        {props?.templateNumber !== 1
          && props?.templateNumber !== 2
          && (
            <Input
              icon={' '}
              label={t('menuSubtitle')}
              type="text"
              name="name"
              required
              value={props?.editSubTitle}
              change={props?.handleSubTitleChange}
              placeholder={t('enterSubtitle')}
              error={props?.editSubTitle?.length > 25}
              errorMessage={t('subtitleshouldbe25orlessthanorequalto25letters')}
            />
          )}
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={t('update')}
              click={props?.submitTitle}
              classes="success-button"
              disabled={disableButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TitleEdit.propTypes = {
  setTitleModal: PropTypes.node.isRequired,
  editTitle: PropTypes.string.isRequired,
  handleTitleChange: PropTypes.func.isRequired,
  editSubTitle: PropTypes.string.isRequired,
  handleSubTitleChange: PropTypes.func.isRequired,
  submitTitle: PropTypes.func.isRequired,
  templateNumber: PropTypes.number.isRequired,
};

export default TitleEdit;
