import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExportIcon } from '../../shared/component/svgIcon';

function ExportButton() {
  const { t } = useTranslation();

  return (
    <div className="export-button-text">
      <ExportIcon />
      <span>{t('export')}CSV</span>
    </div>
  );
}

export default ExportButton;
