import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from '../../../shared/component/form/Input';
import InputTags from '../../../shared/component/form/InputTags';
import Button from '../../../shared/component/Button';
import ImageUploadBox from './ImageUploadBox';
import { convertBytes } from '../../../utils/helpers';

import closeButton from '../../../assets/icons/close-circle.png';
import storeLocation from '../../../utils/api/store-location';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function AddSingleFloorMap(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    storeId,
    addStoreFromEditPage,
    closeModal,
    submitFloorMap,
  } = props;

  const [floorMapValue, setFloorMapValue] = useState({
    tags: [],
  });
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleFunction = (name, changeValue, key = 0) => {
    if (name === 'tags' && key === 13) {
      setFloorMapValue((prevValue) => ({
        ...prevValue,
        [name]: Array.isArray(changeValue) ? changeValue : [...prevValue.tags, changeValue],
      }));
    } else if (name === 'name') {
      setFloorMapValue((prevValue) => ({
        ...prevValue,
        [name]: changeValue,
      }));
    } else if (name === 'files') {
      if (changeValue) {
        setFloorMapValue((prevValue) => ({
          ...prevValue,
          file: changeValue,
          viewImage: URL.createObjectURL(changeValue),
          fileName: changeValue?.name,
          type: changeValue?.type?.split('/')?.[1],
          size: convertBytes(changeValue?.size || 0),
        }));
      }
    }
  };
  const deleteFile = () => {
    setFloorMapValue((prevValue) => ({
      ...prevValue,
      file: undefined,
      viewImage: undefined,
      fileName: undefined,
      type: undefined,
      size: undefined,
      layout: [],
    }));
  };

  const submit = () => {
    if (addStoreFromEditPage) {
      setLoading(true);
      const formData = new FormData();

      formData.append('name', floorMapValue.name);
      formData.append('floor', floorMapValue.file);
      if (floorMapValue?.tags) {
        floorMapValue?.tags?.forEach((tag, index) => {
          formData.append(`tags[${index}]`, tag);
        });
      }
      // Append an empty layout
      formData.append('layout', []);

      storeLocation.addSingleFloor(formData, storeId)
        .then(() => {
          setLoading(false);
          closeModal(false);
          submitFloorMap();
        })
        .catch((err) => {
          setLoading(false);
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else {
      submitFloorMap(floorMapValue);
      closeModal(false);
    }
  };

  useEffect(() => {
    if (floorMapValue.name && floorMapValue.file) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [floorMapValue]);
  return (
    <div className="floor-map-form">
      <div className="floor-map-fields">
        <Input
          icon={' '}
          label={t('floorName')}
          name="name"
          value={floorMapValue?.name}
          change={handleFunction}
          required
          placeholder={t('enterFloorName')}
        />
        <InputTags
          icon={' '}
          label={t('tags')}
          name="tags"
          addData={handleFunction}
          required={false}
          value={floorMapValue?.tags}
          placeholder={t('enterTags')}
        />
        <ImageUploadBox
          title={t('floorMap')}
          setPicture={handleFunction}
          name="files"
          maxFileSize={2}
          required
        />
        {
          floorMapValue.file && (
            <div className="selected-image-session">
              <div className="thumb-image">
                <img alt="selected-images-thumb" src={floorMapValue.viewImage} />
              </div>
              <div className="file-name">
                <div>{floorMapValue.fileName}</div>
                <div className="subtext"><span>{floorMapValue.type}</span> {floorMapValue.size}</div>
              </div>
              <div className="file-close" onClick={() => deleteFile()} role="presentation">
                <img alt="close-button" src={closeButton} />
              </div>
            </div>
          )
        }
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={() => closeModal(false)} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('upload')}
            click={submit}
            classes={loading ? 'success-button loading-btn' : 'success-button'}
            disabled={disableButton}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
AddSingleFloorMap.propTypes = {
  storeId: PropTypes.string,
  addStoreFromEditPage: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  submitFloorMap: PropTypes.func.isRequired,
};

AddSingleFloorMap.defaultProps = {
  storeId: '',
  addStoreFromEditPage: false,
};

export default AddSingleFloorMap;
