import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragOverlay } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  fetchScheduleLayoutContentList,
  setFilter as setContentFilter,
} from '../../../../redux/slices/ScheduleLayoutMyContentSlice';
import DraggableContent from './DragableContent';
import {
  fetchScheduleLayoutPlaylistList,
  setFilter as setPlaylistFilter,
} from '../../../../redux/slices/ScheduleLayoutPlaylistSlice';
import DraggablePlaylist from './DragablePlaylist';
import SearchBox from '../../../../shared/component/form/SearchBox';

function ContentList(props) {
  const { t } = useTranslation();
  const {
    activeId,
    selectedTab,
    setSelectedTab,
  } = props;

  const {
    contents,
    pageCount: contentPageCount,
    totalPageCount: contentTotalPageCount,
    status: contentStatus,
    filter: contentFilter,
  } = useSelector((state) => state.scheduleLayoutMyContent);

  const {
    playlist,
    pageCount: playlistPageCount,
    totalPageCount: playlistTotalPageCount,
    status: playlistStatus,
    filter: playlistFilter,
  } = useSelector((state) => state.scheduleLayoutPlaylist);

  const dispatch = useDispatch();

  const [isFirstRender, setFirstRender] = useState(true);
  const contentListTabs = [
    {
      id: 1,
      label: t('content'),
      key: 'content',
    },
    {
      id: 2,
      label: t('playlist'),
      key: 'playlist',
    },
  ];

  const getMoreContents = (page, filter = {}) => {
    if (contentStatus !== 'loading' && selectedTab === 'content') {
      dispatch(fetchScheduleLayoutContentList({ page, ...filter }));
    } else if (playlistStatus !== 'loading' && selectedTab === 'playlist') {
      dispatch(fetchScheduleLayoutPlaylistList({ page, ...filter }));
    }
  };

  const handleScroll = () => {
    if (contentPageCount < contentTotalPageCount && selectedTab === 'content') {
      getMoreContents(contentPageCount + 1, { name: contentFilter?.name || null });
    } else if (playlistPageCount < playlistTotalPageCount && selectedTab === 'playlist') {
      getMoreContents(playlistPageCount + 1, { name: playlistFilter?.name || null });
    }
  };

  const handleSearchChange = (name, value) => {
    if (selectedTab === 'content') {
      dispatch(setContentFilter({ [name]: value }));
    } else if (selectedTab === 'playlist') {
      dispatch(setPlaylistFilter({ [name]: value }));
    }
  };

  useEffect(() => {
    if (selectedTab === 'content' && !isFirstRender) {
      getMoreContents(1, { name: contentFilter?.name || null });
    } else if (selectedTab === 'playlist' && !isFirstRender) {
      getMoreContents(1, { name: playlistFilter?.name || null });
    }
  }, [contentFilter, playlistFilter]);

  useEffect(() => {
    if (contentPageCount === 0 || playlistPageCount === 0) {
      getMoreContents(1);
      setFirstRender(false);
    }
  }, [selectedTab]);

  return (
    <div className="schedule-layout-content-list-wrap">
      <div className="content-list-tabs">
        {contentListTabs?.map((tab) => (
          <div
            role="presentation"
            onClick={() => setSelectedTab(tab?.key)}
            className="each-tab"
          >
            <h1
              style={{ '--active-tab-color': tab?.key === selectedTab ? '#03A84B' : '#717880' }}
            >
              {tab?.label}
            </h1>
            <div
              className="active-tab-border"
              style={{ '--active-tab-width': tab?.key === selectedTab ? '95%' : '0%' }}
            />
          </div>
        ))}
      </div>
      <div className="content-list-search">
        <SearchBox
          type="text"
          name="name"
          value={selectedTab === 'content'
            ? contentFilter?.name : playlistFilter?.name}
          change={handleSearchChange}
          placeholder={`Search by ${selectedTab} name...`}
        />
      </div>
      <div className="content-listing">
        {selectedTab === 'content'
          ? (
            <div className="list-of-contents" id="list-of-contents">
              <InfiniteScroll
                dataLength={contents.length}
                next={handleScroll}
                height="76vh"
                hasMore={contentPageCount < contentTotalPageCount}
                loader={contentStatus === 'loading' && (
                  <h4 className="text-center">
                    <CircularProgress />
                  </h4>
                )}
                className="infinite-scroll"
              >
                {contents?.map((content) => (
                  <DraggableContent
                    content={content}
                  />
                ))}
                {activeId
                  && (
                    <DragOverlay>
                      <DraggableContent
                        content={contents?.find((each) => each.id === activeId)}
                        draggingElement
                      />
                    </DragOverlay>
                  )}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="list-of-contents">
              <div className="list-of-contents" id="list-of-contents">
                <InfiniteScroll
                  dataLength={playlist.length}
                  next={handleScroll}
                  height="76vh"
                  hasMore={playlistPageCount < playlistTotalPageCount}
                  loader={playlistStatus === 'loading' && (
                    <h4 className="text-center">
                      <CircularProgress />
                    </h4>
                  )}
                  className="infinite-scroll"
                >
                  {playlist?.map((eachPlaylist) => (
                    <DraggablePlaylist
                      playlist={eachPlaylist}
                    />
                  ))}
                  {activeId
                    && (
                      <DragOverlay>
                        <DraggablePlaylist
                          playlist={playlist?.find((each) => each.id === activeId)}
                          draggingElement
                        />
                      </DragOverlay>
                    )}
                </InfiniteScroll>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

ContentList.propTypes = {
  activeId: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.node.isRequired,
};

export default ContentList;
