import React from 'react';
import PropTypes from 'prop-types';
import { MoreMenuSideIcon } from '../../../shared/component/svgIcon';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import ImageCarousel from './ImageCarousel';
import storeImage from '../../../assets/images/Group.png';

function StoreGridListView(props) {
  const {
    storeList,
    action,
    upload,
  } = props;
  return storeList.length === 0
    ? (
      <h1 className="text-center">No Data Found</h1>
    )
    : (
      <div className="store-grid-list">
        {
          storeList.map((data, index) => (
            <div className="grid-item">
              {
                data.store_images.length > 0 ? (
                  <div className="grid-image-carousel">
                    <ImageCarousel images={data.store_images} />
                  </div>
                ) : (
                  <div className="upload-image-set" role="presentation" onClick={() => upload(data.id)}>
                    <div className="upload-image-icon"><img alt="store_image" src={storeImage} /></div>
                  </div>
                )
              }
              <div className="grid-content">
                <div className="grid-content-header">
                  <div className="gird-title">
                    {data.name}
                    <div className="tags">{String(index + 1).padStart(3, '0')}</div>
                  </div>
                  <div className="action-button">
                    <DropDownMenu
                      action={action}
                      icon={<MoreMenuSideIcon />}
                      value={data}
                      dropdown="tableForm"
                      tooltip=""
                    />
                  </div>
                </div>
                <div className="grid-content-footer">{data.state}, {data.country}</div>
              </div>
            </div>
          ))
        }
      </div>
    );
}

StoreGridListView.propTypes = {
  storeList: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  action: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    function: PropTypes.func,
    key: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  upload: PropTypes.func.isRequired,
};
StoreGridListView.defaultProps = {
  storeList: [],
  action: [],
};

export default StoreGridListView;
