import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

function PopupModal(props) {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let value;
  if (props?.name === 'Devices') {
    value = (
      <div className="popup">
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={props?.tooltip} onClick={handleClick}>
            {props?.list.length} {props?.name}
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxHeight: '20vh',
              overflow: 'scroll',
              filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.25))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: currentLanguageCode === 'ar' ? 'none' : '14px',
                left: currentLanguageCode === 'ar' ? '14px' : 'none',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
              '.MuiMenuItem-root': {
                fontFamily: 'Poppins',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {props?.list?.map((val) => <MenuItem>{val?.name} : {val?.status ? 'Updated required' : 'Updated'}</MenuItem>)}
        </Menu>
      </div>
    );
  } else {
    value = (props?.list.length > 1 ? (
      <div className="popup">
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={props?.tooltip} onClick={handleClick}>
            {props?.list.length} {props?.name}
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxHeight: '20vh',
              overflow: 'scroll',
              filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.25))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: currentLanguageCode === 'ar' ? 'none' : '14px',
                left: currentLanguageCode === 'ar' ? '14px' : 'none',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
              '.MuiMenuItem-root': {
                fontFamily: 'Poppins',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {props?.list?.map((item) => <MenuItem>{item}</MenuItem>)}
        </Menu>
      </div>
    ) : (
      <div>
        {props?.list[0] && props?.list[0].substring(0, 30)}{props?.list[0] && props?.list[0].length > 29 && '...'}
      </div>
    ));
  }
  return value;
}

PopupModal.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};
PopupModal.defaultProps = {
  list: [],
  name: '',
};

export default PopupModal;
