import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared/component/Button';
import { Input } from '../../../../shared/component/form/Input';

function EmailVerification(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState(props?.email || '');
  const handleChange = (name, changeValue) => {
    setEmail(changeValue);
  };
  const submit = () => {
    props?.verifyOtp(email);
  };

  const handleClose = () => {
    props?.closeModal(false);
  };
  return (
    <div className="deleteAccount">
      <div className="request-content">
        <span className="description">
          {t('pleaseVerifyYourAccountToContinue')}
        </span>
      </div>
      <Input
        icon={' '}
        label={t('emailAddress')}
        type="text"
        name="email"
        value={email}
        change={handleChange}
        placeholder="Enter Email"
      />
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('cancel')}
            click={handleClose}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submit}
            classes="success-button"
            disabled={email === ''}
          />
        </div>
      </div>
    </div>
  );
}

EmailVerification.propTypes = {
  closeModal: PropTypes.node.isRequired,
  verifyOtp: PropTypes.func.isRequired,
  email: PropTypes.string,
};

EmailVerification.defaultProps = {
  email: '',
};

export default EmailVerification;
