import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getSchedules: (data = {}) => {
    const params = { ...data, limit: data?.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/schedules`, { params });
  },
  getCalendarSchedules: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/schedules/schedule-list`, { params });
  },
  getSpecialSchedules: (data = {}) => {
    const params = { ...data, limit: data?.limit || 20, special: true };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/schedules`, { params });
  },
  getSingleSchedule: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/schedules/${data}`, {}),
  addSchedule: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/schedules`, body);
  },
  checkConfig: (data = {}, id = null) => {
    const body = { ...data };
    let response;
    if (id) {
      response = post(`${process.env.REACT_APP_API_URL}cms/v1/schedules/conflictsCheck/${id}`, body);
    } else {
      response = post(`${process.env.REACT_APP_API_URL}cms/v1/schedules/conflictsCheck`, body);
    }
    return response;
  },
  updateSchedule: (data = {}, id = null) => {
    const params = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/schedules/${id}`, params);
  },
  cancelSchedule: (data) => patch(`${process.env.REACT_APP_API_URL}cms/v1/schedules/cancel/${data}`, {}),
  deleteSchedule: (data) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/schedules/cancel/${data}`, {}),
  scheduleDataExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/schedules/export`, { ...data }),
  getDevices: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/schedules/schedule-devices`, { params });
  },
  getDevicesGroups: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/schedules/schedule-device-groups`, { params });
  },
  advanceSettings: (data) => post(`${process.env.REACT_APP_API_URL}cms/v1/schedules/advanceSettings`, data),
};
