import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart } from '@mui/x-charts';
import { styled } from '@mui/material/styles';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { fetchStorageStats } from '../../../redux/slices/DashBoardSlice';
import ImageIcon from '../../../assets/images/dashboard/image-icon.png';
import VideoIcon from '../../../assets/images/dashboard/video-icon.png';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 14,
}));

function PieCenterLabel({ children, size }) {
  const {
    width,
    height,
    left,
    top,
  } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / size}>
      {children}
    </StyledText>
  );
}
PieCenterLabel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
};
PieCenterLabel.defaultProps = {
  children: [],
  size: '',
};

function StorageStats() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { storageStatsData, limitedStorage } = useSelector((state) => state.dashboardPieCharts);
  const total = storageStatsData?.reduce((assignValue, currentOs) => (assignValue + parseFloat(currentOs.total)), 0);
  const percent = ((total / limitedStorage) * 100);
  const totalValue = [
    {
      value: percent,
      color: '#0A7BCC',
    },
    {
      value: 100 - percent,
      color: '#F7FBFE',
    },
  ];
  useEffect(() => {
    if (storageStatsData?.length === 0) {
      dispatch(fetchStorageStats());
    }
  }, []);
  return (
    <div className="storage-stats-container">
      <div className="storage-stats-header">
        <h3>{t('storageStats')}</h3>
      </div>
      <div className="pie-chart-wrap">
        <div className="pie-chart">
          <PieChart
            series={[
              {
                startAngle: -90,
                endAngle: 90,
                paddingAngle: 0,
                innerRadius: 95,
                outerRadius: 120,
                data: totalValue,
              },
            ]}
            height={250}
            margin={{ right: 5 }}
            slotProps={{
              legend: { hidden: true },
            }}
          >
            <PieCenterLabel size="3">{percent.toFixed(2)}%</PieCenterLabel>
            <PieCenterLabel size="2.2">
              {total?.toFixed(2)} {t('gB')} / {limitedStorage}{t('gB')}
            </PieCenterLabel>
          </PieChart>
        </div>
        <div className="legend">
          {storageStatsData?.map((item) => (
            <div className="each-legend" key={item.label}>
              <div className="image-container">
                <div className="image-content">
                  <img src={item.label === 'image' ? ImageIcon : VideoIcon} alt="images" className="icon" />
                </div>
                <div className="file-name">
                  <span>{t(item.label)}</span>
                  <span className="file-count">{t('files')} {item.value}</span>
                </div>
                <p className="file-size">{item.file_size} {item.type}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StorageStats;
