/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../../../shared/component/Button';
import { fetchContentLayoutWidgets } from '../../../redux/slices/ContentLayoutWidgetsSlice';

function SelectedWidgetPopup(props) {
  const {
    selectedWidgetType,
    selectedLayout,
    onClickSubmit,
    onClickPrevious,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    contentLayoutWidgets,
    pageCount,
    totalPageCount,
    status,
  } = useSelector((state) => state.contentLayoutWidgets);
  const [selectedWidget, setSelectedWidget] = useState({});

  const getSavedLayout = (page, filteredValues = {}) => {
    dispatch(fetchContentLayoutWidgets({ page, ...filteredValues }));
  };

  const handleScroll = () => {
    if (pageCount < totalPageCount) {
      getSavedLayout(pageCount + 1, { widget_type: selectedWidgetType });
    }
  };

  useEffect(() => {
    getSavedLayout(1, { widget_type: selectedWidgetType });
  }, []);

  return (
    <div className="content-layout-saved-widgets">
      {(() => {
        let finalDataToShow;
        if (contentLayoutWidgets?.length === 0 && pageCount === 0) {
          finalDataToShow = (
            <div className="saved-widgets-loading">
              <CircularProgress color="success" />
            </div>
          );
        } else if (contentLayoutWidgets?.length === 0 && pageCount === 1) {
          finalDataToShow = (
            <div className="saved-widgets-loading">
              <p>No data found</p>
            </div>
          );
        } else {
          finalDataToShow = (
            <InfiniteScroll
              dataLength={contentLayoutWidgets?.length}
              next={handleScroll}
              height="50vh"
              hasMore={pageCount < totalPageCount}
              loader={status === 'loading' && (
                <div className="content-layout-loader-wrap">
                  <CircularProgress color="success" />
                </div>
              )}
            >
              <div className="saved-widgets-wrap">
                {contentLayoutWidgets?.map((widget) => (
                  <div
                    role="presentation"
                    onClick={() => setSelectedWidget(widget)}
                    className="each-widget"
                  >
                    <div
                      className="each-widget-image-wrap"
                      style={{
                        border: selectedWidget?._id === widget?._id
                          ? '2px solid #212B36'
                          : '2px solid #FFFFFF',
                      }}
                    >
                      <img src={widget?.preview_image} alt="" className="each-widget-image" />
                    </div>
                    <h1>{widget?.name}</h1>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          );
        }
        return finalDataToShow;
      })()}
      <div className="add-content-type-button">
        <Button
          label={t('back')}
          click={() => onClickPrevious(selectedLayout, 'edit')}
          classes="default-button"
        />
        <Button
          label={t('add')}
          click={() => onClickSubmit(selectedLayout, selectedWidget)}
          classes="success-button"
          disabled={Boolean(!selectedWidget?._id)}
        />
      </div>
    </div>
  );
}

SelectedWidgetPopup.propTypes = {
  selectedWidgetType: PropTypes.string.isRequired,
  selectedLayout: PropTypes.objectOf().isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
};

export default SelectedWidgetPopup;
