import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';

import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';

import { fetchSingleDisplayGroup, singleDisplayGroupAdd } from '../../../redux/slices/DisplayGroupSlice';
import {
  fetchDisplaySignageList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/DGDisplaySignageSlice';
import height from '../../../utils/size-variables';
import { descriptionValidation, nameValidation } from '../../../shared/component/form/Validation';

function DisplayGroupsEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    displaySignage,
    pageCount,
    totalPageCount,
    totalDataCount,
    status,
    filter,
    tableFilter,
  } = useSelector((state) => state.dGDisplay);
  const {
    singleDisplayGroup,
    newDisplayGroup,
  } = useSelector((state) => state.displayGroup);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [deviceSelection, setDeviceSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [value, setValue] = useState({
    devices: {},
    selectedDeviceDetails: [],
  });
  const [disable, setDisable] = useState(true); // Update Button status
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  // Function to dispatch display signage details
  const getDisplaySignage = (counts, search) => {
    dispatch(fetchDisplaySignageList({ page: counts, ...search, limit: 20 }));
  };

  // Function to fetch next page
  const handleScroll = () => {
    getDisplaySignage(pageCount + 1, filter);
  };

  // Function to navigate back and reset redux state
  const addDisplayGroups = () => {
    dispatch(singleDisplayGroupAdd({}));
    navigate('../');
  };

  // Function to update the redux and re-direct to add-view page
  const submit = () => {
    if (nameValidation(1, 100, value?.name)
      && descriptionValidation(500, value?.description)) {
      dispatch(singleDisplayGroupAdd(value));
      navigate('../add-view');
    }
  };

  // Function to handle name and description changes
  const handleChange = (name, data) => {
    setValue({ ...value, [name]: data }); // Set the key-value pair as before
  };

  const handleRowSelection = (selectedRows) => {
    setDeviceSelection(selectedRows);
    if (Object.keys(selectedRows).length === displaySignage.length) {
      setAllRowsSelected(true);
    } else {
      setAllRowsSelected(false);
    }
  };

  // Column header for display signage table
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOS'),
      accessorKey: 'hardware',
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
  ];

  useEffect(() => {
    if (Object.keys(deviceSelection).length >= 20 || allRowsSelected) {
      const newDeviceSelection = { ...deviceSelection };
      displaySignage.forEach((data) => {
        if (!newDeviceSelection[data?.id]) {
          newDeviceSelection[data?.id] = true;
        }
      });
      setDeviceSelection(newDeviceSelection);
    }
  }, [displaySignage]);

  // Update the select devices if device selection changes
  useEffect(() => {
    const selectedDevices = displaySignage.filter((device) => Object.keys(deviceSelection).includes(device.id));
    setValue({
      ...value,
      devices: deviceSelection,
      selectedDeviceDetails: selectedDevices,
    });
  }, [deviceSelection, displaySignage]);

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDisplaySignage(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getDisplaySignage(1);
    }
  }, []);

  // If a page reloads update the value after single display group dispatch
  useEffect(() => {
    if (Object.keys(singleDisplayGroup).length > 0 && Object.keys(newDisplayGroup).length === 0) {
      // Convert the selected content id's into material table readable format
      const newObj = {};
      singleDisplayGroup?.devices?.forEach((item) => {
        newObj[item?.id] = true;
      });

      setValue({
        ...singleDisplayGroup,
        devices: newObj,
        selectedDeviceDetails: singleDisplayGroup.devices,
      });

      setDeviceSelection(newObj);
    }
  }, [singleDisplayGroup]);

  // Function to disable button state
  useEffect(() => {
    // Selected deviceGroup data from API call
    const deviceGroupBeforeEdit = {
      name: singleDisplayGroup?.name || '',
      description: singleDisplayGroup?.description || '',
      devices: singleDisplayGroup?.devices
        ?.map((device) => device.id)
        ?.sort((a, b) => {
          const aNumber = parseInt(a, 16); // Parse a as a hexadecimal number
          const bNumber = parseInt(b, 16); // Parse b as a hexadecimal number
          return aNumber - bNumber; // Compare the parsed numbers
        }),
    };
    // Edited deviceGroup data from value
    const deviceGroupAfterEdit = {
      name: value?.name,
      description: value?.description ? value?.description : '',
      devices: value?.selectedDeviceDetails
        ?.map((device) => device.id)
        .sort((a, b) => {
          const aNumber = parseInt(a, 16); // Parse a as a hexadecimal number
          const bNumber = parseInt(b, 16); // Parse b as a hexadecimal number
          return aNumber - bNumber; // Compare the parsed numbers
        }),
    };
    if (
      value?.name
      && Object.keys(deviceSelection).length > 0
      && !deepEqual(deviceGroupBeforeEdit, deviceGroupAfterEdit)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  // useEffect will run only once when page loads
  useEffect(() => {
    // Else-if part will execute when if user navigate from add-view page
    if (Object.keys(singleDisplayGroup).length === 0) {
      dispatch(fetchSingleDisplayGroup(params?.id));
    } else if (Object.keys(newDisplayGroup).length !== 0) {
      setValue({
        ...newDisplayGroup,
        devices: newDisplayGroup.devices,
        selectedDeviceDetails: newDisplayGroup.selectedDeviceDetails,
      });
      setDeviceSelection(newDisplayGroup.devices);
    }
  }, []);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('deviceGroups'), value.name]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addDisplayGroups}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('groupName')}
                  placeholder={t('enterGroupName')}
                  type="text"
                  name="name"
                  required
                  value={value.name}
                  change={handleChange}
                  error={Object.keys(value)?.includes('name') && !nameValidation(1, 100, value?.name)}
                  errorMessage={t('deviceGroupNameValidation')}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  value={value.description}
                  placeholder={t('enterDescription')}
                  change={handleChange}
                  error={!descriptionValidation(500, value?.description)}
                  errorMessage={t('descriptionValidation')}
                />
              </div>
              <Table
                header={columns}
                value={[
                  ...value.selectedDeviceDetails,
                  ...displaySignage.filter((device) => !Object.keys(value?.devices)?.includes(device.id)),
                ]}
                status={status}
                totalPageCount={totalPageCount}
                reduxColumnFiltersSorting={tableFilter}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalPageCount}
                rowSelection={deviceSelection}
                setRowSelection={handleRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={tableFilter?.columnFilters?.length > 0}
                onFilterOrSortingChange={onFilterOrSortingChange}
                isFirstRender={isFirstRender}
                height={height.formTableHeight}
                disableFullScreenToggle
                enableRowSelection
                toolbarLeft={(
                  <TableHeaderSelectCount
                    select={Object.keys(deviceSelection)?.length}
                    total={totalDataCount}
                  />
                )}
              />
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={addDisplayGroups} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={t('continue')}
                  click={submit}
                  classes="success-button"
                  disabled={disable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayGroupsEdit;
