/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import {
//   changeDateFormat,
//   changeDateTimeFormat,
//   currencySymbol,
// } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';
import rentalHistory from '../../utils/api/rental-history';

export const fetchRentalHistory = createAsyncThunk(
  'rentalHistory/fetchRentalHistory',
  async (data, { dispatch }) => {
    const response = await rentalHistory.getDeviceRentals(data)
      .then((response1) => {
        const tableValues = response1?.data?.results;
        return { ...response1?.data, results: tableValues, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  rentalHistory: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilter: {},
  status: 'succeed',
};

export const rentalHistorySlice = createSlice({
  name: 'rentalHistory',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetRentalState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchRentalHistory.pending, (state) => {
        console.log(state);
        state.status = 'loading';
      })
      .addCase(fetchRentalHistory.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.rentalHistory = datas;
          } else {
            state.rentalHistory.push(...datas);
          }
        }
      })
      .addCase(fetchRentalHistory.rejected, (state) => {
        console.log(state);
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setTableFilter,
  resetRentalState,
} = rentalHistorySlice.actions;

export default rentalHistorySlice.reducer;
