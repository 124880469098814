/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import popScheduleApi from '../../utils/api/pop-schedule';
import { setErrorNotification } from './NotificationSlice';
import { changeDateTimeFormat, convertSecondsToHoursAndMinutes } from '../../utils/helpers';

export const fetchPOPScheduleList = createAsyncThunk(
  'pop/',
  async (data, { dispatch }) => {
    const response = await popScheduleApi.getPopSchedule(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val?._source,
        scheduleStartDate: changeDateTimeFormat(val?._source?.scheduleStart),
        scheduleEndDate: changeDateTimeFormat(val?._source?.scheduleEnd),
        deviceOff: convertSecondsToHoursAndMinutes(val._source.totalDeviceOff),
        outOfSync: convertSecondsToHoursAndMinutes(val._source.outOfSync),
        matchingSchedule: convertSecondsToHoursAndMinutes(val._source.matchingSchedule),
        completionPercentage: `${val._source.percentage}%`,
        sequentialData: JSON.parse(val?._source?.sequentialData).map((sequential) => ({
          ...sequential,
          startsAt: changeDateTimeFormat(sequential?.startsAt),
          stopsAt: changeDateTimeFormat(sequential?.stopsAt),
          duration: convertSecondsToHoursAndMinutes(sequential.duration),
        })),
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  popData: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const popScheduleSlice = createSlice({
  name: 'popSchedule',
  initialState,
  reducers: {
    setCount: (state) => {
      state.pageCount = 1;
      state.totalPageCount = 1;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetPopState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(fetchPOPScheduleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPOPScheduleList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action.payload.code === 200) {
          const data = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.popData = data;
          } else {
            state.popData.push(...data);
          }
        }
      })
      .addCase(fetchPOPScheduleList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setCount,
  setFilter,
  setTableFilter,
  resetPopState,
} = popScheduleSlice.actions;

export default popScheduleSlice.reducer;
