import React from 'react';
import PropTypes from 'prop-types';

function CardStatus(props) {
  return (
    <div className="progress">
      <div className="progress-text">
        <h2>{props?.title}</h2>
        <h3>{props?.subTitle}</h3>
      </div>
      <div className="icon-container">
        {props?.icon}
      </div>
    </div>
  );
}
CardStatus.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default CardStatus;
