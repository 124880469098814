import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import deleteIcon from '../../../assets/icons/active-delete.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import { UploadIcon } from '../svgIcon';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { contentUploadValidation } from '../../../utils/helpers';

function FileUpload(props) {
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const removeImage = () => {
    props?.setPicture(props?.name, null);
    props?.setImgData(null);
  };

  const onChangePicture = async (e) => {
    const file = e.target?.files?.[0];
    const maxFileSizeInMB = 300;
    const fileType = file?.type === 'application/pdf' ? 'application/pdf' : file.type.split('/')[0];
    const checkFileValid = await contentUploadValidation(file, maxFileSizeInMB, fileType, true);
    if (checkFileValid === '') {
      props?.setPicture(e.target.name, e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  const handleViewPdf = () => {
    const newTab = window.open(props?.imgData, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.label}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      {
        props?.imgData
          ? (
            <div className="image-banner">
              {(() => {
                let mediaDisplay;
                if (props?.type?.includes('image')) {
                  mediaDisplay = <img src={props?.imgData} alt="content_image" className="content-image" />;
                } else if (props?.type?.includes('video')) {
                  mediaDisplay = (
                    <video
                      src={props?.imgData}
                      width="100%"
                      height="100%"
                      controls
                      poster={props?.thumbnail}
                    >
                      <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                    </video>
                  );
                } else if (props?.type?.includes('pdf')) {
                  mediaDisplay = (
                    <div
                      className="pdf-icon-container"
                      role="presentation"
                      onClick={handleViewPdf}
                    >
                      <img
                        src={pdfIcon}
                        className="pdf-icon"
                        alt="pdf"
                      />
                    </div>
                  );
                }
                return mediaDisplay;
              })()}
              {!props?.hideDeleteicon
                && (
                  <div role="presentation" className="remove-button" onClick={removeImage}>
                    <img alt="remove_image" src={deleteIcon} />
                  </div>
                )}
            </div>
          ) : (
            <div className="image-banner">
              <div role="presentation" className="upload-image-box" onClick={onButtonClick}>
                <UploadIcon />
                <label htmlFor="browse">Click or <span>Browse</span> to Upload Files from your device</label>
                <p>Supported Formats : <span>PNG, JPEG, MP4</span></p>
              </div>
            </div>
          )
      }
      <input
        type="file"
        name={props?.name}
        id="file"
        ref={inputFile}
        accept="image/*,video/*, application/pdf"
        onChange={onChangePicture}
        style={{ display: 'none' }}
      />
    </div>
  );
}

FileUpload.propTypes = {
  imgData: PropTypes.string.isRequired,
  setPicture: PropTypes.func.isRequired,
  setImgData: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  hideDeleteicon: PropTypes.bool.isRequired,
};

FileUpload.defaultProps = {
  label: '',
};

export default FileUpload;
