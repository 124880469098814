/* eslint no-underscore-dangle: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../Table';
import Button from '../../../../shared/component/Button';
import { addImageType } from '../../../../utils/helpers';

function ContentRearrange(props) {
  const { t } = useTranslation();
  const {
    layout,
    closeModal,
    setAvailableLayouts,
  } = props;
  const [tableValue, setTableValues] = useState(layout?.contents);
  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const handleRearrange = () => {
    setAvailableLayouts((prevValue) => prevValue.map((eachLayout) => {
      let updatedLayout = eachLayout;
      if (eachLayout._id === layout._id) {
        updatedLayout = {
          ...eachLayout,
          contents: tableValue,
        };
      }
      return updatedLayout;
    }));
    closeModal();
  };

  const columns = [
    {
      header: 'Content name',
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
    },
    {
      header: 'Content type',
      accessorKey: 'file_type',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => addImageType(row.original.file_type),
    },
  ];

  return (
    <div className="rearrange-wrap">
      <div className="rearrange-table">
        <DataTable
          header={columns}
          value={tableValue}
          status="succeed"
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount=""
          onFilterOrSortingChange={() => { }}
          isFirstRender
          rowSelection={contentSelection}
          setRowSelection={setContentSelection}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          enable={false}
          height="400px"
          disableSorting
          disableFullScreen
          enableRowOrdering
          disableFilterButton
          setValue={setTableValues}
        />
      </div>
      <div className="rearrange-button-wrap">
        <Button
          label={t('back')}
          click={closeModal}
          classes="default-button"
        />
        <Button
          label={t('continue')}
          click={handleRearrange}
          classes="success-button"
        />
      </div>
    </div>
  );
}

ContentRearrange.propTypes = {
  layout: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contents: PropTypes.arrayOf().isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  setAvailableLayouts: PropTypes.node.isRequired,
};

export default ContentRearrange;
