/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import SelectBox from '../../../shared/component/form/SelectBox';
import aiInstance from '../../../utils/api/ai-instance';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchDeviceUsageList } from '../../../redux/slices/AiAnalyticsSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Button from '../../../shared/component/Button';

const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, value,
}) => {
  const RADIAN = Math.PI / 360;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontSize: '13px' }}
    >
      {`${value} h`}
    </text>
  );
};

function UsageReport(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dateList = [
    {
      id: 0,
      label: t('today'),
    },
    {
      id: 1,
      label: t('byLastDay'),
    },
    {
      id: 2,
      label: t('byLastWeek'),
    },
    {
      id: 3,
      label: t('byLastMonth'),
    },
    {
      id: 4,
      label: t('customDate'),
    },
  ];
  const reportsList = [
    {
      id: 0,
      label: t('byInstance'),
    },
    {
      id: 1,
      label: t('byDevice'),
    },
  ];
  const { instanceList, deviceUsageList } = useSelector((state) => state?.AiAnalytics);
  const containerRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState({
    instances: instanceList?.[0]?.label,
    instance_id: instanceList?.[0]?.id,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const displayStatusData = [
    { name: 'Used', value: value?.aiInstanceUsageReport?.usage_hour || 0, color: '#3B82F6' },
    { name: 'Available', value: value?.aiInstanceUsageReport?.available_hours || 0, color: '#14B8A6' },
  ];

  const getUsageAiInstance = () => {
    const aiInstanceId = value?.instance_id;
    aiInstance.aiInstanceUsageReport({ aiInstanceId }).then((response) => {
      setValue((prev) => ({
        ...prev,
        aiInstanceUsageReport: response.data,
      }));
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
    });
  };

  const handleFunction = (name, changeValue) => {
    if (name === 'instances') {
      setValue({
        ...value,
        [name]: changeValue?.label,
        instance_id: changeValue?.id,
      });
    } else if (name === 'deviceDate') {
      if (changeValue?.id === 4) {
        setOpenModal(true);
      }
      setValue({ ...value, [name]: changeValue?.label });
    } else if (name === 'start_date' || name === 'end_date') {
      setValue({ ...value, [name]: dayjs(changeValue).format('YYYY-MM-DD') });
    } else {
      setValue({ ...value, [name]: changeValue?.label });
    }
  };

  const submit = () => {
    dispatch(fetchDeviceUsageList({ startDate: value.start_date, endDate: value.end_date }));
    setOpenModal(false);
  };

  useEffect(() => {
    if (value?.reportType === 'By Instance') {
      getUsageAiInstance();
      const updateDimensions = () => {
        if (containerRef.current) {
          const width = containerRef.current.clientWidth;
          const height = containerRef.current.clientHeight;
          setDimensions({ width, height });
        }
      };
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }
    return () => { };
  }, [value?.reportType, value?.instance_id]);
  useEffect(() => {
    if (instanceList && instanceList.length > 0) {
      setValue({
        instances: instanceList[0].label,
        instance_id: instanceList[0].id,
        reportType: t('byDevice'),
        deviceDate: t('today'),
      });
    }
  }, [instanceList]);
  useEffect(() => {
    if (value.deviceDate === 'Today') {
      const date = moment(new Date()).format('YYYY-MM-DD');
      dispatch(fetchDeviceUsageList({ startDate: date, endDate: date }));
    } else if (value.deviceDate === 'By Last Day') {
      const lastDay = moment().subtract(1, 'day').format('YYYY-MM-DD');
      dispatch(fetchDeviceUsageList({ startDate: lastDay, endDate: lastDay }));
    } else if (value.deviceDate === 'By Last Week') {
      const startOfLastWeek = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
      const endOfLastWeek = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
      dispatch(fetchDeviceUsageList({ startDate: startOfLastWeek, endDate: endOfLastWeek }));
    } else if (value.deviceDate === 'By Last Month') {
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const endOfLastMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      dispatch(fetchDeviceUsageList({ startDate: startOfLastMonth, endDate: endOfLastMonth }));
    }
  }, [value.deviceDate]);
  return (
    <div className="usage-report">
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={{
          title: t('playerVersionUpdate'),
          content: (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: 'flex' }}>
                  <DateCalendar
                    onChange={(dateValue) => handleFunction('start_date', dateValue)}
                    value={dayjs(value?.start_date)}
                  />
                  <DateCalendar
                    onChange={(dateValue) => handleFunction('end_date', dateValue)}
                    value={dayjs(value?.end_date)}
                  />
                </div>
              </LocalizationProvider>
              <div className="form-button-right">
                <Button
                  label={t('cancel')}
                  click={() => setOpenModal(false)}
                  classes="default-button"
                />
                <Button
                  label={t('apply')}
                  click={submit}
                  classes="success-button"
                />
              </div>
            </div>
          ),
        }}
      />
      <h1 className="title">{t('usageReport')}</h1>
      <div className={`content-body ${!props?.data ? 'with-data' : ''}`}>
        {props?.data
          && (
            <div className="no-data-found">
              <h1 className="no-data-message">{t('nodataFound')}</h1>
              <p className="no-data-description">
                {t('youCanonlaccesstheusagereportbyenablingtheAIfortheRespectiveDevice')}
              </p>
            </div>
          )}
        {!props?.data
          && (
            <div className="usage-report-container">
              <div className="report-title-wrapper">
                <span className="report-title">{t('reportsBasedOnAverageTimeOfInstance')}</span>
                <div className="select-boxes">
                  {value?.reportType === 'By Instance'
                    && (
                      <SelectBox
                        name="instances"
                        lists={instanceList}
                        value={value?.instances}
                        onchange={handleFunction}
                        placeholder={t('select')}
                        id="instances"
                      />
                    )}
                  {value?.reportType === 'By Device'
                    && (
                      <SelectBox
                        name="deviceDate"
                        lists={dateList}
                        value={value?.deviceDate}
                        onchange={handleFunction}
                        placeholder={t('select')}
                        id="deviceDate"
                      />
                    )}
                  <SelectBox
                    name="reportType"
                    lists={reportsList}
                    value={value?.reportType}
                    onchange={handleFunction}
                    placeholder={t('select')}
                    id="reportType"
                  />
                </div>
              </div>
              <div className="chart-container">
                {value?.reportType === 'By Instance'
                  && (
                    <div className="pie-chart-container">
                      <div className="pieChart-wrap" ref={containerRef}>
                        <PieChart
                          width={dimensions.width}
                          height={dimensions.height}
                        >
                          <Pie
                            data={displayStatusData}
                            dataKey="value"
                            cx={dimensions.width / 2}
                            cy={dimensions.height / 2}
                            label={renderCustomizedLabel}
                            // Calculate based on parent width & height
                            innerRadius={0}
                            outerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 20}
                            labelLine={false}
                            fill="#0088FE"
                          >
                            {displayStatusData.map((entry) => (
                              <Cell style={{ outline: 'none' }} key={entry.name} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </div>
                      <div className="pie-chart-legend">
                        {displayStatusData?.map((item) => (
                          <div className="legend-wrap">
                            <div className="each-legend">
                              <div className="marker" style={{ backgroundColor: item?.color }} />
                              <span className="label">{item?.name}</span>
                            </div>
                            <p className="hours">{item?.value} h</p>
                          </div>
                        ))}
                        <div className="legend-wrap">
                          <span className="total-label">{t('total')}</span>
                          <p className="hours">{value?.aiInstanceUsageReport?.total_hours}h</p>
                        </div>
                      </div>
                    </div>
                  )}
                {value?.reportType === 'By Device'
                  && (
                    <div className="bar-chart-container">
                      <div className="barChart-wrap">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            width={500}
                            height={300}
                            data={deviceUsageList.results}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                            barSize={20}
                          >
                            <XAxis
                              axisLine={false}
                              dataKey="name"
                              scale="point"
                              className="x-axis"
                              padding={{ left: 10, right: 10 }}
                            />
                            <YAxis
                              axisLine={false}
                              tickLine={false}
                              className="y-axis"
                              ticks={[0, 50, 100, 150, 200]}
                              tickFormatter={(hoursValues) => `${hoursValues} h`}
                            />
                            <Tooltip />
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <Bar dataKey="pv" fill="#6EC284" background={{ fill: 'transparent' }} />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

UsageReport.propTypes = {
  data: PropTypes.arrayOf().isRequired,
};

export default UsageReport;
