/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CheckBoxInput,
  RadioButton,
} from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

function FileTypesPopup(props) {
  const {
    singleGridLayout,
    handleSetFileType,
    closeModal,
    availableMediaTypes,
    availableWidgets,
    fileTypeAllowedInOneSection,
    videoAllowedGrid,
  } = props;
  const { t } = useTranslation();
  const [singleGrid, setSingleGrid] = useState({});
  const [selectedType, setSelectedTab] = useState('media');

  const handleChangeFileType = (value) => {
    setSingleGrid((prevValue) => {
      let updatedSingleGrid = prevValue;
      if (prevValue?.file_types?.includes(value)) {
        // Remove the value from file_types
        updatedSingleGrid = {
          ...prevValue,
          file_types: prevValue?.file_types?.filter((type) => type !== value),
        };
      } else {
        // Add the value to file_types
        updatedSingleGrid = {
          ...prevValue,
          file_types: [...prevValue.file_types, value],
        };
      }
      return updatedSingleGrid;
    });
  };

  const handleChangeWidgetType = (value) => {
    setSingleGrid((prevValue) => {
      let updatedSingleGrid = prevValue;
      if (prevValue?.file_types?.includes(value)) {
        // Remove the value from file_types
        updatedSingleGrid = {
          ...prevValue,
          file_types: prevValue?.file_types?.filter((type) => type !== value),
        };
      } else {
        // Add the value to file_types
        updatedSingleGrid = {
          ...prevValue,
          file_types: [value],
        };
      }
      return updatedSingleGrid;
    });
  };

  const fileTypeTabs = [
    {
      id: 1,
      label: t('media'),
      key: 'media',
    },
    {
      id: 2,
      label: t('widgets'),
      key: 'widgets',
    },
  ];

  const handleSwitchTab = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setSingleGrid(singleGridLayout);

    if (availableWidgets
      .map((eachMediaTyp) => eachMediaTyp.key)
      .some((key) => singleGridLayout?.file_types?.includes(key))) {
      setSelectedTab('widgets');
    }
  }, [singleGridLayout]);

  return (
    <div className="add-content-type-button-wrap">
      <div className="add-content-type-wrap">
        <div className="add-content-type-tabs">
          {fileTypeTabs?.map((tab) => (
            <div
              role="presentation"
              onClick={() => handleSwitchTab(tab?.key)}
              className="each-tab"
            >
              <h1
                style={{ '--active-tab-color': tab?.key === selectedType ? '#03A84B' : '#717880' }}
              >
                {tab?.label}
              </h1>
              <div
                className="active-tab-border"
                style={{ '--active-tab-width': tab?.key === selectedType ? '95%' : '0%' }}
              />
            </div>
          ))}
        </div>
        <div className="add-content-type-container">
          {selectedType === 'media'
            && (
              <div className="add-content-media">
                <div className="add-content-types">
                  {availableMediaTypes?.map((mediaType) => (
                    <CheckBoxInput
                      label={mediaType?.label}
                      value={mediaType?.key}
                      checked={singleGrid?.file_types?.includes(mediaType?.key)}
                      onChange={handleChangeFileType}
                      disabled={
                        (availableWidgets
                          .map((eachMediaTyp) => eachMediaTyp.key)
                          .some((key) => singleGrid?.file_types?.includes(key)))
                        || (!(singleGrid?._id === videoAllowedGrid || videoAllowedGrid === null)
                          && fileTypeAllowedInOneSection
                            .map((eachMediaTyp) => eachMediaTyp.key)
                            .some((key) => key === mediaType?.key))
                      }
                    />
                  ))}
                </div>
                <p><b>{t('videoYoutubeUrlPdfPlaylist')}</b> {t('canBeAddedInOnlyOneSection')}<span>*</span></p>
              </div>
            )}
          {selectedType === 'widgets'
            && (
              <div className="add-content-media">
                <div className="add-content-types">
                  {availableWidgets?.map((mediaType) => (
                    <RadioButton
                      label={mediaType?.label}
                      value={mediaType?.key}
                      checked={singleGrid?.file_types?.includes(mediaType?.key)}
                      onChange={() => { }}
                      handleOnClick={handleChangeWidgetType}
                      allowUnselect
                      disabled={
                        availableMediaTypes
                          .map((eachMediaTyp) => eachMediaTyp.key)
                          .some((key) => singleGrid?.file_types?.includes(key))
                      }
                    />
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="add-content-type-button">
        <Button
          label={t('back')}
          click={closeModal}
          classes="default-button"
        />
        <Button
          label={t('continue')}
          click={() => handleSetFileType(singleGrid)}
          classes="success-button"
          disabled={singleGrid?.file_types?.length === 0}
        />
      </div>
    </div>
  );
}

FileTypesPopup.propTypes = {
  singleGridLayout: PropTypes.string.isRequired,
  handleSetFileType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  availableMediaTypes: PropTypes.arrayOf().isRequired,
  availableWidgets: PropTypes.arrayOf().isRequired,
  fileTypeAllowedInOneSection: PropTypes.arrayOf().isRequired,
  videoAllowedGrid: PropTypes.string,
};

FileTypesPopup.defaultProps = {
  videoAllowedGrid: null,
};

export default FileTypesPopup;
