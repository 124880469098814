import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';

function Tabs(props) {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [tabName, setTabName] = useState(tab || '');

  const handleSwitchTab = (key) => {
    setTabName(key);
    navigate(`../${key}`);
  };

  useEffect(() => {
    if (tab && tab !== tabName) {
      setTabName(tab);
    }
  }, [tab, tabName]);

  return (
    <div className="settings-tabs-container">
      <div className="tabs-wrap">
        {props?.actions?.map((item) => (
          <div
            role="presentation"
            onClick={() => handleSwitchTab(item?.key)}
            className="each-tab"
            key={item?.key}
          >
            <div className="tab-text">
              {item?.icon
                && (
                  <span>{React.cloneElement(item?.key === tabName ? item?.activeIcon : item?.icon)}</span>
                )}
              <h1
                style={{ '--active-tab-color': item?.key === tabName ? '#03A84B' : '#717880' }}
              >
                {item?.label}
              </h1>
            </div>
            <div
              className="active-tab-border"
              style={{ '--active-tab-width': item?.key === tabName ? '95%' : '0%' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
Tabs.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.element,
      activeIcon: PropTypes.element,
    }),
  ).isRequired,
};
export default Tabs;
