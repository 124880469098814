/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../../shared/component/form/Input';
import InputTags from '../../../shared/component/form/InputTags';
import Button from '../../../shared/component/Button';
import { nameValidation, tagValidation } from '../../../shared/component/form/Validation';
import templates from '../../../utils/api/templates';
import { createOrUpdateTemplate, setPage, updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function SaveAndTagsPopup(props) {
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [content, setContent] = useState(props?.content);
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleClose = () => {
    props?.setOpenModal(false);
  };

  const handleFunction = (name, changeValue, key = 0) => {
    if (key === 13 && name === 'search_tags') {
      const newData = JSON.parse(JSON.stringify(content));
      newData[0].search_tags = Array?.isArray(changeValue) ? changeValue : [...newData[0].search_tags, changeValue];
      setContent(newData);
    } else if (name === 'filename') {
      const newData = JSON.parse(JSON.stringify(content));
      newData[0].template_name = changeValue;
      setContent(newData);
    }
  };

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 25, content?.[0]?.template_name)
      && tagValidation(30, 10, content?.[0]?.search_tags)) {
      setUploading(true);
      setDisable(true);
      const postData = [
        {
          _id: content?.[0]?._id,
          multi_pricing: content?.[0]?.multi_pricing,
          search_tags: content?.[0]?.search_tags,
          styles: content?.[0]?.styles,
          tags: content?.[0]?.tags,
          template_backgroundImage_url: content?.[0]?.template_backgroundImage_url,
          template_contents: content?.[0]?.template_contents,
          template_image_url: content?.[0]?.template_image_url,
          template_images: content?.[0]?.template_images,
          template_name: content?.[0]?.template_name,
          template_number: content?.[0]?.template_number,
          template_type: content?.[0]?.template_type,
          template_title: content?.[0]?.template_title,
          template_sub_title: content?.[0]?.template_sub_title,
          clone: content?.[0]?.clone,
          content_id: content?.[0]?.content_id,
        },
      ];
      templates?.updateSavedTemplate(postData)?.then((res) => {
        setContent(() => [{ ...res?.data }]);
        dispatch(updateCurrentTemplate({ ...res?.data }));
        dispatch(createOrUpdateTemplate({ ...res?.data }));
        dispatch(setPage('tab-2'));
        setUploading(false);
        navigate(`/admin/templates/view-html/${id}`);
      })
        .catch((error) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (content?.[0]?.template_name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [content?.[0]?.template_name]);

  return (
    <div>
      <div className="saveAndTags-popup">
        <div className="form">
          <Input
            icon={' '}
            label={t('fileName')}
            type="text"
            name="filename"
            required
            value={content?.[0]?.template_name}
            change={handleFunction}
            placeholder={t('enterFileName')}
            error={
              !nameValidation(1, 25, content?.[0]?.template_name)
              && submitButtonClicked
            }
            errorMessage={t('fileNameValidation')}
          />
          <InputTags
            icon={' '}
            label={t('tags')}
            name="search_tags"
            addData={handleFunction}
            required={false}
            value={content?.[0]?.search_tags}
            placeholder={t('enterMediaTags')}
            error={
              !tagValidation(30, 10, content?.[0]?.search_tags)
              && submitButtonClicked
            }
            errorMessage={t('tagsValidation')}
          />
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label={t('back')}
                click={handleClose}
                classes="default-button"
              />
            </div>
            <div className="form-button">
              <Button
                label={t('save')}
                click={submit}
                disabled={disable}
                classes={uploading ? 'success-button loading-btn' : 'success-button'}
                loading={uploading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SaveAndTagsPopup.propTypes = {
  setOpenModal: PropTypes.node.isRequired,
  content: PropTypes.arrayOf.isRequired,
};

export default SaveAndTagsPopup;
