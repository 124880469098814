/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Joyride from 'react-joyride';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  Outlet,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import Register from '../Register';
import Login from '../Login';
import Dashboard from '../Dashboard';
import SideBar from '../../shared/component/SideBar';
import Header from '../../shared/component/Header';
import MyContent from '../MyContent';
import PlayList from '../PlayList';
import PlayListAdd from '../PlayList/components/PlayListAdd';
import PlayListAddView from '../PlayList/components/PlayListAddView';
import PlayListView from '../PlayList/components/PlayListView';
import PlayListEdit from '../PlayList/components/PlayListEdit';
import Roles from '../Roles';
import RolesAdd from '../Roles/components/RolesAdd';
import RolesEdit from '../Roles/components/RolesEdit';
import Users from '../Users';
import UserAdd from '../Users/components/UserAdd';
import UserEdit from '../Users/components/UserEdit';
import Location from '../Location';
import LocationAdd from '../Location/components/LocationAdd';
import LocationEdit from '../Location/components/LocationEdit';
import LocationView from '../Location/components/LocationView';
import DisplayGroups from '../DisplayGroups';
import DisplayGroupsAdd from '../DisplayGroups/components/DisplayGroupsAdd';
import DisplayGroupsAddView from '../DisplayGroups/components/DisplayGroupsAddView';
import DisplayGroupsEdit from '../DisplayGroups/components/DisplayGroupsEdit';
import DisplayGroupsView from '../DisplayGroups/components/DisplayGroupsView';
import DisplaySignage from '../DisplaySignage';
import DisplaySignageEdit from '../DisplaySignage/components/DisplaySignageEdit';
import DisplaySignageAdd from '../DisplaySignage/components/DisplaySignageAdd';
import DisplaySignageView from '../DisplaySignage/components/DisplaySignageView';
import ContentView from '../MyContent/components/ContentView';
import Schedule from '../Schedule';
import ScheduleAdd from '../Schedule/components/ScheduleAdd';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import Chatbot from '../Chatbot';
import ScheduleView from '../Schedule/components/ScheduleView';
import RolesView from '../Roles/components/RoleView';
import ScheduleEdit from '../Schedule/components/ScheduleEdit';
import UserView from '../Users/components/UserView';
import Notification from '../../shared/component/Notification';
import SpecialSchedule from '../Schedule/components/SpecialSchedule';
import Bigcalender from '../Schedule/components/CalendarSchedule';
import Footer from '../../shared/component/Footer';
import Subscriptions from '../Subscriptions';
import Snapshot from '../Snapshot';
import POPAnalytics from '../POPAnalytics';
import Templates from '../Templates';
import TableRenderPage from '../Templates/components/TableRenderPage';
import HtmlRenderPage from '../Templates/components/HtmlRenderPage';

import Integration from '../Integration/index';
import AiAnalyticsDashBoard from '../AiAnalyticsDashboard';
import AiAnalyticsDevices from '../AiAnalyticsDevices';
import AiAnalyticsReports from '../AiAnalyticsReports';
import Settings from '../Settings';

import styles from '../../assets/json/joyride-styles.json';

import backRoutes from '../../assets/json/previous-navigate-steps.json';
import nextRoutes from '../../assets/json/next-navigate-steps.json';
import FloorMapDeviceAdd from '../Location/components/FloorMapDeviceAdd';
import FloorMapDeviceView from '../Location/components/FloorMapDeviceView';
import FloorMapDeviceEdit from '../Location/components/FloorMapDeviceEdit';
import EditFloorMap from '../Location/components/FloorMapEdit';
// import StyleRenderPage from '../Templates/components/StyleRenderPage';
// User Api
import userApi from '../../utils/api/users';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { setLocalStorageItem, checkFeatures, checkPermission } from '../../utils/helpers';
import WalkthroughSteps from './WalkthroughSteps';
import AuthGuard from './AuthGuard';
import NotFoundPage from './NotFoundPage';

import {
  AdvancedContentManagementIcon,
  AiAnalyticsIcon,
  ContentManagementIcon,
  DashboardIcon,
  DisplayManagementIcon,
  FacilityIcon,
  ReportIcon,
  ScheduleManagementIcon,
  UserManagementIcon,
} from '../../shared/component/svgIcon';
import ViewImage from '../Templates/components/viewImage';
import Widget from '../Widgets/index';
import AddForm from '../Widgets/components/Weather/AddForm';
import WidgetImage from '../Widgets/components/Weather/PreviewWidget';
import ClockAddForm from '../Widgets/components/Clock/ClockAddForm';
import PreviewClockWidget from '../Widgets/components/Clock/PreviewClockWidget';
import RSSFeedAddForm from '../Widgets/components/RssFeed/RssAddForm';
import RSSFeedImagePage from '../Widgets/components/RssFeed/PreviewRssWidget';
// import WeatherComponent from '../Widgets/components/Weather/WeatherComponent';
import LayoutSchedule from '../Schedule/components/layoutSchedule';
import WidgetTemplate from '../Widgets/components/Weather/WidgetTemplate';
import EditForm from '../Widgets/components/Weather/EditForm';
import EditClock from '../Widgets/components/Clock/EditClock';
import RssEditForm from '../Widgets/components/RssFeed/RssEditForm';
import ClockTemplate from '../Widgets/components/Clock/ClockTemplate';
import ContentLayout from '../ContentLayout';
import ContentLayoutAdd from '../ContentLayout/components/ContentLayoutAdd';
import ContentLayoutEdit from '../ContentLayout/components/ContentLayoutEdit';
import AiReportWomanCharts from '../AiAnalyticsReports/AiReportWomanCharts';
import AiReportAgeCharts from '../AiAnalyticsReports/AiReportAgeCharts';

const routsData = [
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        permission: [
          { modal: 'dashboard', name: 'view', permission: 'viewDashboard' },
        ],
        component: <Dashboard />,
      },
    ],
  },
  {
    path: 'my-content',
    children: [
      {
        path: '',
        permission: [
          { modal: 'content', name: 'view', permission: 'getContent' },
        ],
        component: <Navigate to="list" />,
      },
      {
        path: ':page',
        permission: [
          { modal: 'content', name: 'view', permission: 'getContent' },
        ],
        component: <MyContent />,
      },
    ],
  },
  {
    path: 'templates',
    children: [
      {
        path: '',
        permission: [
          { modal: 'templates', name: 'view', permission: 'getTemplatesList' },
        ],
        component: <Templates />,
      },
      {
        path: 'edit-content/:id',
        permission: [
          { modal: 'templates', name: 'create', permission: 'getTemplatesList' },
        ],
        component: <TableRenderPage />,
      },
      {
        path: 'view-html/:id',
        permission: [
          { modal: 'templates', name: 'create', permission: 'getTemplatesList' },
        ],
        component: <HtmlRenderPage />,
      },
      {
        path: 'view-image/:id',
        permission: [
          { modal: 'templates', name: 'view', permission: 'getTemplatesList' },
        ],
        component: <ViewImage />,
      },
    ],
  },
  {
    path: 'content-layout',
    children: [
      {
        path: '',
        permission: [
          { modal: 'layouts', name: 'view', permission: 'getLayouts' },
        ],
        component: <Navigate to="layouts" />,
      },
      {
        path: ':tab',
        permission: [
          { modal: 'layouts', name: 'view', permission: 'getLayouts' },
        ],
        component: <ContentLayout />,
      },
    ],
  },
  {
    path: 'apps',
    children: [
      {
        path: '',
        permission: [
          { modal: 'apps', name: 'view', permission: 'getApps' },
        ],
        component: <Navigate to="widgets" />,
      },
      {
        path: ':tab',
        permission: [
          { modal: 'apps', name: 'view', permission: 'getApps' },
        ],
        component: <Widget />,
      },
    ],
  },
  {
    path: 'settings',
    children: [
      {
        path: '',
        permission: [],
        component: <Navigate to="profile" />,
      },
      {
        path: ':tab',
        permission: [],
        component: <Settings />,
      },
    ],
  },
  {
    path: 'playlist',
    children: [
      {
        path: '',
        permission: [
          { modal: 'playlist', name: 'view', permission: 'getPlaylist' },
        ],
        component: <PlayList />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'playlist', name: 'create', permission: 'addPlaylist' },
        ],
        component: <PlayListAdd />,
      },
      {
        path: 'add-view',
        permission: [
          { modal: 'playlist', name: 'create', permission: 'addPlaylist' },
          { modal: 'playlist', name: 'edit', permission: 'editPlaylist' },
        ],
        component: <PlayListAddView />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'playlist', name: 'view', permission: 'getPlaylist' },
        ],
        component: <PlayListView />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'playlist', name: 'edit', permission: 'editPlaylist' },
        ],
        component: <PlayListEdit />,
      },
    ],
  },
  {
    path: 'integration',
    children: [
      {
        path: '',
        permission: [
          { modal: 'playlist', name: 'view', permission: 'getPlaylist' },
        ],
        component: <Integration />,
      },
    ],
  },
  {
    path: 'roles',
    children: [
      {
        path: '',
        permission: [
          { modal: 'role', name: 'view', permission: 'getRole' },
        ],
        component: <Roles />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'role', name: 'create', permission: 'addRole' },
        ],
        component: <RolesAdd />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'role', name: 'edit', permission: 'editRole' },
        ],
        component: <RolesEdit />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'role', name: 'view', permission: 'getRole' },
        ],
        component: <RolesView />,
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        permission: [
          { modal: 'user', name: 'view', permission: 'getUsers' },
        ],
        component: <Users />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'user', name: 'create', permission: 'addUser' },
        ],
        component: <UserAdd />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'user', name: 'view', permission: 'getUsers' },
        ],
        component: <UserView />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'user', name: 'edit', permission: 'editUser' },
        ],
        component: <UserEdit />,
      },
    ],
  },
  {
    path: 'location',
    children: [
      {
        path: '',
        permission: [
          { modal: 'store', name: 'view', permission: 'getStore' },
        ],
        component: <Navigate to="list" />,
      },
      {
        path: ':page',
        permission: [
          { modal: 'store', name: 'view', permission: 'getStore' },
        ],
        component: <Location />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'store', name: 'create', permission: 'addStore' },
        ],
        component: <LocationAdd />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'store', name: 'edit', permission: 'editStore' },
        ],
        component: <LocationEdit />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'store', name: 'view', permission: 'getStore' },
        ],
        component: <LocationView />,
      },
      {
        path: 'edit-floor-map/:id',
        permission: [
          { modal: 'store', name: 'edit', permission: 'editStore' },
        ],
        component: <EditFloorMap />,
      },
    ],
  },
  {
    path: 'display-signage',
    children: [
      {
        path: '',
        permission: [
          { modal: 'device', name: 'view', permission: 'getDisplaySignage' },
        ],
        component: <Navigate to="list" />,
      },
      {
        path: ':page',
        permission: [
          { modal: 'device', name: 'view', permission: 'getDisplaySignage' },
        ],
        component: <DisplaySignage />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'device', name: 'create', permission: 'addDisplaySignage' },
        ],
        component: <DisplaySignageAdd />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'device', name: 'view', permission: 'getDisplaySignage' },
        ],
        component: <DisplaySignageView />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'device', name: 'edit', permission: 'editDisplaySignage' },
        ],
        component: <DisplaySignageEdit />,
      },
    ],
  },
  {
    path: 'display-groups',
    children: [
      {
        path: '',
        permission: [
          { modal: 'display_groups', name: 'view', permission: 'getDisplayGroup' },
        ],
        component: <DisplayGroups />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'display_groups', name: 'create', permission: 'addDisplayGroup' },
        ],
        component: <DisplayGroupsAdd />,
      },
      {
        path: 'add-view',
        permission: [
          { modal: 'display_groups', name: 'create', permission: 'addDisplayGroup' },
          { modal: 'display_groups', name: 'edit', permission: 'editDisplayGroup' },
        ],
        component: <DisplayGroupsAddView />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'display_groups', name: 'view', permission: 'getDisplayGroup' },
        ],
        component: <DisplayGroupsView />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'display_groups', name: 'edit', permission: 'editDisplayGroup' },
        ],
        component: <DisplayGroupsEdit />,
      },
    ],
  },
  {
    path: 'schedules',
    children: [
      {
        path: '',
        permission: [
          { modal: 'schedule', name: 'view', permission: 'getSchedule' },
        ],
        component: <Schedule />,
      },
      {
        path: 'add',
        permission: [
          { modal: 'schedule', name: 'create', permission: 'addSchedule' },
        ],
        component: <ScheduleAdd />,
      },
      {
        path: 'edit/:id',
        permission: [
          { modal: 'schedule', name: 'edit', permission: 'editSchedule' },
        ],
        component: <ScheduleEdit />,
      },
      {
        path: 'view/:id',
        permission: [
          { modal: 'schedule', name: 'view', permission: 'getSchedule' },
        ],
        component: <ScheduleView />,
      },
    ],
  },
  {
    path: 'calendar-schedule',
    children: [
      {
        path: '',
        permission: [
          { modal: 'schedule', name: 'view_calendar_view', permission: 'getCalendarSchedule' },
        ],
        component: <Bigcalender />,
      },
    ],
  },
  {
    path: 'special-schedules',
    children: [
      {
        path: '',
        permission: [
          { modal: 'schedule', name: 'view_special_schedule', permission: 'viewSpecialSchedule' },
        ],
        component: <SpecialSchedule />,
      },
    ],
  },
  {
    path: 'subscription',
    children: [
      {
        path: '',
        permission: [
          { modal: 'subscription', name: 'view', permission: 'getSubscription' },
          { modal: 'subscription', name: 'view', permission: 'getTransaction' },
        ],
        component: <Subscriptions />,
      },
    ],
  },
  {
    path: 'snapshot',
    children: [
      {
        path: '',
        permission: [
          { modal: 'snapshot', name: 'create_display_snapshot', permission: 'getDisplaySnapshot' },
          { modal: 'snapshot', name: 'create_display_group_snapshot', permission: 'getGroupsSnapshot' },
          { modal: 'snapshot', name: 'create_store_snapshot', permission: 'getStoreSnapshot' },
          { modal: 'snapshot', name: 'create_schedule_snapshot', permission: 'getScheduleSnapshot' },
        ],
        features: [],
        component: <Snapshot />,
      },
    ],
  },
  {
    path: 'pop-device',
    children: [
      {
        path: '',
        permission: [
          { modal: 'pop_device', name: 'view', permission: 'getPOPDevice' },
        ],
        component: <POPAnalytics />,
      },
    ],
  },
  {
    path: 'ai-analytics-dashboard',
    children: [
      {
        path: '',
        permission: [
          { modal: 'al_analytics', name: 'ai_instance', permission: 'getSchedule' },
        ],
        component: <AiAnalyticsDashBoard />,
      },
    ],
  },
  {
    path: 'ai-analytics-reports',
    children: [
      {
        path: '',
        permission: [
          { modal: 'al_analytics', name: 'ai_instance', permission: 'getSchedule' },
        ],
        component: <AiAnalyticsReports />,
      },
      {
        path: ':id',
        permission: [
          { modal: 'al_analytics', name: 'ai_instance', permission: 'getSchedule' },
        ],
        component: <AiReportWomanCharts />,
      },
    ],
  },
  {
    path: 'ai-analytics-age-reports',
    children: [
      {
        path: '',
        permission: [
          { modal: 'al_analytics', name: 'ai_instance', permission: 'getSchedule' },
        ],
        component: <AiReportAgeCharts />,
      },
    ],
  },
  {
    path: 'ai-analytics-devices',
    children: [
      {
        path: '',
        permission: [
          { modal: 'al_analytics', name: 'ai_instance', permission: 'getSchedule' },
        ],
        component: <AiAnalyticsDevices />,
      },
    ],
  },
  // {
  //   path: 'account-preference',
  //   children: [
  //     {
  //       path: '',
  //       permission: [
  //         { modal: 'preference', name: 'view', permission: 'getPreference' },
  //       ],
  //       component: <AccountPreference />,
  //     },
  //   ],
  // },
];

function WrapperRoutes({ setStepIndex, setRunWalkthrough }) {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer open/close
  const [sidebarMenu, setSideBarMenu] = useState([
    {
      id: 1,
      name: t('home'),
      accordion: true,
      showAccordionArrow: false,
      enable: checkPermission('viewDashboard') && checkFeatures('dashboard', 'view'),
      subMenus: [
        {
          name: t('dashboard'),
          class: 'menu',
          activeClass: 'menu active',
          svgIcon: <DashboardIcon />,
          url: '/admin/dashboard',
          enable: checkPermission('viewDashboard') && checkFeatures('dashboard', 'view'),
          activeMenu: false,
          key: 'dashboard',
        },
      ],
    },
    {
      id: 2,
      name: t('contentManagement'),
      accordion: false,
      svgIcon: <ContentManagementIcon />,
      showAccordionArrow: true,
      enable: (checkPermission('getContent') && checkFeatures('content', 'view'))
        || (checkPermission('getTemplatesList') && checkFeatures('templates', 'view'))
        || (checkPermission('getPlaylist') && checkFeatures('playlist', 'view'))
        || (checkPermission('getApps') && checkFeatures('apps', 'view'))
        || (checkPermission('getLayouts') && checkFeatures('layouts', 'view')),
      subMenus: [
        {
          name: t('myContent'),
          class: 'menu my-content-link',
          activeClass: 'menu active my-content-link',
          url: '/admin/my-content',
          activeMenu: false,
          enable: checkPermission('getContent') && checkFeatures('content', 'view'),
          key: 'my_content',
        },
        {
          name: t('Templates'),
          class: 'menu',
          activeClass: 'menu active',
          url: '/admin/templates',
          active: '/admin/templates',
          enable: checkPermission('getTemplatesList') && checkFeatures('templates', 'view'),
          key: 'Templates',
        },
        {
          name: t('playlist'),
          class: 'menu playlist-nav',
          activeClass: 'menu active playlist-nav',
          url: '/admin/playlist',
          activeMenu: false,
          enable: checkPermission('getPlaylist') && checkFeatures('playlist', 'view'),
          key: 'playlist',
        },
      ],
    },
    {
      id: 3,
      name: t('advancedContentManagement'),
      accordion: false,
      svgIcon: <AdvancedContentManagementIcon />,
      showAccordionArrow: true,
      enable: (checkPermission('getLayouts') && checkFeatures('layouts', 'view'))
        || (checkPermission('getApps') && checkFeatures('apps', 'view'))
        || (checkPermission('positeasy') && checkFeatures('integration', 'positeasy')),
      subMenus: [
        {
          name: t('integration'),
          class: 'menu playlist-nav',
          activeClass: 'menu active',
          url: '/admin/integration',
          activeMenu: false,
          enable: checkPermission('positeasy') && checkFeatures('integration', 'positeasy'),
          key: 'integration',
        },
        {
          name: t('layout'),
          // class: 'menu playlist-nav',
          // activeClass: 'menu active playlist-nav',
          url: '/admin/content-layout',
          activeMenu: false,
          enable: checkPermission('getLayouts') && checkFeatures('layouts', 'view'),
          key: 'content-layout',
        },
        {
          name: t('apps'),
          class: 'menu',
          activeClass: 'menu active',
          url: '/admin/apps',
          active: '/admin/apps',
          enable: checkPermission('getApps') && checkFeatures('apps', 'view'),
          key: 'apps',
        },
      ],
    },
    {
      id: 4,
      name: t('facilityManagement'),
      accordion: false,
      svgIcon: <FacilityIcon />,
      showAccordionArrow: true,
      enable: checkPermission('getStore') && checkFeatures('store', 'view'),
      subMenus: [
        {
          name: t('facilities'),
          class: 'menu store-location-bar',
          activeClass: 'menu active store-location-bar',
          // svgIcon: <FacilityIcon />,
          url: '/admin/location',
          activeMenu: false,
          enable: checkPermission('getStore') && checkFeatures('store', 'view'),
          key: 'store_location',
        },
      ],
    },
    {
      id: 5,
      name: t('deviceManagement'),
      accordion: false,
      svgIcon: <DisplayManagementIcon />,
      showAccordionArrow: true,
      enable: (checkPermission('getDisplaySignage') && checkFeatures('device', 'view'))
        || (checkPermission('getDisplayGroup') && checkFeatures('display_groups', 'view')),
      subMenus: [
        {
          name: t('signageDevices'),
          class: 'menu display-signage-sideBar',
          activeClass: 'menu active display-signage-sideBar',
          url: '/admin/display-signage',
          activeMenu: false,
          enable: checkPermission('getDisplaySignage') && checkFeatures('device', 'view'),
          key: 'display_signage',
        },
        {
          name: t('deviceGroups'),
          activeClass: 'menu active display-groups-nav',
          class: 'menu display-groups-nav',
          url: '/admin/display-groups',
          activeMenu: false,
          enable: checkPermission('getDisplayGroup') && checkFeatures('display_groups', 'view'),
          key: 'display_groups',
        },
      ],
    },
    {
      id: 6,
      name: t('scheduleManagement'),
      accordion: false,
      svgIcon: <ScheduleManagementIcon />,
      enable: (checkPermission('getSchedule') && checkFeatures('schedule', 'view'))
        || (checkPermission('viewSpecialSchedule') && checkFeatures('schedule', 'view_special_schedule')),
      showAccordionArrow: true,
      subMenus: [
        {
          name: t('schedules'),
          class: 'menu schedules-bar',
          activeClass: 'menu active schedules-bar',
          url: '/admin/schedules',
          activeMenu: false,
          enable: checkPermission('getSchedule') && checkFeatures('schedule', 'view'),
          key: 'schedules',
        },
        {
          name: t('specialSchedule'),
          class: 'menu special-schedules-bar',
          activeClass: 'menu active special-schedules-bar',
          url: '/admin/special-schedules',
          activeMenu: false,
          enable: checkPermission('viewSpecialSchedule') && checkFeatures('schedule', 'view_special_schedule'),
          key: 'special_schedules',
        },
      ],
    },
    {
      id: 7,
      name: t('aiAnalytics'),
      accordion: false,
      svgIcon: <AiAnalyticsIcon />,
      enable: (checkPermission('getContent') && checkFeatures('al_analytics', 'ai_instance'))
        || (checkPermission('getContent') && checkFeatures('al_analytics', 'ai_instance')),
      showAccordionArrow: true,
      subMenus: [
        {
          name: t('dashboard'),
          class: 'menu schedules-bar',
          activeClass: 'menu active ai_analytics_reports',
          url: '/admin/ai-analytics-dashboard',
          activeMenu: false,
          enable: checkPermission('getContent') && checkFeatures('al_analytics', 'ai_instance'),
          key: 'ai_analytics_dashboard',
        },
        {
          name: t('reports'),
          class: 'menu special-schedules-bar',
          activeClass: 'menu active ai_analytics_reports',
          url: '/admin/ai-analytics-reports',
          activeMenu: false,
          enable: checkPermission('getContent') && checkFeatures('al_analytics', 'ai_instance'),
          key: 'ai_analytics_reports',
        },
        {
          name: t('devices'),
          class: 'menu special-schedules-bar',
          activeClass: 'menu active ai_analytics_reports',
          url: '/admin/ai-analytics-devices',
          activeMenu: false,
          enable: checkPermission('getContent') && checkFeatures('al_analytics', 'ai_instance'),
          key: 'ai-analytics-devices',
        },
      ],
    },
    {
      id: 8,
      name: t('reports'),
      accordion: false,
      svgIcon: <ReportIcon />,
      enable: (checkPermission('getPOPDevice') && checkFeatures('pop_device', 'view'))
        || (checkPermission('getDisplaySnapshot') && checkFeatures('snapshot', 'create_display_snapshot'))
        || (checkPermission('getGroupsSnapshot') && checkFeatures('snapshot', 'create_display_group_snapshot'))
        || (checkPermission('getStoreSnapshot') && checkFeatures('snapshot', 'create_store_snapshot'))
        || (checkPermission('getScheduleSnapshot') && checkFeatures('snapshot', 'create_schedule_snapshot')),
      showAccordionArrow: true,
      subMenus: [
        {
          name: t('snapshot'),
          class: 'menu',
          activeClass: 'menu active',
          url: '/admin/snapshot',
          enable: (checkPermission('getDisplaySnapshot') && checkFeatures('snapshot', 'create_display_snapshot'))
            || (checkPermission('getGroupsSnapshot') && checkFeatures('snapshot', 'create_display_group_snapshot'))
            || (checkPermission('getStoreSnapshot') && checkFeatures('snapshot', 'create_store_snapshot'))
            || (checkPermission('getScheduleSnapshot') && checkFeatures('snapshot', 'create_schedule_snapshot')),
          activeMenu: false,
          key: 'snapshot',
        },
        {
          name: t('popDevice'),
          class: 'menu',
          activeClass: 'menu active',
          url: '/admin/pop-device',
          enable: checkPermission('getPOPDevice') && checkFeatures('pop_device', 'view'),
          activeMenu: false,
          key: 'pop_device',
        },
      ],
    },
    {
      id: 9,
      name: t('userManagement'),
      accordion: false,
      svgIcon: <UserManagementIcon />,
      showAccordionArrow: true,
      enable: (checkPermission('getRole') && checkFeatures('role', 'view'))
        || (checkPermission('getUsers') && checkFeatures('user', 'view')),
      subMenus: [
        {
          name: t('role'),
          class: 'menu roles-bar',
          activeClass: 'menu active roles-bar',
          url: '/admin/roles',
          enable: checkPermission('getRole') && checkFeatures('role', 'view'),
          activeMenu: false,
          key: 'roles_permission',
        },
        {
          name: t('users'),
          class: 'menu users-bar',
          activeClass: 'menu active users-bar',
          url: '/admin/users',
          enable: checkPermission('getUsers') && checkFeatures('user', 'view'),
          activeMenu: false,
          key: 'users',
        },
      ],
    },
  ]);
  return (
    <div>
      <div className="layout">
        <div className="header-session">
          <Header
            setStepIndex={setStepIndex}
            setRunWalkthrough={setRunWalkthrough}
            setSideBarMenu={setSideBarMenu}
            setDrawerOpen={setDrawerOpen}
          />
        </div>
        <div className="main-session">
          <SideBar
            sidebarMenu={sidebarMenu}
            setSideBarMenu={setSideBarMenu}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />
          <div className="content-session">
            <div className="outlet-container">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
        {/* <Notification /> */}
      </div>
    </div>
  );
}

function WrapperRoutes2() {
  return (
    <div>
      <div className="layout">
        <Outlet />
      </div>
    </div>
  );
}

function Router() {
  const data = JSON.parse(localStorage.getItem('data'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locate = useLocation();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const currentPath = locate.pathname;
  const [stepIndex, setStepIndex] = useState(0);
  const [runWalkthrough, setRunWalkthrough] = useState(false);
  const src = `https://studio.d-id.com/agents/share?id=agt_OhnTrnWe&key=
  WjI5dloyeGxMVzloZFhSb01ud3hNRGswT0RNM09UYzFPVFEzT1RFNU5qYzROekU2UTJkVmJITjBXRkl3
  WVVwRk5VRk1hQzB0VUZrMQ==`;
  const handleWalkthroughEnd = () => {
    // change walkthrough status api
    userApi.changeWalkthroughUser(data?.user?._id).then(() => {
      setRunWalkthrough(false);
      setLocalStorageItem('data', {
        ...data,
        user: {
          ...data?.user,
          walkthrough: true,
        },
      });
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
  };
  const handleCallback = (props) => {
    const {
      index,
      action,
      lifecycle,
      step,
      status,
      type,
    } = props;
    if (action === 'skip') {
      handleWalkthroughEnd();
    }
    if (action === 'update' && lifecycle === 'tooltip' && step.target === '.my-content-link') {
      navigate('/admin/my-content/list');
    }
    if (action === 'next' && lifecycle === 'complete') {
      const nextPage = nextRoutes.find((val) => val.key === step.target);
      if (nextPage) {
        navigate(nextPage.value);
      }
      setStepIndex(index + 1);
    }
    if (action === 'next' && lifecycle === 'init' && type === 'error:target_not_found') {
      setStepIndex(index + 1);
    }
    if (action === 'prev' && lifecycle === 'complete') {
      const prevPage = backRoutes.find((val) => val.key === step.target);
      if (prevPage) {
        navigate(prevPage.value);
      }
      setStepIndex(index - 1);
    }
    if (action === 'prev' && lifecycle === 'init' && type === 'error:target_not_found') {
      setStepIndex(index - 1);
    }
    if (status === 'finished') {
      handleWalkthroughEnd();
    }
  };
  useEffect(() => {
    const style = document.createElement('style');
    style.id = 'recaptcha-style';
    document.head.appendChild(style);
    if (locate.pathname.startsWith('/admin')) {
      style.innerHTML = '.grecaptcha-badge { visibility: hidden; }';
    } else {
      style.innerHTML = '';
    }

    return () => {
      document.head.removeChild(style);
    };
  }, [locate.pathname]);

  useEffect(() => {
    if (data?.user) {
      setRunWalkthrough(!data?.user?.walkthrough);
    } else {
      setRunWalkthrough(false);
    }
  }, []);
  return (
    <div>
      <Joyride
        steps={WalkthroughSteps(currentLanguageCode, t)}
        continuous
        stepIndex={stepIndex}
        run={runWalkthrough
          && !currentPath?.includes('/admin/templates/')
          && !currentPath?.includes('/login')
          && !currentPath?.includes('/admin/apps/weather-image-page')
          && !currentPath?.includes('/admin/apps/RssFeed-image-page')
          && !currentPath?.includes('/admin/content-layout/add/')
          && !currentPath?.includes('/admin/apps/clock-image-page')
          && !currentPath?.includes('/admin/content-layout/edit')
          && !currentPath?.includes('/floor-map')}
        callback={handleCallback}
        showSkipButton
        showProgress
        disableOverlayClose
        locale={{
          back: t('back'),
          close: t('next'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        styles={styles}
      />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        <Routes>
          <Route path="/" exact element={<Navigate to="/login" />} />
          <Route exact path="/login" element={<Login setRunWalkthrough={setRunWalkthrough} />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/not-found" element={<NotFoundPage />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/chatbot-heygen"
            element={<Chatbot />}
          />
          <Route
            exact
            path="/chatbot-did"
            element={(
              <iframe
                title="chatbot"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  height: '100vh',
                  width: '700px',
                  maxWidth: '100%',
                  border: 'none',
                }}
                src={src}
              />
            )}
          />
          <Route exact path="/admin/location/add/floor-map-add/:id" element={<FloorMapDeviceAdd />} />
          <Route exact path="/admin/location/view/floor-map-view/:id" element={<FloorMapDeviceView />} />
          <Route exact path="/admin/location/edit/floor-map-edit/:id" element={<FloorMapDeviceEdit />} />
          <Route exact path="/admin/templates/edit-content/:id" element={<TableRenderPage />} />
          <Route exact path="/admin/templates/view-html/:id" element={<HtmlRenderPage />} />
          <Route exact path="/admin/templates/view-image/:id" element={<ViewImage />} />
          <Route exact path="/admin/apps/weather-image-page" element={<WidgetImage />} />
          {/* <Route exact path="/admin/apps/weather-image-page" element={<WeatherComponent />} /> */}
          <Route exact path="/admin/apps/clock-image-page" element={<PreviewClockWidget />} />
          <Route exact path="/admin/apps/RssFeed-image-page" element={<RSSFeedImagePage />} />
          <Route exact path="/admin/schedules/add/layout" element={<LayoutSchedule />} />
          <Route exact path="/admin/schedules/edit/:id/layout" element={<LayoutSchedule />} />
          <Route exact path="/admin/content-layout/add/:id" element={<ContentLayoutAdd />} />
          <Route exact path="/admin/content-layout/edit/:id" element={<ContentLayoutEdit />} />
          <Route
            path="/admin"
            element={(
              <WrapperRoutes
                setStepIndex={setStepIndex}
                setRunWalkthrough={setRunWalkthrough}
              />
            )}
          >
            {
              routsData.map((route) => (
                <Route path={route.path}>
                  {
                    route?.children?.map((child) => (
                      <Route
                        path={child.path}
                        element={(
                          <AuthGuard
                            permission={child.permission}
                            Component={child.component}
                          />
                        )}
                      />
                    ))
                  }
                </Route>
              ))
            }
          </Route>
          {/* widget */}
          <Route
            path="/admin/apps"
            element={(
              <WrapperRoutes
                setStepIndex={setStepIndex}
                setRunWalkthrough={setRunWalkthrough}
              />
            )}
          >
            {/* <Route exact path="/admin/apps" element={<Widget />} /> */}
            <Route exact path="/admin/apps/weather-template/add-weather/:id" element={<AddForm />} />
            <Route exact path="/admin/apps/weather-template/edit-weather/:id" element={<EditForm />} />
            <Route exact path="/admin/apps/clock-template/edit-clock/:id" element={<EditClock />} />
            <Route exact path="/admin/apps/rssfeed-template/edit-rssfeed/:id" element={<RssEditForm />} />
            <Route exact path="/admin/apps/weather-template" element={<WidgetTemplate />} />
            <Route exact path="/admin/apps/clock-template" element={<ClockTemplate />} />
            <Route exact path="/admin/apps/clock-template/add-clock/:id" element={<ClockAddForm />} />
            <Route exact path="/admin/apps/rssfeed-template/add-RssFeed/:id" element={<RSSFeedAddForm />} />
            {
              routsData.map((route) => (
                <Route path={route.path}>
                  {
                    route?.children?.map((child) => (
                      <Route
                        path={child.path}
                        element={(
                          <AuthGuard
                            permission={child.permission}
                            Component={child.component}
                          />
                        )}
                      />
                    ))
                  }
                </Route>
              ))
            }
          </Route>
          <Route path="/admin" element={<WrapperRoutes2 />}>
            {/* <Route path="my-content/view/:id" element={<ContentView />} /> */}
            <Route path="libraries/view/:id" element={<ContentView />} />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </GoogleReCaptchaProvider>
      <Notification />
    </div>
  );
}
Router.propTypes = {
  index: PropTypes.number,
  action: PropTypes.string,
  lifecycle: PropTypes.string,
  step: PropTypes.shape({
    target: PropTypes.string,
  }),
  status: PropTypes.string,
  type: PropTypes.string,
};
WrapperRoutes.propTypes = {
  setStepIndex: PropTypes.node.isRequired,
  setRunWalkthrough: PropTypes.node.isRequired,
};
Router.defaultProps = {
  index: undefined,
  action: undefined,
  lifecycle: undefined,
  step: undefined,
  status: undefined,
  type: undefined,
};

export default Router;
