import React from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../shared/component/Button';
import { BackButton } from '../../../../shared/component/svgIcon';
import { addNewSchedule } from '../../../../redux/slices/ScheduleSlice';

function LayoutHeader(props) {
  const { type } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newSchedule } = useSelector((state) => state.schedules);
  const previousClick = () => {
    if (props?.type === 'scheduleAdd') {
      navigate('/admin/schedules/add');
      // Destructure and remove the `entireLayout` key from `schedule`
      const { entireLayout, ...restOfSchedule } = newSchedule.schedule;
      dispatch(addNewSchedule({
        ...newSchedule,
        type,
        schedule: {
          ...restOfSchedule,
        },
      }));
    } else if (props?.type === 'scheduleEdit') {
      navigate(`/admin/schedules/edit/${props?.newSchedule?.id}?type=edit`);
    } else if (props?.type === 'scheduleClone') {
      navigate(`/admin/schedules/edit/${props?.newSchedule?.id}?type=clone`);
    }
  };

  return (
    <div className="layout-header-wrap">
      <div className="layout-header-left">
        <span
          role="presentation"
          onClick={previousClick}
        >
          <BackButton />
        </span>
        <h1>{t('scheduleLayout')}</h1>
      </div>
      <Button
        label={t('continue')}
        click={() => props?.handleSubmitContentLayout()}
        classes="success-button"
        disabled={false}
      // loading={uploading}
      />
    </div>
  );
}

LayoutHeader.propTypes = {
  type: PropTypes.string.isRequired,
  newSchedule: PropTypes.objectOf().isRequired,
  handleSubmitContentLayout: PropTypes.func.isRequired,
};

export default LayoutHeader;
