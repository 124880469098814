/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import myContent from '../../utils/api/my-content';
import { convertBytes, changeDateTimeFormat } from '../../utils/helpers';

export const fetchScheduleContentList = createAsyncThunk(
  'contents/fetchScheduleContents',
  async (data) => {
    const response = await myContent.getContents(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        author: val.createdBy?.name,
        file_size: convertBytes(val.file_size) || '0 KB',
        createDate: changeDateTimeFormat(val.createdAt),
        updateDate: changeDateTimeFormat(val.updatedAt),
        file_type_to_show: val?.file_type ? val.file_type.charAt(0).toUpperCase() + val.file_type.slice(1) : '',
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues };
    }).catch((error) => error?.response?.data);
    return response;
  },
);

const initialState = {
  contents: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  muiTableData: {},
};

export const scheduleMyContentSlice = createSlice({
  name: 'scheduleContent',
  initialState,
  reducers: {
    setCount: (state) => {
      state.pageCount = 1;
      state.totalPageCount = 1;
    },
    setMuiTableData: (state, action) => {
      state.muiTableData = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetScheduleContentState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(fetchScheduleContentList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchScheduleContentList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code) {
          state.error = action?.payload;
        } else {
          const datas = action.payload.results;
          // if (datas.length > 0) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.contents = datas;
          } else {
            state.contents.push(...datas);
          }
          // }
        }
      })
      .addCase(fetchScheduleContentList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setCount,
  setFilter,
  setMuiTableData,
  resetScheduleContentState,
} = scheduleMyContentSlice.actions;

export default scheduleMyContentSlice.reducer;
