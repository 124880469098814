import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';

function PopOver(props) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiPaper: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px !important',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Popover
        id={props?.id}
        open={Boolean(props?.anchorEl)}
        anchorEl={props?.anchorEl}
        onClose={props?.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {props?.content}
      </Popover>
    </ThemeProvider>

  );
}

PopOver.propTypes = {
  anchorEl: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  id: PropTypes.string,
};
PopOver.defaultProps = {
  id: '',
  anchorEl: false,
};

export default PopOver;
