/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import schedules from '../../utils/api/schedule';

import {
  changeTimeFormat,
  getTimeDuration,
  getDatesInRange,
  changeDateTimeFormat,
  convertBytes,
} from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

function processDateRanges(dateRange) {
  return dateRange.map((dateVal) => ({
    start: new Date(dateVal?.start_date_utc),
    end: new Date(dateVal?.end_date_utc),
  }));
}

const contentFitOptions = [
  { value: 'auto', label: 'Auto' },
  { value: 'fill', label: 'Fill' },
  { value: 'stretch', label: 'Stretch' },
  { value: 'fit', label: 'Fit' },
];

const contentOrientationOptions = [
  { value: 'landscape', label: 'Landscape' },
  { value: 'portrait', label: 'Portrait' },
];

const contentVolumeOptions = [
  { value: 'always', label: 'Always' },
  { value: 'custom range', label: 'Custom Time Range' },
];

export const formatScheduleData = (schedule) => {
  const now = `${schedule?.start_only_date} ${schedule?.start_time}`;
  const then = `${schedule?.end_only_date} ${schedule?.end_time}`;
  let allDays = [];
  if (schedule.repeat) {
    allDays = getDatesInRange(
      new Date(schedule.recurrences.start_only_date),
      new Date(schedule.recurrences.end_only_date),
    );
  }
  return {
    ...schedule,
    advanceSettings: schedule?.advance_settings?.map((setting) => ({
      ...setting,
      content_action_label: contentFitOptions
        ?.find((fitOption) => fitOption?.value === setting?.content_action)?.label,
      content_orientation_label: contentOrientationOptions
        ?.find((fitOption) => fitOption?.value === setting?.content_orientation)?.label,
      volume_play_range_type_label: contentVolumeOptions
        ?.find((fitOption) => fitOption?.value === setting?.volume_play_range_type)?.label,
      volume_play_range: setting?.volume_play_range_type === 'custom range'
        ? setting?.volume_play_range?.map((playRange) => ({
          start_time: playRange?.start_time,
          end_time: playRange?.end_time,
        }))
        : undefined,
    })),
    scheduleDate: `
        ${schedule?.repeat ? schedule?.recurrences?.start_only_date : schedule?.start_only_date}
        to
        ${schedule?.repeat ? schedule?.recurrences?.end_only_date : schedule?.end_only_date}
      `,
    scheduledTime: `
        ${changeTimeFormat(now)}
        to
        ${changeTimeFormat(then)}
      `,
    status: schedule?.status,
    contents_list: schedule?.contents.map((content) => content.name),
    // devices_list: schedule?.devices.map((device) => device.name),
    video_count: schedule?.contents.filter((content) => content.file_type === 'video/mp4').length,
    image_count: schedule?.contents.filter((content) => content.file_type !== 'video/mp4'
      && content.file_type !== 'widget').length,
    contents: schedule?.layouts?._id
      ? []
      : schedule?.contents.map((content) => ({
        ...content,
        id: content._id,
        author: content.createdBy?.name,
        createDate: changeDateTimeFormat(content.createdAt),
        updateDate: changeDateTimeFormat(content.updatedAt),
        file_size: convertBytes(content.file_size),
      })),
    contents_to_view: schedule?.contents
      ?.filter((content) => content?.file_type !== 'widget')
      ?.map((content) => ({
        ...content,
        id: content._id,
        author: content.createdBy?.name,
        createDate: changeDateTimeFormat(content.createdAt),
        updateDate: changeDateTimeFormat(content.updatedAt),
        file_size: convertBytes(content.file_size),
      })),
    widgets_to_view: schedule?.contents
      ?.filter((content) => content?.file_type === 'widget'),
    devices: schedule?.devices?.map((device) => device?._id),
    devices_list: schedule?.devices?.map((device) => ({ ...device, id: device?._id })),
    device_groups: schedule?.device_groups?.map((deviceGroup) => deviceGroup?._id),
    device_groups_list: schedule?.device_groups?.map((deviceGroup) => ({ ...deviceGroup, id: deviceGroup?._id })),
    playlists: schedule?.layouts?._id
      ? []
      : schedule?.playlists.map((playlist) => ({ ...playlist, id: playlist._id })),
    playlists_to_view: schedule?.playlists.map((playlist) => ({ ...playlist, id: playlist._id })),
    devices_count: schedule?.devices.length,
    hour: getTimeDuration(now, then),
    id: schedule._id,
    allDays,
    layouts: schedule?.layouts?._id
      ? { ...schedule?.layouts, oldLayout: true }
      : null,
  };
};

export const fetchCalendarScheduleList = createAsyncThunk(
  'calendarSchedules/fetchCalendarSchedules',
  async (data, { dispatch }) => {
    try {
      const response = await schedules.getCalendarSchedules({
        start_date: data.start_date,
        end_date: data.end_date,
      });

      const result = response?.data.flatMap((val) => {
        const processedDateRanges = processDateRanges(val?.dateRange);
        return processedDateRanges.map((dateRange) => ({
          ...val,
          title: val?.schedule_id.name,
          start: dateRange.start,
          end: dateRange.end,
          start_utc_time: dateRange.start,
          end_utc_time: dateRange.end,
          schedule_id: val?.schedule_id._id,
          repeat: val?.schedule_id,
          id: val?._id,
        }));
      });

      return { result, date: data, code: 200 };
    } catch (error) {
      dispatch(setErrorNotification(error?.response?.data));
      return error;
    }
  },
);

export const fetchScheduleList = createAsyncThunk(
  'schedules/fetchSchedules',
  async (data, { dispatch }) => {
    const response = await schedules.getSchedules(data)
      .then((response1) => {
        const tableData = response1?.data?.results.map((val) => formatScheduleData(val));
        return { ...response1?.data, results: tableData, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchSpecialScheduleList = createAsyncThunk(
  'schedules/fetchSpecialSchedules',
  async (data, { dispatch }) => {
    const response = await schedules.getSpecialSchedules(data)
      .then((response1) => {
        const tableData = response1?.data?.results.map((val) => formatScheduleData(val));
        return { ...response1?.data, results: tableData, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchSingleSchedule = createAsyncThunk(
  'schedules/fetchSingleSchedules',
  async (data, { dispatch }) => {
    const response = await schedules.getSingleSchedule(data)
      .then((response1) => ({ ...formatScheduleData(response1?.data), code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  calendarSchedules: [],
  week: [],
  schedules: [],
  newSchedule: {
    schedule: {
      playlists: [],
      playlistDetails: [],
      contents: [],
      contentsDetails: [],
      devices: [],
      device_groups: [],
      repeat_days: [],
      advanceSettings: [],
      repeat: false,
      special: false,
    },
    muiDateTime: {},
  },
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeed',
  filter: {},
  tableFilter: {},
  specialSchedules: [],
  specialPageCount: 0,
  specialTotalPageCount: 1,
  specialTotalDataCount: 0,
  specialFilter: {},
  specialScheduleTableFilter: {},
};

export const scheduleSlice = createSlice({
  name: 'mySchedule',
  initialState,
  reducers: {
    // Reducer to add new schedule to the schedule list
    createNewSchedule: (state, action) => {
      const scheduleList = [
        formatScheduleData(action.payload),
        ...state.schedules,
      ];

      // Update schedule list
      state.schedules = scheduleList;

      // If the added schedule is special schedule
      if (action.payload.special) {
        const specialScheduleList = [
          formatScheduleData(action.payload),
          ...state.specialSchedules,
        ];
        state.specialSchedules = specialScheduleList;
      }
    },
    // Reducer to update the schedule by ID.
    updateSchedule: (state, action) => {
      const updatedSchedule = action.payload; // schedule to be added at the top
      const scheduleList = state.schedules; // Assign the existing schedule to another variable
      const specialScheduleList = state.specialSchedules; // Assign the existing special schedule to another variable
      // Find the index of the updated schedule by ID
      const index = scheduleList.findIndex((schedule) => schedule._id === updatedSchedule._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        scheduleList[index] = formatScheduleData(updatedSchedule);
      }
      state.schedules = scheduleList; // Finally update the schedule list

      // If the added schedule is special schedule
      if (action.payload.special) {
        // Find the index of the updated special schedule by ID
        const indexToUpdate = specialScheduleList.findIndex((schedule) => schedule._id === updatedSchedule._id);
        // If the index found, replace the entire data
        if (indexToUpdate !== -1) {
          specialScheduleList[index] = formatScheduleData(updatedSchedule);
        }
        state.specialSchedules = specialScheduleList; // Finally update the special schedule list
      }
    },
    // Reducer to delete the schedule by ID
    deleteSchedule: (state, action) => {
      const scheduleList = state.schedules;
      const specialScheduleList = state.specialSchedules;

      // Find the index to delete by ID
      const index = scheduleList.findIndex((schedule) => schedule.id === action.payload.id);
      // If the index found, remove that index from list of schedules
      if (index !== -1) {
        scheduleList.splice(index, 1);
      }
      state.schedules = scheduleList; // Update the schedules state after deleted

      // If the added schedule is special schedule
      if (action.payload.special) {
        // Find the index to delete by ID
        const indexToDelete = specialScheduleList.findIndex((schedule) => schedule.id === action.payload.id);
        // If the index found, remove that index from list of special schedules
        if (indexToDelete !== -1) {
          specialScheduleList.splice(index, 1);
        }
        state.specialSchedules = specialScheduleList; // Finally update the special schedule list
      }
    },
    fetchSingleSchedules: (state, action) => {
      state.newSchedule = state.schedules.find((schedule) => schedule.id === action?.payload);
    },
    addNewSchedule: (state, action) => {
      state.newSchedule = action.payload;
    },
    clearErrorSchedules: (state, action) => {
      state.error = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setSpecialFilter: (state, action) => {
      state.specialFilter = action.payload;
    },
    setSpecialScheduleTableFilter: (state, action) => {
      state.specialScheduleTableFilter = action.payload;
    },
    resetScheduleState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchScheduleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchScheduleList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const fetchedData = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.schedules = fetchedData;
          } else {
            const finalData = state.schedules; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.schedules = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchScheduleList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchCalendarScheduleList.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(fetchCalendarScheduleList.fulfilled, (state, action) => {
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const fetchedData = action?.payload?.result;
          const existingData = state.calendarSchedules;
          const mergedData = existingData?.concat(fetchedData);
          state.calendarSchedules = mergedData;
          if (!action?.payload?.date?.status) {
            if (action?.payload?.date.start_date !== action?.payload?.date.end_date) {
              state.week.push(action?.payload?.date);
            }
          } else {
            state.calendarSchedules = fetchedData;
          }
        }
      })
      .addCase(fetchCalendarScheduleList.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchSpecialScheduleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSpecialScheduleList.fulfilled, (state, action) => {
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.specialPageCount = action.payload.page;
          state.specialTotalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.specialTotalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.specialSchedules = fetchedData;
          } else {
            const finalData = state.specialSchedules; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.specialSchedules = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchSpecialScheduleList.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchSingleSchedule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleSchedule.fulfilled, (state, action) => {
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          state.newSchedule = action?.payload;
        }
      })
      .addCase(fetchSingleSchedule.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  createNewSchedule,
  updateSchedule,
  deleteSchedule,
  clearErrorSchedules,
  setFilter,
  fetchSingleSchedules,
  addNewSchedule,
  // addAdvanceSettings,
  setTableFilter,
  setSpecialFilter,
  setSpecialScheduleTableFilter,
  resetScheduleState,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
