import React from 'react';
import PropTypes from 'prop-types';
import { SlickTemplateNext } from '../../../shared/component/svgIcon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function RightArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        position: 'absolute',
        right: '-15px',
        width: '60px',
        height: '50px',
        top: '50px',
      }}
      onClick={onClick}
      role="none"
    >
      <span style={{ width: '100%', height: '100%', margin: '5px' }}>
        <SlickTemplateNext />
      </span>
    </div>
  );
}

RightArrow.propTypes = {
  style: PropTypes.objectOf().isRequired,
  onClick: PropTypes.objectOf().isRequired,
};

export default RightArrow;
