import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { changeDateFormat, currencySymbol, useRecaptcha } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import { ViewIcon } from '../../../shared/component/svgIcon';
import Button from '../../../shared/component/Button';
import { RadioButton } from '../../../shared/component/form/Input';

function PlanSwitchPaymentMode(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { VerifyRecaptcha } = useRecaptcha();
  const {
    newPlanId,
    planSwitchTenure,
    backModal,
    handleChangePlanSummary,
    planSwitchSummary,
    subscriptionStatus,
  } = props;
  const localData = JSON.parse(localStorage.getItem('data'));
  // const [planSwitchSummary, setPlanSwitchSummary] = useState({});
  // const [loadingSummaryData, setLoadingSummaryData] = useState(true);
  const [paymentMode, setPaymentMode] = useState('');
  const [disable, setDisable] = useState(false);
  const [buttonStatus, setButtonStatus] = useState('');
  const currency = planSwitchSummary?.active_subscription?.currency || 'USD';
  const activeSubscriptionDetails = [
    {
      field: t('displays'),
      value: planSwitchSummary?.active_subscription?.display || 0,
    },
    {
      field: t('tenure'),
      value: `${planSwitchSummary?.active_subscription?.tenure || 0} months`,
      startDate: changeDateFormat(planSwitchSummary?.active_subscription?.start_date),
      endDate: changeDateFormat(planSwitchSummary?.active_subscription?.end_date),
    },
    {
      field: t('remainingDays'),
      value: planSwitchSummary?.active_subscription?.remaining_days || 0,
    },
  ];

  const planSwitchDetails = [
    {
      title: subscriptionStatus === 'suspended'
        ? `${t('currentPlan')} ( ${planSwitchSummary?.new_plan?.display} ${t('activeDevices')})`
        : t('currentPlan'),
      planDetails: [
        {
          field: t('planName'),
          value: planSwitchSummary?.current_plan?.plan_name || '',
        },
        {
          field: t('unitPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.current_plan?.unit_price?.toFixed(2) || 0}`,
        },
        {
          field: t('totalPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.current_plan?.plan_amount?.toFixed(2) || 0}`,
        },

      ],
    },
    {
      title: t('newPlan'),
      planDetails: [
        {
          field: t('planName'),
          value: planSwitchSummary?.new_plan?.plan_name || '',
        },
        {
          field: t('unitPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.new_plan?.unit_price?.toFixed(2) || 0}`,
        },
        {
          field: t('totalPrice'),
          value: `${currencySymbol(currency)} ${planSwitchSummary?.new_plan?.plan_amount?.toFixed(2) || 0}`,
          tooltip: <>The calculated amount is based on the <br />Remaining days × Displays × Cost per device.</>,
        },
      ],
    },
  ];
  /* Online disable condition description */
  /* if exiting user preferred payment status offline means disable online */
  /* if user change trial to paid plan when choose offline mode initially not yet choose online */
  /* if existing user change trial to pain plan disabled the online mode */
  const paymentModeOptions = [
    // {
    //   label: t('online'),
    //   key: 'online',
    // },
    {
      label: t('offline'),
      key: 'offline',
    },
  ];

  const handleChangePayment = (value) => {
    if (value) {
      setPaymentMode(value);
    }
  };

  const back = () => backModal(false);

  const submit = async () => {
    const recaptchaToken = await VerifyRecaptcha('plan-change-summary');
    const deviceCount = localData?.user?.company?.previous_subscription_id?.device?.count;
    setDisable(true);
    setButtonStatus('loading');
    if (paymentMode === 'online' && planSwitchSummary?.payment_summary?.amount_to_paid > 0) {
      const request = (subscriptionStatus === 'active' || subscriptionStatus === 'suspended')
        ? {
          plan: newPlanId,
          tenure: planSwitchTenure,
          recaptchaToken,
          payment_mode: 'online',
        }
        : {
          plan: newPlanId,
          tenure: planSwitchTenure,
          payment_mode: 'online',
          recaptchaToken,
          device: deviceCount,
          type: 'renewal',
        };
      subscription.planChange(request, subscriptionStatus)
        .then((res) => {
          setDisable(false);
          setButtonStatus('');
          window.open(`${res?.data?.targetUrl}?paymentid=${res?.data?.payid}`, '_blank')?.focus();
          back();
        })
        .catch((error) => {
          setDisable(false);
          setButtonStatus('');
          dispatch(setErrorNotification(error?.response ? error?.response?.data : error));
        });
    } else {
      setDisable(false);
      handleChangePlanSummary(newPlanId, planSwitchTenure, planSwitchSummary);
    }
  };

  // useEffect(() => {
  //   setLoadingSummaryData(true);
  //   subscription.getPlanChangeSummary({
  //     new_plan_id: newPlanId,
  //     tenure: planSwitchTenure,
  //   })
  //     .then((res) => {
  //       setLoadingSummaryData(false);
  //       setPlanSwitchSummary(res?.data);
  //     })
  //     .catch((err) => {
  //       setLoadingSummaryData(false);
  //       dispatch(setErrorNotification(err?.response?.data));
  //     });
  // }, [newPlanId]);

  useEffect(() => {
    if (paymentMode) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [paymentMode]);
  return (
    <div className="plan-switch-summary">
      {/* {loadingSummaryData
        && (
          <div className="loader-wrap">
            <CircularProgress />
          </div>
        )} */}
      <div className="active-subscription-wrap">
        <h2 className="section-header">
          {subscriptionStatus === 'suspended' ? t('suspendSubscription') : t('activeSubscription')}
        </h2>
        <div className="active-subscription-details">
          {activeSubscriptionDetails.map((detail) => (
            <div className="subscription-detail">
              <h4>{detail.field}:</h4>
              <p>{detail?.value}</p>
              {(detail?.startDate && detail?.endDate)
                && <span>({detail?.startDate} - {detail?.endDate})</span>}
            </div>
          ))}
        </div>
      </div>
      <div className="plan-switch-details">
        {planSwitchDetails.map((plan) => (
          <div className="plan-details-wrap">
            <h2 className="section-header">{plan.title}</h2>
            <div className="plan-details">
              {plan.planDetails.map((detail) => (
                <div className={detail?.tooltip ? 'plan-detail' : 'plan-detail pr-20'}>
                  <h4>{detail.field}:</h4>
                  <p>{detail?.value}</p>
                  {detail?.tooltip
                    && (
                      <Tooltip
                        title={detail?.tooltip}
                        placement="top"
                      >
                        <span className="tooltip-container">
                          <ViewIcon />
                        </span>
                      </Tooltip>
                    )}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="plan-details-wrap">
          <h2 className="section-header">{t('paymentSummary')}</h2>
          <div className="payment-summary">
            <h4>{t('amountRemainingFromExistingPlan')} :</h4>
            <div className="price pr-20">
              <p>
                {currencySymbol(currency)} {planSwitchSummary?.current_plan?.plan_remaining_amount || 0}
              </p>
            </div>
          </div>
          <div className="payment-summary">
            <h4>{t('amountAdjustedFromExistingPlan')} :</h4>
            <div className="price">
              <p>
                - {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.adjust_from_existing_plan || 0}
              </p>
              <Tooltip
                title={t('adjustedToolTipMessage')}
                placement="top"
              >
                <span className="tooltip-container">
                  <ViewIcon />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="payment-summary">
            {(() => {
              let addonHeader;
              if (Number(planSwitchSummary?.addon_summary?.addOnPrice) > 0) {
                addonHeader = (
                  <h4>{t('addonAmountToBePaid')} :</h4>
                );
              } else if (Number(planSwitchSummary?.addon_summary?.addOnPrice) < 0) {
                addonHeader = (
                  <h4>{t('addonAmountToBeAddedInWallet')} :</h4>
                );
              } else {
                addonHeader = (
                  <h4>{t('addonAmount')} :</h4>
                );
              }
              return addonHeader;
            })()}
            <div className="price pr-20">
              {(() => {
                let addOnnPrice;
                if (Number(planSwitchSummary?.addon_summary?.addOnPrice) > 0) {
                  addOnnPrice = (
                    <p>
                      {currencySymbol(currency)} {Math.abs(planSwitchSummary?.addon_summary?.addOnPrice) || 0}
                    </p>
                  );
                } else if (Number(planSwitchSummary?.addon_summary?.addOnPrice) < 0) {
                  addOnnPrice = (
                    <p>
                      + {currencySymbol(currency)} {Math.abs(planSwitchSummary?.addon_summary?.addOnPrice) || 0}
                    </p>
                  );
                } else {
                  addOnnPrice = (
                    <p>
                      {currencySymbol(currency)} 0.00
                    </p>
                  );
                }
                return addOnnPrice;
              })()}
            </div>
          </div>
          <div className="payment-summary">
            <h4>{t('wallet')} :</h4>
            <div className="price pr-20">
              {(() => {
                let walletPrice;
                if (Number(planSwitchSummary?.payment_summary?.credit_to_wallet) > 0) {
                  walletPrice = (
                    <p className="amount-credit">
                      + {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.credit_to_wallet || 0}
                    </p>
                  );
                } else if (Number(planSwitchSummary?.payment_summary?.debit_from_wallet) > 0) {
                  walletPrice = (
                    <p className="amount-debit">
                      - {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.debit_from_wallet || 0}
                    </p>
                  );
                } else {
                  walletPrice = (
                    <p>
                      {currencySymbol(currency)} 0.00
                    </p>
                  );
                }
                return walletPrice;
              })()}
            </div>
          </div>
        </div>
        <div className="final-price">
          <h4>{t('amountToBePaid')} :</h4>
          <p className="pr-20">
            {currencySymbol(currency)} {planSwitchSummary?.payment_summary?.amount_to_paid?.toFixed(2) || 0}
          </p>
        </div>
      </div>
      <div className="payment-mode">
        <h2 className="payment-title">{t('paymentMode')}</h2>
        <div className="payment-options-wrap">
          {paymentModeOptions?.map((item) => (
            <div className="each-content-option">
              <RadioButton
                checked={paymentMode === item.key}
                label={item?.label}
                value={item?.key}
                onChange={handleChangePayment}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button" style={{ width: '5vw' }}>
          <Button
            label={t('back')}
            click={back}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('continue')}
            click={submit}
            classes={buttonStatus === 'loading'
              ? 'success-button loading-btn'
              : 'success-button'}
            disabled={disable}
            loading={buttonStatus === 'loading'}
          />
        </div>
      </div>
    </div>
  );
}

PlanSwitchPaymentMode.propTypes = {
  newPlanId: PropTypes.string.isRequired,
  planSwitchTenure: PropTypes.number.isRequired,
  handleChangePlanSummary: PropTypes.func.isRequired,
  backModal: PropTypes.func.isRequired,
  planSwitchSummary: PropTypes.objectOf().isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
};

export default PlanSwitchPaymentMode;
