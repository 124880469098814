const DownloadContent = async (content) => {
  let response;
  await fetch(content.signedUrl)
    .then(async (res) => {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', content.name);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      response = error;
    });
  return response;
};

export default DownloadContent;
