import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../../../shared/component/CircularProgress';
import { convertBytes } from '../../../utils/helpers';
import Toggle from '../../Schedule/components/ToggleButton';

function ContentStatus(props) {
  const { t } = useTranslation();
  const {
    totalSize,
    totalGB,
    tabPages,
    pageViewChange,
    page,
  } = props;
  const [toShow, setToShow] = useState(0);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const temp = totalSize / 1024 / 1024 / 1024;
    setPercentage(Math.round((temp / totalGB) * 100) || 0);
    setToShow(convertBytes(totalSize));
  }, [totalSize]);

  return (
    <div className="content-status">
      <Toggle button1={pageViewChange} active={page} tabPages={tabPages} />
      <div className="progress storage-bar">
        <CircularProgressBar percentage={percentage || 0} />
        <div className="progress-text">
          <h2>{t('storage')}</h2>
          <h3>{toShow || '0 GB'} / {props?.totalGB || 1} GB
          </h3>
        </div>
      </div>
    </div>
  );
}
ContentStatus.propTypes = {
  totalSize: PropTypes.number.isRequired,
  totalGB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pageViewChange: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  tabPages: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
    activeIcon: PropTypes.string,
  })),
};
ContentStatus.defaultProps = {
  tabPages: [],
};

export default ContentStatus;
