import React from 'react';
import PropTypes from 'prop-types';
import cancel from '../../assets/images/cancel.png';

function ErrorAlert(props) {
  return (
    <div className="success-wrap">
      <img src={cancel} alt="tick" className="success-img" />
      <h2 className="text-danger">{props?.message}</h2>
    </div>
  );
}

ErrorAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorAlert;
