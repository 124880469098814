import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import { fetchContentSchedules } from '../../../redux/slices/DashBoardSlice';
import DataTable from '../../Table';
import ModalBox from '../../../shared/component/ModalBox';

function Schedules() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contentData } = useSelector((state) => state.dashboardPieCharts);
  const [createContentModal, setCreateContentModal] = useState({
    title: '',
    content: 0,
  });
  const closeModal = () => {
    setCreateContentModal({
      title: '',
      content: 0,
    });
  };

  const columns = [
    {
      header: t('contentName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original?.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type?.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original?.contents?.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
      enableFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
    },
    {
      header: t('mediaType'),
      accessorKey: 'media_type',
      enableFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
    },
    {
      header: t('createdAt'),
      accessorKey: 'createdAt',
      enableFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
    },
    {
      header: t('playCount'),
      accessorKey: 'play_count',
      enableFilters: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
    },
  ];
  const getData = () => {
    dispatch(fetchContentSchedules());
  };
  useEffect(() => {
    if (contentData?.length === 0) {
      getData();
    }
  }, []);

  return (
    <div className="dashboard-schedule-wrap">
      <ModalBox
        status={createContentModal.content !== 0}
        closeModal={closeModal}
        modalView={createContentModal}
        notification={false}
      />
      <div className="schedules-header">
        <div className="header-left">
          <h2>{t('mostScheduledContent(s)')}</h2>
        </div>
      </div>
      {contentData?.length > 0
        ? (
          <div className="schedule-table">
            <DataTable
              height="70vh"
              value={contentData}
              header={columns}
              onFilterOrSortingChange={() => { }}
              className="my-data-table"
              rowSelection={{}}
              sorting={[]}
              disableTopToolbar={false}
            />
          </div>
        ) : (
          <div> no data </div>
        )}
    </div>
  );
}
Schedules.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
      file_type: PropTypes.string,
      contents: PropTypes.shape({
        thumbnailSignedUrls: PropTypes.objectOf(),
      }),
    }),
  }),
};
Schedules.defaultProps = {
  row: {},
};

export default Schedules;
