import React from 'react';
import PropTypes from 'prop-types';

import calender from '../../assets/icons/calendar.png';
import time from '../../assets/icons/timer.png';

function HeatMapView(props) {
  const { data } = props;
  return (
    <div className="table-device-view">
      <div className="contents-body">
        <div className="header">
          <div className="schedule-details-list">
            <div className="image"><img alt="image_icon" src={calender} /></div>
            {data?.start_only_date} to {data?.end_only_date}
          </div>
          <div className="schedule-details-list">
            <div className="image"><img alt="image_icon" src={time} /></div>
            {data?.start_time} to {data?.end_time}
          </div>
        </div>
        <div className="header">
          <div className="schedule-details-list">
            <div className="color available-color"> </div> Available Days
          </div>
          <div className="schedule-details-list">
            <div className="color conflict-color"> </div> Conflict Days
          </div>
        </div>
        <div className="table-view">
          <table>
            <tr>
              <td>Devices</td>
              {
                data?.allDays.map((val) => <td>{val}</td>)
              }
            </tr>
            {
              data?.devices.map((val) => (
                <tr>
                  <td>{val.name}</td>
                  {
                    data?.allDays.map((val1, index) => {
                      let values = '';
                      if (val1) {
                        values = <td className={index % 2 === 0 ? 'available-color' : 'conflict-color'}>1</td>;
                      }
                      return values;
                    })
                  }
                </tr>
              ))
            }
          </table>
        </div>
        <div className="message-view"> </div>
      </div>
    </div>
  );
}

HeatMapView.propTypes = {
  data: PropTypes.shape({
    start_only_date: PropTypes.string,
    end_only_date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    allDays: PropTypes.arrayOf(),
    devices: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
};
HeatMapView.defaultProps = {
  data: {},
};

export default HeatMapView;
