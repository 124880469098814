import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';

import { Input, InputDate } from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import Button from '../../../shared/component/Button';

function RepeatForm(props) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    ...props?.startDate,
    started_date: props?.startDate?.start_date,
  });
  const [date, setDate] = useState({
    started_date: dayjs(values?.start_date),
    complete_date: values?.complete_date ? dayjs(values?.complete_date) : undefined,
  });
  const [message, setMessage] = useState('');
  const [disable, setDisable] = useState(true);
  const handleChange1 = (event) => {
    let select = [];
    if (event.target.name === 'repeat_days') {
      if (values.repeat_days.includes(event.target.value)) {
        select = values.repeat_days;
        const index = select.indexOf(event.target.value);
        if (index > -1) {
          select.splice(index, 1);
        }
      } else {
        select = [...values.repeat_days, event.target.value];
      }
    }
    setValues({ ...values, [event.target.name]: select });
  };
  const handleChange = (name, value) => {
    if (name === 'repeat_type') {
      if (value) {
        setValues({ ...values, [name]: value.id });
      }
    } else if (name === 'started_date' || name === 'complete_date') {
      setDate((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setValues((prevState) => ({
        ...prevState,
        [name]: dayjs(value).format('YYYY-MM-DD'),
      }));
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const addSchedule = () => {
    props?.closeModal(false);
  };

  useEffect(() => {
    // Split the date strings and create Date objects
    const startDate = new Date(values.started_date);
    const endDate = new Date(values.complete_date);

    if (values.started_date
      && values.repeat_count
      && values.repeat_type
      && values.complete_date
      && startDate < endDate
    ) {
      setDisable(false);
      setMessage(
        values.repeat_type === 'DAILY'
          ? `Occurs every ${values.repeat_count > 1 ? values.repeat_count : ''} 
        day starting ${values.started_date} until ${values.complete_date}`
          : `Occurs every ${values.repeat_count > 1 ? values.repeat_count : ''} 
          week ${values?.repeat_days} starting ${values.started_date} until ${values.complete_date}`,
      );
    } else {
      setDisable(true);
      setMessage('');
    }
  }, [values]);
  const typesList = [
    {
      id: 'DAILY',
      label: t('daily'),
    },
    {
      id: 'WEEKLY',
      label: t('weekly'),
    },
  ];

  return (
    <div className="repeat-form">
      <div className="repeat-form-body">
        <div className="form-field">
          <InputDate
            label={t('startDate')}
            type="date"
            name="started_date"
            value={date?.started_date}
            required={false}
            change={handleChange}
          />
        </div>
        <div className="form-field-1">
          <Input
            icon={' '}
            label={t('repeatEvery')}
            type="text"
            name="repeat_count"
            value={values?.repeat_count}
            required={false}
            disablePast
            change={handleChange}
          />
          <SelectBox
            icon={' '}
            label={t('repeatType')}
            name="repeat_type"
            required={false}
            value={values?.repeat_type}
            lists={typesList}
            onchange={handleChange}
            id="controllable-repeat-type-demo"
          />
        </div>
        {values?.repeat_type === 'WEEKLY' && (
          <div className="form-field">
            <FormControl component="fieldset">
              <FormGroup
                aria-label="position"
                onChange={handleChange1}
                row
              // defaultChecked={values?.repeat_days}
              >
                <FormControlLabel
                  value="MO"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('MO')} />}
                  label={t('mon')}
                />
                <FormControlLabel
                  value="TU"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('TU')} />}
                  label={t('tue')}
                />
                <FormControlLabel
                  value="WE"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('WE')} />}
                  label={t('wed')}
                />
                <FormControlLabel
                  value="TH"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('TH')} />}
                  label={t('thu')}
                />
                <FormControlLabel
                  value="FR"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('FR')} />}
                  label={t('fri')}
                />
                <FormControlLabel
                  name="repeat_days"
                  value="SA"
                  control={<Checkbox checked={values?.repeat_days.includes('SA')} />}
                  label={t('sat')}
                />
                <FormControlLabel
                  value="SU"
                  name="repeat_days"
                  control={<Checkbox checked={values?.repeat_days.includes('SU')} />}
                  label={t('sun')}
                />
              </FormGroup>
            </FormControl>
          </div>
        )}
        <div className="form-field">
          <InputDate
            label={t('endDate')}
            type="date"
            name="complete_date"
            value={date?.complete_date}
            required={false}
            change={handleChange}
            minDate={date?.started_date}
            disablePast
          />
        </div>
        <div className="form-field info-text">{message}</div>
      </div>
      <div className="repeat-form-buttons">
        <div className="form-button">
          <Button label={t('back')} click={addSchedule} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('done')}
            click={() => props?.setRepeated(values)}
            classes="success-button"
            disabled={disable}
          />
        </div>
      </div>
    </div>
  );
}
RepeatForm.propTypes = {
  setRepeated: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  startDate: PropTypes.shape({
    start_date: PropTypes.string.isRequired,
  }).isRequired,
};
export default RepeatForm;
