import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import { DotIcon, WarningIcon } from '../../../shared/component/svgIcon';

function DeviceContentLimitAlert(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    backModal,
  } = props;
  const back = () => backModal(false);

  return (
    <div className="plan-switch-summary width100">
      <div className="active-subscription-wrap">
        <p className="section-body">
          <WarningIcon /> {`You're currently connected to ${props?.currentDeviceCount} devices,
          but your plan allows only ${props?.allowDeviceCount}. 
          Please disconnect ${props?.balanceDeviceCount} devices to continue`}
        </p>
        <ul className="section-body-list">
          <li className="section-body-item">
            <div className="item">{t('currentDevices')}</div>
            <div>:</div>
            <div className="item2">{props?.currentDeviceCount}</div>
          </li>
          <li className="section-body-item">
            <div className="item">{t('downgradeLimit')}</div>
            <div>:</div>
            <div className="item2">{props?.allowDeviceCount}</div>
          </li>
          <li className="section-body-item">
            <div className="item">{t('toBeDeleted')}</div>
            <div>:</div>
            <div className="item2">{props?.balanceDeviceCount}</div>
          </li>
        </ul>
      </div>
      <div className="active-subscription-wrap">
        <h2 className="section-header">{t('toRemoveDevices')}</h2>
        <ul className="rules-list">
          <li className="rule"><span><DotIcon /></span>{t('goToDeviceManagementSignageDeviceSelectDeviceDelete')}</li>
          <li className="rule"><span><DotIcon /></span>{t('thisIsAPermanentActionAndCannotBeUndone')}</li>
        </ul>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button" style={{ width: '5vw' }}>
          <Button
            label={t('back')}
            click={back}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('continue')}
            click={() => navigate('/admin/display-signage/list')}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}

DeviceContentLimitAlert.propTypes = {
  currentDeviceCount: PropTypes.number.isRequired,
  allowDeviceCount: PropTypes.number.isRequired,
  balanceDeviceCount: PropTypes.number.isRequired,
  backModal: PropTypes.func.isRequired,
//   planSwitchSummary: PropTypes.objectOf().isRequired,
//   subscriptionStatus: PropTypes.string.isRequired,
};

export default DeviceContentLimitAlert;
