import {
  get,
} from './base/index';

export default {
  getCount: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard`, { params });
  },
  getDeviceCountByStore: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/getDeviceCountByStore`),

  scheduleBarChart: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/scheduleBarChart`),

  getDisplayStatus: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/display-status`),

  getDisplayOs: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/display-os`, { params });
  },

  getContentSchedules: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/most-schedule-contents`),

  getStorageStats: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/storage-stats`),

  getScheduleSyncStatus: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/schedule-sync-status`),
  getRecentActivities: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/recent-activity`),
  getStoreData: () => get(`${process.env.REACT_APP_API_URL}cms/v1/dashboard/store-list`),
};
