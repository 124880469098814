import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { InfoIcon, RightArrow } from '../../../../shared/component/svgIcon';
import Button from '../../../../shared/component/Button';
import ModalBox from '../../../../shared/component/ModalBox';
// import ResumeSubscription from './ResumeSubscription';
import CancelSubscription from './CancelSubscription';
import {
  capitalize,
  changeDateFormat,
  changeDateTimeFormat,
  changeSubscriptionTenure,
  currencySymbol,
  intlDateTimeFormat,
} from '../../../../utils/helpers';
import subscriptions from '../../../../utils/api/subscription';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { getSubscriptionBillingDetails } from '../../../../redux/slices/SubscriptionSlice';

function Billing() {
  const [billingDetails, setBillingDetails] = useState();
  const { t } = useTranslation();
  const subscriptionInformation = [
    {
      label: t('plan'),
      value: billingDetails?.subscription_info?.plan_name,
    },
    {
      label: t('subscriptionTenure'),
      value: `${changeDateFormat(billingDetails?.subscription_info?.start_date)}
      - ${changeDateFormat(billingDetails?.subscription_info?.end_date)} 
      [${changeSubscriptionTenure(billingDetails?.subscription_info?.tenure)}]`,
    },
    {
      label: t('totalDevices'),
      value: billingDetails?.subscription_info?.device,
    },
    {
      label: t('totalStorage'),
      value: `${billingDetails?.subscription_info?.storage} GB`,
    },
    {
      label: t('nextBillingDate'),
      value: (billingDetails?.subscription_info?.next_billing_date
        && billingDetails?.subscription_info?.status === 'active')
        ? `${t('nextPaymentOn')} ${intlDateTimeFormat(billingDetails?.subscription_info?.next_billing_date)}`
        : '-', // Handle case when date is unavailable,
    },
    {
      label: t('nextBillingAmount'),
      value: billingDetails?.subscription_info?.status === 'active'
        ? `${currencySymbol('₹', billingDetails?.subscription_info?.total_price)}`
        : '-',
    },
  ];
  const billingInformation = [
    {
      label: t('billingName'),
      value: billingDetails?.billing_info?.name,
    },
    {
      label: t('billingAddress'),
      value: billingDetails?.billing_info?.address,
    },
    {
      label: t('contact'),
      value: billingDetails?.billing_info?.phone_number,
    },
  ];
  const additionalInformation = [
    {
      label: t('transactionID'),
      value: billingDetails?.additional_info?.transaction_id,
    },
    {
      label: t('subscription_Id'),
      value: billingDetails?.subscription_info?.subscription_id,
    },
    {
      label: t('transactionDate'),
      value:
        billingDetails?.additional_info?.transaction_date
          ? `${changeDateTimeFormat(billingDetails?.additional_info?.transaction_date)}` : '',
    },
    {
      label: t('transactionType'),
      value: billingDetails?.additional_info?.transaction_type,
    },
    {
      label: t('price'),
      value: `${currencySymbol('₹', billingDetails?.additional_info?.total_price)}`,
    },
  ];
  const [billingInfo, setBillingInfo] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contentView, setContentView] = useState({});
  const [loading, setLoading] = useState(false);
  const { subscriptionBillingDetails } = useSelector((state) => state?.subscription);
  const dispatch = useDispatch();
  // const handleOpenResume = () => {
  //   setContentView({
  //     title: t('resumeSubscription'),
  //     content: <ResumeSubscription
  //       setOpenModal={setOpenModal}
  //     />,
  //   });
  //   setOpenModal(true);
  // };
  const getBillingDetails = () => {
    setLoading(true);
    subscriptions.getBillingDetails()
      .then((res) => {
        setBillingDetails(res?.data);
        dispatch(getSubscriptionBillingDetails(res?.data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
        setLoading(false);
      });
  };
  const handleOpenSuspend = () => {
    setContentView({
      title: t('cancelSubscription'),
      content: <CancelSubscription
        setOpenModal={setOpenModal}
        getBillingDetails={getBillingDetails}
      />,
    });
    setOpenModal(true);
  };

  // const refundRequest = () => {
  //   setContentView({
  //     title: t('cancelSubscription'),
  //     content: <CancelSubscription
  //       setOpenModal={setOpenModal}
  //     />,
  //   });
  //   setOpenModal(true);
  // };

  useEffect(() => {
    if (subscriptionBillingDetails?.length === 0) {
      getBillingDetails();
    } else {
      setBillingDetails(subscriptionBillingDetails);
    }
  }, []);

  return (
    <div className="billing">
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={contentView}
      />
      {loading
        ? (
          <div className="loader-wrap">
            <CircularProgress />
          </div>
        )
        : (
          <>
            <div className="subscription-form">
              <div className="title-wrap">
                <div className="left">
                  <h1 className="title">{t('subscriptionInfo')}</h1>
                  <h2 className={`status ${billingDetails?.subscription_info?.status}`}>
                    {capitalize(billingDetails?.subscription_info?.status || '')}
                  </h2>
                  {billingDetails?.subscription_info?.status === 'suspended'
                    && (
                      <Tooltip title={t('subscriptionAccessUntilItsExpires')}>
                        <div>
                          <InfoIcon />
                        </div>
                      </Tooltip>
                    )}
                </div>
                {/* <div className="form-button">
            <Button
              label={t('requestForRefund')}
              click={refundRequest}
              classes="default-button"
              disabled={status === 'active'}
            />
          </div> */}
              </div>
              <div className="subscription-details">
                {subscriptionInformation?.map((subscription) => (
                  <div className="detail-row" key={subscription}>
                    <div className="detail-label">{subscription?.label}</div>
                    <span style={{ color: '#717880' }}>:</span>
                    <h2 className="detail-value">{subscription?.value}</h2>
                  </div>
                ))}
              </div>
            </div>
            <div className="billing-form">
              <div
                className="billing-header"
                role="presentation"
                onClick={() => setBillingInfo(!billingInfo)}
              >
                <h2>{t('billingInfo')}</h2>
                <span
                  style={{
                    transform: billingInfo ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                >
                  <RightArrow />
                </span>
              </div>
              <div
                className="billing-details"
                style={{
                  height: billingInfo ? '155px' : '0px',
                }}
              >
                {billingInformation?.map((billing) => (
                  <div className="detail-row" key={billing}>
                    <div className="detail-label">{billing?.label}</div>
                    <span style={{ color: '#717880' }}>:</span>
                    <h2 className="detail-value">{billing?.value}</h2>
                  </div>
                ))}
              </div>
            </div>
            <div className="billing-form" style={{ border: additionalInfo ? 'none' : '' }}>
              <div
                className="billing-header"
                role="presentation"
                onClick={() => setAdditionalInfo(!additionalInfo)}
              >
                <h2>{t('additionalInfo')}</h2>
                <span
                  style={{
                    transform: additionalInfo ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                >
                  <RightArrow />
                </span>
              </div>
              <div
                className="billing-details"
                style={{
                  height: additionalInfo ? '305px' : '0px',
                }}
              >
                {additionalInformation?.map((additional) => (
                  <div className="detail-row" key={additional}>
                    <div className="detail-label">{additional?.label}</div>
                    <span style={{ color: '#717880' }}>:</span>
                    <h2 className="detail-value">{additional?.value}</h2>
                  </div>
                ))}
                <div className="form-button-group">
                  {/* <div className="form-button">
              <Button
                label={t('resumeSubscription')}
                click={handleOpenResume}
                classes="default-button"
                disabled={status === 'active'}
              />
            </div> */}
                  <div className="form-button">
                    <Button
                      label={t('cancelSubscription')}
                      click={handleOpenSuspend}
                      classes="danger-button"
                      disabled={billingDetails?.subscription_info?.status !== 'active'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default Billing;
