/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import roles from '../../utils/api/roles';
import { setErrorNotification } from './NotificationSlice';

export const fetchRolesList = createAsyncThunk(
  'roles/fetchRoles',
  async (data, { dispatch }) => {
    const response = await roles.getRoles(data).then((response1) => ({
      ...response1?.data,
      results: response1?.data.results.map((val) => ({ ...val, id: val._id })),
      code: 200,
    }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchPermissionList = createAsyncThunk(
  'roles/fetchPermissions',
  async (data, { dispatch }) => {
    const response = await roles.getPermissions(data).then((response1) => (
      response1?.data?.map((role) => ({
        ...role,
        id: role?._id,
      }))
    )).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
      return [];
    });
    return response;
  },
);

export const fetchRolesListAll = createAsyncThunk(
  'roles/fetchRolesAll',
  async (data, { dispatch }) => {
    const response = await roles.getRolesAll()
      .then((response1) => response1?.data.map((val) => ({ ...val, id: val._id, label: val.name })))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  roles: [],
  permissions: [],
  pageCount: 0,
  totalPageCount: 1,
  rolesAllList: [],
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // Reducer to add the new role to top of the role list
    createNewRole: (state, action) => {
      const newRole = action.payload; // role to be added at the top
      const roleList = [
        {
          ...newRole,
          id: newRole._id,
        },
        ...state.roles, // Existing roles
      ];
      state.roles = roleList; // update the role list
    },
    // Reducer to update the role by ID.
    updateRole: (state, action) => {
      const updatedRole = action.payload; // role to be added at the top
      const roleList = state.roles; // Assign the existing role to another variable
      // Find the index of the updated role by ID
      const index = roleList.findIndex((role) => role._id === updatedRole._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        roleList[index] = {
          ...updatedRole,
          id: updatedRole._id,
        };
      }
      state.roles = roleList; // Finally update the role list
    },
    // Reducer to delete the role by ID
    deleteRole: (state, action) => {
      const roleList = state.roles;

      // Find the index to delete by ID
      const index = roleList.findIndex((role) => role.id === action.payload);
      // If the index found, remove that index from list of roles
      if (index !== -1) {
        roleList.splice(index, 1);
      }
      state.roles = roleList; // Update the roles state after deleted
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetRolesState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchRolesList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRolesList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.roles = fetchedData;
          } else {
            const finalData = state.roles; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.roles = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchRolesList.rejected, (state) => {
        state.status = 'failed';
      });
    builder.addCase(fetchRolesListAll.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = 'succeeded';
      state.rolesAllList = action.payload;
    });
    builder.addCase(fetchPermissionList.fulfilled, (state, action) => {
      // Add user to the state array
      state.permissions = action.payload;
    });
  },
});

export const {
  createNewRole,
  updateRole,
  deleteRole,
  setFilter,
  setTableFilter,
  resetRolesState,
} = rolesSlice.actions;

export default rolesSlice.reducer;
