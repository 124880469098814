import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';
import downloadIcon from '../../../assets/icons/download.png';

import {
  fetchSnapshotList,
  setFilter,
  setTableFilterData,
} from '../../../redux/slices/SnapshotSlice';
import { changeDateTimeFormat, mimString } from '../../../utils/helpers';
import exportCSV from '../../Global/exportCSV';

function HistoryList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    snapshot,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilterData,
    totalDataCount,
  } = useSelector((state) => state.snapshot);

  const [filterAllow, setFilterAllow] = useState(false);

  const [columnFilters, setColumnFilters] = useState(tableFilterData?.columnFilters || []);
  const [globalFilter, setGlobalFilter] = useState(tableFilterData?.globalFilter || '');
  const [sorting, setSorting] = useState(tableFilterData?.sorting || []);
  const [rowSelection, setRowSelection] = useState({});

  const getSnapshot = (counts, search) => {
    dispatch(fetchSnapshotList({ page: counts, limit: 20, ...search }));
  };

  const handleScroll = () => {
    getSnapshot(pageCount + 1, filter);
  };

  const downloadFile = (e) => {
    exportCSV(
      ['target', 'captured_on', 'total', 'received', 'error'],
      [
        {
          target: e.device.name,
          captured_on: changeDateTimeFormat(e.updatedAt),
          total: '1',
          received: e.snapshot_captured_status ? '1' : '0',
          error: e.snapshot_captured_status ? '0' : '1',
        },
      ],
    );
  };

  const columns = [
    {
      header: t('target'),
      accessorKey: 'name',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => mimString(row?.original?.target),
    },
    {
      header: t('targetName'),
      accessorKey: 'target_name',
    },
    {
      header: t('capturedOn'),
      accessorKey: 'captured_on',
      Cell: ({ row }) => changeDateTimeFormat(row?.original?.captured_on),
    },
    {
      header: t('total'),
      accessorKey: 'total',
      Cell: ({ row }) => row?.original?.requestSnapshot,
      // Cell: () => '1',
    },
    {
      header: t('received'),
      accessorKey: 'received',
      Cell: ({ row }) => row?.original?.receivedSnapshot,
    },
    {
      header: t('error'),
      accessorKey: 'error',
      Cell: ({ row }) => row?.original?.unReceivedSnapshot,
    },
    {
      header: t('action'),
      accessorKey: 'action',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <div onClick={() => downloadFile(row.original)} role="presentation" className="download-icon">
          <img src={downloadIcon} alt="download" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (snapshot.length === 0 && !filterAllow) {
      getSnapshot(1, filter);
      setFilterAllow(true);
    } else if (filterAllow) {
      try {
        dispatch(setTableFilterData({
          columnFilters,
          globalFilter,
          sorting,
        }));
        let filterDate = {};
        if (columnFilters.length > 0) {
          columnFilters.map((val) => {
            filterDate = { ...filterDate, [val.id]: val.value };
            return val;
          });
        }
        if (sorting.length > 0) {
          sorting.map((val) => {
            filterDate = {
              ...filterDate,
              sortField: val.id,
              sortBy: val.desc ? 'desc' : 'asc',
            };
            return val;
          });
        }
        dispatch(setFilter(filterDate));
        getSnapshot(1, filterDate);
      } catch (error) {
        console.error(error);
      }
    }
  }, [sorting, columnFilters, globalFilter]);

  return (
    <DataTable
      header={columns}
      value={snapshot}
      status={status}
      totalPageCount={totalPageCount}
      pageCount={pageCount}
      scrollData={handleScroll}
      totalDataCount={totalDataCount}
      setColumnFilters={setColumnFilters}
      setGlobalFilter={setGlobalFilter}
      setSorting={setSorting}
      columnFilters={columnFilters}
      globalFilter={globalFilter}
      sorting={sorting}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
      height="60vh"
    />
  );
}
HistoryList.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      target: PropTypes.string.isRequired,
      target_name: PropTypes.string.isRequired,
      captured_on: PropTypes.string.isRequired,
      requestSnapshot: PropTypes.string.isRequired,
      receivedSnapshot: PropTypes.string.isRequired,
      unReceivedSnapshot: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
HistoryList.defaultProps = {
  row: null,
};

export default HistoryList;
