/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setErrorNotification } from './NotificationSlice';
import storeLocation from '../../utils/api/store-location';
import { changeDateTimeFormat } from '../../utils/helpers';

export const getStoreLocationList = createAsyncThunk(
  'storeListLocation/fetchStoreLocation',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocations(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        area_city: `${val?.area}, ${val?.city}`,
        state_country: `${val?.state}, ${val?.country}`,
        createDate: changeDateTimeFormat(val.createdAt),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  storeLocationList: [],
  storePageCount: 0,
  storeTotalPageCount: 1,
  storeTotalDataCount: 0,
  storeStatus: 'succeeded',
  storeFilter: {},
  storeTableFilter: {},
};

export const storesListSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    // Reducer to add the new user to top of the user list
    setStoreFilter: (state, action) => {
      state.storeFilter = action.payload;
    },
    setStoreTableFilter: (state, action) => {
      state.storeTableFilter = action.payload;
    },
    resetStoreState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getStoreLocationList.pending, (state) => {
        state.storeStatus = 'loading';
      })
      .addCase(getStoreLocationList.fulfilled, (state, action) => {
        // Add user to the state array
        state.storeStatus = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.storePageCount = action.payload.page;
          state.storeTotalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.storeTotalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.storeLocationList = fetchedData;
          } else {
            const finalData = state.storeLocationList; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.storeLocationList = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(getStoreLocationList.rejected, (state) => {
        state.storeStatus = 'failed';
      });
  },
});

export const {
  setStoreFilter,
  setStoreTableFilter,
  resetStoreState,
} = storesListSlice.actions;

export default storesListSlice.reducer;
