/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import snapshotApi from '../../utils/api/snapshot';

export const fetchSnapshotList = createAsyncThunk(
  'storeLocation/fetchSnapshotList',
  async (data) => {
    const response = await snapshotApi.historySnapshot(data)
      .then((response1) => {
        const tableData = response1?.data?.results.map((val) => ({
          ...val,
          target_name: val?.snapshots.map((val1) => val1.device.name).join(', '),
          captured_on: val?.snapshots[0].updatedAt,
          target: val?.snapshots[0].target,
          request_id: val?.snapshots[0].request_id,
        }));
        return {
          ...response1?.data,
          results: tableData,
        };
      })
      .catch((error) => error?.response?.data);
    return response;
  },
);

const initialState = {
  snapshot: [],
  pageCount: 1,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilterData: {},
  countryData: {},
};

export const snapshotSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilterData: (state, action) => {
      state.tableFilterData = action.payload;
    },
    resetSnapshotState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSnapshotList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSnapshotList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code) {
          state.error = action?.payload;
        } else {
          const datas = action.payload.results;
          // if (datas.length > 0) {
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.snapshot = datas;
          } else {
            state.snapshot.push(...datas);
          }
        }
      })
      .addCase(fetchSnapshotList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setTableFilterData,
  resetSnapshotState,
} = snapshotSlice.actions;

export default snapshotSlice.reducer;
