import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';

function NewContent(props) {
  const { t } = useTranslation();
  const inputFile = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="new-content">
      <div className="content-buttons">
        <div className="buttons-list">
          <Button
            id="upload-media"
            label={t('uploadMediaFromYourDevice')}
            click={() => onButtonClick()}
            classes="default-button button-outline-success"
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            multiple
            onChange={(e) => props?.selectMedia(e.target.files)}
            accept="image/*,video/*, application/pdf"
            style={{ display: 'none' }}
          />
          <Button
            id="upload-url"
            label={t('addUrl')}
            click={() => props?.selectURL('url')}
            classes="default-button button-outline-success"
          />
          <Button
            id="upload-youtube-Link"
            label={t('addYoutubeLink')}
            click={() => props?.selectURL('youtube')}
            classes="default-button button-outline-success"
          />
        </div>
      </div>
    </div>
  );
}

NewContent.propTypes = {
  selectMedia: PropTypes.func.isRequired,
  selectURL: PropTypes.func.isRequired,
};

export default NewContent;
