/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import storeLocation from '../../utils/api/store-location';
import { changeDateTimeFormat } from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchStoreLocationList = createAsyncThunk(
  'storeLocation/fetchStoreLocation',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocations(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        area_city: `${val?.area}, ${val?.city}`,
        state_country: `${val?.state}, ${val?.country}`,
        createDate: changeDateTimeFormat(val.createdAt),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchCityStateCountry = createAsyncThunk(
  'storeLocation/fetchCityStateCountry',
  async (data, { dispatch }) => {
    const response = await storeLocation.getCityStateCountry(data)
      .then((response1) => ({
        ...response1.data,
        city: response1.data.city.map((val) => ({ value: val, label: val })),
        state: response1.data.state.map((val) => ({ value: val, label: val })),
        country: response1.data.country.map((val) => ({ value: val, label: val })),
        code: 200,
      })).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchStoreLocationListAll = createAsyncThunk(
  'storeLocation/fetchStoreLocationAll',
  async (data, { dispatch }) => {
    const response = await storeLocation.getLocationsAll()
      .then((response1) => {
        const storeList = response1?.data?.map((val) => ({
          ...val,
          id: val._id,
          label: val.name,
          area_city: `${val?.area}, ${val?.city}`,
        }));
        return { storeList, code: 200 };
      })
      .catch((error) => dispatch(setErrorNotification(error?.response)));
    return response;
  },
);

const initialState = {
  storeLocation: [],
  storeLocationAll: [],
  singleStoreData: {
    floorMapFiles: [],
    storeImages: [],
  },
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
  countryData: {},
};

export const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    // Reducer to add the new store to top of the store list
    createNewStore: (state, action) => {
      const newStore = action?.payload?.[0]; // store to be added at the top
      const storeList = [
        {
          ...newStore,
          area_city: `${newStore?.area}, ${newStore?.city}`,
          state_country: `${newStore?.state}, ${newStore?.country}`,
          createDate: changeDateTimeFormat(newStore.createdAt),
          id: newStore._id,
        },
        ...state.storeLocation, // Existing stores
      ];
      state.storeLocation = storeList; // update the store list
    },
    // Reducer to update the store by ID.
    updateStore: (state, action) => {
      const updatedStore = action?.payload?.[0]; // store to be added at the top
      const storeList = state.storeLocation; // Assign the existing store to another variable
      // Find the index of the updated store by ID
      const index = storeList.findIndex((store) => store._id === updatedStore._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        storeList[index] = {
          ...updatedStore,
          area_city: `${updatedStore?.area}, ${updatedStore?.city}`,
          state_country: `${updatedStore?.state}, ${updatedStore?.country}`,
          createDate: changeDateTimeFormat(updatedStore.createdAt),
          id: updatedStore._id,
        };
      }
      state.storeLocation = storeList; // Finally update the store list
    },
    // Reducer to delete the store by ID
    deleteStore: (state, action) => {
      const storeList = state.storeLocation;

      // Find the index to delete by ID
      const index = storeList.findIndex((store) => store.id === action.payload);
      // If the index found, remove that index from list of stores
      if (index !== -1) {
        storeList.splice(index, 1);
      }
      state.storeLocation = storeList; // Update the stores state after deleted
    },
    clearErrorLocation: (state, action) => {
      state.error = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    createNewStoreLocally: (state, action) => {
      state.singleStoreData = action.payload;
    },
    resetLocationState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchStoreLocationList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStoreLocationList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.storeLocation = fetchedData;
          } else {
            const finalData = state.storeLocation; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.storeLocation = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchStoreLocationList.rejected, (state) => {
        state.status = 'failed';
      });

    builder.addCase(fetchStoreLocationListAll.fulfilled, (state, action) => {
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.storeLocationAll = action.payload.storeList;
      }
    });

    builder.addCase(fetchCityStateCountry.fulfilled, (state, action) => {
      // Add user to the state array
      if (action?.payload?.code === 200) {
        state.countryData = action.payload;
      }
    });
  },
});

export const {
  createNewStore,
  updateStore,
  deleteStore,
  setFilter,
  setTableFilter,
  createNewStoreLocally,
  resetLocationState,
} = storeLocationSlice.actions;

export default storeLocationSlice.reducer;
