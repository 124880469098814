import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getDevices: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/devices`, { params });
  },
  getDevicesList: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/devices/getDeviceList`, { params });
  },
  getSingleDevice: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/devices/${data}`, {}),
  addDevice: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/devices`, body);
  },
  updateDevice: (data = {}) => {
    const params = {
      name: data.name,
      store: data.store,
      // company: '652fb69524659686eab8fa8a',
    };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/devices/${data.id}`, params);
  },
  deleteDevice: (data) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/devices/${data}`, {}),
  getTimeZone: () => get('http://worldtimeapi.org/api/timezone', {}),
  getDevicesAll: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/devices/getDeviceList`, { params });
  },
  getOrientation: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/devices/getOrientationList`, { params });
  },
  getDeviceSchedules: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/devices/device-schedule-list/${data}`),
  getStorageStats: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/devices/device-storage-stats/${data}`),
  deviceDataExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/devices/export`, { ...data }),
  getActivityLogs: (data = {}) => {
    const params = { ...data, limit: data.limit || 1000 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/devices/getActivityLogs`, { params });
  },
};
