/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Cell,
  Pie,
  PieChart,
  Tooltip,
} from 'recharts';
import { SmallRefreshIcon, ViewIcon } from '../../../shared/component/svgIcon';

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontSize: '13px' }}
    >
      {(percent * 100) % 1 !== 0 ? `${(percent * 100).toFixed(1)} %` : `${(percent * 100).toFixed(0)} %`}
    </text>
  );
};
function CustomTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const { value, name } = payload[0].payload; // Destructure from payload

    return (
      <div className="custom-tooltip">
        <p className="label">{`${name} : ${value.toFixed(2)}%`}</p>
      </div>
    );
  }

  return null; // Return null if tooltip is not active
}
// const CustomTooltip = ({ active, payload }) => {
//   if (active && payload && payload.length) {
//     const { value, name, count } = payload[0].payload; // Destructure from payload

//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${name} : ${value.toFixed(2)}%`}</p>
//         <p className="desc">{`Actual Count: ${count}`}</p>
//       </div>
//     );
//   }

//   return null; // Return null if tooltip is not active
// };
function ReportsByGender(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [displayStatusData, setDisplayStatusData] = useState([]);

  const men = props?.value?.men || 0;
  const women = props?.value?.women || 0;

  const totalCount = men + women; // Total count of men and women

  const menPercentage = totalCount ? (men / totalCount) * 100 : 0; // Calculate men percentage
  const womenPercentage = totalCount ? (women / totalCount) * 100 : 0;
  const getReportData = () => {
    const data = [
      {
        name: 'Men',
        value: menPercentage,
        color: '#6096FF',
        id: 'men',
      },
      {
        name: 'Women',
        value: womenPercentage,
        color: '#EC4899',
        id: 'women',
      },
    ];
    setDisplayStatusData(data);
  };
  const handleChange = (id) => {
    navigate(`/admin/ai-analytics-reports/${id}`);
  };

  // Update the width and height of the area chart container
  useEffect(() => {
    getReportData();
  }, [womenPercentage, menPercentage]);
  useEffect(() => {
    getReportData();
    const updateDimensions = () => {
      const containerWidth = document.getElementById('pieChart-container').clientWidth;
      const containerHeight = document.getElementById('pieChart-container').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);
  return (
    <div className="report-by-gender">
      <div className="report-header">
        <div className="header-left">
          <h3 className="title">{t('reportsByGender')}</h3>
          <ViewIcon className="status-view-icon" />
        </div>
      </div>
      <div className="pie-chart-container">
        <div className="pieChart-wrap" id="pieChart-container">
          <PieChart
            width={dimensions.width}
            height={dimensions.height}
          >
            <Pie
              data={displayStatusData}
              dataKey="value"
              cx={dimensions.width / 2}
              cy={dimensions.height / 2}
              label={renderCustomizedLabel}
              // Calculate based on parent width & height
              innerRadius={0}
              outerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 20}
              labelLine={false}
              fill="#0088FE"
              cursor="pointer"
              onClick={(data) => handleChange(data.id)}
            >
              {displayStatusData.map((entry) => (
                <Cell style={{ outline: 'none' }} key={entry.name} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </div>
      </div>
      <div className="pie-chart-legend">
        {displayStatusData?.map((item) => (
          <div className="each-legend" key={item}>
            <div className="marker" style={{ backgroundColor: item?.color }} />
            <span className="label">{item?.name}</span>
          </div>
        ))}
      </div>
      <div className="last-time">
        <SmallRefreshIcon />
        <span className="last-time-message">{props?.time}</span>
      </div>
    </div>
  );
}
ReportsByGender.propTypes = {
  value: PropTypes.shape({
    men: PropTypes.number,
    women: PropTypes.number,
  }),
  time: PropTypes.string,
};
ReportsByGender.defaultProps = {
  value: PropTypes.shape({
    men: PropTypes.number,
    women: PropTypes.number,
  }),
  time: '',
};
CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        value: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};
export default ReportsByGender;
