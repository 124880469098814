const height = {
  tableHeight: `${window.innerHeight - 251}px`,
  cardsHeight: `${window.innerHeight - 251}px`,
  onlyTableHeight: `${window.innerHeight - 231}px`,
  formTableHeight: `${window.innerHeight - 371}px`,
  halfTableHeight: `${window.innerHeight - 421}px`,
  widgetHeight: `${window.innerHeight - 55}px`,
};
if (window.innerHeight > 791) {
  height.tableHeight = '72vh';
  height.cardsHeight = '72vh';
  height.onlyTableHeight = '74vh';
  height.formTableHeight = '55vh';
  height.halfTableHeight = '53vh';
  height.widgetHeight = '100vh';
}
if (window.innerHeight <= 791 && window.innerHeight > 731) {
  height.tableHeight = '70vh';
  height.cardsHeight = '70vh';
  height.onlyTableHeight = '72vh';
  height.formTableHeight = '51vh';
  height.halfTableHeight = '42vh';
  height.widgetHeight = '100vh';
}
if (window.innerHeight <= 731 && window.innerHeight > 695) {
  height.tableHeight = '69vh';
  height.cardsHeight = '68vh';
  height.onlyTableHeight = '70vh';
  height.formTableHeight = '48vh';
  height.halfTableHeight = '39vh';
  height.widgetHeight = '100vh';
}
if (window.innerHeight <= 695 && window.innerHeight > 599) {
  height.tableHeight = '67vh';
  height.cardsHeight = '66vh';
  height.onlyTableHeight = '69vh';
  height.formTableHeight = '45vh';
  height.halfTableHeight = '37vh';
  height.widgetHeight = '100vh';
}
if (window.innerHeight <= 599 && window.innerHeight > 100) {
  height.tableHeight = '62vh';
  height.cardsHeight = '63vh';
  height.onlyTableHeight = '66vh';
  height.formTableHeight = '37vh';
  height.halfTableHeight = '35vh';
  height.widgetHeight = '100vh';
}
export default height;
