import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../../shared/component/form/Input';
import Button from '../../../../shared/component/Button';

function DeleteConfirmation(props) {
  const { t } = useTranslation();
  const reasonList = [
    {
      label: 'Too Expensive',
      key: 'Too Expensive',
    },
    {
      label: 'Not User Friendly',
      key: 'Not User Friendly',
    },
    {
      label: 'Difficult to use',
      key: 'Difficult to use',
    },
    {
      label: 'Technical Issues',
      key: 'Technical Issues',
    },
    {
      label: 'Lack of features',
      key: 'Lack of features',
    },
    {
      label: 'Poor Customer Service',
      key: 'Poor Customer Service',
    },
  ];
  const [selectedReason, setSelectedReason] = useState('');
  const handleOptionSwitch = (value) => {
    setSelectedReason(value);
  };
  const back = () => {
    props?.back(props?.email, props?.otp);
  };
  const submit = () => {
    props?.closeModal(false);
  };
  return (
    <div className="cancel-subscription">
      <div className="cancel-msg">
        {t('deleteConfirmationMessage')}
      </div>
      <div className="content-body">
        <h1 className="feedback-prompt">
          {t('deleteConfirmationFeedbackPrompt')}
        </h1>
        {reasonList?.map((option) => (
          <RadioButton
            label={option?.label}
            value={option?.key}
            checked={selectedReason === option?.key}
            onChange={handleOptionSwitch}
          // disabled={option?.disabled}
          />
        ))}
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={back}
              classes="default-button"
            />
          </div>
          <div className="form-button">
            <Button
              label={t('sendRequest')}
              click={submit}
              classes="danger-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DeleteConfirmation.propTypes = {
  closeModal: PropTypes.node.isRequired,
  back: PropTypes.func.isRequired,
  otp: PropTypes.string,
  email: PropTypes.string,
};

DeleteConfirmation.defaultProps = {
  otp: '',
  email: '',
};

export default DeleteConfirmation;
