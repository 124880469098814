import React from 'react';
import PropTypes from 'prop-types';
import { SearchIcon } from '../svgIcon';

function SearchBox(props) {
  const changes = (e) => {
    props?.change(e.target.name, e.target.value);
  };

  return (
    <div className="search-input-wrap">
      <span><SearchIcon /></span>
      <input
        type={props?.type}
        name={props?.name}
        className="search-input-box"
        value={props?.value}
        onChange={changes}
        readOnly={props?.readonly}
        placeholder={props?.placeholder}
        id={props?.name}
        maxLength={props?.maxLength}
        autoComplete="off"
      />
    </div>
  );
}

SearchBox.propTypes = {
  type: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

SearchBox.defaultProps = {
  readonly: false,
  value: '',
  placeholder: '',
  maxLength: Infinity,
};

export default SearchBox;
