/* eslint no-underscore-dangle: 0 */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../shared/component/Button';
import myContent from '../../../utils/api/my-content';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function ModalConfirmContent(props) {
  const dispatch = useDispatch();
  const advancedSettingData = props?.rowData;
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    content_id: contentId,
    required_size_in_pixels: requiredSize,
    signedUrl: imageUrl,
    file_type: fileType,
    content_name: contentName,
  } = advancedSettingData;

  const handleBack = () => {
    props?.setModalOpen(false);
  };

  function resizeImage(imageAsUrl, targetWidth, targetHeight) {
    return new Promise((resolve, reject) => {
      fetch(imageAsUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

            canvas.toBlob((resizedBlob) => {
              if (!resizedBlob) {
                reject(new Error('Error converting canvas to blob'));
              }

              const resizedFile = new File([resizedBlob], contentName || 'resize_image', {
                type: fileType,
              });

              resolve(resizedFile);
            }, fileType || 'image/jpeg');
          };
          img.src = URL.createObjectURL(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const submit = async () => {
    try {
      setButtonLoading(true);
      const resizedFile = await resizeImage(
        imageUrl,
        requiredSize?.width_in_resolution,
        requiredSize?.height_in_resolution,
      );

      const uploadedContent = await myContent.uploadResizedImage(
        contentId,
        {
          file: resizedFile,
          width_in_resolution: requiredSize?.width_in_resolution,
          height_in_resolution: requiredSize?.height_in_resolution,
        },
      );

      if (uploadedContent?.data?._id) {
        await props?.makeAdvanceSettingsCall(true);
      }
      handleBack();
    } catch (error) {
      console.error(error);
      dispatch(setErrorNotification({ message: 'Error while resizing content!' }));
    }
  };
  return (
    <div>
      <p>Impact:</p>
      <p>* Content Resolution will be {requiredSize?.width_in_resolution} x {requiredSize?.height_in_resolution}</p>
      {/* <p>* Content size will be 2mb</p> */}
      <p>* New Content will be uploaded</p>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label="Back"
            click={handleBack}
            disabled={buttonLoading}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label="Resize & Upload"
            click={submit}
            classes="success-button"
            disabled={buttonLoading}
            loading={buttonLoading}
          />
        </div>
      </div>
    </div>
  );
}

ModalConfirmContent.propTypes = {
  setModalOpen: PropTypes.node.isRequired,
  rowData: PropTypes.objectOf().isRequired,
  makeAdvanceSettingsCall: PropTypes.func.isRequired,
};

export default ModalConfirmContent;
