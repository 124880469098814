export const weatherApi = async (lat, lng) => {
  const apiKey = '961815cf7b7e1a3e3e7069397ba0a717';
  const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${apiKey}`;

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const weatherLocation = await response.json();
  return weatherLocation;
};

export const getRssTemplate = async (feedUrl) => {
  const PROXY_URL = 'https://signage-api-dev.wejha.com/v1/proxy/';
  const RSS_URL = feedUrl;
  const FULL_URL = PROXY_URL + RSS_URL;

  const response = await fetch(FULL_URL);
  const responseText = await response.text();
  const parsedData = new window.DOMParser().parseFromString(responseText, 'text/xml');
  const items = parsedData.querySelectorAll('item');
  const rssData = [];
  items.forEach((item) => {
    const link = item.querySelector('link') ? item.querySelector('link').textContent : '#';
    const title = item.querySelector('title') ? item.querySelector('title').textContent : 'No Title';
    let imageUrl = ''; // Default image URL

    const enclosure = item.querySelector('enclosure');
    if (enclosure) {
      imageUrl = enclosure.getAttribute('url');
    } else {
      const mediaContent = item.querySelector('media\\:content, content');
      if (mediaContent) {
        imageUrl = mediaContent.getAttribute('url');
      }
    }
    rssData.push({ link, title, imageUrl });
  });

  return rssData;
};
