import React from 'react';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  color: '#30A84B',
  height: 28,
  borderRadius: 30,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 0,
    width: 0,
    backgroundColor: '#fff',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#30A84B',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function SliderComponent(props) {
  const handleSliderChange = (event, newValue) => {
    props?.changeFunc(props?.name, newValue);
  };
  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.name}>
        {props?.label}{props?.required && <span className="text-danger">*</span>}
      </label>
      <div className="icon-slider">
        {props?.icon
          && (
            <div className="icon-wrap">
              {props?.icon}
            </div>
          )}
        <PrettoSlider
          value={props?.value} // Pass the current value from state to the slider component
          onChange={handleSliderChange} // Pass the event handler function
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          defaultValue={props?.defaultValue || 0}
        />
      </div>
    </div>
  );
}

SliderComponent.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeFunc: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
  icon: PropTypes.element,
};

SliderComponent.defaultProps = {
  label: '',
  value: '',
  defaultValue: 0,
  icon: null,
};

export default SliderComponent;
