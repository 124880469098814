import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getPlaylists: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/playlists`, { params });
  },
  getSinglePlaylist: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/playlists/${data}`, {}),
  addPlaylist: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/playlists`, body);
  },
  updatePlaylist: (data = {}) => {
    const params = {
      contents: data.contents,
      description: data.description,
      name: data.name,
    };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/playlists/${data.id}`, params);
  },
  deletePlaylist: (data) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/playlists/${data}`, {}),
  playlistExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/playlists/export`, { ...data }),
};
