import React from 'react';
import PropTypes from 'prop-types';

function TableHeaderSelectCount(props) {
  const { select, total } = props;
  return (
    <div className="table-select-count">
      {select} of {total} { select > 1 ? 'rows' : 'row' } selected
    </div>
  );
}

TableHeaderSelectCount.propTypes = {
  select: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default TableHeaderSelectCount;
