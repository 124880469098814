import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';

import {
  fetchSnapshotScheduleList,
  setFilter,
  setMuiFilterData,
} from '../../../redux/slices/SnapshotScheduleSlice';
import height from '../../../utils/size-variables';

function SchedulesList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    rowSelection,
    setRowSelection,
  } = props;

  const {
    schedules,
    pageCount,
    totalPageCount,
    status,
    filter,
    muiFilterData,
    totalDataCount,
  } = useSelector((state) => state.snapshotSchedules);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [columnVisibility, setColumnVisibility] = useState({});

  const getDevicesGroup = (counts, search) => {
    if (status === 'succeed') {
      dispatch(fetchSnapshotScheduleList({
        page: counts,
        limit: 20,
        status: ['inProgress'],
        ...search,
      }));
    }
  };

  const handleScroll = () => {
    getDevicesGroup(pageCount + 1, filter);
  };

  const columns = [
    {
      header: t('scheduleName'),
      accessorKey: 'name',
      muiFilterTextFieldProps: { placeholder: 'Filter by Schedule Name' },
    },
    {
      header: t('scheduledDate'),
      accessorKey: 'start_date_utc',
      accessorFn: (originalRow) => new Date(originalRow.start_date_utc),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.scheduleDate,
    },
    {
      header: t('scheduledTime'),
      accessorKey: 'scheduledTime',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      filterVariant: 'none',
    },
    {
      header: t('timeZone'),
      accessorKey: 'time_zone',
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setMuiFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDevicesGroup(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(muiFilterData).length === 0) {
      getDevicesGroup(1);
    }
  }, []);

  return (
    <DataTable
      header={columns}
      value={schedules}
      onFilterOrSortingChange={onFilterOrSortingChange}
      reduxColumnFiltersSorting={muiFilterData}
      status={status}
      totalPageCount={totalPageCount}
      pageCount={pageCount}
      scrollData={handleScroll}
      totalDataCount={totalDataCount}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
      height={height.formTableHeight}
      enableMultiRowSelection={false}
      columnVisibility={columnVisibility}
      isFirstRender={isFirstRender}
      setColumnVisibility={setColumnVisibility}
      enableRowSelection
    />
  );
}
SchedulesList.propTypes = {
  rowSelection: PropTypes.objectOf().isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default SchedulesList;
