/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../shared/component/form/Input';
import { changeDateFormat, currencySymbol } from '../../../utils/helpers';
import Button from '../../../shared/component/Button';

function AIRenewal(props) {
  const { instanceData } = props;
  const [renewHours, setRenewHours] = useState('');
  const [instance, setInstance] = useState(instanceData);
  const validFrom = changeDateFormat(new Date());
  const [disable, setDisable] = useState(true);
  const { t } = useTranslation();
  const handleFunction = (name, changeValue) => {
    let price;
    setRenewHours(changeValue);
    if (changeValue === '200') {
      price = 30;
    } else if (changeValue === '400') {
      price = 60;
    } else if (changeValue === '600') {
      price = 80;
    }
    setInstance((prevState) => ({
      ...prevState,
      unitPrice: 0,
      totalPrice: price,
      instanceCount: 1,
      validFrom,
      instance_id: props?.instanceData?._id,
      instance_hours: changeValue,
    }));
  };
  const handleClose = () => {
    props?.close(false);
  };
  const submit = () => {
    props?.submitRenewal(instance);
  };
  useEffect(() => {
    if (instance?.instance_hours) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [instance.instance_hours]);
  useEffect(() => {
    if (renewHours) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [renewHours]);
  return (
    <div className="display-information">
      <div className="display-form">
        <div className="field">
          <Input
            icon={' '}
            label={t('hours')}
            type="number"
            name="hours"
            required
            value={instance?.instance_hours}
            change={handleFunction}
            placeholder={t('0 h')}
          />
        </div>
        <div className="field-price">
          {`${instance?.instance_hours || '0'} h`} * {1}  {t('instance')} =
          {currencySymbol('$', instance?.totalPrice || 0)}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button">
          <Button
            label={t('back')}
            click={handleClose}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={t('confirm')}
            click={submit}
            classes="success-button"
            disabled={disable}
          />
        </div>
      </div>
    </div>
  );
}

AIRenewal.propTypes = {
  close: PropTypes.node.isRequired,
  submitRenewal: PropTypes.func.isRequired,
  instanceData: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),

};
AIRenewal.defaultProps = {
  instanceData: {
    _id: '',
  },
};

export default AIRenewal;
