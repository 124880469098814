import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import { singleDisplayGroupAdd } from '../../../redux/slices/DisplayGroupSlice';
import {
  fetchDisplaySignageList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/DGDisplaySignageSlice';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';
import height from '../../../utils/size-variables';
import { descriptionValidation, nameValidation } from '../../../shared/component/form/Validation';

function DisplayGroupsAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Display signage selectors
  const {
    displaySignage,
    pageCount,
    totalPageCount,
    totalDataCount,
    filter,
    tableFilter,
    status,
  } = useSelector((state) => state.dGDisplay);
  // Display_Group signage selectors
  const { newDisplayGroup } = useSelector((state) => state.displayGroup);

  const [isFirstRender, setIsFirstRender] = useState(true);
  // Table filter and sort variables
  const [deviceSelection, setDeviceSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  // State to handle new display signage value
  const [value, setValue] = useState({
    devices: {},
    selectedDeviceDetails: [],
  });
  const [disable, setDisable] = useState(true); // To handle button state
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  // Function to fetch display signage list
  const getDisplaySignage = (counts, search) => {
    dispatch(fetchDisplaySignageList({ page: counts, ...search, limit: 20 }));
  };

  // Function to handle scroll
  const handleScroll = () => {
    getDisplaySignage(pageCount + 1, filter);
  };

  // Function that reset display group redux state and navigate previous page
  const navigateBack = () => {
    dispatch(singleDisplayGroupAdd({}));
    navigate('../');
  };

  // Function that update display group redux state and navigate add-view page
  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, value?.name)
      && descriptionValidation(500, value?.description)) {
      dispatch(singleDisplayGroupAdd(value));
      navigate('../add-view');
    }
  };

  // Function to handle name and description change
  const handleChange = (name, data) => {
    setValue({ ...value, [name]: data });
  };

  const handleRowSelection = (selectedRows) => {
    setDeviceSelection(selectedRows);
    if (Object.keys(selectedRows).length === displaySignage.length) {
      setAllRowsSelected(true);
    } else {
      setAllRowsSelected(false);
    }
  };

  // Column header for display signage table
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOS'),
      accessorKey: 'hardware',
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
  ];

  useEffect(() => {
    if (Object.keys(deviceSelection).length >= 20 || allRowsSelected) {
      const newDeviceSelection = { ...deviceSelection };
      displaySignage.forEach((data) => {
        if (!newDeviceSelection[data?.id]) {
          newDeviceSelection[data?.id] = true;
        }
      });
      setDeviceSelection(newDeviceSelection);
    }
  }, [displaySignage]);

  // Update selected devices in value when deviceSelection changes
  useEffect(() => {
    const selectedDevices = displaySignage.filter((device) => Object.keys(deviceSelection).includes(device.id));
    setValue({
      ...value,
      devices: deviceSelection,
      selectedDeviceDetails: selectedDevices || [],
    });
  }, [deviceSelection]);

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDisplaySignage(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getDisplaySignage(1);
    }
  }, []);

  // useEffect to change button state
  useEffect(() => {
    if (value?.name && value?.selectedDeviceDetails?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  // useEffect will run only once when page loads
  useEffect(() => {
    if (Object.keys(newDisplayGroup).length !== 0) {
      setValue(newDisplayGroup);
      setDeviceSelection(newDisplayGroup.devices);
      dispatch(singleDisplayGroupAdd({}));
    }
  }, []);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('deviceGroups'), t('createNewDisplayGroups')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={navigateBack}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('groupName')}
                  type="text"
                  name="name"
                  required
                  value={value?.name}
                  change={handleChange}
                  placeholder={t('enterGroupName')}
                  error={!nameValidation(1, 100, value?.name) && submitButtonClicked}
                  errorMessage={t('deviceGroupNameValidation')}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  value={value?.description}
                  change={handleChange}
                  placeholder={t('enterDescription')}
                  error={!descriptionValidation(500, value?.description) && submitButtonClicked}
                  errorMessage={t('descriptionValidation')}
                />
              </div>
              <Table
                header={columns}
                value={[
                  ...value.selectedDeviceDetails || [],
                  ...displaySignage.filter((display) => !Object.keys(deviceSelection).includes(display.id)),
                ]}
                status={status}
                totalPageCount={totalPageCount}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={tableFilter}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalPageCount}
                rowSelection={deviceSelection}
                setRowSelection={handleRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                isFirstRender={isFirstRender}
                enable={tableFilter?.columnFilters?.length > 0}
                height={height.formTableHeight}
                disableFullScreenToggle
                enableRowSelection
                toolbarLeft={(
                  <TableHeaderSelectCount
                    select={Object.keys(deviceSelection).length}
                    total={totalDataCount}
                  />
                )}
              />
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button
                  label={t('back')}
                  click={navigateBack}
                  classes="default-button"
                />
              </div>
              <div className="form-button">
                <Button
                  label={t('continue')}
                  classes="success-button"
                  disabled={disable}
                  click={submit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayGroupsAdd;
