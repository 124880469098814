const importFileandPreview = (file, revoke) => new Promise((resolve, reject) => {
  try {
    window.URL = window.URL || window.webkitURL;
    const preview = window.URL.createObjectURL(file);
    if (revoke) {
      window.URL.revokeObjectURL(preview);
    }
    setTimeout(() => {
      resolve(preview);
    }, 100);
  } catch (error) {
    reject(error);
  }
});

const getVideoDuration = (videoFile) => new Promise((resolve, reject) => {
  try {
    if (videoFile) {
      if (videoFile.type.match('video')) {
        importFileandPreview(videoFile).then((url) => {
          const video = document.createElement('video');
          video.addEventListener('loadeddata', () => {
            resolve(video.duration);
          });
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
          //  window.URL.revokeObjectURL(url);
        });
      }
    }
  } catch (error) {
    reject(error);
  }
});

const getVideoThumbnail = (file, videoTimeInSeconds) => new Promise((resolve) => {
  if (file.type.match('video')) {
    importFileandPreview(file).then((urlOfFIle) => {
      const video = document.createElement('video');
      const snapImage = function () {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        const success = image.length > 100000;
        if (success) {
          URL.revokeObjectURL(urlOfFIle);
          resolve(image);
        }
        return success;
      };
      const timeupdate = function () {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }
      };
      video.addEventListener('loadeddata', () => {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
        }
      });
      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = urlOfFIle;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.currentTime = videoTimeInSeconds;
      video.play();
    });
  }
});

const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {
  const fractions = [];
  return new Promise((resolve) => {
    getVideoDuration(videoFile).then(async (duration) => {
      for (let i = 0; i < duration; i += duration / numberOfThumbnails) {
        if (fractions.length === 5) {
          break;
        }
        fractions.push(Math.floor(i));
      }
      const promiseArray = fractions.map((time) => getVideoThumbnail(videoFile, time));
      await Promise.all(promiseArray).then((res) => {
        resolve({ res, duration });
      }).catch((err) => {
        console.error(err);
      });
    });
  });
};

export default generateVideoThumbnails;
