import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom'; // This is needed to get query params
import SelectBox from '../../shared/component/form/SelectBox';
import { InputDate } from '../../shared/component/form/Input';
import ReportTotalCount from './components/ReportTotalCount';
import aiInstance from '../../utils/api/ai-instance';
import ReportAgeGroup from './components/ReportAgeGroup';
import ReportTimes from './components/ReportTimes';
import { fetchDeviceLists } from '../../redux/slices/AiAnalyticsSlice';
import { fetchStoreLocationListAll } from '../../redux/slices/StoreLocationSlice';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';

function AiReportWomanCharts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation(); // Used to access query parameters
  const { deviceList } = useSelector((state) => state?.AiAnalytics);
  const { storeLocationAll } = useSelector((state) => state?.store);
  const [gender, setGender] = useState('men'); // Default to 'men'
  const [reportsValue, SetReportsValue] = useState({
    reportType: 'By Count',
  });
  const [countData, setCountData] = useState({
    count: [],
    chartCount: [],
  });
  const [countMaleFemaleData, setCountMaleFemaleData] = useState({
    count: [],
    chartCount: [],
  });

  const [GraphTitle, setGraphTitle] = useState('');

  const ReportList = [
    { id: 0, label: 'By Count' },
    { id: 1, label: 'By Age Group' },
    { id: 2, label: 'By Stay/Stare Time' },
  ];
  const handleFunction = (name, changeValue) => {
    if (name === 'location') {
      SetReportsValue({ ...reportsValue, [name]: changeValue?.label, locationId: changeValue?.id });
    } else if (name === 'device') {
      SetReportsValue({ ...reportsValue, [name]: changeValue?.label, deviceId: changeValue?.id });
    } else if (name === 'reportType') {
      SetReportsValue({ ...reportsValue, [name]: changeValue?.label });
    } else {
      SetReportsValue({ ...reportsValue, [name]: changeValue });
    }
  };

  const getData = (data) => {
    aiInstance.getGenderReport(data).then((response) => {
      let count = [];
      let chartCount = [];
      if (data.gender === 'Male') {
        count = response.data.male_counts;
        chartCount = response.data.male_counts_chart.map((val) => ({
          name: val.time_range,
          value: val.count,
          group: val.gender,
          id: val.time_range.replace(' ', '_'),
        }));
      } else {
        count = response.data.female_counts;
        chartCount = response.data.female_counts_chart.map((val) => ({
          name: val.time_range,
          value: val.count,
          group: val.gender,
          id: val.time_range.replace(' ', '_'),
        }));
      }
      setCountData({ count, chartCount });
    }).catch((error) => {
      dispatch(setErrorNotification(error.response.data));
    });
  };
  const getAgeData = (data) => {
    aiInstance.getAgeReport(data).then((response) => {
      const count = response.data.ageGroupList.map((val) => ({
        ageRange: val.ageRange,
        value: gender === 'men' ? val.male : val.female,
        stay: gender === 'men'
          ? Math.round(val.male_stay_time / 60 / 60)
          : Math.round(val.female_stay_time / 60 / 60),
        stray: gender === 'men'
          ? Math.round(val.male_stare_time / 60 / 60)
          : Math.round(val.female_stare_time / 60 / 60),
        percentage: gender === 'men'
          ? Math.round((val.male * 100) / val.total)
          : Math.round((val.female * 100) / val.total),
      }));
      const chartCount = response.data.barChartList.map((val) => ({
        name: val.ageRange,
        value: gender === 'men' ? val.male : val.female,
        stay: gender === 'men'
          ? Math.round(val.male_stay_time / 60 / 60)
          : Math.round(val.female_stay_time / 60 / 60),
        stray: gender === 'men'
          ? Math.round(val.male_stare_time / 60 / 60)
          : Math.round(val.female_stare_time / 60 / 60),
        percentage: gender === 'men'
          ? Math.round((val.male * 100) / val.total)
          : Math.round((val.female * 100) / val.total),
        id: val.ageRange.replace(' ', '_'),
      }));
      setCountMaleFemaleData({ count, chartCount });
    }).catch((error) => {
      dispatch(setErrorNotification(error.response.data));
    });
  };

  const filterFunction = (data) => {
    const request = {
      gender: gender === 'men' ? 'Male' : 'Female',
      device_id: reportsValue.deviceId,
      store: reportsValue.locationId,
      date: reportsValue.start_date ? dayjs(reportsValue.start_date).format('YYYY-MM-DD') : null,
    };
    if (reportsValue?.reportType === 'By Count') {
      request.time_range = data.time_range;
      request.count = data.count;
      getData(request);
    } else if (reportsValue?.reportType === 'By Age Group') {
      request.ageRange = data.ageRange;
      request.total = data.value;
      getAgeData(request);
    } else if (reportsValue?.reportType === 'By Stay/Stare Time') {
      request.ageRange = data.ageRange;
      request.stareTime = data.stray;
      request.stayTime = data.stay;
      getAgeData(request);
    }
  };
  // Extract the 'gender' query parameter from the URL
  useEffect(() => {
    if (deviceList.length === 0) {
      dispatch(fetchDeviceLists());
    }
    if (storeLocationAll.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const genderParam = searchParams.get('gender') || location.pathname.split('/').pop(); // Get gender from URL path
    setGender(genderParam); // Set the gender based on query param
    const request = {
      gender: genderParam === 'men' ? 'Male' : 'Female',
      device_id: reportsValue.deviceId,
      store: reportsValue.locationId,
      date: reportsValue.start_date ? dayjs(reportsValue.start_date).format('YYYY-MM-DD') : null,
    };
    if (reportsValue?.reportType === 'By Count') {
      setGraphTitle('Graph based on average count');
      getData(request);
    } else if (reportsValue?.reportType === 'By Age Group') {
      setGraphTitle('Graph based on average age group');
      getAgeData(request);
    } else if (reportsValue?.reportType === 'By Stay/Stare Time') {
      setGraphTitle('Graph based on average stay/stare time');
      getAgeData(request);
    }
  }, [reportsValue]);

  return (
    <div className="ai-reports">
      <div className="top-content">
        <h1 className="title">Reports</h1>
        <div className="filters">
          <p className="filter-label">{t('filterBy')}: </p>
          <div className="select-boxes">
            <div className="select-box-container">
              <SelectBox
                name="location"
                lists={storeLocationAll}
                value={reportsValue?.location}
                onchange={handleFunction}
                placeholder={t('select')}
                customPadding="0px 4px 0px 5px"
                disableClearable
              />
            </div>
            <div className="select-box-container">
              <SelectBox
                name="device"
                lists={deviceList}
                value={reportsValue?.device}
                onchange={handleFunction}
                placeholder={t('select')}
                customPadding="0px 4px 0px 5px"
                disableClearable
              />
            </div>
            <div className="select-box-container">
              <InputDate
                name="start_date"
                value={reportsValue?.start_date}
                change={handleFunction}
                customPadding="8px 0px 8px 7px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bar-chart">
        <div className="bar-chart-top-content">
          <p className="title">{GraphTitle}</p>
          <div className="bar-chart-category">
            <SelectBox
              name="reportType"
              lists={ReportList}
              onchange={handleFunction}
              value={reportsValue?.reportType}
              customPadding="0px 4px 0px 5px"
              disableClearable
            />
          </div>
        </div>
        {reportsValue?.reportType === 'By Count' && (
          <ReportTotalCount gender={gender} countData={countData} filterFunction={filterFunction} />
        )}
        {reportsValue?.reportType === 'By Age Group' && (
          <ReportAgeGroup gender={gender} countData={countMaleFemaleData} filterFunction={filterFunction} />
        )}
        {reportsValue?.reportType === 'By Stay/Stare Time' && (
          <ReportTimes gender={gender} countData={countMaleFemaleData} filterFunction={filterFunction} />
        )}
      </div>
    </div>
  );
}

export default AiReportWomanCharts;
