import React from 'react';
// import { Link } from 'react-router-dom';
// import logoImage from '../../assets/images/footer-logo.png';

function Footer() {
  return (
    <div className="footer">
      <p>&#169; Wejha</p>
      {/* <Link target="blank" to="https://wejha.com/"><img alt="footer_logo" src={logoImage} /></Link> */}
    </div>
  );
}

export default Footer;
