import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../Table';

import rolesApi from '../../utils/api/roles';

import TopContent from '../../shared/component/TopContent';
import DeleteModal from '../../shared/component/DeleteModal';
import DropDownMenu from '../../shared/component/DropDownMenu';
import PopupModal from '../../shared/component/PopupModel';

import {
  deleteRole,
  fetchRolesList,
  setFilter,
  setTableFilter,
} from '../../redux/slices/RolesSlice';
import { checkFeatures, checkPermission } from '../../utils/helpers';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { MoreMenuIcon } from '../../shared/component/svgIcon';
import height from '../../utils/size-variables';

function Roles() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    roles,
    pageCount,
    totalPageCount,
    status,
    filter,
    tableFilter,
    totalDataCount,
  } = useSelector((state) => state.roles);

  const [deleteId, setDeleteId] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getRoles = (counts, search) => {
    dispatch(fetchRolesList({ page: counts, limit: 20, ...search }));
  };

  const handleScroll = () => {
    getRoles(pageCount + 1, filter);
  };

  const getDeleteRoleId = (id) => {
    setDeleteId(id);
    setOpenDeleteModal(true);
  };

  const viewRole = (id) => {
    navigate(`view/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewRole,
      key: 'view',
      disabled: !checkPermission('getRole') || !checkFeatures('role', 'view'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteRoleId,
      key: 'delete',
      disabled: !checkPermission('deleteRole') || !checkFeatures('role', 'delete'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('role'),
      accessorKey: 'name',
    },
    {
      header: t('permissions'),
      accessorKey: 'permissions',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ cell }) => <PopupModal list={cell.getValue()} name="Permissions list" />,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
        />
      ),
    },
  ];

  const addPlaylist = () => {
    navigate('add');
  };

  const deleteRoleById = () => {
    rolesApi.deleteRole(deleteId)
      .then(() => {
        dispatch(deleteRole(deleteId));
        setOpenDeleteModal(false);
      }).catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getRoles(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getRoles(1);
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('roles')}
          buttonClass="success-button roles-button"
          button={checkPermission('addRole') && checkFeatures('role', 'create')}
          buttonLabel={t('newRole')}
          click={addPlaylist}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteRoleById}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisRole?')}
          subTitle=<span> {t('thisWillDeleteThisRolePermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area roles-table">
              <DataTable
                header={columns}
                value={roles}
                status={status}
                onFilterOrSortingChange={onFilterOrSortingChange}
                reduxColumnFiltersSorting={tableFilter}
                isFirstRender={isFirstRender}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                scrollData={handleScroll}
                totalDataCount={totalDataCount}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                height={height.onlyTableHeight}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Roles.propTypes = {
  cell: PropTypes.shape({
    getValue: PropTypes.func.isRequired,
  }),
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.objectOf().isRequired,
  }),
};
Roles.defaultProps = {
  cell: null,
  row: null,
};
export default Roles;
