/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Button from '../../../shared/component/Button';

function LocationMarker({ position, zoom, click }) {
  const map = useMap();
  map.on('click', (event) => {
    click({
      lat: event.latlng.lat,
      lng: event.latlng.lng,
    });
  });
  useEffect(() => {
    map.flyTo(position, zoom);
  }, [position]);

  return (null);
}

function Location(props) {
  const { t } = useTranslation();

  const [selectLocation, setSelectLocation] = useState({
    lat: props?.data?.lat ?? '',
    lng: props?.data?.lng ?? '',
  });
  const [position] = useState([props?.data?.lat ?? 20.5937, props?.data?.lng ?? 78.9629]);
  const [zoom] = useState();

  const customIcon = new Icon({
    iconUrl: require('../../../assets/images/dashboard/marker.png'),
    iconSize: [35, 35],
  })


  const handleClick = (event) => {
    let coords = event.latlng;
  }

  const submit = () => {
    props?.setLatLng(selectLocation);
  };

  return (
    <div className="location-wrap" style={{ width: '70vw', height: '70vh' }}>
      <div className="lat-lng-text">{t('latitude')} : {selectLocation.lat}</div>
      <div className="lat-lng-text">{t('longitude')} : {selectLocation.lng}</div>
      <MapContainer
        center={{ lat: 40.5937, lng: 78.9629 }}
        zoom={7}
        style={{ height: '80%', width: '100%' }}
        onClick={handleClick}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {selectLocation && (
          <Marker position={selectLocation} icon={customIcon}>
          </Marker>
        )}
        <LocationMarker position={position} zoom={zoom} click={setSelectLocation} />
      </MapContainer>
      <br />
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={() => props?.close(false)} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={t('setLocation')}
            click={submit}
            classes={'success-button'}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
}
export default Location;
