import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import HtmlTemplateOne from './Template1/HtmlTemplate1';
import HtmlTemplateTwo from './Template2/HtmlTemplate2';
import HtmlTemplateThree from './Template3/HtmlTemplate3';
import HtmlTemplateFour from './Template4/HtmlTemplate4';
import HtmlTemplateFive from './Template5/HtmlTemplate5';
import HtmlTemplateSix from './Template6/HtmlTemplate6';
import HtmlTemplateSeven from './Template7/HtmlTemplate7';
import HtmlTemplateEight from './Template8/HtmlTemplate8';
import HtmlTemplateNine from './Template9/HtmlTemplate9';
import HtmlTemplateTen from './Template10/HtmlTemplate10';
import HtmlTemplateEleven from './Template11/HtmlTemplate11';
import HtmlTemplateTwelve from './Template12/HtmlTemplate12';
import HtmlTemplateThirteen from './Template13/HtmlTemplate13';
import HtmlTemplateFifteen from './Template15/HtmlTemplate15';

function HtmlRenderPage() {
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const { id } = useParams();
  const pageObject = [HtmlTemplateOne, HtmlTemplateTwo, HtmlTemplateThree, HtmlTemplateFour, HtmlTemplateFive,
    HtmlTemplateSix, HtmlTemplateSeven, HtmlTemplateEight, HtmlTemplateNine, HtmlTemplateTen, HtmlTemplateEleven,
    HtmlTemplateTwelve, HtmlTemplateThirteen, HtmlTemplateThirteen, HtmlTemplateFifteen];
  const Page = pageObject[id - 1];
  return (
    <Page templateData={templateData} />
  );
}

export default HtmlRenderPage;
