import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DataTable from '../../Table';

import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';
import {
  fetchSnapshotStoreLocationList,
  setFilter,
  setMuiFilterData,
} from '../../../redux/slices/SnapshotStoreLocationSlice';
import {
  fetchCityStateCountry,
} from '../../../redux/slices/StoreLocationSlice';
import height from '../../../utils/size-variables';

function StoresList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    rowSelection,
    setRowSelection,
  } = props;

  const {
    storeLocation,
    pageCount,
    totalPageCount,
    status,
    filter,
    muiFilterData,
    totalDataCount,
  } = useSelector((state) => state.snapshotStore);

  const {
    countryData,
  } = useSelector((state) => state.store);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [columnVisibility, setColumnVisibility] = useState({});

  const getDevicesGroup = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchSnapshotStoreLocationList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getDevicesGroup(pageCount + 1, filter);
  };

  const columns = [
    {
      header: t('storeName'),
      accessorKey: 'name',
    },
    {
      header: t('storeID'),
      accessorKey: 'store_id',
    },
    {
      header: t('areaAndCity'),
      accessorKey: 'city',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.city,
      muiFilterTextFieldProps: { placeholder: 'Filter by City' },
      Cell: ({ row }) => row.original.area_city,
      filterFn: 'commonFIlterFn',
    },
    {
      header: t('state'),
      accessorKey: 'state',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.state,
    },
    {
      header: t('country'),
      accessorKey: 'country',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.country,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setMuiFilterData(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getDevicesGroup(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (pageCount === 0 && Object.keys(muiFilterData).length === 0) {
      dispatch(fetchCityStateCountry());
      getDevicesGroup(1);
    }
  }, []);

  return (
    <DataTable
      header={columns}
      value={storeLocation}
      onFilterOrSortingChange={onFilterOrSortingChange}
      reduxColumnFiltersSorting={muiFilterData}
      status={status}
      totalPageCount={totalPageCount}
      pageCount={pageCount}
      scrollData={handleScroll}
      totalDataCount={totalDataCount}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
      height={height.formTableHeight}
      columnVisibility={columnVisibility}
      setColumnVisibility={setColumnVisibility}
      isFirstRender={isFirstRender}
      enableRowSelection
      toolbarLeft={(
        <TableHeaderSelectCount
          select={Object.keys(rowSelection).length}
          total={totalDataCount}
        />
      )}
    />
  );
}
StoresList.propTypes = {
  rowSelection: PropTypes.objectOf().isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default StoresList;
