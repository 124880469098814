import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import loginImage from '../../assets/images/login/loginPage.jpg';
import passwordIcon from '../../assets/icons/password1.png';

import { Input1 } from '../../shared/component/form/Input';
import Button from '../../shared/component/Button';

import auth from '../../utils/api/auth';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword() {
  const query = useQuery();
  const token = query.get('token');
  const [value, setValue] = useState({});
  const [errorMessage, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('data');
  }, [value]);

  const changeHandle = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const submit = () => {
    if (value.password === value.c_password) {
      auth.resetPassword({ password: value.password }, token)
        .then(() => {
          navigate('/login');
        })
        .catch((error) => {
          if (error?.response?.data?.code === 401) {
            setError(error?.response?.data?.message);
          }
          if (error?.response?.data?.code === 400) {
            setError(error?.response?.data?.message);
          }
        });
    } else {
      setError('Password Not Match');
    }
  };

  return (
    <div className="login">
      <div className="page">
        <div className="banner-wrap">
          <img src={loginImage} className="forgot-password-banner" alt="" />
        </div>
        <div className="form-session">
          <div className="form login-forms">
            <h1 className="loginTitle">Reset Password</h1>
            <p className="text-danger">{errorMessage}</p>
            <Input1
              icon={passwordIcon}
              label="New Password"
              type="password"
              name="password"
              change={changeHandle}
            />
            <Input1
              icon={passwordIcon}
              label="Confirm Password"
              type="password"
              name="c_password"
              change={changeHandle}
            />
            <Button label="Reset Password" click={submit} classes="success-button" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
