import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import youtubeIcon from '../../../../assets/images/youtube-icon.png';
import urlIcon from '../../../../assets/images/url-image.png';
import pdfIcon from '../../../../assets/images/pdf-icon.png';
import loginImage from '../../../../assets/images/loginPage.jpg';

function DraggableContent(props) {
  const {
    content,
    draggingElement,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({
    id: content?.id,
    data: content,
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="single-draggable-content-wrap"
    >
      {
        (() => {
          let singleContent;
          if (content?.file_type === 'youtube') {
            singleContent = (
              <div
                style={{ '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF' }}
                className="single-draggable-content"
              >
                <img
                  alt=""
                  src={youtubeIcon}
                  loading="lazy"
                  role="presentation"
                  className="content-thumbnail default"
                />
                <div className="content-details">
                  <h1>{content.name}</h1>
                  <h5>Youtube</h5>
                </div>
              </div>
            );
          } else if (content?.file_type === 'url') {
            singleContent = (
              <div
                style={{ '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF' }}
                className="single-draggable-content"
              >
                <img
                  alt=""
                  src={urlIcon}
                  loading="lazy"
                  role="presentation"
                  className="content-thumbnail default"
                />
                <div className="content-details">
                  <h1>{content.name}</h1>
                  <h5>Url</h5>
                </div>
              </div>
            );
          } else if (content?.file_type?.includes('pdf')) {
            singleContent = (
              <div
                style={{ '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF' }}
                className="single-draggable-content"
              >
                <img
                  alt=""
                  src={pdfIcon}
                  loading="lazy"
                  role="presentation"
                  className="content-thumbnail default"
                />
                <div className="content-details">
                  <h1>{content.name}</h1>
                  <h5>PDF</h5>
                </div>
              </div>
            );
          } else if (content?.file_type?.includes('template')) {
            singleContent = (
              <div
                style={{
                  '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF',
                  '--draggingIndex': draggingElement ? 99 : 0,
                }}
                className="single-draggable-content"
              >
                <img
                  alt=""
                  src={
                    content?.thumbnailSignedUrls?.px_50x50
                    || loginImage
                  }
                  loading="lazy"
                  role="presentation"
                  className="content-thumbnail"
                />
                <div className="content-details">
                  <h1>{content.name}</h1>
                  <h5>Template</h5>
                </div>
              </div>
            );
          } else {
            singleContent = (
              <div
                style={{
                  '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF',
                  '--draggingIndex': draggingElement ? 99 : 0,
                }}
                className="single-draggable-content"
              >
                <img
                  alt=""
                  src={
                    content?.thumbnailSignedUrls?.px_50x50
                    || loginImage
                  }
                  loading="lazy"
                  role="presentation"
                  className="content-thumbnail"
                />
                <div className="content-details">
                  <h1>{content.name}</h1>
                  <h5>{content?.file_type?.includes('video') ? 'Video' : 'Image'}</h5>
                </div>
              </div>
            );
          }
          return singleContent;
        })()
      }
    </div>
  );
}

DraggableContent.propTypes = {
  draggingElement: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    file_type: PropTypes.string.isRequired,
    thumbnailSignedUrls: PropTypes.shape({
      px_50x50: PropTypes.string.isRequired,
    }),
  }),
};

DraggableContent.defaultProps = {
  content: {},
  draggingElement: false,
};

export default DraggableContent;
