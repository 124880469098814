import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../shared/component/form/Input';

function DeviceInfo(props) {
  const { t } = useTranslation();
  const value = props?.data;
  return (
    <div className="device-info-wrap">
      <div className="signage-form">
        <div className="single-row">
          <Input
            icon={' '}
            label={t('deviceBrand')}
            type="text"
            name="brand"
            readonly
            value={value?.brand}
          />
          <Input
            icon={' '}
            type="text"
            label={t('deviceName')}
            name="name"
            readonly
            required={false}
            value={value?.name}
          />
        </div>
        <div className="single-row">
          <Input
            icon={' '}
            label={t('deviceOS')}
            type="text"
            name="hardware"
            readonly
            value={value?.hardware}
          />
          <Input
            icon={' '}
            label={t('size')}
            type="text"
            name="size"
            readonly
            value={value?.screen_size}
          />
        </div>
        <div className="single-row">
          <Input
            icon={' '}
            type="text"
            label={t('resolution')}
            name="resolution"
            readonly
            value={value?.resolution}
          />
          <Input
            icon={' '}
            type="text"
            label={t('orientation')}
            name="orientation"
            readonly
            value={value?.orientation}
          />
        </div>
        <div className="single-row">
          <Input
            icon={' '}
            type="text"
            label={t('playerVersion')}
            name="player_app_version"
            readonly
            value={value?.player_app_version}
          />
          {/* <Input
            icon={' '}
            type="text"
            label="Device SNo"
            name="device_serial_number"
            readonly
            value={value?.device_serial_number}
          /> */}
        </div>
      </div>
    </div>
  );
}

DeviceInfo.propTypes = {
  data: PropTypes.objectOf().isRequired,
};

export default DeviceInfo;
