/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/component/Button';
import ModalBox from '../../../shared/component/ModalBox';
import { Input } from '../../../shared/component/form/Input';
import ModalContent from './ModalContent';
import ModalConfirmContent from './ModalConfirmContent';

import SelectBox from '../../../shared/component/form/SelectBox';
import { AdvanceEditIcon, ViewIcon } from '../../../shared/component/svgIcon';
import DataTable from '../../Table';
import { capitalize } from '../../../utils/helpers';
import schedule from '../../../utils/api/schedule';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function AdvanceSettings(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalView, setModalView] = useState();
  const [modalTitle, setModalTitle] = useState(t('contents'));
  const { newSchedule } = useSelector((state) => state.schedules);
  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    start_transition: false,
    end_transition: false,
    duration: false,
    volume_play_range_type: false,
  });
  const [open, setOpen] = useState(false);

  const [tableValue, setTableValue] = useState([]);

  const contentFitOptions = [
    { value: 'auto', label: t('auto') },
    { value: 'fill', label: t('fill') },
    { value: 'stretch', label: t('stretch') },
    { value: 'fit', label: t('fit') },
  ];

  const contentOrientationOptions = [
    { value: 'landscape', label: t('landscape') },
    { value: 'portrait', label: t('portrait') },
  ];

  const contentVolumeOptions = [
    { value: 'always', label: t('always') },
    { value: 'custom range', label: t('customRange') },
  ];

  const availableTransitions = [
    { value: 'FadeIn', label: t('fadeIn') },
    { value: 'FadeOut', label: t('fadeOut') },
    { value: 'SlideInUp', label: t('slideInUp') },
    { value: 'SlideInDown', label: t('slideInDown') },
    { value: 'SlideInLeft', label: t('slideInLeft') },
    { value: 'SlideInRight', label: t('slideInRight') },
    { value: 'SlideOutUp', label: t('slideOutUp') },
    { value: 'SlideOutDown', label: t('slideOutDown') },
    { value: 'SlideOutLeft', label: t('slideOutLeft') },
    { value: 'SlideOutRight', label: t('slideOutRight') },
  ];

  const updateSettings = (
    prevSettings,
    columnFilter,
    changeValue,
    isCustomRange = false,
  ) => prevSettings?.map((setting) => {
    const matchesFilter = (
      setting?.content_id?.toString() === columnFilter?.contentId?.toString()
      && setting?.device_id?.toString() === columnFilter?.deviceId?.toString()
      && (!columnFilter?.contentLayout || setting?.partition_id?.toString() === columnFilter?.partitionId?.toString())
    );

    if (matchesFilter) {
      const updatedSetting = {
        ...setting,
        [`${columnFilter?.rowName}_label`]: isCustomRange ? t('customRange') : changeValue?.label,
      };

      if (isCustomRange) {
        updatedSetting[columnFilter?.rowName] = 'custom range';
      } else if (columnFilter?.rowName === 'duration') {
        updatedSetting[columnFilter?.rowName] = Number(changeValue);
      } else {
        updatedSetting[columnFilter?.rowName] = changeValue?.value;
      }

      if (isCustomRange) {
        updatedSetting.volume_play_range = changeValue;
      } else if (changeValue?.value === 'always') {
        updatedSetting.volume_play_range = [];
      }

      return updatedSetting;
    }

    return setting;
  });

  const handleCustomVolumeChange = (columnFilter, changeValue) => {
    setTableValue((prevSettings) => updateSettings(prevSettings, columnFilter, changeValue, true));
  };

  const openVolumeController = (columnFilter) => {
    const selectColumnValue = tableValue
      ?.find((setting) => setting?.content_id?.toString() === columnFilter?.contentId?.toString()
        && setting?.device_id?.toString() === columnFilter?.deviceId?.toString()
        && (!columnFilter?.contentLayout
          || setting?.partition_id?.toString() === columnFilter?.partitionId?.toString()));

    setModalTitle(t('customRangeTime'));
    setModalView(
      <ModalContent
        closeModal={() => setOpen(false)}
        onSave={handleCustomVolumeChange}
        columnFilter={columnFilter}
        selectedValue={selectColumnValue}
      />,
    );
    setOpen(true);
  };

  const handleInputChange = (columnFilter, changeValue) => {
    if (changeValue?.value === 'custom range') {
      openVolumeController(columnFilter);
    } else {
      setTableValue((prevSettings) => updateSettings(prevSettings, columnFilter, changeValue));
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const back = () => {
    if (props?.advanceSettingEdit) {
      props?.closeModal(false);
    } else {
      props?.back(props?.name, props?.data);
    }
  };

  const mapAdvanceSettings = (settings, checkPartition, contentResized = false) => {
    let advanceSettingsToDisplay = [];

    if (contentResized) {
      advanceSettingsToDisplay = tableValue?.map((setting) => {
        const output = { ...setting };

        const newData = settings?.find((val) => val.content_id === setting.content_id
          && val.device_id === setting.device_id
          && (!checkPartition || val.partition_id === setting.partition_id));

        if (newData) {
          output.content_fit = newData.content_fit;
          output.content_size_in_pixels = newData.content_size_in_pixels;
        }

        return output;
      });
    } else {
      advanceSettingsToDisplay = settings.map((setting) => {
        let output = {
          ...setting,
          content_action_label: contentFitOptions
            ?.find((fitOption) => fitOption?.value === setting?.content_action)?.label,
          content_orientation_label: contentOrientationOptions
            ?.find((fitOption) => fitOption?.value === setting?.content_orientation)?.label,
          volume_play_range_type_label: contentVolumeOptions
            ?.find((fitOption) => fitOption?.value === setting?.volume_play_range_type)?.label,
        };

        const findCondition = (val) => val.content_id === setting.content_id
          && val.device_id === setting.device_id
          && (!checkPartition || val.partition_id === setting.partition_id);

        const existingData = tableValue?.find(findCondition) || null;

        if (existingData) {
          output = existingData;
        }

        return output;
      });
    }
    return advanceSettingsToDisplay;
  };

  const makeAdvanceSettingsCall = async (contentResized = false) => {
    let scheduleData = {};
    if (props?.type === 'scheduleAdd') {
      scheduleData = newSchedule?.schedule;
    } else if (props?.type === 'scheduleEdit') {
      scheduleData = newSchedule;
    }
    try {
      const apiParams = {
        contents: props?.data?.contents || scheduleData?.contents,
        devices: props?.data?.devices || scheduleData?.devices,
        device_groups: props?.data?.device_groups || scheduleData?.device_groups,
        playlists: props?.data?.playlists || scheduleData?.playlists,
      };
      if (scheduleData.layouts) {
        apiParams.layout = {
          myContentLayout_id: scheduleData?.layouts?._id,
          device_layouts: scheduleData?.layouts?.device_layouts,
          content_layouts: scheduleData?.layouts?.layouts?.map((eachLayout) => {
            let finalLayout;
            if (
              !(
                eachLayout?.file_types?.[0] === 'weather'
                || eachLayout?.file_types?.[0] === 'clock'
                || eachLayout?.file_types?.[0] === 'rss'
              )
            ) {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: scheduleData?.layouts?.oldLayout
                  ? eachLayout?.contents
                  : eachLayout?.contents
                    ?.filter((content) => content?.file_type !== 'playlist')
                    ?.map((content) => content?._id),
                playlists: scheduleData?.layouts?.oldLayout
                  ? eachLayout?.playlists
                  : eachLayout?.contents
                    ?.filter((content) => content?.file_type === 'playlist')
                    ?.map((content) => content?._id),
              };
            } else {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: eachLayout?.contents,
              };
            }
            return finalLayout;
          }),
        };
      }
      const response = await schedule.advanceSettings(apiParams);
      const advanceSetting = mapAdvanceSettings(
        response?.data?.advanceSettings,
        Object.keys(tableValue)?.includes('layouts'),
        contentResized,
      );
      setTableValue(advanceSetting);
    } catch (errors) {
      dispatch(setErrorNotification(errors?.response?.data || 'An error occurred while fetching advance settings'));
    }
  };

  const openResizingModal = (rowData) => {
    if (rowData?.content_fit === 'oversize' || rowData?.content_fit === 'unsupported') {
      setModalTitle(t('resizeConfirmation'));
      setModalView(<ModalConfirmContent
        setModalOpen={setOpen}
        rowData={rowData}
        makeAdvanceSettingsCall={makeAdvanceSettingsCall}
      />);
      setOpen(true);
    }
  };

  const columns = [
    {
      header: t('signageName'),
      accessorKey: 'device_id',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => row.original?.device_name,
    },
    {
      header: t('resolution'),
      accessorKey: 'content_size_in_pixels' && 'device_resolution',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <>
          Device: {row.original?.device_resolution?.display_width_pixels}
          x {row.original?.device_resolution?.display_height_pixels} <br />
          Content: {row.original?.content_size_in_pixels?.width_in_resolution}
          x {row.original?.content_size_in_pixels?.height_in_resolution}
        </>
      ),
    },
    {
      header: t('content'),
      accessorKey: 'content_name',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
    },
    {
      header: t('sizeFit'),
      accessorKey: 'content_fit',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Header: () => (
        <div className="schedule-status">
          <span>{t('status')}</span>
          <Tooltip title="Content resolution exceeds display resolution">
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      Cell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: row.original?.content_fit !== 'matched' && '#E8212E' }}>
            {capitalize(row.original?.content_fit)}
          </span>
          {!row?.original?.file_type?.includes('video')
            && (
              <div
                label=""
                role="button"
                aria-label="Edit settings"
                tabIndex="0"
                onClick={() => openResizingModal(row.original)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openResizingModal(row.original);
                  }
                }}
                style={{ marginLeft: '15px' }}
              >
                <AdvanceEditIcon
                  color={row.original?.content_fit === 'matched' ? '#9EA3A9' : '#212B36'}
                />
              </div>
            )}
        </div>
      ),
    },
    {
      header: t('apply'),
      accessorKey: 'content_action',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'content_action',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            contentLayout: false,
          }}
          value={row.original?.content_action_label}
          onchange={handleInputChange}
          lists={contentFitOptions}
        />
      ),
    },
    {
      header: t('orientationFit'),
      accessorKey: 'content_orientation',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        let orientationMatch;
        if (row.original?.content_orientation === row.original?.device_orientation) {
          orientationMatch = <span>Matched</span>;
        } else {
          orientationMatch = <span style={{ color: '#E8212E' }}>Mismatch</span>;
        }
        return orientationMatch;
      },
    },
    {
      header: t('dimension'),
      accessorKey: 'content_orientation',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'content_orientation',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            contentLayout: false,
          }}
          value={row.original?.content_orientation_label}
          onchange={handleInputChange}
          lists={contentOrientationOptions}
        />
      ),
    },
    {
      header: t('startTransition'),
      accessorKey: 'start_transition',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'start_transition',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            contentLayout: false,
          }}
          value={row?.original?.start_transition}
          onchange={handleInputChange}
          lists={availableTransitions}
        />
      ),
    },
    {
      header: t('endTransition'),
      accessorKey: 'end_transition',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'end_transition',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            contentLayout: false,
          }}
          value={row?.original?.end_transition}
          onchange={handleInputChange}
          lists={availableTransitions}
        />
      ),
    },
    {
      header: t('videoVolume'),
      accessorKey: 'volume_play_range_type',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => {
        let volumeController = <span>-</span>;
        if (row.original.file_type === 'video/mp4') {
          volumeController = (
            <div>
              <SelectBox
                name={{
                  rowName: 'volume_play_range_type',
                  contentId: row.original?.content_id,
                  deviceId: row.original?.device_id,
                  contentLayout: false,
                }}
                value={row?.original?.volume_play_range_type_label}
                onchange={handleInputChange}
                lists={contentVolumeOptions}
              />
            </div>
          );
        }
        return volumeController;
      },
    },
    {
      header: t('imageDuration'),
      accessorKey: 'duration',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => {
        let durationController = <span>-</span>;
        if (row.original?.file_type !== 'video/mp4') {
          durationController = (
            <Input
              type="number"
              name={{
                rowName: 'duration',
                contentId: row.original?.content_id,
                deviceId: row.original?.device_id,
                contentLayout: false,
              }}
              value={row?.original?.duration || 0}
              change={handleInputChange}
            />
          );
        }
        return durationController;
      },
    },
  ];

  const columnsLayout = [
    {
      header: t('signageName'),
      accessorKey: 'device_id',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => row.original?.device_name,
    },
    {
      header: t('Partition'),
      accessorKey: 'partition_id',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => row.original?.partition_name,
    },
    {
      header: t('resolution'),
      accessorKey: 'content_size_in_pixels' && 'device_resolution',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <>
          Device: {row.original?.device_resolution?.display_width_pixels}
          x {row.original?.device_resolution?.display_height_pixels} <br />
          Content: {row.original?.content_size_in_pixels?.width_in_resolution}
          x {row.original?.content_size_in_pixels?.height_in_resolution}
        </>
      ),
    },
    {
      header: t('content'),
      accessorKey: 'content_name',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
    },
    {
      header: t('sizeFit'),
      accessorKey: 'content_fit',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Header: () => (
        <div className="schedule-status">
          <span>{t('status')}</span>
          <Tooltip title="Content resolution exceeds display resolution">
            <div className="tooltip-container">
              <ViewIcon className="status-view-icon" />
            </div>
          </Tooltip>
        </div>
      ),
      Cell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: row.original?.content_fit !== 'matched' && '#E8212E' }}>
            {capitalize(row.original?.content_fit)}
          </span>
          {!row?.original?.file_type?.includes('video')
            && (
              <div
                label=""
                role="button"
                aria-label="Edit settings"
                tabIndex="0"
                onClick={() => openResizingModal(row.original)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openResizingModal(row.original);
                  }
                }}
                style={{ marginLeft: '15px' }}
              >
                <AdvanceEditIcon
                  color={row.original?.content_fit === 'matched' ? '#9EA3A9' : '#212B36'}
                />
              </div>
            )}
        </div>
      ),
    },
    {
      header: t('apply'),
      accessorKey: 'content_action',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'content_action',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            partitionId: row.original?.partition_id,
            contentLayout: true,
          }}
          value={row.original?.content_action_label}
          onchange={handleInputChange}
          lists={contentFitOptions}
        />
      ),
    },
    {
      header: t('orientationFit'),
      accessorKey: 'content_orientation',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        let orientationMatch;
        if (row.original?.content_orientation === row.original?.device_orientation) {
          orientationMatch = <span>Matched</span>;
        } else {
          orientationMatch = <span style={{ color: '#E8212E' }}>Mismatch</span>;
        }
        return orientationMatch;
      },
    },
    {
      header: t('dimension'),
      accessorKey: 'device_orientation',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'content_orientation',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            partitionId: row.original?.partition_id,
            contentLayout: true,
          }}
          value={row.original?.content_orientation_label}
          onchange={handleInputChange}
          lists={contentOrientationOptions}
        />
      ),
    },
    {
      header: t('startTransition'),
      accessorKey: 'start_transition',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'start_transition',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            partitionId: row.original?.partition_id,
            contentLayout: true,
          }}
          value={row?.original?.start_transition}
          onchange={handleInputChange}
          lists={availableTransitions}
        />
      ),
    },
    {
      header: t('endTransition'),
      accessorKey: 'end_transition',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => (
        <SelectBox
          label=""
          name={{
            rowName: 'end_transition',
            contentId: row.original?.content_id,
            deviceId: row.original?.device_id,
            partitionId: row.original?.partition_id,
            contentLayout: true,
          }}
          value={row?.original?.end_transition}
          onchange={handleInputChange}
          lists={availableTransitions}
        />
      ),
    },
    {
      header: t('videoVolume'),
      accessorKey: 'volume_play_range_type',
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => {
        let volumeController = <span>-</span>;
        if (row.original.file_type === 'video/mp4') {
          volumeController = (
            <div>
              <SelectBox
                name={{
                  rowName: 'volume_play_range_type',
                  contentId: row.original?.content_id,
                  deviceId: row.original?.device_id,
                  partitionId: row.original?.partition_id,
                  contentLayout: true,
                }}
                value={row?.original?.volume_play_range_type_label}
                onchange={handleInputChange}
                lists={contentVolumeOptions}
              />
            </div>
          );
        }
        return volumeController;
      },
    },
    {
      header: t('imageDuration'),
      accessorKey: 'duration',
      enableColumnFilter: false,
      enableColumnDragging: false,
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => {
        let durationController = <span>-</span>;
        if (row.original?.file_type !== 'video/mp4') {
          durationController = (
            <Input
              type="number"
              name={{
                rowName: 'duration',
                contentId: row.original?.content_id,
                deviceId: row.original?.device_id,
                partitionId: row.original?.partition_id,
                contentLayout: true,
              }}
              value={row?.original?.duration || 0}
              change={handleInputChange}
            />
          );
        }
        return durationController;
      },
    },
  ];

  const submit = () => {
    props?.setValue({
      ...props?.value,
      ...props?.data,
      advanceSettings: tableValue,
    });
    props?.closeModal(false);
  };

  useEffect(() => {
    if (props?.type === 'scheduleAdd') {
      setTableValue(newSchedule?.schedule?.advanceSettings);
    } else if (props?.type === 'scheduleEdit') {
      setTableValue(newSchedule?.advanceSettings);
    }

    const fetchData = async () => {
      await makeAdvanceSettingsCall();
    };

    // Fetch advance settings only if it not a advance settings Edit
    if (!props?.advanceSettingEdit) {
      fetchData();
    }
  }, []);

  return (
    <div className="advance-settings-wrap-schedule">
      <div className="advance-setting">
        {(
          newSchedule?.schedule?.layouts || newSchedule?.layouts
            ? (
              <DataTable
                header={columnsLayout}
                value={tableValue?.length ? tableValue : []}
                status="succeed"
                onFilterOrSortingChange={() => { }}
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                totalDataCount=""
                rowSelection={contentSelection}
                setRowSelection={setContentSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable
                height="50vh"
                disableFilter
                disableSorting
                disableFullScreen
                disableFilterButton
                enableGrouping
                groupedColumnMode
                expanded
                grouping={['device_id', 'partition_id']}
                disableFullScreenToggle
              />
            ) : (
              <DataTable
                header={columns}
                value={tableValue?.length ? tableValue : []}
                status="succeed"
                onFilterOrSortingChange={() => { }}
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                totalDataCount=""
                rowSelection={contentSelection}
                setRowSelection={setContentSelection}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                enable
                height="50vh"
                disableFilter
                disableSorting
                disableFullScreen
                disableFilterButton
                enableGrouping
                groupedColumnMode
                expanded
                grouping={['device_id']}
              />
            )
        )}
      </div>
      <div className="advance-settings-bottom">
        <p>Only Image and Video Contents Will be listed in the advance settings</p>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={back} classes="default-button" />
          </div>
          <div className="form-button">
            <Button label={t('applySetting')} click={submit} classes="success-button" />
          </div>
        </div>
      </div>
      <ModalBox status={open} modalView={{ content: modalView, title: modalTitle }} closeModal={closeModal} />
    </div>
  );
}

AdvanceSettings.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.objectOf().isRequired,
  setValue: PropTypes.node.isRequired,
  data: PropTypes.objectOf(PropTypes).isRequired,
  name: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  advanceSettingEdit: PropTypes.bool,
  row: PropTypes.shape({
    index: PropTypes.string.isRequired,
    original: PropTypes.objectOf(),
  }),
};
AdvanceSettings.defaultProps = {
  row: {},
  advanceSettingEdit: false,
};

export default AdvanceSettings;
