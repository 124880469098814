import React from 'react';
import PropTypes from 'prop-types';
import GridView from './GridView';

function GridListView(props) {
  return props?.value.length === 0
    ? (
      <h1 className="text-center">No Data Found</h1>
    )
    : (
      <div className="grid-list">
        {
          props?.value.map((data) => <GridView action={props?.action} value={data} />)
        }
      </div>
    );
}

GridListView.propTypes = {
  value: PropTypes.arrayOf(),
  action: PropTypes.arrayOf(),
};
GridListView.defaultProps = {
  value: {},
  action: [],
};

export default GridListView;
