import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { DeleteIcon, SlickNext, SlickPrev } from '../../../shared/component/svgIcon';

function MultipleFileView(props) {
  const {
    multiContentData,
    setMultiContentData,
  } = props;
  const sliderRef = useRef();
  const dispatch = useDispatch();
  const [sliderIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: 'linear',
    beforeChange: (current, next) => setSlideIndex(next),
    prevArrow: null,
    nextArrow: null,
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handleViewPdf = (pdfFile) => {
    const newTab = window.open(pdfFile, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      dispatch(setErrorNotification({ message: 'Something went wrong.' }));
    }
  };

  // function to remove a single file based on index
  const handleRemoveFile = () => {
    setMultiContentData((prevContents) => prevContents.filter((_, index) => index !== sliderIndex));
  };

  return (
    <div className="multi-content-view-wrap">
      <div
        role="presentation"
        onClick={handlePrev}
        className="prev-btn"
        style={{ display: sliderIndex === 0 ? 'none' : 'block' }}
      >
        <span>
          <SlickPrev />
        </span>
      </div>
      <Slider
        {...settings}
        ref={sliderRef}
        className="multi-content-slider-wrap"
      >
        {multiContentData?.map((content) => (
          <div className="single-content-container">
            {
              (() => {
                let singleContent;
                if (content?.file?.type?.includes('image')) {
                  singleContent = (
                    <img
                      src={URL.createObjectURL(content?.file)}
                      alt=""
                      className="single-content-image"
                    />
                  );
                } else if (content?.file?.type?.includes('video')) {
                  singleContent = (
                    <video
                      src={URL.createObjectURL(content?.file)}
                      controls
                      poster={content?.thumbnail}
                      preload="none"
                      className="single-content-video"
                    >
                      <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                    </video>
                  );
                } else if (content?.file?.type?.includes('pdf')) {
                  singleContent = (
                    <div
                      onClick={() => handleViewPdf(URL.createObjectURL(content?.file))}
                      className="pdf-icon-container"
                      role="presentation"
                    >
                      <img
                        alt="pdf"
                        src={pdfIcon}
                        loading="lazy"
                        role="presentation"
                        className="single-content-pdf"
                      />
                    </div>
                  );
                }
                return singleContent;
              })()
            }
          </div>
        ))}
      </Slider>
      <div
        role="presentation"
        className="next-btn"
        onClick={handleNext}
        style={{ display: sliderIndex === multiContentData.length - 1 ? 'none' : 'block' }}
      >
        <span>
          <SlickNext />
        </span>
      </div>
      <div className="current-slider-count">
        <span>{sliderIndex + 1} / {multiContentData?.length}</span>
      </div>
      <div
        role="presentation"
        className="delete-icon-wrap"
        onClick={handleRemoveFile}
        style={{ display: multiContentData?.length === 1 ? 'none' : 'flex' }}
      >
        <DeleteIcon />
      </div>
    </div>
  );
}

MultipleFileView.propTypes = {
  multiContentData: PropTypes.arrayOf().isRequired,
  setMultiContentData: PropTypes.node.isRequired,
};

export default MultipleFileView;
