import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SelectBox from '../../shared/component/form/SelectBox';
import { InputDate } from '../../shared/component/form/Input';
import ReportsByGender from './components/ReportsByGender';
import ReportsByAge from './components/ReportsByAge';
import ReportsByTime from './components/ReportsByTime';
import Button from '../../shared/component/Button';
import aiInstance from '../../utils/api/ai-instance';
import { fetchDeviceLists } from '../../redux/slices/AiAnalyticsSlice';
import { fetchStoreLocationListAll } from '../../redux/slices/StoreLocationSlice';
import { checkFeatures, secondsToHms } from '../../utils/helpers';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceList } = useSelector((state) => state?.AiAnalytics);
  const { storeLocationAll } = useSelector((state) => state?.store);

  const [value, setValue] = useState({});
  const [time, setTime] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [uploading, setUploading] = useState(false);
  const user = JSON.parse(localStorage.getItem('data'));
  const submit = () => {
    setUploading(true);
    navigate('/admin/subscription');
  };
  const getData = () => {
    const request = {
      device_id: filterData.deviceId,
      store: filterData.locationId,
      date: filterData.start_date ? dayjs(filterData.start_date).format('YYYY-MM-DD') : null,
    };
    Promise.all([
      aiInstance.genderCount(request),
      aiInstance.getAgeCount(request),
      aiInstance.getTimeCount(request),
    ]).then((values) => {
      setValue((prev) => ({
        ...prev,
        women: values[0]?.data.length > 0 ? values[0]?.data[0].womenCount : 0,
        men: values[0]?.data.length > 0 ? values[0]?.data[0].menCount : 0,
        ageGroup: values[1]?.data,
        ...values[2]?.data,
      }));
    }).catch((error) => {
      dispatch(setErrorNotification(error));
    });
  };
  const handleFunction = (name, changeValue) => {
    if (name === 'location') {
      setFilterData({ ...filterData, [name]: changeValue?.label, locationId: changeValue?.id });
    } else if (name === 'device') {
      setFilterData({ ...filterData, [name]: changeValue?.label, deviceId: changeValue?.id });
    } else {
      setFilterData({ ...filterData, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (deviceList.length === 0) {
      dispatch(fetchDeviceLists());
    }
    if (storeLocationAll.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
    if (checkFeatures('al_analytics', 'ai_instance')) {
      aiInstance.instanceCount()
        .then((res) => {
          setValue((prev) => ({
            ...prev,
            instanceActive: res.data,
          }));
        })
        .catch((error) => {
          dispatch(setErrorNotification(error));
        });
    }
  }, []);

  useEffect(() => {
    if (value?.ageGroup?.length > 0 || value?.men > 0) {
      setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    }
  }, [value]);

  useEffect(() => {
    getData();
  }, [filterData]);
  return (
    <div className="ai-dashboard">
      <div className={`ai-dashboard-container ${value?.instanceActive?.active_count === 0 ? 'not-access' : ''}`}>
        <div className="top-content">
          <h1 className="title">{t('welcomeBack')} {user?.user?.name}</h1>
          <div className="filters">
            <p className="filter-label">{t('filterBy')}: </p>
            <div className="select-boxes">
              <SelectBox
                name="location"
                lists={storeLocationAll}
                value={filterData?.location}
                onchange={handleFunction}
                placeholder={t('select')}
                id="location"
                customPadding="0px 4px 0px 5px"
                disableClearable
              />
              <SelectBox
                name="device"
                lists={deviceList}
                value={filterData?.device}
                onchange={handleFunction}
                placeholder={t('select')}
                id="location"
                customPadding="0px 4px 0px 5px"
                disableClearable
              />
              <InputDate
                name="start_date"
                value={filterData?.start_date}
                change={handleFunction}
                customPadding="8px 0px 8px 7px"
              />
            </div>
          </div>
        </div>
        <div className="chart-container">
          <ReportsByGender
            value={value}
            time={secondsToHms(time)}
          />
          <ReportsByAge
            value={value}
            time={secondsToHms(time)}
          />
        </div>
        <div className="time-chart-container">
          <ReportsByTime
            value={value}
            time={secondsToHms(time)}
          />
        </div>
      </div>
      {value?.instanceActive?.active_count === 0 && (
        <div className="empty-state">
          <div className="empty-state-wrap">
            <p>{t('youCantUseThisFeatureRightNowPleaseUpgradeToGainAccess')}</p>
            <Button
              label={uploading ? t('loading') : t('buyNow')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
