import React, { useState } from 'react';
import { useLocation } from 'react-router';
import cookies from 'js-cookie';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/images/logo.png';

import UserForm from './components/userForm';
import CompanyForm from './components/companyForm';
import EmailValidation from './components/validation';

import { WorldIcon } from '../../shared/component/svgIcon';
import DropDownMenu from '../../shared/component/DropDownMenu';

function Register() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [formStage, setFormStage] = useState(location?.state?.formStage || 1);
  const [formData, setFormData] = useState();
  const [subTitle, setSubtitle] = useState(
    <>{t('letGetYouToCreate')} <br /> {t('startsByCreating')}</>,
  );

  const handleLanguage = (code) => {
    i18next.changeLanguage(code);
    window.location.reload();
  };

  const menuList = [
    {
      code: 'en',
      name: 'English',
      function: handleLanguage,
      disabled: currentLanguageCode === 'en',
    },
    {
      code: 'ar',
      name: 'عربي (Arabic)',
      function: handleLanguage,
      disabled: currentLanguageCode === 'ar',
    },
  ];

  return (
    <div className="register-form-wrap">
      <div className="register-form">
        <img src={logo} alt="Logo" className="register-logo" />
        <div className="form-header">
          <h1>{t('createYourAccount')}</h1>
          <p>{subTitle}</p>
        </div>
        {formStage === 1
          && (
            <EmailValidation
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
              setSubtitle={setSubtitle}
            />
          )}
        {formStage === 2
          && (
            <UserForm
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
            />
          )}
        {formStage === 3
          && (
            <CompanyForm
              formData={formData}
              setFormData={setFormData}
              setFormStage={setFormStage}
              page={location?.state?.page}
            // user_email={location?.state?.user_email}
            />
          )}

      </div>
      <div className={currentLanguageCode === 'ar'
        ? 'language-wrap-absolute-ar'
        : 'language-wrap-absolute'}
      >
        <p>{currentLanguageCode === 'en' ? 'English' : 'عربي '}</p>
        <DropDownMenu
          dropdown="dropdown"
          icon={<WorldIcon />}
          action={menuList}
          tooltip={t('language')}
        />
      </div>
    </div>
  );
}

export default Register;
