/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import cookies from 'js-cookie';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import FileFormat from '../FileFormat';
import PopupBox from '../ContentUploadConfirmation';
import { interRegular } from '../../../../assets/fonts/base64Fonts';

function FlashTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [disable, setDisable] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguageCode = cookies?.get('i18next') || 'en';
  const htmlRef = useRef(null);

  const cssStyles = `
.flash-template {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-left: 20px;
  overflow: hidden;
}

.list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.items {
  display: flex;
  align-items: center;
}

.item-image {
  width: 150px;
  height: 108px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.right-text {
  display: flex;
  align-items: center;
}

.right-text-wrapper {
  width: 174px;
  text-align: center;
}

.right-image-wrapper {
  position: absolute;
  z-index: -1;
  width: 195px;
  height: 238px;
  overflow: hidden;
}

.right-image {
  width: 100%;
  height: 100%;
}
  `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        htmlContent={htmlContent}
        setNotification={setNotification}
        fileType={fileType}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const backgroundImage = data?.[0]?.template_backgroundImage_url?.[0];
  const backgroundColor = data?.[0]?.styles?.backgroundColor;
  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <style>
        {`
         @font-face {
          font-family: 'Inter';
          src: url('data:font/truetype;charset=utf-8;base64,${interRegular}') format('truetype');
          font-weight: 400;
         }
         @font-face {
          font-family: 'Inter';
          src: url('data:font/truetype;charset=utf-8;base64,${interRegular}') format('truetype');
          font-weight: 600;
         }
        `}
      </style>
      <div className="template-container-7">
        <div className="inner-container">
          <div
            className="flash-template"
            ref={htmlRef}
            style={{ background: `${backgroundColor}` }}
          >
            <div className="list-container">
              <div className="list-items">
                {contentDetails?.map((item) => (
                  <div className="items">
                    {item?.image
                      && (
                        <img src={item?.image} alt="" className="item-image" />
                      )}
                    <div className="text-wrapper">
                      <span style={{
                        fontSize: `${styles?.[1]?.fontSize}`,
                        color: `${styles?.[1]?.color}`,
                        fontFamily: `${styles?.[1]?.fontFamily}`,
                        textDecoration: 'line-through',
                      }}
                      >
                        MRP {item?.mrp_price}
                      </span>
                      <span style={{
                        fontSize: `${styles?.[2]?.fontSize}`,
                        color: `${styles?.[2]?.color}`,
                        fontFamily: `${styles?.[2]?.fontFamily}`,
                        fontWeight: '600',
                      }}
                      >
                        Offer Price {item?.offer_price}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="right-text">
                <div
                  className="right-text-wrapper"
                  style={{
                    marginLeft: currentLanguageCode === 'en' ? '30px' : '',
                    marginRight: currentLanguageCode === 'ar' ? '30px' : '',
                  }}
                >
                  <span style={{
                    fontSize: `${styles?.[0]?.fontSize}`,
                    color: `${styles?.[0]?.color}`,
                    fontFamily: `${styles?.[0]?.fontFamily}`,
                    fontWeight: '700',
                  }}
                  >
                    {data?.[0]?.template_title}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="right-image-wrapper"
              style={{
                right: currentLanguageCode === 'ar' ? '' : '0',
                left: currentLanguageCode === 'ar' ? '0' : '',
                transform: currentLanguageCode === 'ar' ? 'rotate(180deg)' : '',
              }}
            >
              <img src={backgroundImage} alt="" className="right-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FlashTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default FlashTemplate;
