import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';

function ReportTotalCount({ gender, countData, filterFunction }) {
  const barColor = gender === 'women' ? '#EC4899' : 'rgb(1, 149, 247)';
  const { t } = useTranslation();
  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('timePeriod'),
      accessorKey: 'time_range',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('ageGroup'),
      accessorKey: 'gender',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
    },
    {
      header: t('totalCount'),
      accessorKey: 'count',
      enableColumnActions: false,
      enableResizing: false,
    },
  ];

  const exportData = () => {
    const columnHeaders = ['Time Period', 'Age Group', 'Total Count'];
    const returnData = countData?.count.map((val) => ({
      'Time Period': val.time_range,
      'Age Group': val.gender,
      'Total Count': val.count,
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };
  return (
    <div className="report-user-total-count">
      <div className="bar-chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={400}
            data={countData?.chartCount}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: '#717880', fontSize: 13 }} />
            <YAxis tick={{ fill: '#717880', fontSize: 13 }} />
            <Tooltip />
            <Bar
              dataKey="value"
              fill={barColor}
              barSize={30}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData?.count || []}
          status="success"
          onFilterOrSortingChange={filterFunction}
          totalPageCount={1}
          pageCount={1}
          rowSelection={[]}
          scrollData={() => { }}
          exportData={exportData}
          totalDataCount={10}
          exportButton
        />
      </div>
    </div>
  );
}
ReportTotalCount.propTypes = {
  gender: PropTypes.string.isRequired,
  filterFunction: PropTypes.func.isRequired,
  countData: PropTypes.objectOf(PropTypes.arrayOf),
};
ReportTotalCount.defaultProps = {
  countData: {
    count: [],
    chartCount: [],
  },
};
export default ReportTotalCount;
