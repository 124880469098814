/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import TopContent from '../../../shared/component/TopContent';
import { Input, InputDate, InputTime } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import ModalBox from '../../../shared/component/ModalBox';
import ContentsList from './ContentsList';
import SelectBox from '../../../shared/component/form/SelectBox';
import DeviceList from './DeviceList';
import RepeatForm from './RepeatForm';
import { AdvanceEditIcon } from '../../../shared/component/svgIcon';
import AdvanceSettings from './AdvanceSetting';
import timeZonesList from '../../../assets/json/time-zone.json';

import PreviewContent from './PreviewContent';

import scheduleApi from '../../../utils/api/schedule';
import {
  changeDateTimeFormat,
  checkFeatures,
  checkPermission,
  convertBytes,
} from '../../../utils/helpers';

import {
  addNewSchedule,
  fetchCalendarScheduleList,
  fetchScheduleList,
  formatScheduleData,
  setFilter,
  setTableFilter,
  updateSchedule,
} from '../../../redux/slices/ScheduleSlice';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ContentOptions from './ContentOptions';
import SavedLayouts from './layoutSchedule/SavedLayouts';
import { resetScheduleDisplaySignageState } from '../../../redux/slices/ScheduleDisplaySignageSlice';
import { resetScheduleDisplayGroupState } from '../../../redux/slices/ScheduleDisplayGroupSlice';

function ScheduleEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const week = useSelector((state) => state.schedules.week);
  const devicesData = useSelector((state) => state.display.displaySignage);
  const devicesGroupData = useSelector((state) => state.displayGroup.displayGroup);
  const { schedules, newSchedule } = useSelector((state) => state.schedules);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [selectContents, setSelectContents] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [repeatDisable, setRepeatDisable] = useState(true);
  const [repeatText, setRepeatText] = useState(t('doesNotRepeat'));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [existingScheduleData, setExistingScheduleData] = useState({});
  const [value, setValue] = useState({
    playlists: [],
    playlistDetails: [],
    device_list: [],
    device_groups_list: [],
    contents: [],
    contentsDetails: [],
    devices: [],
    device_groups: [],
    repeat_days: [],
    repeat: false,
    time_zone: '',
  });
  const [date, setDate] = useState({});

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);

  let backClick;

  const addSchedule = () => {
    navigate('../');
  };
  const sortedArray = week.slice().sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date));
  const submit = () => {
    // Split the date and time strings and create Date objects
    const startDuration = new Date(`${value.start_date}T${value.start_time}`);
    const endDuration = new Date(`${value.end_date}T${value.end_time}`);

    // Check if start date and time is greater than or equal to end date and time
    if (startDuration.getTime() < endDuration.getTime()) {
      setDisable(true);
      setUploading(true);
      const request = {
        name: value.name,
        repeat: value.repeat,
        start_only_date: value.start_date,
        end_only_date: value.end_date,
        start_time: value.start_time,
        end_time: value.end_time,
        time_zone: value.time_zone,
        description: (value?.description || value?.description === '')
          ? value?.description
          : undefined,
        special: value.special,
        contents: value?.layouts?._id ? [] : value.contents,
        devices: value.devices,
        device_groups: value.device_groups,
        playlists: value?.layouts?._id ? [] : value.playlists,
      };

      if (value.repeat) {
        request.recurrences = {
          interval: value.repeat_count,
          frequency: value.repeat_type,
          start_only_date: value.started_date,
          end_only_date: value.complete_date,
        };

        if (value.repeat_type === 'WEEKLY') {
          request.recurrences.by_week_day = value.repeat_days;
        }
      }

      if (value.layouts) {
        request.layout = {
          myContentLayout_id: value?.layouts?._id,
          device_layouts: value?.layouts?.device_layouts,
          content_layouts: value?.layouts?.layouts?.map((eachLayout) => {
            let finalLayout;
            if (!(eachLayout?.file_types?.[0] === 'weather'
              || eachLayout?.file_types?.[0] === 'clock'
              || eachLayout?.file_types?.[0] === 'rss')) {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: value?.layouts?.oldLayout
                  ? eachLayout?.contents
                  : eachLayout?.contents
                    ?.filter((content) => content?.file_type !== 'playlist')
                    ?.map((content) => content?._id),
                playlists: value?.layouts?.oldLayout
                  ? eachLayout?.playlists
                  : eachLayout?.contents
                    ?.filter((content) => content?.file_type === 'playlist')
                    ?.map((content) => content?._id),
              };
            } else {
              finalLayout = {
                _id: eachLayout?._id,
                name: eachLayout?.name,
                grid_column_start: eachLayout?.grid_column_start,
                grid_column_end: eachLayout?.grid_column_end,
                grid_row_start: eachLayout?.grid_row_start,
                grid_row_end: eachLayout?.grid_row_end,
                partition_id: eachLayout?.partition_id,
                file_types: eachLayout?.file_types,
                contents: eachLayout?.contents,
              };
            }
            return finalLayout;
          }),
        };
      }
      if (value?.advanceSettings) {
        request.advance_settings = newSchedule?.advanceSettings.map((setting) => ({
          content_id: setting.content_id,
          playlist: setting.playlist,
          device_id: setting.device_id,
          device_group: setting.device_group,
          partition_id: setting.partition_id,
          partition_name: setting.partition_name,
          content_action: setting.content_action,
          content_fit: setting.content_fit,
          content_size_in_pixels: setting.content_size_in_pixels,
          required_size_in_pixels: setting.required_size_in_pixels,
          content_orientation: setting.content_orientation,
          duration: setting.duration,
          volume: setting.volume,
          volume_play_range_type: setting.volume_play_range_type,
          volume_play_range: setting.volume_play_range,
          start_transition: setting.start_transition,
          end_transition: setting.end_transition,
        }));
      }

      if (searchParams.get('type') === 'clone') {
        scheduleApi.addSchedule(request).then(() => {
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            setDisable(false);
            navigate('../');
            dispatch(fetchScheduleList({ page: 1 }));
            dispatch(setFilter({}));
            dispatch(setTableFilter({}));
          }, 3000);
        }).catch((errors) => {
          setUploading(false);
          setError(errors?.response?.data?.message);
          setDisable(false);
          dispatch(setErrorNotification(errors?.response?.data));
        });
      } else {
        scheduleApi.updateSchedule(request, value.id).then((res) => {
          dispatch(updateSchedule(res?.data));
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            setDisable(false);
            // dispatch(setFilter({}));
            // dispatch(setTableFilter({}));
            navigate('../');
          }, 3000);
          if (sortedArray[0]?.start_date) {
            dispatch(fetchCalendarScheduleList({
              start_date: sortedArray[0]?.start_date,
              end_date: sortedArray[sortedArray.length - 1]?.end_date,
              status: 'add',
            }));
          }
        }).catch((errors) => {
          setError(errors?.response?.data?.message);
          setDisable(false);
          setUploading(false);
          dispatch(setErrorNotification(errors?.response?.data));
        });
      }
    } else {
      dispatch(setErrorNotification({ message: 'Enter a valid schedule time' }));
    }
  };

  const selectData = (name, data) => {
    if (name === 'time_zone') {
      if (data) {
        setValue((prevState) => ({
          ...prevState,
          time_zone: data.id,
        }));
      }
    } else if (name === 'devices' || name === 'contents') {
      setValue((prevState) => ({
        ...prevState,
        ...data,
      }));
    } else if (name === 'start_date' || name === 'end_date') {
      setDate((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      setValue((prevState) => ({
        ...prevState,
        [name]: dayjs(data).format('YYYY-MM-DD'),
      }));
    } else if (name === 'start_time' || name === 'end_time') {
      setDate((prevState) => ({
        ...prevState,
        [name]: data,
      }));

      // Formatting time
      const hour = data?.$H;
      const minute = data?.$m;
      const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
      setValue((prevState) => ({
        ...prevState,
        [name]: formattedTime,
      }));
    } else {
      setValue((prevState) => ({
        ...prevState,
        [name]: data,
      }));
    }
  };

  const backModal = (name, data) => {
    backClick(name, data);
  };

  const advanceSetting = async (name, data) => {
    selectData(name, data);

    setModalView({
      content: <AdvanceSettings
        type="scheduleEdit"
        value={value}
        setValue={setValue}
        data={data}
        name={name}
        closeModal={setOpen}
        selectData={selectData}
        values={value}
        back={backModal}
      />,
      title: (t('advanceSetting')),
    });
  };

  const viewData = (name, data) => {
    const selectedData = (name === 'devices' || (name === 'contents' && selectDevices?.length > 0))
      ? advanceSetting
      : selectData;

    setModalView({
      content: (
        <PreviewContent
          data={data}
          closeModal={setOpen}
          name={name}
          selectData={selectedData}
          values={value}
          back={backModal}
        />
      ),
      title: 'Contents',
    });
  };

  const setRepeated = (repValues) => {
    setValue({ ...value, ...repValues, repeat: true });
    setMessage(
      repValues.repeat_type === 'DAILY'
        ? `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
day starting ${repValues.started_date} until ${repValues.complete_date}`
        : `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
week ${repValues?.repeat_days} starting ${repValues.started_date} until ${repValues.complete_date}`,
    );
    setOpen(false);
  };

  const openModalContent = (name = 'contents', data = {}) => {
    const oldData = Object.keys(data)?.length !== 0 ? data : value;
    setModalView({
      content: <ContentsList selectData={viewData} closeModal={setOpen} values={oldData} name={name} />,
      title: 'Contents',
    });
    setOpen(true);
  };

  const openModalRepeat = () => {
    setModalView({
      content: <RepeatForm
        closeModal={setOpen}
        setRepeated={setRepeated}
        startDate={value}
      />,
      title: 'Repeat',
    });
    setOpen(true);
  };

  const removeContentsTags = (name, key) => {
    if (key.type === 'contents' || key.type === 'playlists') {
      const contentsList = value[key.type].filter((e) => e !== key.id);
      let detail = '';
      let oldValue = [];
      let updateData;
      if (key.type === 'contents') {
        detail = 'contentsDetails';
        oldValue = value.contentsDetails.filter((content) => content.id !== key.id);
        updateData = newSchedule?.schedule?.advanceSettings
          .filter((schedule) => schedule.content_id !== key.id);
      } else if (key.type === 'playlists') {
        detail = 'playlistDetails';
        oldValue = value.playlistDetails.filter((content) => content.id !== key.id);
        updateData = newSchedule?.schedule?.advanceSettings
          .filter((schedule) => schedule.playlist !== key.id);
      }
      setValue(
        {
          ...value, [key.type]: contentsList, [detail]: oldValue, advanceSettings: updateData,
        },
      );
    } else if ('layouts' in value && key.type === 'layout') {
      const { layouts: _, ...newValue } = value;
      setValue({ ...newValue, advanceSettings: [] });
      setSelectContents((prevValue) => [
        ...prevValue.filter((content) => content.type === 'layout'),
      ]);
    } else {
      const deviceList = value[key.type].filter((e) => e !== key.id);
      let detail = '';
      let oldValue = [];
      let updateData;
      if (key.type === 'devices') {
        detail = 'devices_list';
        oldValue = value.devices_list.filter((content) => content.id !== key.id);
        updateData = newSchedule?.schedule?.advanceSettings
          .filter((schedule) => schedule.device_id !== key.id);
      } else if (key.type === 'device_groups') {
        detail = 'device_groups_list';
        oldValue = value.device_groups_list.filter((content) => content.id !== key.id);
        updateData = newSchedule?.schedule?.advanceSettings
          .filter((schedule) => schedule.device_group !== key.id);
      }
      setValue(
        {
          ...value, [key.type]: deviceList, [detail]: oldValue, advanceSettings: updateData,
        },
      );
    }
  };

  const openSavedLayouts = () => {
    setModalView({
      title: 'Saved Layouts',
      content: <SavedLayouts
        closeModal={setOpen}
        type={searchParams.get('type') === 'edit' ? 'scheduleEdit' : 'scheduleClone'}
      />,
    });
    setOpen(true);
  };

  const openContentOptions = () => {
    if (selectContents?.length === 0) {
      setModalView({
        title: 'Confirmation',
        content: (
          <ContentOptions
            openSavedLayouts={openSavedLayouts}
            openModalContent={openModalContent}
            closeModal={setOpen}
          />
        ),
      });
      setOpen(true);
    } else {
      openModalContent();
    }
  };

  const openModalDevice = (name = 'device', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView({
      content: <DeviceList
        selectData={viewData}
        closeModal={setOpen}
        values={oldData}
        name={name}
        layout={value?.layouts}
        contents={value?.contentsDetails}
        playlists={value?.playlistDetails}
      />,
      title: 'Device Contents',
    });
    setOpen(true);
  };

  backClick = (name, data) => {
    if (name === 'contents' || name === 'playlists') {
      openModalContent(name, data);
    } else if (name === 'devices' || name === 'device_groups') {
      openModalDevice(name, data);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const setScheduleValue = (schedulesData1, type) => {
    const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
    setTimeZone(timeZones);
    if (schedulesData1) {
      setValue({
        ...schedulesData1,
        name: type === 'edit' ? schedulesData1?.name : undefined,
        recurrences: type === 'edit' && schedulesData1?.recurrences,
        description: schedulesData1?.description,
        start_date: schedulesData1.start_only_date,
        end_date: schedulesData1.end_only_date,
        special: schedulesData1.special,
        contents: schedulesData1.contents && schedulesData1.contents.map((val) => val?.id),
        contentsDetails: schedulesData1.contents && schedulesData1.contents,
        playlists: schedulesData1.playlists && schedulesData1.playlists.map((val) => val.id),
        playlistDetails: schedulesData1.playlists && schedulesData1.playlists.map((val) => ({
          ...val,
          author: val.createdBy?.name,
          createDate: changeDateTimeFormat(val.createdAt),
          updateDate: changeDateTimeFormat(val.updatedAt),
          totalFileSize: convertBytes(val.totalFileSize),
        })),
        started_date: schedulesData1?.recurrences?.start_only_date,
        repeat_count: schedulesData1?.recurrences?.interval,
        repeat_type: schedulesData1?.recurrences?.frequency,
        complete_date: schedulesData1?.recurrences?.end_only_date,
        repeat_days: schedulesData1?.recurrences && schedulesData1?.recurrences?.frequency !== 'DAILY'
          ? schedulesData1?.recurrences?.by_week_day
          : [],
        all_contents: schedulesData1.contents
          ? schedulesData1.contents.map((value1) => ({ ...value1, type: 'contents' }))
          : [],
        all_playlists: schedulesData1.playlists
          ? schedulesData1.playlists.map((value1) => ({ ...value1, type: 'playlists' }))
          : [],
        all_devices: schedulesData1.devices_list
          ? schedulesData1.devices_list.map((value1) => ({ ...value1, type: 'devices' }))
          : [],
        all_device_groups: schedulesData1.device_groups_list
          ? schedulesData1.device_groups_list.map((value1) => ({ ...value1, type: 'device_groups' }))
          : [],
      });
      if (type === 'edit') {
        setDate({
          start_date: dayjs(schedulesData1.start_only_date),
          end_date: dayjs(schedulesData1.end_only_date),
          start_time: dayjs(`2022-12-04T${schedulesData1.start_time}`),
          end_time: dayjs(`2022-12-04T${schedulesData1.end_time}`),
        });
        if (schedulesData1?.recurrences?.repeat) {
          setMessage(`Occurs every day starting 
        ${schedulesData1?.recurrences?.start_only_date} until 
        ${schedulesData1?.recurrences?.end_only_date}`);
        }
      }
    }
  };

  useEffect(() => {
    setSearchParams(searchParams);
    if (schedules?.length === 0 && !newSchedule?._id) {
      // Need to write API call here
      scheduleApi.getSingleSchedule(params?.id)
        .then((res) => {
          setExistingScheduleData(formatScheduleData(res?.data));
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else if (newSchedule?._id) {
      setExistingScheduleData(newSchedule);
    } else {
      setExistingScheduleData(schedules?.find((schedule) => schedule?.id === params?.id));
    }
  }, []);

  useEffect(() => {
    if (date.start_date && date.start_time && date.end_date && date.end_time && value.name && value.time_zone) {
      if (
        (value.contents.length > 0
          || value.playlists.length > 0
          || Object.keys(value)?.includes('layouts'))
        && (value.devices.length > 0 || value.device_groups.length > 0)
      ) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
    let singleDevice = [];
    let singleDevicesGroup = [];
    const device = devicesData.length > 0
      ? devicesData.filter((e) => value.devices.includes(e.id))
      : value?.all_devices?.filter((e) => value.devices.includes(e.id));
    singleDevice = device ? device?.map((value1) => ({ ...value1, type: 'devices' })) : [];
    const deviceGroup = devicesGroupData.length > 0
      ? devicesGroupData.filter((e) => value.device_groups.includes(e.id))
      : value?.all_device_groups?.filter((e) => value.device_groups.includes(e.id));
    singleDevicesGroup = deviceGroup ? deviceGroup?.map((value1) => ({ ...value1, type: 'device_groups' })) : [];
    if (Object.keys(value)?.includes('layouts') && value?.layouts !== null) {
      setSelectContents([
        {
          id: value?.layouts?._id,
          name: value?.layouts?.name,
          type: 'layout',
        },
      ]);
    } else {
      setSelectContents([
        ...(value?.contentsDetails?.map((value1) => ({ ...value1, type: 'contents' })) || []),
        ...(value?.playlistDetails?.map((value1) => ({ ...value1, type: 'playlists' })) || []),
      ]);
    }
    setSelectDevices([...singleDevice, ...singleDevicesGroup]);
    dispatch(addNewSchedule({ ...value }));
  }, [value]);

  useEffect(() => {
    if (Object.keys(existingScheduleData).length > 0) {
      setScheduleValue(existingScheduleData, searchParams.get('type'));
    }
  }, [existingScheduleData]);

  const openAdvanceModal = () => {
    setModalView({
      content: <AdvanceSettings
        type="scheduleEdit"
        value={value}
        setValue={setValue}
        closeModal={setOpen}
        advanceSettingEdit
      />,
      title: (t('advanceSetting')),
    });
    setOpen(true);
  };

  useEffect(() => {
    if (date.start_date && date.start_time && date.end_date && date.end_time) {
      const now = `${value.start_date} ${value.start_time}`;
      const then = `${value.end_date} ${value.end_time}`;
      const startTime = new Date(now).getTime();
      const endTime = new Date(then).getTime();
      const timeDifference = Math.abs(endTime - startTime);
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      if (hours <= 23) {
        setRepeatDisable(false);
        setRepeatText(t('repeat'));
      } else {
        setValue({ ...value, repeat: false });
        setRepeatDisable(true);
        setRepeatText(() => t('doesNotRepeat'));
      }
    }
  }, [date]);

  useEffect(() => {
    dispatch(resetScheduleDisplaySignageState());
    dispatch(resetScheduleDisplayGroupState());
  }, [selectContents]);
  return (
    <div className="content">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={{ content: <Success message={t('scheduleUpdatedSuccess')} /> }}
        notification
      />
      <div className="main-content">
        <TopContent
          label={[t('schedule'), value?.name]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addSchedule}
        />
        <ModalBox status={open} modalView={modalView} closeModal={closeModal} />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              {error && <p className="text-danger message">{error}</p>}
              <div className="schedule-form">
                <div className="form-field-row">
                  <Input
                    icon={' '}
                    label={t('scheduleName')}
                    type="text"
                    name="name"
                    placeholder={t('enterScheduleName')}
                    required
                    value={value.name}
                    change={selectData}
                  />
                  <SelectBox
                    label={t('timeZone')}
                    name="time_zone"
                    required
                    value={value.time_zone}
                    onchange={selectData}
                    lists={timeZone}
                  />
                </div>
                <div className="form-field">
                  <Input
                    icon={' '}
                    label={t('description')}
                    type="text"
                    name="description"
                    placeholder={t('enterScheduleDescription')}
                    required={false}
                    value={value.description}
                    change={selectData}
                  />
                </div>
                <div className="form-field-row">
                  <div className="form-field-1">
                    <InputDate
                      label={t('scheduleFrom')}
                      type="date"
                      name="start_date"
                      required
                      value={date.start_date}
                      change={selectData}
                    />
                    <InputTime
                      label={t('startTime')}
                      type="time"
                      name="start_time"
                      required
                      value={date.start_time}
                      disabled={date.start_date === undefined}
                      change={selectData}
                    />
                  </div>
                  <div className="form-field-1">
                    <InputDate
                      label={t('scheduleTill')}
                      type="date"
                      name="end_date"
                      required
                      value={date.end_date}
                      change={selectData}
                      disabled={date.start_date === undefined || date.start_time === undefined}
                      minDate={date.start_date}
                    />
                    <InputTime
                      label={t('endTime')}
                      type="time"
                      name="end_time"
                      required
                      value={date.end_time}
                      change={selectData}
                      disabled={
                        date.start_date === undefined
                        || date.start_time === undefined
                        || date.end_date === undefined
                      }
                    />
                  </div>
                </div>
                {
                  checkPermission('repeatSchedule') && (
                    <div className="form-field-button">
                      <Button
                        label={repeatText}
                        classes="info-button"
                        click={openModalRepeat}
                        disabled={repeatDisable || !checkFeatures('schedule', 'repeat_schedule')}
                      />
                    </div>
                  )
                }
                <div className="form-select-field info-text">{message}</div>
                <div className="form-select-field">
                  <MultiSelectBox
                    label={t('targetContent')}
                    buttonLabel={t('select')}
                    clickButton={openContentOptions}
                    required
                    name="contents"
                    addData={removeContentsTags}
                    placeholder={t('selectTargetContent')}
                    value={selectContents}
                    disabled={Object.keys(value)?.includes('layouts') && value?.layouts !== null}
                  />
                </div>
                <div className="form-select-field">
                  <MultiSelectBox
                    label={t('targetDevice')}
                    buttonLabel={t('selectDevice')}
                    clickButton={openModalDevice}
                    required
                    name="devices"
                    addData={removeContentsTags}
                    placeholder={t('selectTargetDevice')}
                    value={selectDevices}
                    disabled={selectContents?.length === 0}
                  />
                </div>
                {newSchedule?.advanceSettings?.length > 0 || newSchedule?.contents?.length > 0 ? (
                  <div>
                    <button
                      onClick={openAdvanceModal}
                      style={{ border: 'none', fontSize: '14px', background: 'none' }}
                      className="add-button borderless-button"
                      type="submit"
                    >
                      <AdvanceEditIcon className="add-button borderless-button" />
                      <span>Advanced Settings</span>
                    </button>
                  </div>
                ) : null}

              </div>
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={addSchedule} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={searchParams.get('type') === 'clone' ? t('create') : t('update')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
          <ModalBox status={open} modalView={modalView} closeModal={closeModal} />
        </div>
      </div>
    </div>
  );
}

export default ScheduleEdit;
