import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../shared/component/Button';
import { contentUpload, setSingleContent } from '../../../redux/slices/TemplateSlice';
import myContent from '../../../utils/api/my-content';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PopupBox({ setOpenContentUploadConfirmation, setNotification }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);

  const submitOverwrite = () => {
    myContent?.getSingleContent(templateData?.content_id).then((res) => {
      dispatch(setSingleContent(res?.data));
      dispatch(contentUpload('update'));
      setNotification(true);
      setOpenContentUploadConfirmation(false);
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };
  const submitNewContent = () => {
    dispatch(setSingleContent({}));
    dispatch(contentUpload('create'));
    setNotification(true);
    setOpenContentUploadConfirmation(false);
  };
  return (
    <div className="publish-or-overwrite-box">
      <div className="confirm-upload-box">
        {t('doYouWantToOverwriteExistingContentOrPublishAsNew ?')}
      </div>
      <div className="publish">
        <Button
          label={t('Overwrite existing')}
          click={submitOverwrite}
          classes="default-button"
        />
        <Button
          label={t('Publish as new')}
          click={submitNewContent}
          classes="success-button"
        />
      </div>
    </div>
  );
}

PopupBox.propTypes = {
  setOpenContentUploadConfirmation: PropTypes.node.isRequired,
  setNotification: PropTypes.node.isRequired,
};
export default PopupBox;
