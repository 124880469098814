import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import SelectBox from '../../../shared/component/form/SelectBox';

import devices from '../../../utils/api/devices';

import { fetchStoreLocationListAll } from '../../../redux/slices/StoreLocationSlice';
import { createNewDisplay } from '../../../redux/slices/DisplaySignageSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { guidValidation, nameValidation } from '../../../shared/component/form/Validation';

function DisplaySignageAddNew(props) {
  const { close, submitDisplay } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store.storeLocationAll);

  const [value, setValue] = useState({});
  const [stores, setStores] = useState([]);
  const [disable, setDisable] = useState(true);

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 50, value?.name)
      && guidValidation(value?.device_system_id)
    ) {
      setDisable(true);
      setUploading(true);
      const data = {
        device_system_id: value.device_system_id,
        name: value.name,
        store: value.store,
      };
      devices.addDevice(data).then((res) => {
        dispatch(createNewDisplay(res?.data));
        submitDisplay(t('displaySignageUpdateSuccess'));
      }).catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'store') {
      if (changeValue) {
        setValue({ ...value, [name]: changeValue.id, store_name: changeValue.label });
      }
    } else {
      setValue({ ...value, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (storeData.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
    if (stores.length === 0) {
      const store = storeData.map((val) => ({ label: val.name, id: val.id }));
      setStores(store);
    }
    if (value.store && value.name && value.device_system_id) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [storeData, value]);

  return (
    <div className="signage-form-wrap">
      <div className="signage-form">
        <div className="input-text-area">
          <SelectBox
            label={t('storeName')}
            name="store"
            required
            value={value.store_name}
            onchange={changeHandle}
            lists={stores}
          />
        </div>
        <div className="input-text-area">
          <Input
            icon={' '}
            label={t('deviceName')}
            type="text"
            name="name"
            required
            value={value?.name}
            change={changeHandle}
            error={!nameValidation(1, 50, value?.name) && submitButtonClicked}
            errorMessage={t('deviceNameValidation')}
          />
        </div>
        <div className="input-text-area">
          <Input
            icon={' '}
            label={t('deviceID')}
            type="text"
            name="device_system_id"
            required
            value={value?.device_system_id}
            change={changeHandle}
            error={!guidValidation(value?.device_system_id) && submitButtonClicked}
            errorMessage={t('deviceIdInvalid')}
          />
        </div>
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={close} classes="default-button" />
        </div>
        <div className="form-button">
          <Button
            label={uploading ? t('creating') : t('create')}
            click={submit}
            classes={uploading ? 'success-button loading-btn' : 'success-button'}
            disabled={disable}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
}
DisplaySignageAddNew.propTypes = {
  close: PropTypes.func.isRequired,
  submitDisplay: PropTypes.func.isRequired,
};
// DisplaySignageAddNew.defaultProps = {
//   toolbarLeft: '',
// };

export default DisplaySignageAddNew;
