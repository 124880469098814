import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

import notification from '../../../utils/api/notification';
import { capitalize, getTimeDifference } from '../../../utils/helpers';

function Notification() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notificationData, setNotificationData] = useState([
    {
      read: true,
    },
    {
      read: true,
    },
    {
      read: true,
    },
    {
      read: true,
    },
  ]);
  const [loadingNotifications, setLoadingNotifications] = useState(true);

  useEffect(() => {
    // Api to fetch notifications and also to change the fetched notification status to read
    const fetchAndUpdateNotifications = async () => {
      try {
        const response = await notification.getNotifications();
        setNotificationData(response?.data);
        setLoadingNotifications(false);

        // Update the status to read
        await notification.updateNotifications();
      } catch (err) {
        dispatch(setErrorNotification(err?.response?.data));
      }
    };

    setLoadingNotifications(true);
    fetchAndUpdateNotifications();
  }, []);

  return (
    <div className="notification-wrap">
      <h1>{t('notification')}</h1>
      <div className="notification-container">
        {notificationData?.map((data) => (
          <div
            className="single-notification"
            style={{ '--notification-color': !data?.read && '#BFEFFF' }}
          >
            <div className="profile-content-wrap">
              {loadingNotifications
                ? <Skeleton variant="circular" sx={{ width: '35px', height: '35px' }} />
                : (
                  <div
                    className="profile-wrap"
                    style={{ '--profile-color': '#486E52' }}
                  >
                    {data?.user?.name && <span>{capitalize(data?.user?.name?.charAt(0))}</span>}
                  </div>
                )}
              <div className="content-wrap">
                {loadingNotifications
                  ? <Skeleton variant="text" sx={{ fontSize: '20px', width: '250px' }} />
                  : (
                    <h2>
                      <span>{data?.user?.name} </span>
                      {data?.event_type}
                      <span> : &quot;{data?.event_name}&quot;</span>
                    </h2>
                  )}
              </div>
            </div>
            <div className="time-wrap">
              {loadingNotifications
                ? <Skeleton variant="text" sx={{ fontSize: '20px', width: '100px' }} />
                : <span>{getTimeDifference(data?.createdAt)}</span>}
            </div>
          </div>
        ))}
        {!loadingNotifications && notificationData?.length === 0
          && (
            <div className="no-new-notifications">
              <p>No new notifications.</p>
            </div>
          )}
      </div>
    </div>
  );
}

export default Notification;
