import { getCookieStorageItem } from '../helpers';
import { get, post } from './base/index';

export default {
  login: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/login`, { ...params });
  },
  logout: (data = {}) => {
    const token = getCookieStorageItem('secure-auth-refresh');
    const params = { ...data, refreshToken: token };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/logout`, { ...params });
  },
  forgotPassword: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/auth/forgot-password`, data),
  getAccessToken: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/auth/refresh-tokens`, data),
  getData: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/auth/login`, { params });
  },
  resetPassword: (data = {}, token = '') => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/reset-password?token=${token}`, { ...params });
  },
  sendVerificationEmail: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/send-otp`, { ...params });
  },
  verifyEmail: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/verify-otp`, { ...params });
  },
  selfRegister: (data = {}, token = '') => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/self-register?token=${token}`, { ...params });
  },
  changePassword: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/change-password`, body);
  },
  sendLogInOtp: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/auth/send-login-otp`, { ...params });
  },
};
