import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { City, Country, State } from 'country-state-city';
import { useTranslation } from 'react-i18next';
import AutocompleteBox from '../../../shared/component/form/AutoComplete';
import { Input } from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import Button from '../../../shared/component/Button';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';

import company from '../../../utils/api/company';
import feature from '../../../utils/api/feature';
import { setLocalStorageItem, useRecaptcha } from '../../../utils/helpers';
import {
  descriptionValidation,
  emailValidation,
  nameValidation,
  phoneNumberValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../shared/component/form/Validation';

function CompanyForm(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { VerifyRecaptcha } = useRecaptcha();
  const [disable, setDisable] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const totalDevice = [
    {
      label: '0 - 10',
    },
    {
      label: '11 - 50',
    },
    {
      label: '51 - 100',
    },
    {
      label: '101 - 250',
    },
    {
      label: '250+',
    },
  ];
  const handleChange = (name, changeValue) => {
    if (changeValue) {
      if (name === 'country') {
        props?.setFormData({
          ...props?.formData,
          country: changeValue.label,
          state: '',
          city: '',
        });
        setCountryCode(changeValue.id);
        const stateData = State.getStatesOfCountry(changeValue.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setStates(stateData);
      }
      if (name === 'state') {
        props?.setFormData({
          ...props?.formData,
          state: changeValue.label,
          city: '',
        });
        const cityData = City.getCitiesOfState(countryCode, changeValue.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCities(cityData);
      }
      if (name === 'city' || name === 'total_device') {
        props?.setFormData({ ...props?.formData, [name]: changeValue.label });
      }
    }
    if (name !== 'country' && name !== 'state' && name !== 'city' && name !== 'total_device') {
      props?.setFormData({ ...props?.formData, [name]: changeValue });
    }
  };

  const submit = async () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, props?.formData?.company_name)
      && textNumberValidation(1, 50, props?.formData?.tax_id)
      && phoneNumberValidation(props?.formData?.phone_number)
      && emailValidation(props?.formData?.company_email)
      && descriptionValidation(255, props?.formData?.address)
      && pincodeValidation(props?.formData?.pincode)
    ) {
      setUploading(true);
      const companyData = {
        company_name: props?.formData?.company_name,
        company_email: props?.formData?.company_email,
        // user_email: props?.user_email || props?.formData?.email,
        tax_id: props?.formData?.tax_id,
        address: props?.formData?.address,
        country: props?.formData?.country,
        state: props?.formData?.state,
        city: props?.formData?.city,
        pincode: props?.formData?.pincode,
        self_signup: true,
        phone_number: props?.formData?.phone_number,
      };
      try {
        const recaptchaToken = await VerifyRecaptcha('companyRegister');
        const companyDataPayload = { ...companyData, recaptchaToken };
        await company.selfRegisterCompany(companyDataPayload);
        setUploading(false);
        setNotification(true);
        if (props?.page === 'login') {
          // If user is authenticated, fetch the features for that user
          const res = await feature.getFeatures();
          setLocalStorageItem('features', res?.data);
        }

        setTimeout(async () => {
          setNotification(false);
          if (props?.page === 'login') {
            // If user is authenticated, fetch the features for that user
            navigate('../admin/dashboard');
          } else {
            // If the user is within the register company,
            // then navigate to dashboard or navigate to company register form
            navigate('../login');
          }
        }, 3000);
      } catch (error) {
        setUploading(false);
        if (error?.response?.data?.message) {
          setErrorMsg(error?.response?.data?.message);
        } else {
          setErrorMsg('An unexpected error occurred.');
        }
      }
    }
  };

  useEffect(() => {
    setErrorMsg('');
    if (
      props?.formData?.company_name
      && props?.formData?.tax_id
      && props?.formData?.phone_number
      && props?.formData?.company_email
      && props?.formData?.address
      && props?.formData?.country
      && props?.formData?.state
      && props?.formData?.city
      && props?.formData?.pincode
      && props?.formData?.total_device
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [props?.formData]);

  useEffect(() => {
    const countryData = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(countryData);
  }, []);

  return (
    <div className="company-form-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('accountCreatedSuccess')} /> }}
      />
      <div className="form-container">
        <div className="single-row">
          <Input
            type="text"
            label={t('companyName')}
            name="company_name"
            value={props?.formData?.company_name}
            change={handleChange}
            error={!nameValidation(1, 100, props?.formData?.company_name) && submitButtonClicked}
            errorMessage={t('companyNameValidation')}
          />
          <Input
            type="text"
            label={t('companyRegistrationNumber')}
            name="tax_id"
            value={props?.formData?.tax_id}
            change={handleChange}
            error={!textNumberValidation(1, 50, props?.formData?.tax_id) && submitButtonClicked}
            errorMessage={t('taxIdValidation')}
          />
        </div>
        <div className="single-row">
          <Input
            type="number"
            label={t('phoneNumber')}
            name="phone_number"
            value={props?.formData?.phone_number}
            change={handleChange}
            error={!phoneNumberValidation(props?.formData?.phone_number) && submitButtonClicked}
            errorMessage={t('invalidPhoneNumber')}
          />
          <Input
            type="text"
            label={t('emailId')}
            name="company_email"
            value={props?.formData?.company_email}
            change={handleChange}
            error={!emailValidation(props?.formData?.company_email) && submitButtonClicked}
            errorMessage={t('invalidEmail')}
          />
        </div>
        <Input
          type="text"
          label={t('address')}
          name="address"
          value={props?.formData?.address}
          change={handleChange}
          error={!descriptionValidation(255, props?.formData?.address) && submitButtonClicked}
          errorMessage={t('addressValidation')}
        />
        <div className="single-row">
          <SelectBox
            label={t('country')}
            name="country"
            value={props?.formData?.country}
            lists={countries}
            id="controllable-country-demo"
            onchange={handleChange}
          />
          <AutocompleteBox
            label={t('state')}
            name="state"
            value={props?.formData?.state}
            lists={states}
            id="controllable-state-demo"
            onchange={handleChange}
            allowNewOption
          />
        </div>
        <div className="single-row">
          <AutocompleteBox
            label={t('city')}
            name="city"
            value={props?.formData?.city}
            lists={cities}
            id="controllable-city-demo"
            onchange={handleChange}
            allowNewOption
          />
          <Input
            type="number"
            label={t('pincode')}
            name="pincode"
            value={props?.formData?.pincode}
            change={handleChange}
            error={!pincodeValidation(props?.formData?.pincode) && submitButtonClicked}
            errorMessage={t('invalidPincode')}
          />
        </div>
        <div className="single-row">
          <SelectBox
            label={t('totalNumberOfDevices')}
            name="total_device"
            value={props?.formData?.total_device}
            lists={totalDevice}
            id="controllable-count"
            onchange={handleChange}
          />
        </div>
      </div>
      {errorMsg && <p className="errorMsg">{errorMsg}</p>}
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('submit')}
            click={submit}
            classes="success-button"
            disabled={disable}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
}
CompanyForm.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    company_name: PropTypes.string,
    tax_id: PropTypes.string,
    phone_number: PropTypes.string,
    company_email: PropTypes.string,
    address: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    pincode: PropTypes.string,
    total_device: PropTypes.string,
  }).isRequired,
  page: PropTypes.string,
};
CompanyForm.defaultProps = {
  page: '',
};

export default CompanyForm;
