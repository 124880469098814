import {
  get,
} from './base/index';

export default {
  getPlans: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/plans/get-plans`, { params });
  },
};
