import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from './Button';

function SubscriptionRenew() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const submit = () => {
    navigate('/admin/subscription');
  };
  const data = JSON.parse(localStorage.getItem('data'));
  const subscriptionValue = data?.user?.company?.subscription;
  const currentDate = new Date();
  const graceDate = new Date(subscriptionValue?.grace_date);
  return (
    <div>
      <div className="sidebar-subscription-renewal">
        <div className="box">
          <div className="message">
            {
              (() => {
                let status;
                if (currentDate > graceDate && subscriptionValue?.status === 'expired') {
                  status = <span>{t('YourSubscriptionHasExpiredRenewNowForUninterruptedAccess')}</span>;
                } else if (subscriptionValue?.status === 'cancelled') {
                  status = <span>{t('YourSubscriptionHasCancelledRenewNowForUninterruptedAccess')}</span>;
                } else {
                  status = <span>{t('YourSubscriptionAboutToExpireRenewNowForUninterruptedAccess')}</span>;
                }
                return status;
              })()
            }
          </div>
          <div className="form-button">
            <Button
              label={t('renewNow>')}
              click={submit}
              classes="success-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionRenew;
