import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
import imgData from '../../../assets/images/login-baner.jpg';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import { changeDateTimeFormat } from '../../../utils/helpers';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import { MoreMenuIcon } from '../../../shared/component/svgIcon';

function GridView(props) {
  const statusCheck = (id, itemStatus) => {
    let isDisabled = false;
    if (id === 'download-content') {
      isDisabled = itemStatus?.includes('url')
        || itemStatus?.includes('youtube')
        || itemStatus?.includes('template');
    } else if (id === 'edit-content') {
      isDisabled = itemStatus?.includes('template');
    }
    return isDisabled;
  };

  return (
    <div className="grid">
      <div className="grid-image">
        {
          (() => {
            let preview;
            if (props?.value?.file_type?.includes('video')) {
              preview = (
                <video
                  src={props?.value?.url ? props?.value?.signedUrl : imgData}
                  width="100%"
                  height="192px"
                  controls
                  poster={props?.value?.thumbnailSignedUrls?.px_600x400}
                  preload="none"
                >
                  <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                </video>
              );
            } else if (props?.value?.file_type?.includes('image')) {
              preview = (
                <img src={props?.value?.url ? props?.value?.signedUrl : imgData} alt="grid_image" />
              );
            } else if (props?.value?.file_type?.includes('youtube')) {
              preview = (
                <YouTube
                  videoId={getYouTubeID(props?.value?.url)}
                  opts={{
                    height: '100%',
                    width: '100%',
                  }}
                  className="youtube-video"
                  onReady={(e) => e.target.pauseVideo()}
                />
              );
            } else if (props?.value?.file_type?.includes('url')) {
              preview = (
                <div className="url-image-container">
                  <img
                    alt="url"
                    src={urlIcon}
                  />
                </div>
              );
            } else if (props?.value?.file_type?.includes('pdf')) {
              preview = (
                <div className="url-image-container">
                  <img
                    alt="url"
                    src={pdfIcon}
                  />
                </div>
              );
            }
            return preview;
          })()
        }
      </div>
      <div className="grid-body">
        <div className="grid-name">
          <label className="title-name" htmlFor={props?.value?.name}>{props?.value?.name}</label>
          <div className="avatar">
            <div className="avatar-img">{props?.value?.author.charAt(0)}</div>
            {/* <div className="avatar-img">{props?.value?.author}</div> */}
            <span className="sub-name">{changeDateTimeFormat(props?.value?.updatedAt)}</span>
          </div>
        </div>
        <div className="grid-action">
          <div>
            <DropDownMenu
              action={props?.action}
              icon={<MoreMenuIcon />}
              value={props?.value}
              dropdown="tableForm"
              tooltip="Menu"
              statusCheck={statusCheck}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

GridView.propTypes = {
  value: PropTypes.shape(),
  action: PropTypes.arrayOf(),
};
GridView.defaultProps = {
  value: {},
  action: [],
};

export default GridView;
