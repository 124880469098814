import {
  get,
  post,
} from './base/index';

export default {
  getDevices: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ota/getOtaDeviceList`),
  getDeviceGroups: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ota/getOtaDeviceGroupList`),
  getNotifications: () => get(`${process.env.REACT_APP_API_URL}cms/v1/ota/findLatestPlayerApp`),
  createOtaRequest: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/ota`, { ...data }),
};
