import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
// import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalBox from '../../../shared/component/ModalBox';
import { Input, InputDate, InputTime } from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import ContentsList from './ContentsList';
import DeviceList from './DeviceList';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import PreviewContent from './PreviewContent';
import RepeatForm from './RepeatForm';
import timeZonesList from '../../../assets/json/time-zone.json';
import Button from '../../../shared/component/Button';
import scheduleApi from '../../../utils/api/schedule';
import {
  fetchCalendarScheduleList,
  fetchScheduleList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/ScheduleSlice';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { checkPermission } from '../../../utils/helpers';

function CalendarSchedulesAdd(props) {
  const { t } = useTranslation();
  let backClick;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [disable, setDisable] = useState(true);
  const [repeatDisable, setRepeatDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalView, setModalView] = useState();
  const [timeZone, setTimeZone] = useState([]);
  const [selectContents, setSelectContents] = useState([]);
  const [selectDevices, setSelectDevices] = useState([]);
  const [notification, setNotification] = useState(false);
  const [repeatText, setRepeatText] = useState(t('doesNotRepeat'));
  const [modalTitle, setModalTitle] = useState(t('contents'));
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [dateTime, setDateTime] = useState({
    startdate: dayjs(props?.selectedDate),
    enddate: dayjs(props?.selectedDate),
    starttime: dayjs(`2024-12-04 ${props?.selectedTime}`),
    endtime: dayjs(`2024-12-04 ${props?.selectedTimeend}`),
  });
  // const timeSteps = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [value, setValue] = useState({
    playlists: [],
    playlistDetails: [],
    contents: [],
    contentsDetails: [],
    devices: [],
    device_groups: [],
    repeat_days: [],
    repeat: false,
    special: false,
    // time_zone: '',
    start_date: props?.selectedDate,
    end_date: props?.selectedDate,
    start_time: props?.selectedTime,
    end_time: props?.selectedTimeend,
  });
  // const timeZoneOptions = moment.tz.names().map((tz, index) => ({
  //   id: index,
  //   // label: `${tz} (UTC${moment.tz(tz).format('Z')})`,
  //   label: tz,
  //   keys: timeSteps,
  //   key: tz,
  // }));
  const devicesData = useSelector((state) => state.display.displaySignage);
  const devicesGroupData = useSelector((state) => state.displayGroup.displayGroup);
  const week = useSelector((state) => state.schedules.week);
  const setRepeated = (repValues) => {
    setValue({ ...value, ...repValues, repeat: true });
    setMessage(
      repValues.repeat_type === 'DAILY'
        ? `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
    day starting ${repValues.started_date} until ${repValues.complete_date}`
        : `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
        week ${repValues?.repeat_days} starting ${repValues.started_date} until ${repValues.complete_date}`,
    );
    setOpen(false);
  };
  const backModal = (name, data) => {
    backClick(name, data);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const submit = () => {
    if (
      dayjs(new Date()).format('YYYY-MM-DD HH:mm') < `${value.start_date} ${value.start_time}`
      && `${value.start_date} ${value.start_time}` < `${value.end_date} ${value.end_time}`
    ) {
      setDisable(true);
      setUploading(true);
      const request = {
        name: value.name,
        repeat: value.repeat,
        start_only_date: value.start_date,
        end_only_date: value.end_date,
        start_time: value.start_time,
        end_time: value.end_time,
        time_zone: value.time_zone,
        special: value.special,
      };

      if (value.description !== '') {
        request.description = value.description;
      }

      if (value.contents.length > 0) {
        request.contents = value.contents;
      }

      if (value.devices.length > 0) {
        request.devices = value.devices;
      }

      if (value.device_groups.length > 0) {
        request.device_groups = value.device_groups;
      }

      if (value.playlists.length > 0) {
        request.playlists = value.playlists;
      }

      if (value.repeat) {
        request.recurrences = {
          interval: value.repeat_count,
          frequency: value.repeat_type,
          start_only_date: value.started_date,
          end_only_date: value.complete_date,
        };

        if (value.repeat_type === 'WEEKLY') {
          request.recurrences.by_week_day = value.repeat_days;
        }
      }
      scheduleApi.addSchedule(request).then(() => {
        setUploading(false);
        setNotification(true);
        setOpen(true);
        const sortedArray = week.slice().sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        dispatch(fetchCalendarScheduleList({
          start_date: sortedArray[0].start_date,
          end_date: sortedArray[sortedArray.length - 1].end_date,
          status: 'add',
        }));
        setModalView(<Success message={t('scheduleCreatedSuccess')} />);
        setModalTitle('');
        setTimeout(() => {
          setNotification(false);
          setDisable(false);
          setOpen(false);
          setModalView('');
          props?.back(false);
          navigate('../calendar-schedule');
          dispatch(fetchScheduleList({ page: 1 }));
          dispatch(setFilter({}));
          dispatch(setTableFilter({}));
        }, 3000);
      }).catch((errors) => {
        setUploading(false);
        setError(errors?.response?.data?.message);
        setDisable(false);
        dispatch(setErrorNotification(errors?.response?.data));
      });
    } else {
      dispatch(setErrorNotification({ message: 'Invalid schedule time' }));
    }
  };
  const openModalRepeat = () => {
    setModalView(<RepeatForm
      closeModal={setOpen}
      setRepeated={setRepeated}
      startDate={value}
    />);
    setModalTitle(t('repeat'));
    setOpen(true);
  };
  const setSpecialSchedule = () => {
    setValue({
      ...value,
      special: !value.special,
    });
  };
  const selectData = (name, data) => {
    if (name === 'time_zone') {
      if (data) {
        setValue((prevState) => ({
          ...prevState,
          time_zone: data.label,
          // time_zone: data.key,
          // time_zone_label: data.label,
        }));
      }
    } else if (name === 'devices' || name === 'contents') {
      setValue((prevState) => ({
        ...prevState,
        ...data,
      }));
    } else if (name === 'start_date' || name === 'end_date') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      setValue((prevState) => ({
        ...prevState,
        [name]: dayjs(data).format('YYYY-MM-DD'),
      }));
    } else if (name === 'start_time' || name === 'end_time') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      // Formatting time
      const hour = data?.$H;
      const minute = data?.$m;
      const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
      setValue((prevState) => ({
        ...prevState,
        [name]: formattedTime,
      }));
    } else {
      setValue((prevState) => ({
        ...prevState,
        [name]: data,
      }));
    }
  };
  const viewData = (name, data) => {
    setModalView(<PreviewContent
      data={data}
      closeModal={setOpen}
      name={name}
      selectData={selectData}
      values={value}
      back={backModal}
    />);
  };
  const removeContentsTags = (name, key) => {
    const contentsList = value[key.type].filter((e) => e !== key.id);
    let detail = '';
    let oldValue = [];
    if (key.type === 'contents') {
      detail = 'contentsDetails';
      oldValue = value.contentsDetails.filter((content) => content.id !== key.id);
    } else if (key.type === 'playlists') {
      detail = 'playlistDetails';
      oldValue = value.playlistDetails.filter((content) => content.id !== key.id);
    }
    setValue({ ...value, [key.type]: contentsList, [detail]: oldValue });
  };
  const openModalContent = (name = 'contents', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<ContentsList
      selectData={viewData}
      closeModal={setOpen}
      values={oldData}
      name={name}
    />);
    setModalTitle(t('contents'));
    setOpen(true);
  };
  const openModalDevice = (name = 'devices', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<DeviceList selectData={viewData} closeModal={setOpen} values={oldData} name={name} />);
    setModalTitle(t('devices'));
    setOpen(true);
  };
  backClick = (name, data) => {
    if (name === 'contents' || name === 'playlists') {
      openModalContent(name, data);
    } else if (name === 'devices' || name === 'device_groups') {
      openModalDevice(name, data);
    }
  };
  // useEffect(() => {
  //   const selectedOption = timeZoneOptions.find((option) => option.keys === option.key);
  //   if (selectedOption) {
  //     if (selectedOption.keys === selectedOption.key) {
  //       setValue({ ...value, time_zone: selectedOption.key, time_zone_label: selectedOption.label });
  //     } else {
  //       setValue({ ...value, time_zone: timeSteps });
  //     }
  //   }
  // }, []);
  useEffect(() => {
    if (value.start_date && value.start_time && value.end_date && value.end_time && value.name && value.time_zone) {
      if (value.contents.length > 0 && value.devices.length > 0) {
        setDisable(false);
      } else if (value.contents.length > 0 && value.device_groups.length > 0) {
        setDisable(false);
      } else if (value.playlists.length > 0 && value.devices.length > 0) {
        setDisable(false);
      } else if (value.playlists.length > 0 && value.device_groups.length > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
    if (value.start_date && value.start_time && value.end_date && value.end_time) {
      const now = `${value.start_date} ${value.start_time}`;
      const then = `${value.end_date} ${value.end_time}`;

      const startTime = new Date(now).getTime();
      const endTime = new Date(then).getTime();
      const timeDifference = Math.abs(endTime - startTime);
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      // const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      if (hours <= 23) {
        setRepeatDisable(false);
        setRepeatText(t('repeat'));
      } else {
        setValue({ ...value, repeat: false });
        setRepeatDisable(true);
        setRepeatText(() => t('doesNotRepeat'));
      }
    }
    let singleDevice = [];
    let singleDevicesGroup = [];
    const device = devicesData.filter((e) => value.devices.includes(e.id));
    singleDevice = device.map((value1) => ({ ...value1, type: 'devices' }));
    const deviceGroup = devicesGroupData.filter((e) => value.device_groups.includes(e.id));
    singleDevicesGroup = deviceGroup.map((value1) => ({ ...value1, type: 'device_groups' }));
    setSelectContents(
      [
        ...value.contentsDetails.map((value1) => ({ ...value1, type: 'contents' })),
        ...value.playlistDetails.map((value1) => ({ ...value1, type: 'playlists' })),
      ],
    );
    setSelectDevices([...singleDevice, ...singleDevicesGroup]);
  }, [value]);
  useEffect(() => {
    const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
    setTimeZone(timeZones);
  }, [navigate]);
  return (
    <div className="calender-schedule-form">
      {error && <p className="text-danger message">{error}</p>}
      <ModalBox
        status={open}
        modalView={{ content: modalView, title: modalTitle }}
        closeModal={closeModal}
        notification={notification}
      />
      <div className="form-field-row">
        <Input
          icon={' '}
          label={t('scheduleName')}
          type="text"
          name="name"
          placeholder={t('enterScheduleName')}
          required
          value={value.name}
          change={selectData}
        />
        <SelectBox
          label={t('timeZone')}
          name="time_zone"
          required
          value={value.time_zone}
          onchange={selectData}
          lists={timeZone}
        />
      </div>
      <div className="form-select-field">
        <Input
          label={t('description')}
          type="text"
          name="description"
          placeholder={t('enterScheduleDescription')}
          required={false}
          value={value.description}
          change={selectData}
        />
      </div>
      <div className="form-field-row">
        <div className="form-field-1">
          <InputDate
            label={t('scheduleFrom')}
            name="start_date"
            required
            value={dateTime.startdate}
            change={selectData}
            disablePast
            sx={{
              paddingTop: '0px',
            }}
          />
          <InputTime
            label={t('startTime')}
            name="start_time"
            required
            value={dateTime.starttime}
            change={selectData}
            minTime={
              value.start_date === dayjs(new Date()).format('YYYY-MM-DD') ? dayjs(new Date()) : undefined
            }
          />
        </div>
        <div className="form-field-1">
          <InputDate
            label={t('scheduleTill')}
            name="end_date"
            required
            value={dateTime.enddate}
            change={selectData}
            disablePast
          />
          <InputTime
            label={t('endTime')}
            name="end_time"
            required
            value={dateTime.endtime}
            change={selectData}
          />
        </div>
      </div>
      {
        checkPermission('repeatSchedule') && (
          <div className="form-field-button">
            <Button
              label={repeatText}
              classes="info-button"
              click={openModalRepeat}
              disabled={repeatDisable}
            />
          </div>
        )
      }
      {message && <div className="form-select-field info-text">{message}</div>}
      {
        checkPermission('addSpecialSchedule') && (
          <div className="form-select-field">
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    sx={{
                      color: '#8E8E8E',
                      '&.Mui-checked': {
                        color: '#30A84B',
                      },
                    }}
                    onChange={setSpecialSchedule}
                  />
                )}
                label={t('specialSchedules')}
              />
            </FormGroup>
          </div>
        )
      }
      <div className="form-select-field">
        <MultiSelectBox
          label={t('targetDeviceGroup')}
          buttonLabel={t('selectDevice')}
          clickButton={openModalDevice}
          required
          name="devices"
          addData={removeContentsTags}
          placeholder={t('selectTargetDevice')}
          value={selectDevices}
        />
      </div>
      <div className="form-select-field">
        <MultiSelectBox
          label={t('contentPlaylist')}
          buttonLabel={t('selectContent')}
          clickButton={openModalContent}
          required
          name="contents"
          addData={removeContentsTags}
          placeholder={t('selectTargetContent')}
          value={selectContents}
        />
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('back')}
            click={() => props?.back(false)}
            classes="default-button"
          />
        </div>
        <div className="form-button">
          <Button
            label={uploading ? t('creating') : t('create')}
            click={submit}
            classes={uploading ? 'success-button loading-btn' : 'success-button'}
            disabled={disable}
            loading={uploading}
          />
        </div>
      </div>
    </div>

  );
}
CalendarSchedulesAdd.propTypes = {
  selectedDate: PropTypes.string,
  selectedTime: PropTypes.string,
  selectedTimeend: PropTypes.string,
  back: PropTypes.func,
};
CalendarSchedulesAdd.defaultProps = {
  selectedDate: '',
  selectedTime: '',
  selectedTimeend: '',
  back: () => { },
};

export default CalendarSchedulesAdd;
