/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import accountPreference from '../../utils/api/account-preference';
import { setErrorNotification } from './NotificationSlice';

export const fetchPreferenceList = createAsyncThunk(
  'displayGroup/fetchPreferenceList',
  async (data, { dispatch }) => {
    const response = await accountPreference.getPreferenceList()
      .then((res) => res?.data)
      .catch((err) => dispatch(setErrorNotification(err)));
    return response;
  },
);

const initialState = {
  preferenceList: [],
  status: '',
};

export const displayPreferenceListSlice = createSlice({
  name: 'preferenceList',
  initialState,
  reducers: {
    resetPreferenceState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPreferenceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPreferenceList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.preferenceList = action?.payload;
      })
      .addCase(fetchPreferenceList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  resetPreferenceState,
} = displayPreferenceListSlice.actions;

export default displayPreferenceListSlice.reducer;
