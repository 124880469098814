/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import TemplateContentUpload from '../TemplateContentUpload';
import ModalBox from '../../../../shared/component/ModalBox';
import PopupBox from '../ContentUploadConfirmation';

/* images */
import vectorBox from '../../../../assets/images/Templates/VectorBox.png';
import listIceIcon from '../../../../assets/images/Templates/GroupList-ice-img.png';
import listIceIcon2 from '../../../../assets/images/Templates/GroupList-icon-2.png';
import listIceIcon3 from '../../../../assets/images/Templates/GroupList-icon-3.png';
import FileFormat from '../FileFormat';

function HtmlTemplateFour({ templateData }) {
  const [data, setData] = useState([]);

  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [disable, setDisable] = useState(true);
  const [htmlContent, setHtmlContent] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [modalView, setModalView] = useState({ content: '', title: '' });

  const cssStyles = `
ul {
  padding: 0;
  margin: 0;
}
h1 {
  margin: 0;
}
img {
  border-style: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.template-container-4 {
  background-color: #000;
  width: 100%;
  padding: 60px 30px 30px 30px;
  position: relative;
  box-sizing: border-box;
}
.template-container-4 .row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.template-container-4 .row .grid-item {
  width: 1080px;
  margin: 0 auto;
}
.template-container-4 .row .grid-item .top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.template-container-4 .row .grid-item .top-section .left-side-ice,
.template-container-4 .row .grid-item .top-section .top-right-image {
  width: 250px;
  height: 300px;
}
.template-container-4 .row .grid-item .top-section .title-section {
  width: 370px;
  height: auto;
}
.template-container-4 .row .grid-item .top-section .title-section .menu-title {
  border-top: 3px solid #f7b42f;
}
.template-container-4 .row .grid-item .top-section .title-section .curved-box {
  margin-top: -157px;
  width: 280px;
  height: auto;
}
.template-container-4 .row .bottom-section {
  width: 1100px;
  margin: 0 auto;
}
.template-container-4 .row .bottom-section .menu-lists {
  margin-top: 4rem;
  width: 40%;
}
.template-container-4 .row .bottom-section .menu-lists .menu-list-header {
  display: flex;
  justify-content: space-between;
}
.template-container-4 .row .bottom-section .menu-lists .menu-list-header .list-ice-icon {
  width: 50px;
  height: 50px;
}
.template-container-4 .row .bottom-section .menu-lists ul.list-group li {
  display: table;
  padding: 0.5rem 0rem;
}
.template-container-4 .row .bottom-section .menu-lists ul.list-group li span {
  display: table-cell;
}
.template-container-4 .row .bottom-section .menu-lists ul.list-group li span:first-child {
  /* TITLE */
  position: relative;
  overflow: hidden;
  /* Don't go underneath the price */
}
.template-container-4 .row .bottom-section .menu-lists ul.list-group li span:first-child:after {
  /* DASHES */
  content: "";
  position: absolute;
  bottom: 0.5em;
  /* Set as you want */
  margin-left: 0.3em;
  /* Keep same for the next span's left padding */
  width: 100%;
  border-bottom: 2.5px dotted #fff;
}
.template-container-4 .row .bottom-section .menu-lists ul.list-group li span + span {
  /* PRICE */
  text-align: right;
  width: 1%;
  /* Trick it */
  vertical-align: bottom;
  /* Keep Price text bottom-aligned */
  padding-left: 0.3em;
}
@media screen and (min-width: 1550px) {
  .template-container-4 .row .grid-item {
    width: 70%;
  }
  .template-container-4 .row .bottom-section {
    width: 75%;
  }
}
@media screen and (max-width: 1280px) {
  .template-container-4 .row .grid-item {
    width: 980px;
  }
  .template-container-4 .row .bottom-section {
    width: 1000px;
  }
}
  `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = () => {
    toPng(ref?.current, { cacheBust: true })
      .then((dataUrl) => {
        // Save the Base64 string in the state
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (ref.current) {
      const content = ref.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=McLaren&display=swap" 
            rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used setTimeout to ensure the page was fully rendered before calling the API */
      /* And then waited for the response */
      setTimeout(() => {
        convertBase64();
      }, 800);
      convertHtml();
    }
  }, [data]);
  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const styleRef = data?.[0]?.styles;
  const templateContent = data?.[0]?.template_contents?.items;
  const SubTitleMenu = data?.[0]?.template_sub_title?.split(' ');
  const listIcons = [listIceIcon, listIceIcon3, listIceIcon2];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      {data && data?.map((item) => (
        <div className="template-container-4" ref={ref}>
          <div className="row">
            <div className="grid-item">
              <div className="top-section">
                <div className="left-side-ice">
                  {item?.template_images?.[0]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[0]?.image}
                        alt=""
                      />
                    )}
                </div>
                <div style={{ textAlign: 'center' }} className="title-section">
                  <img
                    src={vectorBox}
                    alt=""
                    className="curved-box"
                  />
                  <div style={{ marginTop: '-275px' }}>
                    <h1
                      className="menu-title"
                      style={{
                        margin: '0 60px',
                        color: `${styleRef?.title_style?.color}`,
                        fontSize: `${styleRef?.title_style?.fontSize}`,
                        fontFamily: `${styleRef?.title_style?.fontFamily}`,
                      }}
                    >
                      {item?.template_title}
                    </h1>
                    <h1
                      style={{
                        marginBottom: '0',
                        color: `${styleRef?.sub_title_style?.color}`,
                        fontSize: `${styleRef?.sub_title_style?.fontSize}`,
                        fontFamily: `${styleRef?.sub_title_style?.fontFamily}`,
                      }}
                    >
                      {SubTitleMenu?.[0]}
                    </h1>

                    <h2
                      style={{
                        margin: '0 60px',
                        color: `${styleRef?.sub_title_style?.color}`,
                        fontSize: `${styleRef?.sub_title_style?.fontSize}`,
                        fontFamily: `${styleRef?.sub_title_style?.fontFamily}`,
                        borderBottom: '3px solid',
                      }}
                    >
                      {SubTitleMenu?.[1]}
                    </h2>
                  </div>
                </div>

                <div className="top-right-image">
                  {item?.template_images?.[1]?.image !== null
                    && (
                      <img
                        src={item?.template_images?.[1]?.image}
                        alt=""
                      />
                    )}
                </div>
              </div>
            </div>

            <div className="bottom-section">
              <div
                style={{
                  display: 'flex',
                  paddingTop: '2.5rem',
                  justifyContent: 'center',
                }}
              >
                {templateContent?.filter((filterHide) => !filterHide?.hide)
                  ?.map((categoryContent, index) => (
                    <div className="menu-lists" style={{ borderRight: '3px solid #F7B42F', padding: '0 25px' }}>
                      <div className="menu-list-header">
                        <h2
                          style={{
                            color: `${styleRef?.cate_style?.color}`,
                            fontSize: `${styleRef?.cate_style?.fontSize}`,
                            fontFamily: `${styleRef?.cate_style?.fontFamily}`,
                            fontWeight: 'bold',
                          }}
                        >
                          {categoryContent?.category}
                        </h2>
                        <img
                          src={listIcons[index] || listIceIcon2}
                          alt=""
                          className="list-ice-icon"
                        />
                      </div>
                      <ul className="list-group">
                        {categoryContent?.details?.map((detail, i) => (
                          <li key={detail?.id} className="list-group-item">
                            <span
                              className="menu-list-item"
                              style={{
                                color: `${styleRef?.item_style?.color}`,
                                fontSize: `${styleRef?.item_style?.fontSize}`,
                                fontFamily: `${styleRef?.item_style?.fontFamily}`,
                              }}
                            >
                              {detail?.name}
                            </span>
                            <span className="price-separator" />
                            <span
                              className="menu-list-price"
                              style={{
                                color: `${styleRef?.price_style?.color}`,
                                fontSize: `${styleRef?.price_style?.fontSize}`,
                                fontFamily: `${styleRef?.price_style?.fontFamily}`,
                              }}
                            >
                              {categoryContent?.pricing?.[i]?.p1}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

HtmlTemplateFour.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default HtmlTemplateFour;
