import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioButton } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

function FileFormat(props) {
  const [fileType, setFileType] = useState('');
  const { t } = useTranslation();

  const handleClose = () => {
    props?.setNotification(false);
  };

  const submit = () => {
    props?.handleFileType(fileType);
    if (!props?.data?.[0]?.content_id) {
      props?.setNotification(true);
      props?.setOpenContentUploadConfirmation(false);
    } else {
      props?.setNotification(false);
      props?.setOpenContentUploadConfirmation(true);
    }
  };

  return (
    <div className="template-file-format">
      <div className="form">
        {props?.templateType !== 'Integration'
          && (
            <RadioButton
              checked={fileType === 'image'}
              value="image"
              label={t('saveasimage')}
              onChange={(e) => setFileType(e)}
              id="image"
            />
          )}
        <RadioButton
          checked={fileType === 'html'}
          value="html"
          label={t('saveashtml')}
          onChange={(e) => setFileType(e)}
          id="html"
        />
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={t('continue')}
              click={submit}
              classes="success-button"
              disabled={fileType === ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

FileFormat.propTypes = {
  setNotification: PropTypes.node.isRequired,
  handleFileType: PropTypes.func.isRequired,
  setOpenContentUploadConfirmation: PropTypes.func,
  data: PropTypes.arrayOf().isRequired,
  templateType: PropTypes.string,
};

FileFormat.defaultProps = {
  setOpenContentUploadConfirmation: () => { },
  templateType: '',
};

export default FileFormat;
