/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDisplayStatus } from '../../../redux/slices/DashBoardSlice';
// import { capitalize } from '../../../utils/helpers';

function renderCustomizedLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
}) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
}

function DisplayStatus() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    displayStatusData,
  } = useSelector((state) => state.dashboardPieCharts);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // State to handle size of the area-chart

  // const handleClick = () => {
  //   navigate('../display-signage');
  // };

  // Update the width and height of the area chart container
  useEffect(() => {
    dispatch(fetchDisplayStatus());
    const updateDimensions = () => {
      const containerWidth = document.getElementById('pieChart-container').clientWidth;
      const containerHeight = document.getElementById('pieChart-container').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="dashboard-display-status">
      <div className="Status-header">
        <h3>{t('displayStatus')}</h3>
      </div>
      <div className="pieChart-wrap" id="pieChart-container">
        <PieChart
          width={dimensions.width}
          height={dimensions.height}
        >
          <Pie
            data={displayStatusData}
            dataKey="value"
            cx={dimensions.width / 2}
            cy={dimensions.height / 2}
            label={renderCustomizedLabel}
            // Calculate based on parent width & height
            innerRadius={0}
            outerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 20}
            labelLine={false}
            fill="#0088FE"
          >
            {displayStatusData.map((entry) => (
              <Cell style={{ outline: 'none' }} key={entry.name} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
      <div className="pieChart-legend">
        {displayStatusData.map((item) => (
          <div className="each-legend" key={item.label}>
            <div className="marker" style={{ backgroundColor: item?.color }} />
            <p>{t(item?.name)}</p>
            <p>{item?.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DisplayStatus;
