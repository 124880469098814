import {
  get,
  post,
} from './base/index';

export default {
  getDevicesGroups: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/snapshot/get-device-group-list`, { params });
  },
  createCaptureSnapshot: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/snapshot`, body);
  },
  retrySnapshot: (data) => get(`${process.env.REACT_APP_API_URL}cms/v1/snapshot/retry-snapshot/${data}`, {}),
  historySnapshot: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/snapshot`, { params });
  },
  getSnapshots: (data, target) => get(
    `${process.env.REACT_APP_API_URL}cms/v1/snapshot/latest-snapshot/${data}/${target}`,
    {},
  ),
};
