import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import SelectBox from '../../shared/component/form/SelectBox';
import { InputDate } from '../../shared/component/form/Input';
import aiInstance from '../../utils/api/ai-instance';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import ReportGender from './components/ReportGender';
import ReportAgeGrouped from './components/ReportAgeGrouped';
import ReportStayStared from './components/ReportStayStared';

function AiAnalyticsReports() {
  const dispatch = useDispatch();
  const [reportsValue, SetReportsValue] = useState({
    reportType: 'By Gender',
    start_date: dayjs(),
  });
  const [countData, setCountData] = useState([]);
  const [countAgeData, setCountAgeData] = useState([]);
  const [countStareData, setCountStareData] = useState([]);

  const ReportList = [
    { id: 0, label: 'By Gender' },
    { id: 1, label: 'By Age Group' },
    { id: 2, label: 'By Stay/Stare Time' },
  ];
  const handleFunction = (name, changeValue) => {
    if (name === 'reportType') {
      SetReportsValue({ ...reportsValue, [name]: changeValue.label });
    } else {
      SetReportsValue({ ...reportsValue, [name]: changeValue });
    }
  };

  const GenderList = (data) => {
    aiInstance.getGenderReportList(data).then((response) => {
      setCountData(response.data);
    }).catch((error) => {
      dispatch(setErrorNotification(error.response.data));
    });
  };
  const getAgeData = (data) => {
    aiInstance.getAgedReport(data).then((response) => {
      setCountAgeData(response.data);
    }).catch((error) => {
      dispatch(setErrorNotification(error.response.data));
    });
  };
  const getStayData = (data) => {
    aiInstance.getStaredStayReport(data).then((response) => {
      setCountStareData(response.data);
    }).catch((error) => {
      dispatch(setErrorNotification(error.response.data));
    });
  };
  const filterFunction = (data) => {
    const request = {
      date: reportsValue.start_date ? dayjs(reportsValue.start_date).format('YYYY-MM-DD') : null,
    };
    if (reportsValue?.reportType === 'By Gender') {
      request.gender = data.gender;
      request.timeRange = data.time_range;
      request.total_gender = data.total_gender;
      request.device_name = data.device_name;
      request.aiInstance_name = data.aiInstance_name;
      request.store_name = data.store_name;
      GenderList(request);
    } else if (reportsValue?.reportType === 'By Age Group') {
      request.ageGroup = data.ageRange;
      request.timeRange = data.timeRange;
      request.total_Count = data.totalCount;
      request.device_name = data.device_name;
      request.aiInstance_name = data.ai_instance_name;
      request.store_name = data.store_name;
      getAgeData(request);
    } else if (reportsValue?.reportType === 'By Stay/Stare Time') {
      request.timeRange = data.time_range;
      request.total_Count = data.total;
      request.device_name = data.device_name;
      request.aiInstance_name = data.aiInstance_name;
      request.store_name = data.store_name;
      request.StareTime = data.total_stare_time;
      request.StayTime = data.total_stay_time;
      getStayData(request);
    }
  };
  useEffect(() => {
    const request = {
      date: reportsValue.start_date ? dayjs(reportsValue.start_date).format('YYYY-MM-DD') : null,
    };
    if (reportsValue?.reportType === 'By Gender') {
      GenderList(request);
    } else if (reportsValue?.reportType === 'By Age Group') {
      getAgeData(request);
    } else if (reportsValue?.reportType === 'By Stay/Stare Time') {
      getStayData(request);
    }
  }, [reportsValue]);

  return (
    <div className="ai-reports">
      <div className="top-content">
        <h1 className="title">Reports</h1>
      </div>
      {reportsValue?.reportType === 'By Gender' && (
        <ReportGender
          countData={countData}
          filterFunction={filterFunction}
          toolBar={
            (
              <div style={{ display: 'flex', gap: 10 }}>
                <InputDate
                  name="start_date"
                  value={reportsValue?.start_date}
                  change={handleFunction}
                  customPadding="8px 0px 8px 7px"
                />
                <SelectBox
                  name="reportType"
                  lists={ReportList}
                  onchange={handleFunction}
                  value={reportsValue?.reportType}
                  customPadding="0px 4px 0px 5px"
                  disableClearable
                />
              </div>
            )
          }
        />
      )}
      {reportsValue?.reportType === 'By Age Group' && (
        <ReportAgeGrouped
          countData={countAgeData}
          filterFunction={filterFunction}
          toolBar={
            (
              <div style={{ display: 'flex', gap: 10 }}>
                <InputDate
                  name="start_date"
                  value={reportsValue?.start_date}
                  change={handleFunction}
                  customPadding="8px 0px 8px 7px"
                />
                <SelectBox
                  name="reportType"
                  lists={ReportList}
                  onchange={handleFunction}
                  value={reportsValue?.reportType}
                  customPadding="0px 4px 0px 5px"
                  disableClearable
                />
              </div>
            )
          }
        />
      )}
      {reportsValue?.reportType === 'By Stay/Stare Time' && (
        <ReportStayStared
          countData={countStareData}
          filterFunction={filterFunction}
          toolBar={
            (
              <div style={{ display: 'flex', gap: 10 }}>
                <InputDate
                  name="start_date"
                  value={reportsValue?.start_date}
                  change={handleFunction}
                  customPadding="8px 0px 8px 7px"
                />
                <SelectBox
                  name="reportType"
                  lists={ReportList}
                  onchange={handleFunction}
                  value={reportsValue?.reportType}
                  customPadding="0px 4px 0px 5px"
                  disableClearable
                />
              </div>
            )
          }
        />
      )}
    </div>
  );
}

export default AiAnalyticsReports;
