import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { CircularProgress } from '@mui/material';
import templates from '../../../utils/api/templates';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function PosStyleTemplateEditor({ setNewData }) {
  const template = useSelector((state) => state?.currentTemplate?.templateContent);
  const dispatch = useDispatch();
  const [arr, setArr] = useState(template);
  const [loading, setLoading] = useState('');
  useEffect(() => {
    setNewData([arr]);
    dispatch(updateCurrentTemplate(arr));
  }, [arr]);
  const [imageRefs] = useState(template?.template_images?.map(() => createRef()));

  const [selectedMtFont, setSelectedMtFont] = useState(arr?.styles.title_style?.fontFamily);
  const [selectedMstFont, setSelectedMstFont] = useState(arr?.styles?.sub_title_style
    ? arr?.styles?.sub_title_style?.fontFamily : '');
  const [selectedCmFont, setSelectedCmFont] = useState(arr?.styles?.cate_style
    ? arr?.styles?.cate_style?.fontFamily : '');
  const [selectedInFont, setSelectedInFont] = useState(arr?.styles?.item_style
    ? arr?.styles?.item_style?.fontFamily : '');
  const [selectedIdFont, setSelectedIdFont] = useState(arr?.styles?.desc_style
    ? arr?.styles?.desc_style?.fontFamily : '');
  const [selectedIphFont, setSelectedIphFont] = useState(arr?.styles?.offer_price_style
    ? arr?.styles?.offer_price_style?.fontFamily : '');
  const [selectedIpFont, setSelectedIpFont] = useState(arr?.styles?.price_style
    ? arr?.styles?.price_style?.fontFamily : '');

  const fontStyles = ['Arial',
    'Helvetica',
    'Times New Roman',
    'Courier New',
    'Verdana',
    'Georgia',
    'Palatino',
    'Garamond',
    'Bookman',
    'Comic Sans MS',
    'sans-serif',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
    'Lucida Console'];
  const [isMenuTitleOpen, setMtIsOpen] = useState(false);
  const [isMstOpen, setMstIsOpen] = useState(false);
  const [isCmOpen, setCmIsOpen] = useState(false);
  const [isInOpen, setInIsOpen] = useState(false);
  const [isIdOpen, setIdIsOpen] = useState(false);
  const [isIphOpen, setIphIsOpen] = useState(false);
  const [isIpOpen, setIpIsOpen] = useState(false);
  const [isBgOpen, setBgIsOpen] = useState(false);

  const [MenuTitleColor, setMenuTitleColor] = useState(arr?.styles?.title_style
    ? arr?.styles?.title_style?.color : '#000000');
  const [MstColor, setMstColor] = useState(arr?.styles?.sub_title_style
    ? arr?.styles?.sub_title_style?.color : '#000000');
  const [CmColor, setCmColor] = useState(arr?.styles?.cate_style
    ? arr?.styles?.cate_style?.color : '#000000');
  const [InColor, setInColor] = useState(arr?.styles?.item_style
    ? arr?.styles?.item_style?.color : '#000000');
  const [IdColor, setIdColor] = useState(arr?.styles?.desc_style
    ? arr?.styles?.desc_style?.color : '#000000');
  const [IphColor, setIphColor] = useState(arr?.styles?.offer_price_style
    ? arr?.styles?.offer_price_style?.color : '#000000');
  const [IpColor, setIpColor] = useState(arr?.styles?.price_style
    ? arr?.styles?.price_style?.color : '#000000');
  const [bgColor, setBgColor] = useState(arr?.styles?.background_color
    ? arr?.styles?.background_color : '#000000');

  const [selectedMtSize, setSelectedMtSize] = useState(arr?.styles?.title_style?.fontSize);
  const [selectedMstSize, setSelectedMstSize] = useState(arr?.styles?.sub_title_style
    ? arr?.styles?.sub_title_style?.fontSize : '');
  const [selectedCmSize, setSelectedCmSize] = useState(arr?.styles?.cate_style
    ? arr?.styles?.cate_style?.fontSize : '');
  const [selectedInSize, setSelectedInSize] = useState(arr?.styles?.item_style
    ? arr?.styles?.item_style?.fontSize : '');
  const [selectedIdSize, setSelectedIdSize] = useState(arr?.styles?.desc_style
    ? arr?.styles?.desc_style?.fontSize : '');
  const [selectedIphSize, setSelectedIphSize] = useState(arr?.styles?.offer_price_style
    ? arr?.styles?.offer_price_style?.fontSize : '');
  const [selectedIpSize, setSelectedIpSize] = useState(arr?.styles?.price_style
    ? arr?.styles?.price_style?.fontSize : '');

  const handleMtSizeChange = (e) => {
    setSelectedMtSize(e.target.value);
    const newSize = e.target.value;
    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles.title_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.title_style = updatedTitleStyle;
      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };

  const handleMstSizeChange = (e) => {
    setSelectedMstSize(e.target.value);
    const newSize = e.target.value;
    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.sub_title_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.sub_title_style = updatedTitleStyle;
      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleCmSizeChange = (e) => {
    setSelectedCmSize(e.target.value);
    const newSize = e.target.value;
    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.cate_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.cate_style = updatedTitleStyle;
      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleInSizeChange = (e) => {
    setSelectedInSize(e.target.value);
    const newSize = Number(e.target.value);

    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.item_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.item_style = updatedTitleStyle;

      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleIdSizeChange = (e) => {
    setSelectedIdSize(e.target.value);
    const newSize = Number(e.target.value);

    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.desc_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.desc_style = updatedTitleStyle;

      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleIphSizeChange = (e) => {
    setSelectedIphSize(e.target.value);
    const newSize = e.target.value;
    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.offer_price_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.offer_price_style = updatedTitleStyle;
      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleIpSizeChange = (e) => {
    setSelectedIpSize(e.target.value);
    const newSize = e.target.value;
    setArr((prevState) => {
      // Make a copy of the styles object
      const updatedStyles = { ...prevState?.styles };
      // Make a copy of the title_style object
      const updatedTitleStyle = { ...updatedStyles?.price_style };
      // Update the fontSize property
      updatedTitleStyle.fontSize = newSize;
      // Update the title_style within the styles object
      updatedStyles.price_style = updatedTitleStyle;
      // Return the updated state object with the new styles
      return {
        ...prevState,
        styles: updatedStyles,
      };
    });
  };
  const handleMenuTitleDivClick = () => {
    setMtIsOpen(!isMenuTitleOpen);
  };
  const handleMstDivClick = () => {
    setMstIsOpen(!isMstOpen);
  };
  const handleCmDivClick = () => {
    setCmIsOpen(!isCmOpen);
  };
  const handleInDivClick = () => {
    setInIsOpen(!isInOpen);
  };
  const handleIdDivClick = () => {
    setIdIsOpen(!isIdOpen);
  };
  const handleIphDivClick = () => {
    setIphIsOpen(!isIphOpen);
  };
  const handleIpDivClick = () => {
    setIpIsOpen(!isIpOpen);
  };
  const handleBgDivClick = () => {
    setBgIsOpen(!isBgOpen);
  };

  const handleMenuTitleColorChange = (color) => {
    setMenuTitleColor(color?.hex);
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        title_style: {
          ...prevState?.styles?.title_style,
          color: color?.hex,
        },
      },
    }));
    setMtIsOpen(false);
  };
  const handleMstColorChange = (color) => {
    setMstColor(color?.hex);
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        sub_title_style: {
          ...prevState?.styles?.sub_title_style,
          color: color?.hex,
        },
      },
    }));
    setMstIsOpen(false);
  };
  const handleCmColorChange = (color) => {
    setCmColor(color?.hex);
    // Close the color picker after selecting a color
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        cate_style: {
          ...prevState?.styles?.cate_style,
          color: color?.hex,
        },
      },
    }));
    setCmIsOpen(false);
  };
  const handleInColorChange = (color) => {
    setInColor(color?.hex);
    // Close the color picker after selecting a color
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        item_style: {
          ...prevState?.styles?.item_style,
          color: color?.hex,
        },
      },
    }));
    setInIsOpen(false);
  };
  const handleIdColorChange = (color) => {
    setIdColor(color?.hex);
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        desc_style: {
          ...prevState?.styles?.desc_style,
          color: color?.hex,
        },
      },
    }));
    setIdIsOpen(false);
  };
  const handleIphColorChange = (color) => {
    setIphColor(color?.hex);
    // Close the color picker after selecting a color
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        offer_price_style: {
          ...prevState?.styles?.offer_price_style,
          color: color?.hex,
        },
      },
    }));
    setIphIsOpen(false);
  };
  const handleIpColorChange = (color) => {
    setIpColor(color?.hex);
    // Close the color picker after selecting a color
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        price_style: {
          ...prevState?.styles?.price_style,
          color: color?.hex,
        },
      },
    }));
    setIpIsOpen(false);
  };
  const handleChangeBackgroundImage = (e, value) => {
    e.preventDefault();
    imageRefs[value]?.current?.click();
  };
  const handleFileChange = async (e, newValue) => {
    const file = e.target.files[0];
    const request = {
      'background-image': file,
    };
    setLoading(newValue);
    templates.updateTemplateImage(request).then((res) => {
      setLoading('');
      const newData = JSON.parse(JSON.stringify(arr));
      newData.template_images[newValue].image = res?.data?.background_image;
      setArr(newData);
    })
      .catch((error) => {
        setLoading('');
        dispatch(setErrorNotification(error?.response?.data));
      });
    e.target.value = '';
  };
  const removeBackgroundImage = (values) => {
    const updatedImages = [...arr.template_images]; // Create a copy of the original array
    const updatedObject = { ...updatedImages[values], image: null };
    // Replace keyToUpdate with the key you want to update
    updatedImages[values] = updatedObject; // Update the object at the specified index
    setArr((prevState) => ({
      ...prevState,
      template_images: updatedImages, // Set the state with the updated array
    }));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color?.hex);
    // Close the color picker after selecting a color
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        background_color: color?.hex,
      },
    }));
    setBgIsOpen(false);
  };

  const handleMtFamilyChange = (e) => {
    setSelectedMtFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        title_style: {
          ...prevState?.styles?.title_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleMstFamilyChange = (e) => {
    setSelectedMstFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        sub_title_style: {
          ...prevState?.styles?.sub_title_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleCmFamilyChange = (e) => {
    setSelectedCmFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        cate_style: {
          ...prevState?.styles?.cate_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleInFamilyChange = (e) => {
    setSelectedInFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        item_style: {
          ...prevState?.styles?.item_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleIdFamilyChange = (e) => {
    setSelectedIdFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        desc_style: {
          ...prevState?.styles?.desc_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleIphFamilyChange = (e) => {
    setSelectedIphFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        offer_price_style: {
          ...prevState?.styles?.offer_price_style,
          fontFamily: ff,
        },
      },
    }));
  };
  const handleIpFamilyChange = (e) => {
    setSelectedIpFont(e.target.value);
    const ff = e.target.value;
    setArr((prevState) => ({
      ...prevState,
      styles: {
        ...prevState?.styles,
        price_style: {
          ...prevState?.styles?.price_style,
          fontFamily: ff,
        },
      },
    }));
  };
  return (
    <div className="template-style-container">
      <div className="style-container">
        <div className="style-box">
          <div>
            <p style={{ marginLeft: '20px', fontSize: '19px' }}>{t('header')}</p>
          </div>
          <div style={{ display: 'flex' }}>
            <p
              style={{
                width: '30%',
                margin: '0px 0px 0px 20px',
                fontSize: '15px',
                color: 'gray',
              }}
            >
              {t('menuTitle')}
            </p>

            <div>
              <div
                style={{
                  margin: '5px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: MenuTitleColor,
                  cursor: 'pointer',
                }}
                onClick={handleMenuTitleDivClick}
                role="none"
              />
              {isMenuTitleOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={MenuTitleColor} onChange={handleMenuTitleColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{
                  margin: '3px 0px 0px 20px',
                  border: 'none',
                  outline: 'none',
                }}
                id="fontSelector"
                value={selectedMtFont}
                onChange={handleMtFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedMtSize}
                style={{ margin: '3px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleMtSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10px">Small</option>
                <option value="20px">Medium</option>
                <option value="30px">Large</option>
              </select>
            </div>
          </div>
          <div
            style={{ display: 'flex' }}
          >
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 30px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('menuSubtitle')}
            </p>
            <div>
              <div
                style={{
                  margin: '25px 0px 0px 5px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: MstColor,
                  cursor: 'pointer',
                }}
                onClick={handleMstDivClick}
                role="none"
              />
              {isMstOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={MstColor} onChange={handleMstColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={
                  {
                    margin: '25px 0px 0px 25px', border: 'none', outline: 'none',
                  }
                }
                id="fontSelector"
                value={selectedMstFont}
                onChange={handleMstFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedMstSize}
                style={{ margin: '25px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleMstSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10px">Small</option>
                <option value="20px">Medium</option>
                <option value="30px">Large</option>
              </select>
            </div>
          </div>
          <div style={{ margin: '20px 0', border: '0.75px solid #CED0D3', width: '100vw' }} />
          <p style={{ marginLeft: '20px', fontSize: '19px' }}>{t('listItems')}</p>
          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('categoryMenu')}
            </p>
            <div>
              <div
                style={{
                  margin: '25px 0px 0px 0px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: CmColor,
                  cursor: 'pointer',
                }}
                onClick={handleCmDivClick}
                role="none"
              />
              {isCmOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={CmColor} onChange={handleCmColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                id="fontSelector"
                value={selectedCmFont}
                onChange={handleCmFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedCmSize}
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleCmSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10px">Small</option>
                <option value="20px">Medium</option>
                <option value="30px">Large</option>
              </select>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('item name')}
            </p>

            <div>
              <div
                style={{
                  marginTop: '20px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: InColor,
                  cursor: 'pointer',
                }}
                onClick={handleInDivClick}
                role="none"
              />
              {isInOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={InColor} onChange={handleInColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                id="fontSelector"
                value={selectedInFont}
                onChange={handleInFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedInSize}
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleInSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10">Small</option>
                <option value="20">Medium</option>
                <option value="25">Large</option>
              </select>

            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('itemDescription')}
            </p>

            <div>
              <div
                style={{
                  marginTop: '20px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: IdColor,
                  cursor: 'pointer',
                }}
                onClick={handleIdDivClick}
                role="none"
              />
              {isIdOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={IdColor} onChange={handleIdColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                id="fontSelector"
                value={selectedIdFont}
                onChange={handleIdFamilyChange}
              >

                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedIdSize}
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleIdSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10">Small</option>
                <option value="15">Medium</option>
                <option value="20">Large</option>
              </select>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('itemPrice')}
            </p>

            <div>
              <div
                style={{
                  marginTop: '20px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  borderRadius: '2px',
                  backgroundColor: IphColor,
                  cursor: 'pointer',
                }}
                onClick={handleIphDivClick}
                role="none"
              />
              {isIphOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={IphColor} onChange={handleIphColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                id="fontSelector"
                value={selectedIphFont}
                onChange={handleIphFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedIphSize}
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleIphSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10px">Small</option>
                <option value="20px">Medium</option>
                <option value="25px">Large</option>
              </select>

            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              {t('offerprice')}
            </p>
            <div>
              <div
                style={{
                  marginTop: '20px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  backgroundColor: IpColor,
                  cursor: 'pointer',
                }}
                onClick={handleIpDivClick}
                role="none"
              />
              {isIpOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={IpColor} onChange={handleIpColorChange} />
                </div>
              )}
            </div>
            <div>
              <select
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                id="fontSelector"
                value={selectedIpFont}
                onChange={handleIpFamilyChange}
              >
                <option value="">{t('select a font')}</option>
                {fontStyles?.map((font) => (
                  <option style={{ fontFamily: font }} key={font} value={font}>{font}</option>
                ))}
              </select>
            </div>
            <div>
              <select
                id="fontSizeSelector"
                value={selectedIpSize}
                style={{ margin: '20px 0px 0px 50px', border: 'none', outline: 'none' }}
                onChange={handleIpSizeChange}
              >
                <option value="">{t('selectSize')}</option>
                <option value="10px">Small</option>
                <option value="20px">Medium</option>
                <option value="25px">Large</option>
              </select>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <p
              style={
                {
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '15px', color: 'gray',
                }
              }
            >
              Background color
            </p>
            <div>
              <div
                style={{
                  marginTop: '20px',
                  width: '30px',
                  height: '20px',
                  border: '1px solid gray',
                  backgroundColor: bgColor,
                  cursor: 'pointer',
                }}
                onClick={handleBgDivClick}
                role="none"
              />
              {isBgOpen && (
                <div style={{ position: 'absolute', top: '150px', left: '500px' }}>
                  <SketchPicker color={bgColor} onChange={handleBgColorChange} />
                </div>
              )}
            </div>
          </div>
          <div style={{ margin: '20px 0', border: '0.75px solid #CED0D3', width: '100vw' }} />
          {arr?.template_images?.length > 0 && (
            <div style={{ marginBottom: '10px' }}>
              <p
                style={{
                  width: '30%', margin: '20px 0px 0px 20px', fontSize: '19px', color: 'black',
                }}
              >{t('images')}
              </p>
            </div>
          )}
          {arr?.template_images?.length > 0 && arr?.template_images?.map((image, imgIndex) => (
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0 0 20px' }} key={image}>
              <div style={{ width: '100px', height: '50px' }}>
                {loading === imgIndex
                  ? (
                    <div style={{ display: 'grid', placeItems: 'center' }}>
                      <CircularProgress color="success" />
                    </div>
                  )
                  : (
                    image?.image !== null && (
                      <img
                        src={image?.image}
                        alt="background"
                        loading="lazy"
                      />
                    )
                  )}
              </div>
              <div>
                <input
                  type="file"
                  ref={imageRefs[imgIndex]}
                  accept="image/*"
                  id={`fileInput-${imgIndex}`}
                  onChange={(e) => handleFileChange(e, imgIndex)}
                  style={{ display: 'none' }}
                />
                <button
                  type="button"
                  style={{ margin: '0px 0px 0px 50px', border: 'none' }}
                  onClick={(e) => handleChangeBackgroundImage(e, imgIndex)}
                >
                  {image?.image === null ? 'Add-image' : 'Replace'}
                </button>
                {image?.image !== null && (
                  <button
                    type="button"
                    style={{ margin: '0px 0px 0px 50px', border: 'none' }}
                    onClick={() => removeBackgroundImage(imgIndex)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

PosStyleTemplateEditor.propTypes = {
  setNewData: PropTypes.objectOf().isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default PosStyleTemplateEditor;
