import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';

import { checkFeatures, checkPermission } from '../../../utils/helpers';
import { fetchPermissionList } from '../../../redux/slices/RolesSlice';

function RolesView() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions, roles } = useSelector((state) => state.roles);
  const rolesData = roles.find((val) => val.id === params?.id);

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  useEffect(() => {
    if (roles.length === 0) {
      navigate('../');
    }
  }, [params]);
  const editRole = () => {
    navigate(`../edit/${params?.id}`);
  };

  useEffect(() => getPermissions(), []);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('roles'), rolesData?.name]}
          buttonClass="default-button button-outline-success-1"
          button={checkPermission('editRole') && checkFeatures('role', 'edit')}
          buttonLabel={t('editRole')}
          click={editRole}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('role')}
                  type="text"
                  name="name"
                  readonly
                  value={rolesData?.name}
                />
              </div>
              <div className="my-content-permissions">
                <div className="my-content-permissions-list">
                  <h3 className="title">{t('permissions')}</h3>
                  <div className="permission-list">
                    <div className="permission-lis-session">
                      {
                        permissions.map((values) => (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">{values.name}</FormLabel>
                            <FormGroup
                              aria-label="position"
                              row
                              sx={{
                                gap: 7,
                                rowGap: 2,
                              }}
                            >
                              {
                                values?.children.map((e) => (
                                  <FormControlLabel
                                    value={e.value}
                                    control={(
                                      <Checkbox
                                        size="small"
                                        sx={{
                                          color: '#30A84B',
                                          '&.Mui-checked': {
                                            color: '#30A84B',
                                          },
                                        }}
                                        checked={rolesData?.permissions?.includes(e.value)}
                                      // onChange={handleChange}
                                      />
                                    )}
                                    label={e.name}
                                  />
                                ))
                              }
                            </FormGroup>
                          </FormControl>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesView;
