import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/icons/close-circle.png';
import mailIcon from '../../../assets/icons/mail-icon-white.png';
import contentBoard from '../../../assets/images/content-board.png';

function CustomBannerTemplate() {
  const { t } = useTranslation();
  const label = t('reach out to us for more customized templates that perfectly suits your specific needs');
  const [board, setBoard] = useState(true);
  const handleBoardClose = () => {
    setBoard(false);
  };
  return (
    board
    && (
      <div className="template-custom-banner">
        <div className="custom-board-content" onClick={() => handleBoardClose()} role="none">
          <img src={closeIcon} alt="close-icon" />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className="custom-board-content-container">
            <p className="top-content">{t('customTemplates')}</p>
            <p className="mid-content">
              {label}
            </p>
            <div className="mail-icon-container">
              <div className="mail-icon"><img src={mailIcon} alt="mail-icon" />
              </div>
              <div>sales@wejha.com</div>

            </div>
          </div>
          <div className="content-board-image-container">
            <img src={contentBoard} alt="content-board" />
          </div>
        </div>
      </div>
    )
  );
}
export default CustomBannerTemplate;
