/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import contentLayout from '../../utils/api/content-layout';
import { setErrorNotification } from './NotificationSlice';

export const fetchContentLayoutWidgets = createAsyncThunk(
  'contentLayoutWidgets/fetchContentLayoutWidgets',
  async (data, { dispatch }) => {
    const response = await contentLayout.getSavedWidgets(data)
      .then((res) => ({
        ...res?.data,
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  contentLayoutWidgets: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeed',
};

export const contentLayoutWidgetSlice = createSlice({
  name: 'contentLayoutWidgets',
  initialState,
  reducers: {
    resetContentLayoutWidgets: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchContentLayoutWidgets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContentLayoutWidgets.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.contentLayoutWidgets = fetchedData;
          } else {
            const finalData = [...state.contentLayoutWidgets];
            // Add fetchedData to finalData, but only if the id is not already present
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.contentLayoutWidgets = finalData;
          }
        }
        state.status = 'succeed';
      })
      .addCase(fetchContentLayoutWidgets.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  resetContentLayoutWidgets,
} = contentLayoutWidgetSlice.actions;

export default contentLayoutWidgetSlice.reducer;
