import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import SelectBox from '../../../shared/component/form/SelectBox';

import devices from '../../../utils/api/devices';

import { fetchStoreLocationListAll } from '../../../redux/slices/StoreLocationSlice';
import { createNewDisplay } from '../../../redux/slices/DisplaySignageSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DisplaySignageAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store.storeLocationAll);

  const [value, setValue] = useState({});
  const [stores, setStores] = useState([]);
  const [disable, setDisable] = useState(true);

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);

  const addDisplaySignage = () => {
    navigate('../');
  };

  const submit = () => {
    setDisable(true);
    setUploading(true);
    const data = {
      device_system_id: value.device_system_id,
      name: value.name,
      store: value.store,
    };
    devices.addDevice(data).then((res) => {
      dispatch(createNewDisplay(res?.data));
      setUploading(false);
      setNotification(true);
      setTimeout(() => {
        setNotification(false);
        navigate('../');
        setDisable(false);
      }, 3000);
    }).catch((error) => {
      setUploading(false);
      setDisable(false);
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'store') {
      if (changeValue) {
        setValue({ ...value, [name]: changeValue.id, store_name: changeValue.label });
      }
    } else {
      setValue({ ...value, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (storeData.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
    if (stores.length === 0) {
      const store = storeData.map((val) => ({ label: val.name, id: val.id }));
      setStores(store);
    }
    if (value.store && value.name && value.device_system_id) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [storeData, value]);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={{ content: <Success message={t('displaySignageCreatedSuccess')} /> }}
        notification
      />
      <div className="main-content">
        <TopContent
          label={[t('signageDevices'), t('createNewDisplaySignage')]}
          button={false}
          buttonLabel="Back"
          buttonClass="success-button"
          click={addDisplaySignage}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area">
              <div className="signage-form">
                <div className="input-fields">
                  <SelectBox
                    label={t('storeName')}
                    name="store"
                    required
                    value={value.store_name}
                    onchange={changeHandle}
                    lists={stores}
                  />
                  <Input
                    icon={' '}
                    label={t('deviceName')}
                    type="text"
                    name="name"
                    required
                    value={value.name}
                    change={changeHandle}
                  />
                </div>
                <div className="input-text-area">
                  <Input
                    icon={' '}
                    label={t('deviceID')}
                    type="text"
                    name="device_system_id"
                    required
                    value={value.device_system_id}
                    change={changeHandle}
                  />
                </div>
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button label={t('back')} click={addDisplaySignage} classes="default-button" />
                </div>
                <div className="form-button">
                  <Button
                    label={uploading ? t('creating') : t('create')}
                    click={submit}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    disabled={disable}
                    loading={uploading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplaySignageAdd;
