import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../shared/component/svgIcon';
import otaUpdate from '../../../utils/api/otaUpdate';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fetchDisplayGroupList } from '../../../redux/slices/DisplayGroupSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';

function DeviceUpdate(props) {
  const {
    rowSelection,
    setDeviceUpdate,
    closePopup,
    setRowSelection,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [successNotification, setSuccessNotification] = useState();

  // Update functionality
  const submit = () => {
    const data = {
      device_groups: rowSelection,
    };
    otaUpdate.createOtaRequest(data)
      .then(() => {
        setSuccessNotification(true);
        setTimeout(() => {
          setSuccessNotification(false);
          setDeviceUpdate(false);
          setRowSelection({});
          dispatch(fetchDisplayGroupList({ page: 1, limit: 20 }));
          closePopup();
        }, 3000);
      })
      .catch((err) => {
        closePopup();
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  return (
    <div className="ota-update-popup">
      <ModalBox
        status={successNotification}
        closeModal={setSuccessNotification}
        modalView={{ content: <Success message={t('updateRequestSuccess')} /> }}
        notification
      />
      <div className="device-count">
        <span
          role="presentation"
          onClick={closePopup}
        >
          <CloseIcon />
        </span>
        <p> {rowSelection?.length} Device Group Selected</p>
      </div>
      <span
        role="presentation"
        onClick={submit}
      >
        Update Now
      </span>
    </div>
  );
}

function OtaUpdateGroup(props) {
  return (
    // Snackbar to to show update button
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={props?.openPopup}
      onClose={props?.closePopup}
      message={(
        <DeviceUpdate
          rowSelection={props?.rowSelection}
          closePopup={props?.closePopup}
          setDeviceUpdate={props?.setDeviceUpdate}
          setRowSelection={props?.setRowSelection}
        />
      )}
    />
  );
}
DeviceUpdate.propTypes = {
  rowSelection: PropTypes.arrayOf().isRequired,
  setDeviceUpdate: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  setRowSelection: PropTypes.func.isRequired,
};
OtaUpdateGroup.propTypes = {
  openPopup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
  rowSelection: PropTypes.arrayOf().isRequired,
  setDeviceUpdate: PropTypes.func.isRequired,
  setRowSelection: PropTypes.func.isRequired,
};

export default OtaUpdateGroup;
