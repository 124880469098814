import {
  get,
} from './base/index';

export default {
  getDeviceRentals: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/device-rental`, { params });
  },
};
