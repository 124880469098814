import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import devices from '../../../utils/api/devices';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
}));

function PieCenterLabel({ children }) {
  const {
    width,
    height,
    left,
    top,
  } = useDrawingArea();
  return (
    <StyledText x={left + width / 1.4} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

function DeviceStats(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalStorage, setTotalStorage] = useState(0);
  const [pieChartData, setPieChartData] = useState([
    {
      label: t('availableStorage'),
      value: 1,
      key: 'device_available_storage',
      color: '#FACC15',
    },
    {
      label: t('usedByPlayerApp'),
      value: 0,
      key: 'device_used_storage',
      color: '#30A84B',
    },
    {
      label: t('usedByOtherApps'),
      value: 0,
      key: 'other_used_storage',
      color: '#FF5555',
    },
  ]);

  const chartLabel = [
    {
      color: '#FACC15',
      label: t('available'),
    },
    {
      color: '#30A84B',
      label: t('playerApp'),
    },
    {
      color: '#FF5555',
      label: t('otherApps'),
    },
  ];

  const updatePieChartData = (storageData) => {
    const updatedChartData = pieChartData.map((data) => {
      let returnValue = data;
      if (data?.key !== 'other_used_storage' && storageData[data.key]) {
        const updatedValue = storageData[data.key]; // Get the value from the response using the key
        returnValue = {
          ...data,
          value: updatedValue, // Update the value property
        };
      } else if (data?.key === 'other_used_storage' && storageData?.device_total_storage) {
        const availableStorage = storageData.device_total_storage - (
          storageData.device_available_storage + storageData.device_used_storage
        );
        returnValue = {
          ...data,
          value: availableStorage, // Update the value property
        };
      }
      return returnValue;
    });
    // Now set the state or update the chart with the updatedChartData
    // For example, if using state:
    setPieChartData(updatedChartData);
  };

  useEffect(() => {
    devices.getStorageStats(props?.deviceId)
      .then((res) => {
        updatePieChartData(res?.data);
        setTotalStorage(res?.data?.device_total_storage || 0);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  }, []);

  return (
    <div className="device-stats-wrap">
      <PieChart
        series={[
          {
            data: pieChartData,
            cx: 140,
            paddingAngle: 0.5,
            cornerRadius: 2,
            innerRadius: 80,
            outerRadius: 120,
          },
        ]}
        width={300}
        height={300}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
      >
        <PieCenterLabel>{totalStorage} GB</PieCenterLabel>
      </PieChart>
      <div className="chart-labels">
        {chartLabel.map((each) => (
          <div className="each-label">
            <div
              className="for-color"
              style={{ backgroundColor: each?.color }}
            />
            <p>{each?.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
PieCenterLabel.propTypes = {
  children: PropTypes.node.isRequired,
};
DeviceStats.propTypes = {
  deviceId: PropTypes.string.isRequired,
};
export default DeviceStats;
