import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LayoutEditIcon } from './svgIcon';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';

function DropDown(props) {
  const {
    value,
    action,
    icon,
    displayEditIcon,
    onClickEditIcon,
  } = props;

  const inputRef = useRef();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editName, setEditName] = useState(false);
  const [selectedLayoutName, setSelectedLayoutName] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (selectedLayoutName !== '') {
      setAnchorEl(null);
    } else if (selectedLayoutName === '') {
      dispatch(setErrorNotification({ message: 'Name is required!' }));
    }
  };

  // Function to check enter key clicked inside input field
  const handleKeyDown = (event) => {
    if (event?.keyCode === 13
      && event?.key === 'Enter'
      && selectedLayoutName !== ''
    ) {
      setEditName(false);
      onClickEditIcon(value?.id, selectedLayoutName);
    } else if (event?.keyCode === 13
      && event?.key === 'Enter'
      && selectedLayoutName === ''
    ) {
      dispatch(setErrorNotification({ message: 'Name is required!' }));
    } else {
      event.stopPropagation();
    }
  };

  const handleViewInputField = () => {
    setEditName(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  };

  const handleEditSavedLayoutName = (event) => {
    setSelectedLayoutName(event?.target?.value);
  };

  const handleBlur = () => {
    if (selectedLayoutName !== '') {
      onClickEditIcon(value?.id, selectedLayoutName);
      setEditName(false);
    } else if (selectedLayoutName === '') {
      dispatch(setErrorNotification({ message: 'Name is required!' }));
    }
  };

  const handleOptionClick = (clickFunc, sendFullDetails, valueDetails) => {
    clickFunc(sendFullDetails ? valueDetails : valueDetails?.id);
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedLayoutName(value?.name);
  }, [value]);

  return (
    <div className="dropDown-menu-wrap">
      <div
        role="presentation"
        className="action-button"
        id={`basic-button${value?.id}`}
        onClick={handleClick}
      >
        {icon}
      </div>
      <Menu
        id={`basic-menu${value?.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="dropDown-menus"
      >
        <div className="dropDown-menu-header">
          {
            editName
              ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={selectedLayoutName}
                  onChange={(event) => handleEditSavedLayoutName(event)}
                  onBlur={handleBlur}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              )
              : <p>{selectedLayoutName}</p>
          }
          {(displayEditIcon && !editName)
            && (
              <span
                role="presentation"
                onClick={handleViewInputField}
              >
                <LayoutEditIcon
                  color="#212B36"
                  width="20"
                  height="20"
                />
              </span>
            )}
        </div>
        {action?.map((item) => (
          <MenuItem
            onClick={() => handleOptionClick(item?.function, item?.sendFullDetails, value)}
            disabled={item?.disabled}
          >
            <p
              style={{ color: item?.labelColor || '#212B36' }}
              className="dropDown-menu-item"
            >
              {item?.name}
            </p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

DropDown.propTypes = {
  value: PropTypes.objectOf().isRequired,
  action: PropTypes.arrayOf().isRequired,
  icon: PropTypes.string.isRequired,
  displayEditIcon: PropTypes.bool,
  onClickEditIcon: PropTypes.func,
};

DropDown.defaultProps = {
  displayEditIcon: false,
  onClickEditIcon: () => { },
};

export default DropDown;
