import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';

import Button from './Button';
import { SessionExpiredIcon } from './svgIcon';
import { resetAllSlices } from '../../redux/slices/ReduxStateResetSlice';

import auth from '../../utils/api/auth';
import feature from '../../utils/api/feature';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  getCookieStorageItem,
  setCookieStorageItem,
  removeCookieStorageItem,
} from '../../utils/helpers';

function Authenticate(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = getLocalStorageItem('data');
  const refreshToken = getCookieStorageItem('secure-auth-refresh');
  const [loading, setLoading] = useState(false);

  const handleAuthenticate = async () => {
    setLoading(true);
    try {
      const generatedToken = await auth.getAccessToken({
        refreshToken,
      });
      const newToken = {
        user: data?.user,
      };
      setLocalStorageItem('data', newToken);
      setCookieStorageItem('secure-auth-access', generatedToken?.data?.access?.token);
      setCookieStorageItem('secure-auth-refresh', generatedToken?.data?.refresh?.token);

      const token = generatedToken?.data?.access?.token;
      const decoded = jwtDecode(token);
      setLocalStorageItem('role', decoded?.role);

      const planFeatures = await feature.getFeatures();
      setLocalStorageItem('features', planFeatures?.data);
      props?.setSuccessNotification(false);
      setLoading(false);
      window.location.reload();
    } catch (err) {
      navigate('/login');
      dispatch(resetAllSlices());
      props?.setSuccessNotification(false);
      removeCookieStorageItem('secure-auth-access');
      removeCookieStorageItem('secure-auth-refresh');
      localStorage.removeItem('data');
      localStorage.removeItem('role');
      localStorage.removeItem('features');
      localStorage.removeItem('walkthroughCompleted');
      setLoading(false);
    }
  };

  const handleLogout = () => {
    props?.setSuccessNotification(false);
    navigate('/login');
    dispatch(resetAllSlices());
  };

  return (
    <div className="authenticate-wrap">
      <div className="authenticate-container">
        <div className="authenticate-header">
          <h1>{t('sessionExpired')}</h1>
          <span><SessionExpiredIcon /></span>
        </div>
        <p>{t('yourSessionExpired')}</p>
      </div>
      <div className="authenticate-button">
        <Button
          label={t('keepMeLoggedOut')}
          click={handleLogout}
          classes="default-button"
        />
        <Button
          label={t('refresh')}
          click={handleAuthenticate}
          classes={loading ? 'success-button loading-btn' : 'success-button'}
          loading={loading}
        />
      </div>
    </div>
  );
}

Authenticate.propTypes = {
  setSuccessNotification: PropTypes.node.isRequired,
};

export default Authenticate;
