import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';

import {
  DeleteIcon,
  MultiContentEditIcon,
  PlusIcon,
  ViewIcon,
} from '../../../shared/component/svgIcon';
import pdfIcon from '../../../assets/images/pdf-icon.png';
import Button from '../../../shared/component/Button';
import {
  convertBytes,
  fileNameWithoutExtension,
  fileSizeDimensionValidation,
  fileTypeValidation,
  contentUploadValidation,
} from '../../../utils/helpers';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../shared/component/form/Validation';

function SingleFileView(props) {
  const {
    file,
    handleRemoveFile,
    indexOfFile,
    contentName,
    handleEditName,
    thumbnail: thumbnailImage,
  } = props;

  const acceptedFileSize = 300;
  const inputRef = useRef();
  const [sizeAndDimensionValidation, setSizeAndDimensionValidation] = useState('');
  const [deleteIconLoading, setDeleteIconLoading] = useState(true);
  const [editContentName, setEditContentName] = useState(false);

  // Function to check enter key clicked inside input field
  const handleKeyDown = (event) => {
    if (event?.keyCode === 13 && event?.key === 'Enter') {
      setEditContentName(false);
    }
  };

  const handleViewInputField = () => {
    if (sizeAndDimensionValidation === '') {
      setEditContentName(true);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 500);
    }
  };

  useEffect(() => {
    const handleValidation = async () => {
      const value = await fileSizeDimensionValidation(file, acceptedFileSize, true);
      setSizeAndDimensionValidation(value);
      setDeleteIconLoading(false);
    };
    handleValidation();
  }, [file]);

  return (
    <div className="single-file-wrap">
      <div className="single-file-container">
        {
          (() => {
            let thumbnail;
            if (file?.type?.includes('image')) {
              thumbnail = <img src={URL.createObjectURL(file)} alt="" />;
            } else if (file?.type?.includes('video')) {
              thumbnail = <img src={thumbnailImage} alt="" />;
            } else if (file?.type?.includes('pdf')) {
              thumbnail = <img src={pdfIcon} alt="" className="pdf-icon" />;
            }
            return thumbnail;
          })()
        }
        <div className="single-file-details">
          <div className="single-file-name-wrap">
            {editContentName
              ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={contentName}
                  onChange={(event) => handleEditName(event.target.value, indexOfFile)}
                  onBlur={() => setEditContentName(false)}
                  onKeyDown={(event) => handleKeyDown(event)}
                />
              )
              : (
                <h1
                  role="presentation"
                  onClick={handleViewInputField}
                >
                  {contentName}
                </h1>
              )}
          </div>
          {
            (() => {
              let result;
              if (!nameValidation(1, 100, contentName)) {
                result = (
                  <div className="content-name-error">
                    <p className="file-size-error">Invalid content name</p>
                    <Tooltip
                      title="Content name must start with a letter or number,
                       can only contain letters, numbers, and underscores, and be less than 100 characters long."
                      placement="top"
                    >
                      <span className="tooltip-container">
                        <ViewIcon />
                      </span>
                    </Tooltip>
                  </div>
                );
              } else if (sizeAndDimensionValidation === '' && !deleteIconLoading) {
                result = (
                  <div className="type-and-size-wrap">
                    <p>{file?.type?.split('/')?.[1]?.toUpperCase()}</p>
                    <span>{convertBytes(file?.size)}</span>
                  </div>
                );
              } else if (sizeAndDimensionValidation !== '' && !deleteIconLoading) {
                result = (<p className="file-size-error">{sizeAndDimensionValidation}</p>);
              }
              return result;
            })()
          }
        </div>
      </div>
      <div
        className="delete-icon-wrap"
      >
        {deleteIconLoading
          ? (
            <CircularProgress size={20} sx={{ color: '#212B36' }} />
          ) : (
            <div className="delete-edit-icon">
              {!editContentName
                && (
                  <span
                    role="presentation"
                    onClick={handleViewInputField}
                  >
                    <MultiContentEditIcon
                      color={sizeAndDimensionValidation === '' ? '#212B36' : '#9EA3A9'}
                    />
                  </span>
                )}
              <span
                role="presentation"
                onClick={() => handleRemoveFile(indexOfFile)}
              >
                <DeleteIcon
                  width="20"
                  height="20"
                />
              </span>
            </div>
          )}
      </div>
    </div>
  );
}

function MultipleFileUpload(props) {
  const {
    selectedFiles,
    selectMultipleContent,
    closeModal,
  } = props;
  const inputRef = useRef();
  const dispatch = useDispatch();

  const acceptedFileSize = 300;
  const [finalContents, setFinalContents] = useState(selectedFiles || []);
  const [buttonDisable, setButtonDisable] = useState(true);

  // Generate a single thumbnail thumbnail
  const generateSingleThumbnail = async (videoFile) => {
    try {
      const thumbnailArray = await generateVideoThumbnails(videoFile, 1);
      return thumbnailArray?.[0];
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  // function to add more files
  const handleAddFiles = async (addedFiles) => {
    const addedFilesArray = Array.from(addedFiles);

    let checkFileValid = '';

    addedFilesArray.some((file) => {
      const fileType = file?.type === 'application/pdf' ? 'application/pdf' : file.type.split('/')[0];
      checkFileValid = fileTypeValidation(file, fileType);
      return checkFileValid !== ''; // Break the loop if the condition is met
    });
    if (checkFileValid === '') {
      const filesWithThumbnail = await Promise.all(
        addedFilesArray.map(async (file) => ({
          file,
          thumbnail: file?.type?.includes('video') ? await generateSingleThumbnail(file) : null,
          content_name: fileNameWithoutExtension(file?.name || ''),
        })),
      );

      setFinalContents([...finalContents, ...filesWithThumbnail]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  // function to remove a single file based on index
  const handleRemoveFile = (indexToRemove) => {
    setFinalContents((prevContents) => prevContents.filter((_, index) => index !== indexToRemove));
  };

  const handleUploadContent = async () => {
    if (finalContents && finalContents.length > 0) {
      const validContents = await Promise.all(
        finalContents.map(async (content) => {
          const result = await contentUploadValidation(
            content?.file,
            acceptedFileSize,
            content?.file?.type === 'application/pdf' ? 'application/pdf' : content?.file?.type.split('/')[0],
            true,
          );
          return result === '' && nameValidation(1, 100, content?.content_name) ? content : null;
        }),
      );

      // Filter out null values (invalid contents)
      const validContentList = validContents.filter((content) => content !== null);

      selectMultipleContent(validContentList);
    }
  };

  const handleEditName = (newName, index) => {
    setFinalContents((prevContents) => prevContents.map((content, contentIndex) => {
      let contentData = content;
      if (contentIndex === index) {
        contentData = {
          ...content,
          content_name: newName,
        };
      }
      return contentData;
    }));
  };

  useEffect(() => {
    const validateFiles = async () => {
      if (finalContents && finalContents.length > 0) {
        const validations = await Promise.all(
          finalContents.map(async (content) => {
            const result = await contentUploadValidation(
              content?.file,
              acceptedFileSize,
              content?.file?.type === 'application/pdf' ? 'application/pdf' : content?.file?.type.split('/')[0],
              true,
            );
            return result === '' && nameValidation(1, 100, content?.content_name);
          }),
        );

        const acceptedContent = validations.some((isValid) => isValid);
        if (acceptedContent) {
          setButtonDisable(false);
        } else {
          setButtonDisable(true);
        }
      }
    };

    if (finalContents?.length > 0) {
      validateFiles();
    } else {
      setButtonDisable(true);
    }
  }, [finalContents]);

  useEffect(() => {
    setFinalContents(selectedFiles);
  }, []);

  return (
    <div className="multi-file-upload-wrap">
      <div className="multi-files-wrap">
        {finalContents.map((content, index) => (
          <SingleFileView
            file={content.file}
            handleRemoveFile={handleRemoveFile}
            handleEditName={handleEditName}
            indexOfFile={index}
            thumbnail={content.thumbnail && content.thumbnail}
            contentName={content.content_name}
          />
        ))}
      </div>
      <input
        type="file"
        id="file"
        ref={inputRef}
        multiple
        onChange={(e) => handleAddFiles(e.target.files)}
        accept="image/*,video/*, application/pdf"
        style={{ display: 'none' }}
      />
      <div
        role="presentation"
        className="add-more-files"
        onClick={() => inputRef.current.click()}
      >
        <span>
          <PlusIcon
            color="#30A84B"
          />
        </span>
        <span>Add files</span>
      </div>
      <div className="button-wrap">
        <Button
          label="Back"
          click={closeModal}
          classes="default-button"
        />
        <Button
          label="Continue"
          click={handleUploadContent}
          classes="success-button"
          disabled={buttonDisable}
        />
      </div>
    </div>
  );
}

SingleFileView.propTypes = {
  file: PropTypes.objectOf().isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  handleEditName: PropTypes.func.isRequired,
  indexOfFile: PropTypes.number.isRequired,
  contentName: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
};

SingleFileView.defaultProps = {
  thumbnail: null,
};

MultipleFileUpload.propTypes = {
  selectedFiles: PropTypes.objectOf().isRequired,
  selectMultipleContent: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default MultipleFileUpload;
