import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import posTemplate from '../../../utils/api/pos-it-easy';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import odoo from '../../../utils/api/odoo';

function AuthenticatePopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [authToken, setAuthToken] = useState({});

  const handleFunction = (name, value) => {
    setAuthToken((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const submit = () => {
    setUploading(true);
    setDisable(true);
    if (props?.integration?.title === 'POSitEasy') {
      posTemplate.addPosToken(authToken)
        .then(() => {
          setUploading(false);
          setDisable(false);
          props?.setAuthOpenModal(false);
          props?.setOpenModal(true);
        })
        .catch((error) => {
          props?.setAuthOpenModal(false);
          if (error?.response?.data?.code === 500) {
            props?.setErrorModal(true);
          }
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
    if (props?.integration?.title === 'ODOO') {
      odoo.addOdooToken(authToken)
        .then(() => {
          setUploading(false);
          setDisable(false);
          props?.setAuthOpenModal(false);
          props?.setOpenModal(true);
        })
        .catch((error) => {
          props?.setAuthOpenModal(false);
          if (error?.response?.data?.code === 500) {
            props?.setErrorModal(true);
          }
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };
  useEffect(() => {
    if (authToken) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [authToken]);
  return (
    <div className="authenticate-popup">
      <span className="title">
        {t(`welcomeTo${props?.integration?.title}`)}
      </span>
      <span className="sub-title">
        {t(`enterKeyProvidedBy${props?.integration?.title}`)}
      </span>
      <div className="content-body">
        <Input
          icon={' '}
          label={t('code')}
          type="text"
          name="code"
          required
          value={authToken.code}
          change={handleFunction}
          placeholder=""
        />
        {props?.integration?.title === 'ODOO'
          && (
            <Input
              icon={' '}
              label={t('URL End point')}
              type="text"
              name="url"
              required
              value={authToken.url}
              change={handleFunction}
              placeholder=""
            />
          )}
        {props?.integration?.title === 'ODOO' && (<p className="example-url">ex: http://192.168.208.100:3000</p>)}
        <Button
          label={uploading ? t('authenticating') : t('authenticate')}
          click={submit}
          disabled={disable}
          classes={uploading ? 'success-button loading-btn' : 'success-button'}
          loading={uploading}
        />
      </div>
    </div>
  );
}

AuthenticatePopup.propTypes = {
  setAuthOpenModal: PropTypes.func.isRequired,
  setOpenModal: PropTypes.node.isRequired,
  setErrorModal: PropTypes.node.isRequired,
  integration: PropTypes.objectOf.isRequired,
};

export default AuthenticatePopup;
