import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TopContent from '../../../shared/component/TopContent';
import { Input, TextArea } from '../../../shared/component/form/Input';

// import { checkFeatures, checkPermission } from '../../../utils/helpers';
import storeApi from '../../../utils/api/store-location';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import { createNewStoreLocally } from '../../../redux/slices/StoreLocationSlice';
import { MoreMenuIcon } from '../../../shared/component/svgIcon';

function LocationView() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [storeValue, setStoreValue] = useState({});

  const addDevicesInFloor = (index) => {
    navigate(`../view/floor-map-view/${index}`);
  };

  const actions = [
    // {
    //   name: t('view'),
    //   url: '',
    //   function: () => { },
    //   key: 'view',
    // },
    {
      name: t('markDevice'),
      url: '',
      function: addDevicesInFloor,
      key: 'view',
    },
    // {
    //   name: t('download'),
    //   url: '',
    //   function: () => { },
    //   key: 'view',
    // },
  ];

  const fetchLocationDetails = async () => {
    await storeApi.getSingleLocation(params?.id)
      .then((response) => {
        setStoreValue(response?.data?.[0]);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  useEffect(() => {
    if (storeValue) {
      dispatch(createNewStoreLocally(storeValue));
    }
  }, [storeValue]);

  useEffect(() => {
    fetchLocationDetails();
  }, []);

  const addLocation = () => {
    navigate(`../edit/${params?.id}`);
  };

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('facilities'), storeValue?.name]}
          button={false}
          // button={checkPermission('editStore') && checkFeatures('store', 'edit')}
          buttonLabel="Edit Store"
          buttonClass="default-button button-outline-success-1"
          click={addLocation}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              <div className="location-form">
                {storeValue?.store_images?.length > 0
                  && (
                    <>
                      <div className="form-header input-text-area">
                        <h2>{t('storeImage')}</h2>
                      </div>
                      <div className="multi-image-upload">
                        <div className="images-view">
                          {
                            storeValue?.store_images?.map((store) => (
                              <div className="image-item">
                                <div className="image-view">
                                  <img alt="storage_image" src={store.signedUrl} />
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </>
                  )}
                <div className="form-header input-text-area">
                  <h2>{t('storeDetails')}</h2>
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('storeName')}
                    type="text"
                    name="name"
                    required={false}
                    readonly
                    value={storeValue?.name}
                  />
                  <Input
                    icon={' '}
                    label={t('storeID')}
                    type="text"
                    name="store_id"
                    required={false}
                    readonly
                    value={storeValue?.store_id}
                  />
                </div>
                <div className="input-text-area">
                  <TextArea
                    icon={' '}
                    label={t('storeDescription')}
                    type="text"
                    name="description"
                    readonly
                    required={false}
                    value={storeValue?.description}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('timeZone')}
                    type="text"
                    name="time_zone"
                    readonly
                    required={false}
                    value={storeValue?.time_zone}
                  />
                  <Input
                    icon={' '}
                    type="text"
                    label={t('country')}
                    name="country"
                    required={false}
                    readonly
                    value={storeValue?.country}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    type="text"
                    label={t('state')}
                    name="state"
                    readonly
                    required={false}
                    value={storeValue?.state}
                  />
                  <Input
                    icon={' '}
                    type="text"
                    label={t('city')}
                    name="city"
                    readonly
                    required={false}
                    value={storeValue?.city}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('area')}
                    type="text"
                    name="area"
                    readonly
                    required={false}
                    value={storeValue?.area}
                  />
                  <Input
                    icon={' '}
                    label={t('pincode')}
                    type="text"
                    name="pincode"
                    readonly
                    required={false}
                    value={storeValue?.pincode}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('latitude')}
                    type="text"
                    name="latitude"
                    readonly
                    required={false}
                    value={storeValue?.latitude}
                  />
                  <Input
                    icon={' '}
                    label={t('longitude')}
                    type="text"
                    name="longitude"
                    readonly
                    required={false}
                    value={storeValue?.longitude}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('zone')}
                    type="text"
                    name="zone"
                    readonly
                    required={false}
                    value={storeValue?.zone}
                  />
                </div>
              </div>
              {
                storeValue?.floor_map?.length > 0 && (
                  <div className="location-form">
                    <div className="form-header">
                      <h2>{t('floorMap')}</h2>
                    </div>
                    <div className="floor-map-list">
                      {
                        storeValue?.floor_map?.map((floorMap, index) => (
                          <div className="floor-map-item">
                            <div className="top-session">
                              <div className="thumb-image">
                                <img src={floorMap.signedUrl} alt="floorImages" />
                              </div>
                              <div>
                                <DropDownMenu
                                  action={actions}
                                  value={{ id: index }}
                                  icon={<MoreMenuIcon />}
                                  dropdown="tableForm"
                                  tooltip=""
                                />
                              </div>
                            </div>
                            <div className="middle-session">
                              {floorMap.name}
                            </div>
                            <div className="footer-session">
                              {floorMap.size}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationView;
