import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import StyleTemplateEditor from './StyleTemplateEditor';
import StyleDisplayEditor from './StyleDisplayEditor';
import PosStyleTemplateEditor from './PosStyleTemplateEditor';

function StyleRenderPage({ setData }) {
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const templateType = templateData?.template_type;
  return (
    <>
      {templateType === 'Menu Board' && <StyleTemplateEditor templateData={templateData} setNewData={setData} />}
      {templateType !== 'Menu Board' && templateType !== 'Integration'
      && <StyleDisplayEditor templateData={templateData} setNewData={setData} />}
      {templateType === 'Integration' && <PosStyleTemplateEditor templateData={templateData} setNewData={setData} />}
    </>
  );
}
StyleRenderPage.propTypes = {
  setData: PropTypes.node.isRequired,
};
export default StyleRenderPage;
