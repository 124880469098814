/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
// import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDisplayOs } from '../../../redux/slices/DashBoardSlice';
import { capitalize } from '../../../utils/helpers';

function DisplayOs() {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next');
  const dispatch = useDispatch();
  const { displayOsData } = useSelector((state) => state.dashboardPieCharts);

  const [page, setPage] = useState('hardware');
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // State to handle size of the area-chart

  const getDisplayOs = (type) => {
    setPage(type);
    dispatch(fetchDisplayOs({ type }));
  };

  // Update the width and height of the area chart container
  useEffect(() => {
    getDisplayOs(page);
    const updateDimensions = () => {
      const containerWidth = document.getElementById('pieChart-doNet').clientWidth;
      const containerHeight = document.getElementById('pieChart-doNet').clientHeight;
      setDimensions({ width: containerWidth, height: containerHeight });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div className="dashboard-display-os">
      <div className="displayOs-header">
        <div className="header-left">
          <h3>{t('displayOs')}</h3>
        </div>
        <div className="header-right">
          <div className="Toggle-button">
            <div
              onClick={() => getDisplayOs('hardware')}
              role="presentation"
              className={page === 'hardware' ? 'active' : ''}
            >
              <span>{t('display')}</span>
            </div>
            <div
              onClick={() => getDisplayOs('player_app_version')}
              role="presentation"
              className={page === 'player_app_version' ? 'active' : ''}
            >
              <span>{t('player')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="pieChart-wrap">
        <div className="pieChart-doNet" id="pieChart-doNet">
          <PieChart
            width={dimensions.width}
            height={dimensions.height}
          >
            <Pie
              data={displayOsData}
              dataKey="value"
              cx={dimensions.width / 2}
              cy={dimensions.height / 2}
              // Calculate based on parent width & height
              innerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 40}
              outerRadius={(Math.min(dimensions.width, dimensions.height) / 2) - 15}
              labelLine={false}
              fill="#0088FE"
            >
              {displayOsData.map((entry) => (
                <Cell style={{ outline: 'none' }} key={entry.name} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
          <div className="text-container">
            <span>
              {
                displayOsData
                  ?.filter((os) => os.name !== '')
                  ?.reduce((assignValue, currentOs) => assignValue + currentOs.value, 0)
              }
            </span>
            <span>{t('displays')}</span>
          </div>
        </div>
        <div className="legend">
          {displayOsData?.filter((item) => item.label !== '').map((item, index) => (
            <div className="each-legend" key={item.id || index}>
              <div
                className={currentLanguageCode === 'ar' ? 'ar' : 'marker'}
                style={{ backgroundColor: item?.color }}
              />
              <p
                className={currentLanguageCode === 'ar' && 'arabic-label'}
              >
                {capitalize(item?.name)}
              </p>
              <p>{item?.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DisplayOs;
