import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';

function ReportAgeGrouped({ countData, toolBar, filterFunction }) {
  const { t } = useTranslation();

  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('timeDuration'),
      accessorKey: 'timeRange',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('ageGroup'),
      accessorKey: 'ageRange',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalCount'),
      accessorKey: 'totalCount',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('facility'),
      accessorKey: 'store_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('device'),
      accessorKey: 'device_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('aiInstance'),
      accessorKey: 'ai_instance_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    // {
    //   header: t('contentName'),
    //   accessorKey: 'stray',
    //   enableColumnActions: false,
    //   enableColumnFilter: false,
    //   enableResizing: false,
    // },
  ];

  const exportData = () => {
    const columnHeaders = [
      'Time Duration',
      'Age Group',
      'Total Count',
      'Facility',
      'Device',
      'AI Instance',
      // 'Content Name',
    ];
    const returnData = countData.map((val) => ({
      'Time Duration': val?.timeRange,
      'Age Group': val?.ageRange,
      'Total Count': val?.totalCount,
      Facility: val?.store_name,
      Device: val?.device_name,
      'AI Instanc': val?.ai_instance_name,
      // 'Content Name': val?.stray,
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };

  return (
    <div className="report-user-total-count-1">
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData}
          status="success"
          onFilterOrSortingChange={filterFunction}
          rowSelection={[]}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount={10}
          showToolbarRight
          toolbarRightFrond={toolBar}
          exportButton
          exportData={exportData}
        />
      </div>
    </div>
  );
}

ReportAgeGrouped.propTypes = {
  countData: PropTypes.arrayOf(),
  toolBar: PropTypes.element.isRequired,
  filterFunction: PropTypes.func.isRequired,
};
ReportAgeGrouped.defaultProps = {
  countData: [],
};

export default ReportAgeGrouped;
