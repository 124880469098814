/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Country, State, City } from 'country-state-city';
import deepEqual from 'deep-equal';
import AutocompleteBox from '../../../shared/component/form/AutoComplete';
import TopContent from '../../../shared/component/TopContent';
import { Input, TextArea, InputRightInnerIcon } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import SelectBox from '../../../shared/component/form/SelectBox';

import timeZonesList from '../../../assets/json/time-zone.json';

import location from '../../../utils/api/store-location';
import locationIcon from '../../../assets/icons/location.png';
import Location from './Location';

import {
  fetchStoreLocationListAll,
  fetchCityStateCountry,
  updateStore,
} from '../../../redux/slices/StoreLocationSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import ImageUploadBox from './ImageUploadBox';
import { CloseCircleIcon } from '../../../shared/component/svgIcon';
import ViewStoreImage from './ViewStoreImage';
import {
  decimalValidation,
  descriptionValidation,
  nameValidation,
  pincodeValidation,
  textNumberValidation,
} from '../../../shared/component/form/Validation';

function LocationEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();

  const storeList = useSelector((state) => state.store.storeLocation);
  const [dataValue, setDataValue] = useState(storeList.find((val) => val.id === params?.id));
  const [storeValue, setStoreValue] = useState({
    storeImages: [],
    newImages: [],
  });
  const [deletedImageUrls, setDeletedImageUrls] = useState([]);
  const [originalValue, setOriginalValue] = useState({});
  const [state, setState] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(true);
  const [modelContent, setModelContent] = useState(true);

  const addLocation = () => {
    navigate('../');
  };

  const setLatLng = (data) => {
    setStoreValue({ ...storeValue, latitude: Number(data.lat), longitude: Number(data.lng) });
    setNotification(false);
  };

  const setThumbnail = (key) => {
    setStoreValue((prevValue) => ({
      ...prevValue,
      storeImages: prevValue.storeImages.map((image, index) => {
        if (index === key) {
          return {
            ...image,
            setDefaultThumb: true,
          };
        }
        return {
          ...image,
          setDefaultThumb: false,
        };
      }),
    }));
    setNotification(false);
  };

  const openMap = () => {
    setNotificationStatus(false);
    setNotification(true);
    setModelContent({
      title: 'Map Location',
      content: (
        <Location
          setLatLng={setLatLng}
          close={setNotification}
          data={{ lat: storeValue.latitude, lng: storeValue.longitude }}
        />
      ),
    });
  };

  const submit = () => {
    if (nameValidation(1, 50, storeValue?.name)
      && descriptionValidation(500, storeValue?.description)
      && descriptionValidation(100, storeValue?.area)
      && textNumberValidation(1, 30, storeValue?.store_id)
      && pincodeValidation(storeValue?.pincode)
      && decimalValidation(-90, 90, storeValue?.latitude)
      && decimalValidation(-180, 180, storeValue?.longitude)
      && descriptionValidation(50, storeValue?.zone)
    ) {
      setDisableButton(true);
      setUploading(true);
      // Convert object into formData
      const formData = new FormData();

      // Append image files
      if (storeValue?.newImages?.length > 0) {
        storeValue?.newImages?.forEach((imageFile) => {
          formData.append('store', imageFile.file);
        });
        formData.append('update_image', true);
      }
      const thumbnail = storeValue?.storeImages?.find((image) => image.setDefaultThumb === true)?.file;
      if (thumbnail) formData.append('thumbnail', thumbnail);

      // Append deleted-url of the images
      if (deletedImageUrls?.length > 0) {
        deletedImageUrls?.forEach((imageUrl, index) => {
          formData.append(`removed_image[${index}]`, imageUrl);
        });
      }

      if (storeValue?.description) {
        formData.append('description', storeValue?.description);
      }

      if (storeValue?.zone) {
        formData.append('zone', storeValue?.zone);
      }

      // Append other fields
      formData.append('name', storeValue?.name);
      formData.append('store_id', storeValue?.store_id);
      formData.append('country', storeValue?.country);
      formData.append('state', storeValue?.state);
      formData.append('city', storeValue?.city);
      formData.append('area', storeValue?.area);
      formData.append('address', storeValue?.area);
      formData.append('pincode', storeValue?.pincode);
      formData.append('time_zone', storeValue?.time_zone);
      formData.append('latitude', storeValue?.latitude);
      formData.append('longitude', storeValue?.longitude);

      location.updateLocation(formData, storeValue.id).then((res) => {
        dispatch(updateStore(res?.data));
        setUploading(false);
        setNotification(true);
        setNotificationStatus(true);
        setModelContent({ content: <Success message={t('facilityUpdatedSuccess')} /> });
        dispatch(fetchStoreLocationListAll());
        dispatch(fetchCityStateCountry());
        setDisableButton(false);
        setTimeout(() => {
          setNotification(false);
          navigate('../');
        }, 3000);
      }).catch((error) => {
        setUploading(false);
        dispatch(setErrorNotification(error?.response?.data));
        setDisableButton(false);
      });
    }
  };

  const removeImageFile = (index, url, binaryFile = false) => {
    setStoreValue((prevValue) => {
      if (binaryFile) {
        const newImages = [...prevValue.newImages];
        if (index >= 0 && index < newImages.length) {
          newImages.splice(index, 1);
        }
        return { ...prevValue, newImages };
      }

      setDeletedImageUrls((previousValue) => [
        ...previousValue,
        url,
      ]);
      const storeImages = [...prevValue.storeImages];
      if (index >= 0 && index < storeImages.length) {
        storeImages.splice(index, 1);
      }
      return { ...prevValue, storeImages };
    });
  };

  const closeModal = () => {
    setNotification(false);
  };

  const viewImage = (key) => {
    setNotificationStatus(true);
    setNotification(true);
    setModelContent({
      title: '',
      content: (
        <ViewStoreImage
          storeImage={storeValue?.storeImages[key]}
          setThumbnail={setThumbnail}
          indexKey={key}
          closeModal={closeModal}
        />
      ),
    });
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'country_id') {
      if (changeValue) {
        setStoreValue({
          ...storeValue,
          country: changeValue.label,
          state: '',
          city: '',
        });
        setCountryCode(changeValue.id);
        const state1 = State.getStatesOfCountry(changeValue.id).map((val) => ({ id: val.isoCode, label: val.name }));
        setState(state1);
      }
    } else if (name === 'state_id') {
      if (changeValue) {
        const city1 = City.getCitiesOfState(countryCode, changeValue.id)
          .map((val) => ({ id: val.isoCode, label: val.name }));
        setCity(city1);
        setStoreValue({ ...storeValue, state: changeValue.label, city: '' });
      }
    } else if (name === 'city_id') {
      if (changeValue) {
        setStoreValue({ ...storeValue, city: changeValue.label });
      }
    } else if (name === 'time_zone') {
      if (changeValue) {
        setStoreValue({ ...storeValue, [name]: changeValue.label });
      }
    } else if (name === 'pincode' || name === 'longitude' || name === 'latitude') {
      setStoreValue({ ...storeValue, [name]: Number(changeValue) });
    } else if (name === 'files') {
      let selectedFiles = [];
      for (let index = 0; index < changeValue.length; index += 1) {
        selectedFiles = [
          ...selectedFiles,
          {
            file: changeValue[index],
            signedUrl: URL.createObjectURL(changeValue[index]),
            viewUrl: URL.createObjectURL(changeValue[index]),
          },
        ];
      }
      setStoreValue((prevValue) => ({
        ...prevValue,
        newImages: [
          ...prevValue.newImages,
          ...selectedFiles,
        ],
        storeImages: [
          ...prevValue.storeImages,
          ...selectedFiles,
        ],
      }));
    } else {
      setStoreValue({ ...storeValue, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (
      storeValue.name
      && storeValue.store_id
      && storeValue.country
      && storeValue.state
      && storeValue.city
      && storeValue.pincode
      && storeValue.area
      && storeValue.time_zone
      && storeValue.longitude
      && storeValue.latitude
      && !deepEqual(originalValue, storeValue)
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [storeValue]);

  useEffect(() => {
    const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
    setTimeZone(timeZones);
    const country = Country.getAllCountries().map((val) => ({ id: val.isoCode, label: val.name }));
    setCountries(country);
    if (dataValue) {
      const country1 = Country.getAllCountries().find((val) => val.name === dataValue.country);
      setCountryCode(country1?.isoCode);
      const state1 = State.getStatesOfCountry(country1.isoCode).map((val) => ({ id: val.isoCode, label: val.name }));
      setState(state1);
      const selectState = state1.find((val) => val.label === dataValue.state);
      const city1 = City.getCitiesOfState(country1.isoCode, selectState ? selectState.id : '')
        .map((val) => ({ id: val.isoCode, label: val.name }));
      setCity(city1);
      setOriginalValue(dataValue);
      setStoreValue({
        ...dataValue,
        storeImages: dataValue?.store_images.map((image) => ({
          ...image,
          viewUrl: image.signedUrl,
        })),
        newImages: [],
      });
    }
  }, [dataValue]);

  useEffect(() => {
    if (storeList.length === 0) {
      location.getSingleLocation(params?.id)
        .then((response) => (
          setDataValue({
            ...response?.data?.[0],
            id: response?.data?.[0]?._id,
            storeImages: response?.data[0]?.store_images,
          })
        )).catch((err) => (
          dispatch(setErrorNotification(err?.response?.data))
        ));
    }
  }, [params]);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modelContent}
        notification={notificationStatus}
      />
      <div className="main-content">
        <TopContent
          label={[t('facilities'), dataValue?.name]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addLocation}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              <div className="location-form">
                <div className="form-header input-text-area">
                  <h2>{t('storeImage')}</h2>
                </div>
                <div className="multi-image-upload">
                  <div className="file-upload-box">
                    <ImageUploadBox
                      title=""
                      setPicture={changeHandle}
                      name="files"
                      height="200px"
                      multiple="multiple"
                      maxFileSize={2}
                    />
                  </div>
                  <div className="images-view">
                    {
                      storeValue?.storeImages?.map((store, index) => (
                        <div className="image-item">
                          <div
                            className="image-close-icon"
                            onClick={() => removeImageFile(index, store.url)}
                            role="presentation"
                          >
                            <CloseCircleIcon />
                          </div>
                          <div className="image-view" onClick={() => viewImage(index)} role="presentation">
                            <img alt="storage_image" src={store.signedUrl} />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="form-header input-text-area">
                  <h2>{t('storeDetails')}</h2>
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('storeName')}
                    type="text"
                    name="name"
                    required
                    value={storeValue?.name}
                    change={changeHandle}
                    placeholder={t('enterStoreName')}
                    error={!nameValidation(1, 50, storeValue?.name)}
                    errorMessage={t('storeNameValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('storeID')}
                    type="text"
                    name="store_id"
                    required
                    value={storeValue?.store_id}
                    change={changeHandle}
                    placeholder={t('enterStoreID')}
                    error={!textNumberValidation(1, 30, storeValue?.store_id)}
                    errorMessage={t('storeIdValidation')}
                  />
                </div>
                <div className="input-text-area">
                  <TextArea
                    icon={' '}
                    label={t('storeDescription')}
                    type="text"
                    name="description"
                    required={false}
                    value={storeValue?.description}
                    change={changeHandle}
                    placeholder={t('enterStoreDescription')}
                    error={!descriptionValidation(500, storeValue?.description)}
                    errorMessage={t('descriptionValidation')}
                  />
                </div>
                <div className="single-row">
                  <SelectBox
                    label={t('timeZone')}
                    name="time_zone"
                    required
                    value={storeValue?.time_zone}
                    onchange={changeHandle}
                    id="controllable-time-zone-demo"
                    lists={timeZone}
                  />
                  <SelectBox
                    label={t('country')}
                    name="country_id"
                    required
                    value={storeValue?.country}
                    onchange={changeHandle}
                    id="controllable-country-demo"
                    lists={countries}
                  />
                </div>
                <div className="single-row">
                  <AutocompleteBox
                    label={t('state')}
                    name="state_id"
                    required
                    value={storeValue?.state}
                    onchange={changeHandle}
                    lists={state}
                    id="controllable-state-demo"
                    allowNewOption
                  />
                  <AutocompleteBox
                    label={t('city')}
                    name="city_id"
                    required
                    value={storeValue?.city}
                    onchange={changeHandle}
                    lists={city}
                    id="controllable-city-demo"
                    allowNewOption
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('area')}
                    type="text"
                    name="area"
                    required
                    value={storeValue?.area}
                    change={changeHandle}
                    placeholder={t('enterArea')}
                    error={!descriptionValidation(100, storeValue?.area)}
                    errorMessage={t('areaValidation')}
                  />
                  <Input
                    icon={' '}
                    label={t('pincode')}
                    type="text"
                    name="pincode"
                    required
                    value={storeValue?.pincode}
                    change={changeHandle}
                    placeholder={t('enterPincode')}
                    error={!pincodeValidation(storeValue?.pincode)}
                    errorMessage={t('invalidPincode')}
                  />
                </div>
                <div className="single-row">
                  <InputRightInnerIcon
                    icon={locationIcon}
                    label={t('latitude')}
                    type="number"
                    name="latitude"
                    required={false}
                    value={storeValue?.latitude}
                    change={changeHandle}
                    placeholder={t('enterLatitude')}
                    click={openMap}
                    error={!decimalValidation(-90, 90, storeValue?.latitude)}
                    errorMessage={t('invalidLatitude')}
                  />
                  <Input
                    icon={' '}
                    label={t('longitude')}
                    type="number"
                    name="longitude"
                    required={false}
                    value={storeValue?.longitude}
                    change={changeHandle}
                    placeholder={t('enterLongitude')}
                    error={!decimalValidation(-180, 180, storeValue?.longitude)}
                    errorMessage={t('invalidLongitude')}
                  />
                </div>
                <div className="single-row">
                  <Input
                    icon={' '}
                    label={t('zone')}
                    type="text"
                    name="zone"
                    required={false}
                    value={storeValue?.zone}
                    change={changeHandle}
                    placeholder={t('enterZone')}
                    error={!descriptionValidation(50, storeValue?.zone)}
                    errorMessage={t('zoneValidation')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={addLocation} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('updating') : t('update')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disableButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationEdit;
