import React from 'react';
import { useSelector } from 'react-redux';
import TableTemplateEditor from './TableTemplateEditor';
import TableDisplayEditor from './TableDisplayEditor';
import PosTemplateEditor from './PosTemplateEditor';

function TableRenderPage() {
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const localTemplateType = JSON.parse(localStorage.getItem('templateEditData'));
  const templateType = templateData?.template_type || localTemplateType?.template_type;
  return (
    <>
      {templateType === 'Menu Board' && <TableTemplateEditor templateData={templateData} />}
      {templateType !== 'Menu Board' && templateType !== 'Integration'
        && <TableDisplayEditor templateData={templateData} />}
      {templateType === 'Integration' && <PosTemplateEditor templateData={templateData} />}
    </>

  );
}

export default TableRenderPage;
