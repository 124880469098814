/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
// import Button from '../../../../shared/component/Button';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import { markerfeltBase64, arialFontMtBold } from '../../../../assets/fonts/base64Fonts';
import FileFormat from '../FileFormat';
import PopupBox from '../ContentUploadConfirmation';

function KFCTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const htmlRef = useRef(null);

  const cssStyles = `
@font-face {
  font-family: 'Marker Felt';
  src: url('data:font/truetype;charset=utf-8;base64,${markerfeltBase64}') format('truetype');
}
@font-face {
  font-family: 'Arial Rounded MT Bold';
  src: url('data:font/truetype;charset=utf-8;base64,${arialFontMtBold}') format('truetype');
}
.bar-display {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.bar-display-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    position: relative;
}

.left-side {
    display: flex;
    align-items: center;
    text-align: center;
}

.item-name {
    width: 35%;
    margin-bottom: 50px;
}

.item-images {
    display: flex;
    align-items: flex-end;
    margin-left: 30px;
}

.item-image-wrapper {
    margin-top: 41px;
}

.item-image-1 {
    width: 330px;
    height: 300px;
}

.item-image-2 {
    width: 248px;
    height: 195px;
    margin-bottom: 10px;
}

.right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    margin-right: 25px;
    margin-bottom: 90px;
}

.bottom-image-wrapper {
    position: absolute;
    bottom: -12px;
    left: -22px;
    z-index: -1;
}

.bottom-image {
    width: 100vw;
    height: 20%;
}
`;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };
  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const backgroundColor = data?.[0]?.styles?.background;
  const tableImage = data?.[0]?.template_backgroundImage_url?.[0];

  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <div className="template-container-5">
        <div className="inner-container">
          <div
            className="bar-display"
            ref={htmlRef}
            style={{
              backgroundColor: `${backgroundColor}`,
            }}
          >
            <style>
              {`
            @font-face {
              font-family: 'Marker Felt';
              src: url('data:font/truetype;charset=utf-8;base64,${markerfeltBase64}') format('truetype');
            }
            @font-face {
              font-family: 'Arial Rounded MT Bold';
              src: url('data:font/truetype;charset=utf-8;base64,${arialFontMtBold}') format('truetype');
            }
          `}
            </style>
            <div className="bar-display-container">
              <div className="left-side">
                <span
                  className="item-name"
                  style={{
                    fontSize: `${styles?.[0]?.fontSize}`,
                    color: `${styles?.[0]?.color}`,
                    fontFamily: `${styles?.[0]?.fontFamily}`,
                  }}
                >
                  {contentDetails?.[0]?.item_name}
                </span>
                <div className="item-images">
                  <div className="item-image-wrapper">
                    {contentDetails?.[0]?.image
                      && (
                        <img
                          className="item-image-1"
                          src={contentDetails?.[0]?.image}
                          alt=""
                        />
                      )}
                  </div>
                  {contentDetails?.[1]?.image
                    && (
                      <img
                        className="item-image-2"
                        src={contentDetails?.[1]?.image}
                        alt=""
                      />
                    )}
                </div>
              </div>
              <div className="right-side">
                {contentDetails?.[0]?.offer_percentage
                  && (
                    <span style={{
                      fontSize: `${styles?.[2]?.fontSize}`,
                      color: `${styles?.[2]?.color}`,
                      fontFamily: `${styles?.[2]?.fontFamily}`,
                    }}
                    >
                      {contentDetails?.[0]?.offer_percentage}%
                    </span>
                  )}
                {contentDetails?.[0]?.desc
                  && (
                    <span
                      style={{
                        fontSize: `${styles?.[2]?.fontSize}`,
                        color: `${styles?.[2]?.color}`,
                        fontFamily: `${styles?.[2]?.fontFamily}`,
                      }}
                    >
                      OFF
                    </span>
                  )}
                <span style={{
                  fontSize: `${styles?.[1]?.fontSize}`,
                  color: `${styles?.[1]?.color}`,
                  fontFamily: `${styles?.[1]?.fontFamily}`,
                  textWrap: 'nowrap',
                }}
                >
                  {contentDetails?.[0]?.desc}
                </span>
              </div>
              <div className="bottom-image-wrapper">
                <img src={tableImage} alt="" className="bottom-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

KFCTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default KFCTemplate;
