import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import { InvalidIcon } from '../../../shared/component/svgIcon';

function InvalidTokenPopup(props) {
  const { t } = useTranslation();
  const submit = () => {
    props?.setAuthOpenModal(true);
    props?.setOpenModal(false);
    props?.setErrorModal(false);
  };

  return (
    <div className="integration-invalid-token-popup">
      <div className="content">
        <InvalidIcon />
        <span className="invalid-text">{t('entervalidbearertoken')}</span>
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('continue')}
            click={submit}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}

InvalidTokenPopup.propTypes = {
  setOpenModal: PropTypes.node.isRequired,
  setAuthOpenModal: PropTypes.node.isRequired,
  setErrorModal: PropTypes.node.isRequired,
};

export default InvalidTokenPopup;
