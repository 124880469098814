import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currencySymbol } from '../../../utils/helpers';
import { RadioButton } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { getPaymentType } from '../../../redux/slices/SubscriptionSlice';
import aiInstance from '../../../utils/api/ai-instance';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function AiInstancePayment(props) {
  const {
    backModal,
    currency,
    submitPayment,
    closeModal,
    storageInfo,
    instanceChangeSuccess,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentType } = useSelector((state) => state?.subscription);
  const [paymentMode, setPaymentMode] = useState(paymentType);
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const submit = () => {
    if (paymentMode === 'online') {
      setDisable(true);
      setUploading(true);
      const req = {
        buy_instance_count: storageInfo?.totalInstance,
        payment_mode: paymentMode,
      };
      aiInstance.buyAiInstanceOnline(req)
        .then((res) => {
          setUploading(false);
          setDisable(false);
          instanceChangeSuccess('instance');
          window.open(res?.data?.paymentUrl, '_blank')?.focus();
          closeModal(false);
        })
        .catch((error) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    } else {
      submitPayment(storageInfo);
      dispatch(getPaymentType(paymentMode));
    }
  };

  useEffect(() => {
    if (paymentMode) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [paymentMode]);

  return (
    <div className="ai-instance-summary">
      <div className="device-change-content">
        <div className="sass-summary">
          <table>
            <tr>
              <td colSpan="5">
                <div className="table-header">
                  <span>{t('aiAnalyticsSummary')}</span>
                  <div className="success-link" onClick={backModal} role="presentation">{t('editInstance')}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{t('description')}</th>
              <th>{t('unitPrice')}</th>
              <th>{t('quantity')}</th>
              <th>{t('totalPrice')}</th>
              <th>{t('validFrom')}</th>
            </tr>
            <tr>
              <td>{t('instance')}</td>
              <td>{currencySymbol(currency, storageInfo?.unitPrice)}</td>
              <td>{1 > 0 && '+ '}{storageInfo?.totalInstance}</td>
              <td>{currencySymbol(currency, storageInfo?.totalInstancePrice)}</td>
              <td>{storageInfo?.validFrom}</td>
            </tr>
          </table>
          <div className="payment-mode">
            <h2 className="payment-title">{t('paymentMode')}</h2>
            <div className="form">
              <RadioButton
                checked={paymentMode === 'online'}
                value="online"
                label={t('online')}
                onChange={(e) => setPaymentMode(e)}
                id="online"
              />
              {/* <RadioButton
                checked={paymentMode === 'offline'}
                value="offline"
                label={t('offline')}
                onChange={(e) => setPaymentMode(e)}
                id="offline"
              /> */}
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={t('back')}
                    click={backModal}
                    classes="default-button"
                  />
                </div>
                <div className="form-button">
                  <Button
                    label={!uploading ? t('continue') : t('loading')}
                    click={submit}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    disabled={disable}
                    loading={uploading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

AiInstancePayment.propTypes = {
  backModal: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  submitPayment: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  instanceChangeSuccess: PropTypes.func.isRequired,
  storageInfo: PropTypes.string.isRequired,
};

export default AiInstancePayment;
