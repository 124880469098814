import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import ColorPicker from 'react-best-gradient-color-picker';
import { DeleteBackgroundIcon, EditBackgroundIcon, PlusIcon } from '../../../shared/component/svgIcon';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import templates from '../../../utils/api/templates';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function StyleDisplayEditor({ setNewData }) {
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const templateDefaultData = useSelector((state) => state?.currentTemplate?.defaultTemplate);
  const [imageRefs] = useState(templateData?.template_images?.map(() => createRef()));
  const dispatch = useDispatch();
  const styles = templateData?.styles?.column;
  const images = templateData?.template_images;
  const colorData = templateData?.styles?.details;
  const gradientBackground = templateData?.styles?.backgroundColor;
  const solidBackground = templateData?.styles?.background;
  const [gradientColor, setGradientColor] = useState(gradientBackground);
  const [nonGradientColor, setNonGradientColor] = useState(solidBackground);
  const fontStyles = [
    'Lucida Console',
    'Arial',
    'Helvetica',
    'Times New Roman',
    'Courier New',
    'Verdana',
    'Georgia',
    'Palatino',
    'Garamond',
    'Bookman',
    'Comic Sans MS',
    'sans-serif',
    'Trebuchet MS',
    'Arial Black',
    'Impact',
    'Arial Rounded MT Bold',
    'Marker Felt',
    'Inter',
    'Poppins',
    'Alata',
    'Urbanist',
    'Great vibes',
  ];
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [Picker, setPicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const handleColorClick = (index) => {
    setOpenColorPicker(true);
    setSelectedIndex(index);
  };
  const handleColorChange = (color) => {
    setOpenColorPicker(false);
    const styleData = JSON.parse(JSON.stringify(templateData));
    const colorUpdate = styleData?.styles?.details;
    colorUpdate[selectedIndex].color = color?.hex;
    setNewData([styleData]);
    dispatch(updateCurrentTemplate(styleData));
  };
  const handleFontFamily = (e, index) => {
    const styleData = JSON.parse(JSON.stringify(templateData));
    const fontFamilyUpdate = styleData?.styles?.details;
    fontFamilyUpdate[index].fontFamily = e.target.value;
    setNewData([styleData]);
    dispatch(updateCurrentTemplate(styleData));
  };
  const handleFontSize = (e, index) => {
    const styleData = JSON.parse(JSON.stringify(templateData));
    if (e.target.value !== 'default') {
      const fontFamilyUpdate = styleData?.styles?.details;
      fontFamilyUpdate[index].fontSize = e.target.value;
      setNewData([styleData]);
      dispatch(updateCurrentTemplate(styleData));
    } else {
      const fontFamilyUpdate = styleData?.styles?.details;
      fontFamilyUpdate[index].fontSize = templateDefaultData?.styles?.details?.[index]?.fontSize;
      setNewData([styleData]);
      dispatch(updateCurrentTemplate(styleData));
    }
  };
  const handleChangeBackgroundImage = (e, index) => {
    e.preventDefault();
    imageRefs[index]?.current?.click();
  };
  const handleFileChange = async (e, index) => {
    const file = e.target.files[0];
    const request = {
      'background-image': file,
    };
    if (file) {
      templates?.updateTemplateImage(request).then((res) => {
        const newData = JSON.parse(JSON.stringify(templateData));
        newData.template_images[index].image = res?.data?.background_image;
        dispatch(updateCurrentTemplate(newData));
        setNewData([newData]);
        setShowAdd(false);
      })
        .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
      e.target.value = '';
    }
  };
  const removeBackgroundImage = (index) => {
    const newData = JSON.parse(JSON.stringify(templateData));
    newData.template_images[index].image = null;
    dispatch(updateCurrentTemplate(newData));
    setNewData([newData]);
    setShowAdd(true);
  };
  const handleBgColorClick = () => {
    setPicker(true);
  };
  const handleBlur = () => {
    setPicker(false);
    const newData = JSON.parse(JSON.stringify(templateData));
    newData.styles.backgroundColor = gradientColor;
    dispatch(updateCurrentTemplate(newData));
    setNewData([newData]);
  };
  const handleBackgroundChange = (color) => {
    setNonGradientColor(color?.hex);
    setPicker(false);
    const newData = JSON.parse(JSON.stringify(templateData));
    newData.styles.background = color?.hex;
    dispatch(updateCurrentTemplate(newData));
    setNewData([newData]);
  };
  const handleMultipleFontSize = (e, index, value) => {
    const styleData = JSON.parse(JSON.stringify(templateData));
    if (e.target.value !== 'default') {
      const fontFamilyUpdate = styleData?.styles?.details;
      const splittedFonts = fontFamilyUpdate?.[index]?.fontSize?.split(',');
      splittedFonts[value] = e.target.value;
      fontFamilyUpdate[index].fontSize = `${splittedFonts.join(',')}`;
      setNewData([styleData]);
      dispatch(updateCurrentTemplate(styleData));
    } else {
      const fontFamilyUpdate = styleData?.styles?.details;
      fontFamilyUpdate[index].fontSize = templateDefaultData?.styles?.details?.[index]?.fontSize;
      setNewData([styleData]);
      dispatch(updateCurrentTemplate(styleData));
    }
  };

  const handleMultipleFontFamily = (e, index, value) => {
    const styleData = JSON.parse(JSON.stringify(templateData));
    const fontFamilyUpdate = styleData?.styles?.details;
    const splittedStyle = fontFamilyUpdate?.[index]?.fontFamily?.split(',');
    splittedStyle[value] = e.target.value;
    fontFamilyUpdate[index].fontFamily = `${splittedStyle.join(',')}`;
    setNewData([styleData]);
    dispatch(updateCurrentTemplate(styleData));
  };

  return (
    <div className="template-style-display-editor">
      <p className="header">Template</p>
      {styles?.map((data, index) => ( // Added 'index' for unique key
        <div
          className="container"
          style={{ width: colorData?.[index]?.fontFamily?.split(',').length === 2 ? '75%' : '' }}
        >
          <div className="element">
            <p
              className="title"
              style={{ width: colorData?.[index]?.fontFamily?.split(',').length === 2 ? '20%' : '' }}
            >
              {data?.title}
            </p>
            <div
              className="colorBox"
              style={{
                backgroundColor: colorData?.[index]?.color,
              }}
              role="none"
              onClick={() => handleColorClick(index)}
            />
            {openColorPicker && (
              <div className="picker">
                <SketchPicker color={colorData?.[index]?.color} onChange={handleColorChange} />
              </div>
            )}
            {colorData?.[index]?.fontFamily?.split(',').length === 1
              && (
                <select
                  className="selectBox"
                  value={colorData?.[index]?.fontFamily}
                  onChange={(e) => handleFontFamily(e, index)}
                >
                  {fontStyles.map((font) => (
                    <option
                      style={{ fontFamily: font }}
                      key={font}
                      value={font}
                    >
                      {font}
                    </option>
                  ))}
                </select>
              )}
            {colorData?.[index]?.fontFamily?.split(',').length > 1
              && colorData?.[index]?.fontFamily?.split(',').map((fontStyle, value) => (
                <select
                  className="selectBox"
                  value={fontStyle}
                  onChange={(e) => handleMultipleFontFamily(e, index, value)}
                >
                  {fontStyles.map((font) => (
                    <option
                      style={{ fontFamily: font }}
                      key={font}
                      value={font}
                    >
                      {font}
                    </option>
                  ))}
                </select>
              ))}
            {colorData?.[index]?.fontSize?.split(',').length === 1
              && (
                <select
                  id="fontSizeSelector"
                  className="selectBox"
                  value={colorData?.[index]?.fontSize}
                  onChange={(e) => handleFontSize(e, index)}
                >
                  <option value="default">default</option>
                  <option value="20px">20px</option>
                  <option value="30px">30px</option>
                  <option value="40px">40px</option>
                  <option value="45px">45px</option>
                </select>
              )}
            {
              colorData?.[index]?.fontSize?.split(',').length > 1
              && colorData?.[index]?.fontSize?.split(',').map((fontSize, value) => (
                <select
                  id="fontSizeSelector"
                  className="selectBox"
                  value={fontSize}
                  onChange={(e) => handleMultipleFontSize(e, index, value)}
                >
                  <option value="default">default</option>
                  <option value="20px">20px</option>
                  <option value="30px">30px</option>
                  <option value="40px">40px</option>
                  <option value="45px">45px</option>
                </select>
              ))
            }
          </div>
        </div>
      ))}
      {(gradientBackground || solidBackground)
        && (
          <div className="bgContainer">
            <p className="bgTitle">Background color</p>
            <div
              className="bgColorBox"
              style={{
                background: gradientBackground ? gradientColor : nonGradientColor,
              }}
              role="none"
              onClick={() => handleBgColorClick()}
            />
            {Picker
              && (
                <div className="bgPicker">
                  {gradientBackground
                    && (
                      <ColorPicker
                        value={gradientColor}
                        width={240}
                        height={100}
                        onChange={setGradientColor}
                      />
                    )}
                  {solidBackground
                    && (
                      <SketchPicker
                        color={nonGradientColor}
                        onChange={handleBackgroundChange}
                      />
                    )}
                  <p className="closeButton" onClick={handleBlur} role="none">close</p>
                </div>
              )}
          </div>
        )}
      <hr style={{ borderWidth: '0.75px', width: '100%' }} />
      {images?.length > 0 && <p className="header">Logo</p>}
      {images
        && images.map((data, imgIndex) => (
          <div className="imageContainer">
            <div className="imageBox">
              {!showAdd && <img src={data?.image} alt="logo" />}
            </div>
            <div className="icon">
              <input
                type="file"
                ref={imageRefs?.[imgIndex]}
                accept="image/*"
                id={`fileInput-${imgIndex}`}
                onChange={(e) => handleFileChange(e, imgIndex)}
                style={{ display: 'none' }}
              />
              {showAdd
                && (
                  <div onClick={(e) => handleChangeBackgroundImage(e, imgIndex)} role="none">
                    <PlusIcon />
                  </div>
                )}
              {!showAdd
                && (
                  <div onClick={(e) => handleChangeBackgroundImage(e, imgIndex)} role="none">
                    <EditBackgroundIcon />
                  </div>
                )}
            </div>
            {!showAdd
              && (
                <div
                  className="icon"
                  role="none"
                  onClick={() => removeBackgroundImage(imgIndex)}
                >
                  <DeleteBackgroundIcon />
                </div>
              )}
          </div>
        ))}
    </div>
  );
}
StyleDisplayEditor.propTypes = {
  setNewData: PropTypes.node.isRequired,
};
export default StyleDisplayEditor;
