/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import contentLayout from '../../utils/api/content-layout';
import { setErrorNotification } from './NotificationSlice';

const addFileTypesToLayouts = (layouts) => layouts.map((layout) => ({
  ...layout,
  id: layout?._id,
  layouts: layout.layouts.map((eachLayout) => ({
    ...eachLayout,
    file_types: [],
  })),
}));

export const fetchAvailableContentLayout = createAsyncThunk(
  'contentLayout/fetchAvailableContentLayout',
  async (data, { dispatch }) => {
    const response = await contentLayout.getAvailableContentLayout(data)
      .then((availableLayoutStructure) => ({
        results: addFileTypesToLayouts(availableLayoutStructure?.data),
        code: 200,
      }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  availableContentLayout: [],
  status: 'loading',
};

export const contentLayoutSlice = createSlice({
  name: 'contentLayout',
  initialState,
  reducers: {
    resetContentLayoutSlice: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAvailableContentLayout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvailableContentLayout.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          state.availableContentLayout = action?.payload?.results;
        }
      })
      .addCase(fetchAvailableContentLayout.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  resetContentLayoutSlice,
} = contentLayoutSlice.actions;

export default contentLayoutSlice.reducer;
