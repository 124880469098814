/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setErrorNotification } from './NotificationSlice';
import schedule from '../../utils/api/schedule';

export const fetchDisplayGroupList = createAsyncThunk(
  'displayGroup/fetchDisplayGroup',
  async (data, { dispatch }) => {
    const response = await schedule.getDevicesGroups(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        row_selection: Boolean(val?.orientation_flag && val?.version_flag),
        devices_list: val.devices.map((val1) => val1.name),
        total_count: val.devices.map((val1) => val1.name).length,
        devices: val.devices.map((val1) => ({ ...val1, id: val1._id })),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  displayGroup: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  error: {},
  filter: {},
  tableFilter: {},
};

export const scheduleDisplayGroupSlice = createSlice({
  name: 'scheduleDisplayGroup',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetScheduleDisplayGroupState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDisplayGroupList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplayGroupList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.displayGroup = datas;
          } else {
            state.displayGroup.push(...datas);
          }
        }
      })
      .addCase(fetchDisplayGroupList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  singleDisplayGroupAdd,
  setFilter,
  setTableFilter,
  resetScheduleDisplayGroupState,
} = scheduleDisplayGroupSlice.actions;

export default scheduleDisplayGroupSlice.reducer;
