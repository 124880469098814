import React, { useEffect, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

import playlistIcon from '../../../../assets/images/playlistIcon.png';

function DraggablePlaylist(props) {
  const {
    playlist,
    draggingElement,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({
    id: playlist?.id,
    data: {
      ...playlist,
      file_type: 'playlist',
    },
  });

  const [counts, setCounts] = useState({
    image: 0,
    video: 0,
    pdf: 0,
    url: 0,
    youtube: 0,
  });

  useEffect(() => {
    const newCounts = {
      image: 0,
      video: 0,
      pdf: 0,
      url: 0,
      youtube: 0,
    };

    playlist?.contents?.forEach((content) => {
      if (content?.file_type?.includes('image')) {
        newCounts.image += 1;
      } else if (content?.file_type?.includes('video')) {
        newCounts.video += 1;
      } else if (content?.file_type?.includes('pdf')) {
        newCounts.pdf += 1;
      } else if (content?.file_type?.includes('url')) {
        newCounts.url += 1;
      } else if (content?.file_type?.includes('youtube')) {
        newCounts.youtube += 1;
      }
    });

    setCounts(newCounts);
  }, [playlist]);

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="single-draggable-content-wrap"
    >
      <div
        style={{ '--draggingElementColor': draggingElement ? '#F9FAFB' : '#FFFFFF' }}
        className="single-draggable-content"
      >
        <img
          alt=""
          src={playlistIcon}
          loading="lazy"
          role="presentation"
          className="content-thumbnail default"
        />
        <div className="content-details">
          <h1>{playlist.name}</h1>
          <div className="content-types">
            {Object.entries(counts).map(([key, value]) => value !== 0 && (
              <span key={key}>
                {value} {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

DraggablePlaylist.propTypes = {
  draggingElement: PropTypes.bool,
  playlist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    contents: PropTypes.arrayOf().isRequired,
  }),
};

DraggablePlaylist.defaultProps = {
  playlist: {},
  draggingElement: false,
};

export default DraggablePlaylist;
