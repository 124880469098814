/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postTemplatesList } from '../../../redux/slices/PosTemplateSlice';
import Button from '../../../shared/component/Button';
import posTemplate from '../../../utils/api/pos-it-easy';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { updateContent } from '../../../redux/slices/MyContentSlice';
import ModalBox from '../../../shared/component/ModalBox';

function TemplateEdit(props) {
  const [disable, setDisable] = useState(true);
  const [templateData, setTemplateData] = useState(null);
  const [notification, setNotification] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [open, setOpen] = useState(true);
  const [index, setIndex] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const templateDataRef = useRef(templateData);
  const {
    posTemplates,
  } = useSelector((state) => state?.posTemplate);
  const existingTemplates = () => {
    if (posTemplates?.length === 0) {
      dispatch(postTemplatesList());
    }
  };

  useEffect(() => {
    existingTemplates();
  }, []);

  const handleClickSelectTemplate = (data, i) => {
    setIndex(i);
    setTemplateData(data);
  };

  const handleClose = () => {
    setOpen(false);
    props?.setCreateContent({
      title: '',
      content: 0,
    });
  };

  useEffect(() => {
    templateDataRef.current = templateData;
  }, [templateData]);
  const submit = () => {
    setUploading(true);
    setDisable(true);
    const body = {
      html_url: templateDataRef?.current?.html_url,
      script_url: templateDataRef?.current?.script_url,
      html_style: templateDataRef?.current?.styles,
      template_title: templateDataRef?.current?.template_title,
      url: templateDataRef?.current?.template_image_url,
      thumbnail: {
        px_50x50: templateDataRef?.current?.template_image_url,
        px_600x400: templateDataRef?.current?.template_image_url,
      },
    };
    posTemplate.updatePosItEasyTemplate(props?.contentData?._id, body)
      .then((res) => {
        dispatch(updateContent(res?.data));
        setUploading(false);
        setUploadMessage(t('contentUpdatedSuccess'));
        setNotification(true);
        dispatch(updateContent(res?.data));
        setTimeout(() => {
          setNotification(false);
          setDisable(false);
          setOpen(false);
          props?.setCreateContent({
            title: '',
            content: 0,
          });
        }, 3000);
      }).catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  useEffect(() => {
    if (templateData) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [templateData]);

  return (
    open
    && (
      <div className="saved-templates-popup">
        <ModalBox
          status={notification}
          closeModal={setNotification}
          notification
          modalView={{ content: <Success message={uploadMessage} /> }}
        />
        <div className="template-grid">
          {posTemplates?.map((data, dataIndex) => (
            <div className="grid-item" key={data?._id}>
              <div
                className={index === dataIndex ? 'card active' : 'card'}
                role="none"
                onClick={() => handleClickSelectTemplate(data, dataIndex)}
              >
                <div className="inner-card">
                  <img src={data?.template_image_url} alt="" />
                </div>
              </div>
              <span className="name">{data?.template_title}</span>
            </div>
          ))}
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={uploading ? t('loading') : t('confirm')}
              click={submit}
              disabled={disable}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    )
  );
}

TemplateEdit.propTypes = {
  setCreateContent: PropTypes.node.isRequired,
  contentData: PropTypes.shape().isRequired,
};

export default TemplateEdit;
