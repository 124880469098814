import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';
// this comments for content name view
// import PopupModal from '../../../shared/component/PopupModel';

function ReportGender({ countData, toolBar, filterFunction }) {
  const { t } = useTranslation();

  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('timeDuration'),
      accessorKey: 'time_range',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('genderType'),
      accessorKey: 'gender',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalCount'),
      accessorKey: 'total_gender',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('facility'),
      accessorKey: 'store_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('device'),
      accessorKey: 'device_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('aiInstance'),
      accessorKey: 'aiInstance_name',
      enableColumnActions: false,
      enableResizing: false,
    },
    // {
    //   header: t('contentName'),
    //   accessorKey: 'content_name',
    //   enableColumnActions: false,
    //   enableColumnFilter: false,
    //   enableResizing: false,
    //   Cell: ({ row }) => <PopupModal list={row.original.content_name} name="contents list" />,
    // },
  ];

  const exportData = () => {
    const columnHeaders = [
      'Time Duration',
      'Gender Type',
      'Total Count',
      'Facility',
      'Device',
      'AI Instance',
      // 'Content Name',
    ];
    const returnData = countData.map((val) => ({
      'Time Duration': val?.time_range,
      'Gender Type': val?.gender,
      'Total Count': val?.total_gender,
      Facility: val?.store_name,
      Device: val?.device_name,
      'AI Instance': val?.aiInstance_name,
      // 'Content Name': val?.content_name.join(', '),
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };

  return (
    <div className="report-user-total-count-1">
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData}
          status="success"
          onFilterOrSortingChange={filterFunction}
          rowSelection={[]}
          totalPageCount={1}
          pageCount={1}
          scrollData={() => { }}
          totalDataCount={10}
          showToolbarRight
          toolbarRightFrond={toolBar}
          exportButton
          exportData={exportData}
        />
      </div>
    </div>
  );
}

ReportGender.propTypes = {
  countData: PropTypes.arrayOf(),
  toolBar: PropTypes.element.isRequired,
  filterFunction: PropTypes.func.isRequired,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      content_name: PropTypes.arrayOf().isRequired,
    }).isRequired,
  }),
};
ReportGender.defaultProps = {
  countData: [],
  row: null,
};

export default ReportGender;
