/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import FileFormat from '../FileFormat';
import PopupBox from '../ContentUploadConfirmation';

function BirthdayTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const htmlRef = useRef(null);

  const cssStyles = `
body {
    margin: 0;
    padding: 0;
}
.template-container-12 {
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.main-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.left-top-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 150px;
}

.left-top-image img {
    width: 100%;
    height: 100%;
}

.right-top-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 150px;
}

.right-top-image img {
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.person-image {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    border: 7px solid #ECE2E0;
    overflow: hidden;
}

.person-image img {
    width: 100%;
    height: 100%;
}

.content-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.name-box {
    padding: 7px 0px;
    width: 42%;
    background-color: #001C3C;
    border-radius: 20px;
}

.content-description {
    margin-top: 10px;
    font-weight: 500;
    width: 60%;
    line-height: 25px;
}

.left-bottom-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 130px;
    height: 130px;
}

.left-bottom-image img {
    width: 100%;
    height: 100%;
}

.right-bottom-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 130px;
    height: 130px;
}

.right-bottom-image img {
    width: 100%;
    height: 100%;
}
  `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    try {
      // Convert to base64
      const dataUrl = await toPng(htmlRef.current, { cacheBust: true });
      setBase64(dataUrl); // Set the base64 data
      convertImage(dataUrl); // Your function to handle the base64 data
    } catch (error) {
      dispatch(setErrorNotification(error?.response?.data));
    }
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link 
              href="https://fonts.googleapis.com/css2?family=Great+Vibes&family=Urbanist:wght@100..900&display=swap" 
              rel="stylesheet" />           
              <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };
  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const backgroundColor = data?.[0]?.styles?.background;
  const backgroundImage = data?.[0]?.template_backgroundImage_url;
  const header = contentDetails?.[0]?.header?.split(' ');
  const headerFontSize = styles?.[0]?.fontSize?.split(',');
  const headerFontFamily = styles?.[0]?.fontFamily.split(',');
  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <div
        ref={htmlRef}
        className="template-container-12"
      >
        <div
          style={{ backgroundColor: `${backgroundColor}` }}
          className="main-container"
        >
          <div className="left-top-image">
            <img src={backgroundImage?.[0]} alt="" />
          </div>
          <div className="right-top-image">
            <img src={backgroundImage?.[1]} alt="" />
          </div>
          <div className="content">
            <div className="person-image">
              <img src={contentDetails?.[0]?.image} alt="" />
            </div>
            <div className="content-text">
              <div style={{ display: 'flex', gap: '20px' }}>
                <span
                  style={{
                    fontSize: `${headerFontSize?.[1]}`,
                    color: `${styles?.[0]?.color}`,
                    fontFamily: `${headerFontFamily?.[1]}`,
                  }}
                >
                  {header?.[0]}
                </span>
                {header?.length > 0 && header?.slice(1).map((word) => (
                  <span
                    key={word}
                    style={{
                      fontSize: `${headerFontSize?.[0]}`,
                      color: `${styles?.[0]?.color}`,
                      fontFamily: `${headerFontFamily?.[0]}`,
                      fontWeight: '700',
                    }}
                  >
                    {word}
                  </span>
                ))}
              </div>
              <div className="name-box">
                <span
                  style={{
                    fontSize: `${styles?.[2]?.fontSize}`,
                    color: `${styles?.[2]?.color}`,
                    fontFamily: `${styles?.[2]?.fontFamily}`,
                  }}
                >
                  {contentDetails?.[0]?.name}
                </span>
              </div>
              <span
                className="content-description"
                style={{
                  fontSize: `${styles?.[1]?.fontSize}`,
                  color: `${styles?.[1]?.color}`,
                  fontFamily: `${styles?.[1]?.fontFamily}`,
                }}
              >
                {contentDetails?.[0]?.description}
              </span>
            </div>
          </div>
          <div className="left-bottom-image">
            <img src={backgroundImage?.[2]} alt="" />
          </div>
          <div className="right-bottom-image">
            <img src={backgroundImage?.[3]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

BirthdayTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default BirthdayTemplate;
