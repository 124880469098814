import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Input,
  InputDate,
  InputInnerSymbol,
} from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import { currencySymbol } from '../../../utils/helpers';
import Button from '../../../shared/component/Button';
import aiInstance from '../../../utils/api/ai-instance';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function RenewalSummary(props) {
  const {
    instanceChangeSuccess,
    backModal,
    currency,
    getDevices,
    getAllApis,
    renewalInfo,
  } = props;
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [payment, setPayment] = useState({});
  const paymentOption = [
    {
      label: 'Net banking',
    },
    {
      label: 'UPI',
    },
    {
      label: 'Other',
    },
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = (name, changeValue) => {
    if (name === 'transaction_date') {
      const date = moment(changeValue);
      // Check if date is a valid moment object
      const formatDate = date.format('YYYY-MM-DD');
      setPayment((prevValue) => ({
        ...prevValue,
        [name]: changeValue,
        transaction_dates: formatDate,
      }));
    } else {
      setPayment((prevValue) => ({
        ...prevValue,
        [name]: changeValue,
      }));
    }
  };

  const submit = () => {
    setDisable(true);
    setUploading(true);
    const req = {
      instance_total_hours: renewalInfo?.instance_hours,
      ai_instance_id: renewalInfo?.instance_id,
      payment_mode: 'offline',
      transaction_id: payment.transaction_id,
      transaction_date: payment.transaction_dates,
      transaction_type: payment.transaction_type.label,
      transaction_amount: payment.transaction_amount,
      currency: 'INR',
    };
    aiInstance.renewalInstanceOnline(req)
      .then(() => {
        getDevices(1);
        getAllApis();
        setUploading(false);
        setDisable(false);
        instanceChangeSuccess(false);
      })
      .catch((error) => {
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  useEffect(() => {
    if (payment?.totalPrice <= 0) {
      setDisable(false);
    } else if (payment?.transaction_id && payment?.transaction_date && payment.transaction_type) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [payment]);

  return (
    <div className="ai-instance-summary">
      <div className="device-change-content">
        <div className="sass-summary">
          <table>
            <tr>
              <td colSpan="5">
                <div className="table-header">
                  <span>{t('aiAnalyticsSummary')}</span>
                  <div className="success-link" onClick={backModal} role="presentation">{t('editInstance')}</div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{t('description')}</th>
              <th>{t('unitPrice')}</th>
              <th>{t('quantity')}</th>
              <th>{t('totalPrice')}</th>
              <th>{t('validFrom')}</th>
            </tr>
            <tr>
              <td>{t('addOnStorage')}</td>
              <td>{currencySymbol(currency, renewalInfo?.totalPrice)}</td>
              <td>{1 > 0 && '+ '}{renewalInfo?.instanceCount}</td>
              <td>{currencySymbol(currency, renewalInfo?.totalPrice)}</td>
              <td>{renewalInfo?.validFrom}</td>
            </tr>
          </table>
          <div className="payment-form">
            <div className="payment-info-form">
              <h2>{t('transactionDetails')}</h2>
              <div className="single-row">
                <Input
                  type="text"
                  label={t('transactionID')}
                  required
                  name="transaction_id"
                  value={payment?.transaction_id}
                  change={handleChange}
                  placeholder={t('enterTransactionID')}
                />
                <InputDate
                  label={t('transactionDate')}
                  name="transaction_date"
                  required
                  value={payment?.transaction_date}
                  change={handleChange}
                  disableFuture
                />
                <SelectBox
                  label={t('transactionMode')}
                  name="transaction_type"
                  required
                  value={payment.transaction_type}
                  onchange={handleChange}
                  lists={paymentOption}
                  id="select-account"
                  placeholder={t('selectTransactionMode')}
                />
                <InputInnerSymbol
                  type="number"
                  name="transaction_amount"
                  label={t('transactionAmount')}
                  required
                  change={handleChange}
                  value={payment?.transaction_amount}
                  placeholder="0"
                  symbol={currencySymbol(currency)}
                  min={0}
                />
              </div>
            </div>
          </div>
          <div className="form-button-group">
            <div className="form-button">
              <Button
                label={t('back')}
                click={backModal}
                classes="default-button"
              />
            </div>
            <div className="form-button">
              <Button
                label={t('confirm')}
                click={submit}
                classes={uploading ? 'success-button loading-btn' : 'success-button'}
                disabled={disable}
                loading={uploading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RenewalSummary.propTypes = {
  backModal: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  getDevices: PropTypes.func.isRequired,
  getAllApis: PropTypes.func.isRequired,
  instanceChangeSuccess: PropTypes.func.isRequired,
  renewalInfo: PropTypes.objectOf.isRequired,
};

export default RenewalSummary;
