import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { RightArrowIcon } from './svgIcon';

function TopContent(props) {
  const clickAction = () => {
    props?.click();
  };

  return (
    <div className="top-content">
      <div className="top-main">
        <h1 className="top-label">
          {Array.isArray(props?.label) ? <TopTitle title={props?.label} /> : props?.label}
        </h1>
        {
          props?.button && (
            <div className="top-button">
              <Button
                label={props?.buttonLabel}
                click={clickAction}
                classes={props?.buttonClass}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

function TopTitle(props) {
  const navigate = useNavigate();
  return (
    <div className="title-text">
      {props?.title[0] !== undefined
        && <span role="presentation" onClick={() => navigate('../')}>{props?.title[0]}</span>}
      {props?.title[1] !== undefined && <RightArrowIcon />}
      {props?.title[1] !== undefined && props?.title[1]}
      {props?.title[2] !== undefined && <RightArrowIcon />}
      {props?.title[2] !== undefined && props?.title[2]}
    </div>
  );
}

TopContent.propTypes = {
  click: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  button: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
};

TopTitle.propTypes = {
  title: PropTypes.arrayOf().isRequired,
};

export default TopContent;
