/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../../shared/component/Button';
import TopContent from '../../../../shared/component/TopContent';
import {
  createWidgetStoreLocally,
} from '../../../../redux/slices/WidgetTempSlice';

function WidgetTemplate() {
  const selectedWidget = useSelector((state) => state.widgets.selectedWidget);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = () => {
    navigate('/admin/apps');
  };
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedHtmlUrl, setSelectedHtmlUrl] = useState(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const handleCardClick = (templateId, htmlUrl, templateName) => {
    setSelectedTemplate(templateId);
    setSelectedHtmlUrl(htmlUrl);
    setSelectedTemplateName(templateName);
  };
  const handleContinue = () => {
    dispatch(createWidgetStoreLocally({
      ...selectedTemplate,
      text_color: '#000000',
      background_color: '#FFFFFF',
    }));
    if (selectedTemplate && selectedHtmlUrl) {
      navigate(
        `add-weather/${selectedTemplate}`,
        {
          state: {
            id: selectedTemplate,
            html_url: selectedHtmlUrl,
            name: selectedTemplateName,
            script_url: selectedWidget.script_url,
          },
        },
      );
    }
  };
  useEffect(() => {
    if (!selectedWidget) {
      navigateBack();
    }
  }, []);
  return (
    <div className="widget-templates content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('apps'), t('weather')]}
          button={false}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="widget-form">
                <p style={{ fontWeight: '600', margin: '15px' }}>{t('selectStyle')}</p>
                {selectedWidget ? (
                  <div>
                    <div style={{ display: 'flex' }}>
                      {selectedWidget?.widget_templates?.map((template) => (
                        <div
                          role="presentation"
                          key={template?._id}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleCardClick(template?._id, template?.html_url, template?.name)}
                        >
                          <img
                            style={{
                              border: template?._id === selectedTemplate ? '2px solid black' : 'none',
                            }}
                            src={template?.preview_image}
                            alt={template?.name}
                          />
                          <p>{template?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>{t('noWidgetSelected')}</p>
                )}
                <div className="form-button-group">
                  <div className="form-button">
                    <Button label={t('back')} click={navigateBack} classes="default-button" />
                  </div>
                  <div className="form-button">
                    <Button
                      label={t('continue')}
                      click={handleContinue}
                      disabled={!selectedTemplate}
                      classes="success-button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WidgetTemplate;
