const WalkthroughSteps = (language, t) => {
  const steps = [
    {
      target: '.my-content-link',
      title: t('myContent'),
      content: t('exploreContent'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.myContent-createButton',
      title: t('newContent'),
      content: t('createNewContent'),
    },
    {
      target: '.storage-bar',
      title: t('storage'),
      content: t('viewStorage'),
    },
    {
      target: '.mydata-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.playlist-nav',
      title: t('playlist'),
      content: t('managePlaylists'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.playlist-createbutton',
      title: t('newPlaylist'),
      content: t('createNewPlaylistClick'),
    },
    {
      target: '.playlist-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.store-location-bar',
      title: t('facility'),
      content: t('facilitySection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.store-location-createbutton',
      title: t('newStore'),
      content: t('viewStoreLocation'),
    },
    {
      target: '.location-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.display-signage-sideBar',
      title: t('signageDevice'),
      content: t('signageDeviceSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.display-signage-createbutton',
      title: t('newSignageDevice'),
      content: t('createNewSignageDevice'),
    },
    {
      target: '.signage-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.display-groups-nav',
      title: t('deviceGroups'),
      content: t('displayGroupsSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.display-groups-createbutton',
      title: t('newDeviceGroups'),
      content: t('createNewDeviceGroups'),
    },
    {
      target: '.group-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.schedules-bar',
      title: t('schedules'),
      content: t('schedulesSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.schedule-button',
      title: t('newSchedule'),
      content: t('createNewSchedule'),
    },
    {
      target: '.schedule-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.special-schedules-bar',
      title: t('specialSchedules'),
      content: t('specialSchedulesSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.special-schedule-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.roles-bar',
      title: t('roles'),
      content: t('rolesSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.roles-button',
      title: t('newRole'),
      content: t('createNewRole'),
    },
    {
      target: '.roles-table',
      title: t('tableContent'),
      content: t('clickTableContent'),
    },
    {
      target: '.users-bar',
      title: t('users'),
      content: t('usersSection'),
      placement: language === 'ar' ? 'left' : 'right',
    },
    {
      target: '.users-button',
      title: t('newUser'),
      content: t('createNewUser'),
    },
    {
      target: '.user-table',
      title: t('tableContent'),
      content: t('clickTableContentClick'),
    },
  ];

  return steps;
};

export default WalkthroughSteps;
