import React from 'react';
import PropTypes from 'prop-types';

function ContentView(props) {
  return (
    <div className="content-device-view">
      {
        props?.data.map((val) => (
          <div className="content-view-list">
            <div>{val.name} :</div>
            <div>{val.value}</div>
          </div>
        ))
      }
    </div>
  );
}

ContentView.propTypes = {
  data: PropTypes.arrayOf(),
};
ContentView.defaultProps = {
  data: [],
};

export default ContentView;
