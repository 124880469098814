import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { updateCurrentTemplate } from '../../../redux/slices/TemplateSlice';
import arrowLeft from '../../../assets/icons/arrow-left.png';
import Button from '../../../shared/component/Button';

function HtmlHeader({ htmlData, disable, click }) {
  const { id } = useParams();
  const { t } = useTranslation();
  const [loaderMessage, setLoaderMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleArrowClick = () => {
    dispatch(updateCurrentTemplate(...htmlData));
    navigate(`/admin/templates/edit-content/${id}`);
  };

  const handleMouseOver = () => {
    setLoaderMessage('Please wait a few seconds for the template to save.');
  };

  const handleFocus = () => {
    handleMouseOver();
  };

  return (
    <div className="template-default-header">
      {!disable
        ? (
          <div
            className="default-header-left"
            onClick={() => handleArrowClick()}
            role="none"
          >
            <img src={arrowLeft} alt="left-arrow" />
          </div>
        )
        : (
          <div
            className={disable ? 'default-header-left-enable' : 'default-header-left'}
            role="none"
            onMouseOver={handleMouseOver}
            onFocus={handleFocus}
          >
            <img src={arrowLeft} alt="left-arrow" />
            <span>{loaderMessage}</span>
          </div>
        )}
      <div className="header-button" style={{ margin: '10px' }}>
        <Button
          label={!disable ? t('Publish') : 'Generating Image ...'}
          click={click}
          classes="success-button"
          disabled={disable}
        />
      </div>
    </div>
  );
}

HtmlHeader.propTypes = {
  htmlData: PropTypes.objectOf.isRequired,
  disable: PropTypes.bool.isRequired,
  click: PropTypes.func.isRequired,
};

export default HtmlHeader;
