/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import displayGroup from '../../utils/api/devices-group';
import { setErrorNotification } from './NotificationSlice';

export const fetchDisplayGroupList = createAsyncThunk(
  'displayGroup/fetchDisplayGroup',
  async (data, { dispatch }) => {
    const response = await displayGroup.getDevicesGroups(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        devices_list: val.devices.map((val1) => val1.name),
        total_count: val.devices.map((val1) => val1.name).length,
        devices: val.devices.map((val1) => ({ ...val1, id: val1._id })),
        id: val._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchSingleDisplayGroup = createAsyncThunk(
  'displayGroup/fetchSingleDisplayGroup',
  async (data, { dispatch }) => {
    const response = await displayGroup.getSingleDevicesGroup(data).then((response1) => ({
      ...response1?.data,
      devices_list: response1?.data.devices.map((val1) => val1.name),
      total_count: response1?.data.devices.map((val1) => val1.name).length,
      devices: response1?.data.devices.map((val1) => ({ ...val1, id: val1._id })),
      id: response1?.data._id,
      code: 200,
    })).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  displayGroup: [],
  pageCount: 0,
  totalPageCount: 1,
  singleDisplayGroup: {},
  newDisplayGroup: {},
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const displayGroupSlice = createSlice({
  name: 'displayGroup',
  initialState,
  reducers: {
    // Reducer to add the new displayGroup to top of the displayGroup list
    createNewDisplayGroup: (state, action) => {
      const newDisplayGroup = action.payload; // displayGroup to be added at the top
      const displayGroupList = [
        {
          ...newDisplayGroup,
          devices_list: newDisplayGroup.devices.map((device) => device.name),
          total_count: newDisplayGroup.devices.length,
          devices: newDisplayGroup.devices.map((device) => ({ ...device, id: device._id })),
          id: newDisplayGroup._id,
        },
        ...state.displayGroup, // Existing displayGroup
      ];
      state.displayGroup = displayGroupList; // update the displayGroup list
    },
    // Reducer to update the displayGroup by ID.
    updateDisplayGroup: (state, action) => {
      const updatedDisplayGroup = action.payload; // displayGroup to be added at the top
      const displayGroupList = state.displayGroup; // Assign the existing displayGroup to another variable
      // Find the index of the updated displayGroup by ID
      const index = displayGroupList.findIndex((display) => display._id === updatedDisplayGroup._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        displayGroupList[index] = {
          ...updatedDisplayGroup,
          devices_list: updatedDisplayGroup.devices.map((device) => device.name),
          total_count: updatedDisplayGroup.devices.map((device) => device.name).length,
          devices: updatedDisplayGroup.devices.map((device) => ({ ...device, id: device._id })),
          id: updatedDisplayGroup._id,
        };
      }
      state.displayGroup = displayGroupList; // Finally update the displayGroup list
    },
    // Reducer to delete the displayGroup by ID
    deleteDisplayGroup: (state, action) => {
      const displayGroupList = state.displayGroup;

      // Find the index to delete by ID
      const index = displayGroupList.findIndex((display) => display.id === action.payload);
      // If the index found, remove that index from list of displayGroup
      if (index !== -1) {
        displayGroupList.splice(index, 1);
      }
      state.displayGroup = displayGroupList; // Update the displayGroup state after deleted
    },
    singleDisplayGroupAdd: (state, action) => {
      state.newDisplayGroup = action.payload;
    },
    updateSingleDisplayGroup: (state, action) => {
      state.singleDisplayGroup = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetDisplayGroupState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDisplayGroupList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDisplayGroupList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.displayGroup = fetchedData;
          } else {
            const finalData = state.displayGroup; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.displayGroup = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchDisplayGroupList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchSingleDisplayGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleDisplayGroup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add user to the state array
        if (action?.payload?.code === 200) {
          state.singleDisplayGroup = action.payload;
        }
      });
  },
});

export const {
  createNewDisplayGroup,
  updateDisplayGroup,
  deleteDisplayGroup,
  singleDisplayGroupAdd,
  updateSingleDisplayGroup,
  setFilter,
  setTableFilter,
  resetDisplayGroupState,
} = displayGroupSlice.actions;

export default displayGroupSlice.reducer;
