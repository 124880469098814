/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import FileFormat from '../FileFormat';
import IFrameComponent from '../../../../shared/component/IFrameComponent';

function HtmlTemplateFifteen({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [productId, setProductId] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const htmlRef = useRef(null);
  const convertBase64 = async () => {
    try {
      // Convert to base64
      const dataUrl = await toPng(htmlRef.current, { cacheBust: true });
      setBase64(dataUrl); // Set the base64 data
      setDisable(false);
    } catch (error) {
      dispatch(setErrorNotification(error?.response?.data));
      setDisable(false);
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      const filterProductId = data?.[0]?.template_contents?.items
        .filter((category) => !category?.categoryHide)
        /* Maps each filtered category to its products and flattens the resulting arrays into a single array. */
        .flatMap((category) => category?.products
          .filter((product) => !product.hide)?.map((product) => product?._id));
      setProductId(filterProductId);
      const visibleCategories = data?.[0]?.template_contents?.items?.filter((category) => !category?.categoryHide);
      const visibleProducts = visibleCategories?.flatMap((category) => category?.products
        ?.filter((product) => !product?.hide));
      setProductsData(visibleProducts);
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: t('content'),
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        data={data}
        productId={productId}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        templateType={data?.[0]?.template_type}
      />,
    });
  };

  const templateContents = {
    contents: productsData,
  };
  const templateStyles = {
    style: {
      title: data?.[0]?.template_title,
      images: data?.[0]?.template_images,
      ...data?.[0]?.styles,
    },
  };
  const stringContent = JSON.stringify(templateContents);
  const stringStyle = JSON.stringify(templateStyles);
  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <div className="header-height" />
      <div className="template-container-13" ref={htmlRef}>
        {
          data?.length > 0 && productsData?.length > 0 && (
            <IFrameComponent
              htmlUrl={data[0]?.html_url}
              jsUrl={data[0]?.script_url}
              title="clock"
              replacements={[
                ['display_content', stringContent],
                ['display_style', stringStyle],
              ]}
            />
          )
        }
      </div>
    </div>
  );
}

HtmlTemplateFifteen.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default HtmlTemplateFifteen;
