import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTable from '../../Table';
import height from '../../../utils/size-variables';
import ModalBox from '../../../shared/component/ModalBox';
import TransferCredit from './TransferCredit';
import AIRenewalModal from './AIRenewal';
import RenewalSummary from './RenewalSummary';
import RenewalPayment from './RenewalPaymentModal';
import { changeDateFormat } from '../../../utils/helpers';

function CreditManagement(props) {
  let aiRenewal;
  let RenewalPaymentModal;
  const {
    instanceList,
    getDevices,
    getAllApis,
    filterFunction,
  } = props;
  const { t } = useTranslation();
  const [rowSelection, setRowSelection] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({
    orientation: false, // Set orientation column hidden by Default
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({});

  const handleChangeTransfer = () => {
    setModalOpen(true);
    setModalView({
      title: t('transferCredit'),
      content: <TransferCredit
        close={setModalOpen}
      />,
    });
  };
  const aiInstanceSummary = (data) => {
    setModalView({
      title: t('orderSummary'),
      content: <RenewalSummary
        backModal={() => RenewalPaymentModal(data)}
        getDevices={getDevices}
        getAllApis={getAllApis}
        renewalInfo={data}
        instanceChangeSuccess={setModalOpen}
      />,
    });
  };
  RenewalPaymentModal = (data) => {
    setModalView({
      title: t('orderSummary'),
      content: <RenewalPayment
        backModal={() => aiRenewal(data)}
        renewalInfo={data}
        submitPayment={aiInstanceSummary}
        closeModal={setModalOpen}
      />,
    });
  };
  aiRenewal = (instanceData) => {
    setModalOpen(true);
    setModalView({
      title: t('aiRenewal'),
      content: <AIRenewalModal
        close={setModalOpen}
        instanceData={instanceData}
        submitRenewal={RenewalPaymentModal}
      />,
    });
  };

  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('instanceName'),
      accessorKey: 'name',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('activationDate'),
      accessorKey: 'updatedAt',
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => changeDateFormat(row.getValue('updatedAt')),
    },
    {
      header: t('total'),
      accessorKey: 'total_available_hours',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('used'),
      accessorKey: 'used_hours',
      enableColumnFilter: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => {
        const usageHours = row.original.total_available_hours - row.original.available_hours;
        return <span>{usageHours > 0 ? usageHours.toFixed(2) : 0}</span>;
      },
    },
    {
      header: t('available'),
      accessorKey: 'available_hours',
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }) => {
        const totalHours = row.original.available_hours;
        let displayValue;
        if (totalHours == null) {
          displayValue = 0;
        } else if (Number.isInteger(totalHours)) {
          displayValue = totalHours;
        } else {
          displayValue = totalHours.toFixed(2);
        }

        return <span>{displayValue}</span>;
      },
    },
    {
      header: (
        <div className="ai-device-player-column">
          <span>{t('creditTransfer')}</span>
        </div>
      ),
      accessorKey: 'credit_transfer',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      enableSorting: false,
      Cell: () => (
        <div className="credit-transfer" role="none" onClick={handleChangeTransfer}>
          {t('transfer')}
        </div>
      ),
    },
    {
      header: (
        <div className="ai-device-player-column">
          <span>{t('action')}</span>
        </div>),
      accessorKey: 'action',
      size: 130,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <div
          className="credit-transfer"
          role="none"
          onClick={() => aiRenewal(row.original)}
        >
          {t('renew')}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsFirstRender(false);
  }, []);
  return (
    <div className="credit-management">
      <ModalBox
        status={modalOpen}
        closeModal={setModalOpen}
        modalView={modalView}
      />
      <h1 className="title">{t('creditManagement')}</h1>
      <div className={`content-body ${instanceList ? 'with-data' : ''}`}>
        {!instanceList
          && (
            <div className="no-data-found">
              <h1 className="no-data-message">{t('nodataFound')}</h1>
              <p className="no-data-description">
                {t('YoucanonlyviewthereportafterthefeedreceivedfromanAIEnableddevice')}
              </p>
            </div>
          )}
        {instanceList
          && (
            <div className="content-area">
              <div className="my-content">
                <div className="my-content-area ai-device-table">
                  <DataTable
                    className="my-data-table"
                    header={columns}
                    value={instanceList}
                    status="success"
                    fu
                    totalPageCount={1}
                    onFilterOrSortingChange={filterFunction}
                    pageCount={1}
                    scrollData={() => { }}
                    totalDataCount={10}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    height={height.tableHeight}
                    isFirstRender={isFirstRender}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

CreditManagement.propTypes = {
  instanceList: PropTypes.arrayOf().isRequired,
  getDevices: PropTypes.func.isRequired,
  getAllApis: PropTypes.func.isRequired,
  filterFunction: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape().isRequired,
  }).isRequired,
};

export default CreditManagement;
