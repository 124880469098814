import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';
import { SmallRefreshIcon, ViewIcon } from '../../../shared/component/svgIcon';
import YAxisLeftTick from './YAxisLeftTick';

function ReportsByAge(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const ageGroups = [
    { label: '1-9', value: props?.value?.ageGroup?.ageRange1to9 || 0 },
    { label: '10-17', value: props?.value?.ageGroup?.ageRange10to17 || 0 },
    { label: '18-24', value: props?.value?.ageGroup?.ageRange18to24 || 0 },
    { label: '25-34', value: props?.value?.ageGroup?.ageRange25to34 || 0 },
    { label: '35-44', value: props?.value?.ageGroup?.ageRange35to44 || 0 },
    { label: '45-54', value: props?.value?.ageGroup?.ageRange45to54 || 0 },
    { label: '55-64', value: props?.value?.ageGroup?.ageRange55to64 || 0 },
    { label: '65+', value: props?.value?.ageGroup?.ageRange64to100 || 0 },
  ];

  // Calculate the total population for all age groups
  const total = ageGroups.reduce((acc, group) => acc + group.value, 0);

  // Map age group percentages, handling division by zero with a conditional check
  const data = ageGroups.map((group) => ({
    name: group.label,
    pv: total ? (group.value / total) * 100 : 0,
  }));
  const handleChange = () => {
    navigate('/admin/ai-analytics-age-reports');
  };
  // Initialize canvas context for text measurement
  let ctx;
  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      // Create a canvas element and get its 2D context
      ctx = document.createElement('canvas').getContext('2d');
      ctx.font = "14px 'Helvetica Neue";
    }
    // Measure the width of the text in the specified font
    return ctx.measureText(text).width;
  };
  // Space reserved for the axis label
  const BAR_AXIS_SPACE = 30;
  // Calculate the maximum width of Y-axis labels
  const maxTextWidth = useMemo(
    () => data.reduce((acc, cur) => {
      const value = cur.pv;
      const width = measureText14HelveticaNeue(value.toLocaleString());
      if (width > acc) {
        return width;
      }
      return acc;
    }, 0),
    [data],
  );
  return (
    <div className="report-by-gender">
      <div className="report-header">
        <div className="header-left">
          <h3 className="title">{t('reportsByAge')}</h3>
          <ViewIcon className="status-view-icon" />
        </div>
      </div>
      <div className="pie-chart-container" style={{ padding: '0 50px' }}>
        <ResponsiveContainer width="100%" height={50 * data.length} debounce={50}>
          <BarChart
            data={data}
            layout="vertical"
            margin={{ left: 10, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
          >
            <XAxis hide axisLine={false} type="number" />
            <YAxis
              yAxisId={0}
              dataKey="name"
              type="category"
              axisLine={false}
              tickLine={false}
              tick={YAxisLeftTick}
            />
            <YAxis
              orientation="right"
              yAxisId={1}
              dataKey="pv"
              type="category"
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value.toLocaleString()}%`}
              mirror
              tick={{
                transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
              }}
              style={{ fontSize: '13px', textAlign: 'justify' }}
            />
            <Bar
              dataKey="pv"
              minPointSize={2}
              barSize={18}
              background={{ fill: '#F4F4F4' }}
              onClick={handleChange}
            >
              {data.map((d) => (
                <Cell key={d} fill="#0195F7" cursor="pointer" />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="last-time">
        <SmallRefreshIcon />
        <span className="last-time-message">{props?.time}</span>
      </div>
    </div>
  );
}
ReportsByAge.propTypes = {
  value: PropTypes.shape({
    ageGroup: PropTypes.objectOf(),
  }),
  time: PropTypes.string,
};

ReportsByAge.defaultProps = {
  value: {
    ageGroup: {},
  },
  time: '',
};
export default ReportsByAge;
