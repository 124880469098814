import React from 'react';
import ViewTemplate from './components/ViewTemplate';

function Templates() {
  return (
    <ViewTemplate />
  );
}

export default Templates;
