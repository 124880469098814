import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import DataTable from '../../Table';
import exportCSV from '../../Global/exportCSV';

function ReportAgeGroup({ gender, countData, filterFunction }) {
  const barColor = gender === 'women' ? '#EC4899' : 'rgb(1, 149, 247)';
  const { t } = useTranslation();
  const columns = [
    {
      header: t('sNo'),
      size: 70,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('ageGroup'),
      accessorKey: 'ageRange',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('totalCount'),
      accessorKey: 'value',
      enableColumnActions: false,
      enableResizing: false,
    },
    {
      header: t('percentage'),
      accessorKey: 'percentage',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableResizing: false,
    },
  ];

  const exportData = () => {
    const columnHeaders = ['Age Group', 'Total Count', 'Percentage'];
    const returnData = countData.count.map((val) => ({
      'Age Group': val?.ageRange,
      'Total Count': val?.value,
      Percentage: val?.percentage,
    }));
    // Function to export as CSV file
    exportCSV(columnHeaders, returnData);
  };
  return (
    <div className="report-user-total-count">
      <div className="bar-chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={400}
            data={countData?.chartCount}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tick={{ fill: '#717880', fontSize: 13 }} />
            <YAxis tick={{ fill: '#717880', fontSize: 13 }} />
            <Tooltip />
            {/* <Legend /> */}
            <Bar
              dataKey="value"
              fill={barColor}
              barSize={60}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="report-data-table">
        <DataTable
          className="my-data-table"
          header={columns}
          value={countData.count}
          status="success"
          totalPageCount={1}
          onFilterOrSortingChange={filterFunction}
          rowSelection={[]}
          pageCount={1}
          scrollData={() => { }}
          exportButton
          exportData={exportData}
          totalDataCount={10}
        />
      </div>
    </div>
  );
}
ReportAgeGroup.propTypes = {
  gender: PropTypes.string.isRequired, // Define gender as a required string
  filterFunction: PropTypes.func.isRequired,
  countData: PropTypes.objectOf(PropTypes.arrayOf),
};
ReportAgeGroup.defaultProps = {
  countData: {
    count: [],
    chartCount: [],
  },
};
export default ReportAgeGroup;
