import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';

function DisplaySignageView() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const allData = useSelector((state) => state.display.displaySignage);
  const value = allData.find((val) => val.id === params?.id);

  useEffect(() => {
    if (allData.length === 0) {
      navigate('../');
    }
  }, [params]);

  const addDisplaySignage = () => {
    navigate(`../edit/${value.id}`);
  };

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('signageDevices'), value?.name]}
          button={false}
          buttonLabel={t('editDisplaySignage')}
          buttonClass="default-button button-outline-success-1"
          click={addDisplaySignage}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area">
              <div className="signage-form card">
                <Input
                  icon={' '}
                  label={t('signageLocation')}
                  type="text"
                  name="device_system_id"
                  readonly
                  required={false}
                  value={value?.store?.city}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('storeName')}
                  name="store"
                  readonly
                  required={false}
                  value={value?.store?.storeName}
                />
                <Input
                  icon={' '}
                  label={t('deviceBrand')}
                  type="text"
                  name="brand"
                  readonly
                  required={false}
                  value={value?.brand}
                />
                <Input
                  icon={' '}
                  label={t('devicesName')}
                  type="text"
                  name="name"
                  readonly
                  required={false}
                  value={value?.name}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('deviceOS')}
                  name="hardware"
                  readonly
                  required={false}
                  value={value?.hardware}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('size')}
                  name="size"
                  readonly
                  required={false}
                  value={value?.screen_size}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('orientation')}
                  name="orientation"
                  readonly
                  value={value?.orientation}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('resolution')}
                  name="resolution"
                  readonly
                  required={false}
                  value={value?.resolution}
                />
                <Input
                  icon={' '}
                  type="text"
                  label={t('playerVersion')}
                  name="player_app_version"
                  readonly
                  value={value?.player_app_version}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplaySignageView;
