/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/component/Button';
import { Input } from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import InputTags from '../../../shared/component/form/InputTags';
import { RightArrow } from '../../../shared/component/svgIcon';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import templates from '../../../utils/api/templates';
import { createNewContent, updateContent } from '../../../redux/slices/MyContentSlice';
import {
  nameValidation, descriptionValidation, tagValidation, integerValidation,
} from '../../../shared/component/form/Validation';
import {
  contentUpload,
  resetTemplateContent,
  setPage,
  updateCurrentTemplate,
} from '../../../redux/slices/TemplateSlice';
import { uploadImageResize } from '../../../utils/helpers';

function TemplateContentUpload(props) {
  const templateData = useSelector((state) => state?.currentTemplate?.templateContent);
  const contentUploadType = useSelector((state) => state?.currentTemplate?.contentUploadType);
  const existingContent = useSelector((state) => state?.currentTemplate?.content);
  const { t } = useTranslation();
  const orientation = [
    {
      id: 1,
      label: t('portrait'),
    },
    {
      id: 2,
      label: t('landscape'),
    },
  ];
  const transitions = [
    {
      id: 1,
      label: t('fadeIn'),
    },
    {
      id: 2,
      label: t('fadeOut'),
    },
    {
      id: 3,
      label: t('slideInUp'),
    },
    {
      id: 4,
      label: t('slideInDown'),
    },
    {
      id: 5,
      label: t('slideInRight'),
    },
    {
      id: 6,
      label: t('slideInLeft'),
    },
    {
      id: 7,
      label: t('slideOutUp'),
    },
    {
      id: 8,
      label: t('slideOutDown'),
    },
    {
      id: 9,
      label: t('slideOutLeft'),
    },
    {
      id: 10,
      label: t('slideOutRight'),
    },
  ];
  const [notifyMessage, setNotifyMessage] = useState(false);
  const [advanceSetting, setAdvanceSetting] = useState();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [value, setValue] = useState({
    name: existingContent?.name ? existingContent?.name : '',
    description: existingContent?.description ? existingContent?.name : '',
    tags: existingContent?.tags ? existingContent.tags : [],
    orientation: existingContent?.orientation ? existingContent?.orientation : t('landscape'),
    start_transition: existingContent?.start_transition ? existingContent?.start_transition : t('fadeIn'),
    end_transition: existingContent?.end_transition ? existingContent?.end_transition : t('fadeOut'),
    duration: existingContent?.duration ? existingContent?.duration : 5,
  });
  const [imageUrl, setImageUrl] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleFunction = (name, changeValue, key = 0) => {
    if (name === 'tags' && key === 13) {
      setValue({ ...value, [name]: Array?.isArray(changeValue) ? changeValue : [...value.tags, changeValue] });
    } else if (
      (name === 'orientation'
        || name === 'start_transition'
        || name === 'end_transition')
      && changeValue
    ) {
      setValue({ ...value, [name]: changeValue?.label });
    } else if (
      name === 'name'
      || name === 'orientation'
      || name === 'description'
      || name === 'duration'
      || name === 'thumbnail'
      || name === 'volume'
      || name === 'url'
    ) {
      setValue({ ...value, [name]: changeValue });
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl?.split(',');
    const mime = arr?.[0]?.match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr?.length;
    const u8arr = new Uint8Array(n);
    while (n > -1) {
      u8arr[n] = bstr?.charCodeAt(n);
      n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleClose = () => {
    props?.setNotification(false);
    setValue({
      name: '',
      description: '',
      tags: [],
      orientation: t('landscape'),
      start_transition: t('fadeIn'),
      end_transition: t('fadeOut'),
    });
  };
  const submit = () => {
    setSubmitButtonClicked(true);
    if (
      nameValidation(1, 100, value?.name)
      && descriptionValidation(500, value?.description)
      && tagValidation(30, 10, value?.tags)
      && integerValidation(1, Infinity, value?.duration)
    ) {
      const imageRequest = {
        tags: value?.tags,
        name: value?.name,
        orientation: value?.orientation,
        description: value?.description,
        duration: value?.duration,
        start_transition: value?.start_transition,
        end_transition: value?.end_transition,
      };
      imageRequest.file = dataURLtoFile(imageUrl?.image1, value?.name);
      imageRequest.thumbnail = {
        px_600x400: dataURLtoFile(imageUrl?.image2, value?.name),
        px_50x50: dataURLtoFile(imageUrl?.image3, value?.name),
      };
      const htmlRequest = {
        tags: value?.tags,
        name: value?.name,
        orientation: value?.orientation,
        description: value?.description,
        duration: value?.duration,
        start_transition: value?.start_transition,
        end_transition: value?.end_transition,
        html_content: props?.htmlContent,
      };
      htmlRequest.file = dataURLtoFile(imageUrl?.image1, value?.name);
      htmlRequest.thumbnail = {
        px_600x400: dataURLtoFile(imageUrl?.image2, value?.name),
        px_50x50: dataURLtoFile(imageUrl?.image3, value?.name),
      };
      const postData = props?.fileType === 'image' ? imageRequest : htmlRequest;
      setDisable(true);
      setUploading(true);
      const templateType = props?.data?.[0]?.template_type === 'Integration';
      if (templateType) {
        const htmlStyle = { ...props?.data?.[0]?.styles, title: props?.data?.[0]?.template_title };
        const posRequest = {
          tags: value?.tags,
          name: value?.name,
          orientation: value?.orientation,
          description: value?.description,
          duration: value?.duration,
          start_transition: value?.start_transition,
          end_transition: value?.end_transition,
          html_style: htmlStyle,
          template_content: props?.productId,
          html_url: props?.data?.[0]?.html_url,
          script_url: props?.data?.[0]?.script_url,
        };
        posRequest.file = dataURLtoFile(imageUrl?.image1, value?.name);
        posRequest.thumbnail = {
          px_600x400: dataURLtoFile(imageUrl?.image2, value?.name),
          px_50x50: dataURLtoFile(imageUrl?.image3, value?.name),
        };

        templates?.addPosContent(posRequest)
          .then((posResponse) => {
            if (posResponse) {
              setUploading(false);
              setUploading(false);
              setNotifyMessage(true);
              dispatch(createNewContent(posResponse?.data));
              setTimeout(() => {
                setNotifyMessage(false);
                dispatch(setPage('tab-1'));
                navigate('/admin/integration');
              }, 2000);
            }
          }).catch((error) => {
            setUploading(false);
            setDisable(false);
            dispatch(setErrorNotification(error?.response?.data));
          });
      } else {
        if (contentUploadType === 'create') {
          templates
            .addContent(props?.fileType, postData)
            .then(async (res) => {
              const contentId = {
                _id: templateData?._id,
                multi_pricing: templateData?.multi_pricing,
                search_tags: templateData?.search_tags,
                styles: templateData?.styles,
                tags: templateData?.tags,
                template_backgroundImage_url: templateData?.template_backgroundImage_url,
                template_contents: templateData?.template_contents,
                template_image_url: templateData?.template_image_url,
                template_images: templateData?.template_images,
                template_name: templateData?.template_name,
                template_number: templateData?.template_number,
                template_title: templateData?.template_title,
                template_type: templateData?.template_type,
                clone: templateData?.clone,
                content_id: res?.data._id,
              };
              if (contentId?.content_id) {
                templates?.updateSavedTemplate([contentId])?.then((updateRes) => {
                  dispatch(updateCurrentTemplate(updateRes?.data));
                  dispatch(createNewContent(res?.data));
                  setUploading(false);
                  setNotifyMessage(true);
                  setTimeout(() => {
                    setNotifyMessage(false);
                    setDisable(false);
                    handleClose();
                    dispatch(setPage('tab-2'));
                    navigate('/admin/templates');
                  }, 2000);
                })
                  .catch((updateError) => {
                    handleClose();
                    setUploading(false);
                    setDisable(false);
                    if (updateError?.response?.data) {
                      dispatch(setErrorNotification(updateError?.response?.data));
                    } else {
                      dispatch(setErrorNotification({ message: 'Error updating template' }));
                    }
                  });
              }
            })
            .catch((error) => {
              handleClose();
              setUploading(false);
              setDisable(false);
              if (error?.response?.data) {
                dispatch(setErrorNotification(error?.response?.data));
              } else {
                dispatch(setErrorNotification({ message: 'The file must be under 300 MB' }));
              }
            });
        }
        if (contentUploadType === 'update') {
          templates.updateContent(props?.fileType, templateData?.content_id, postData).then((res) => {
            dispatch(updateContent(res?.data));
            dispatch(contentUpload('create'));
            setUploading(false);
            setNotifyMessage(true);
            setTimeout(() => {
              setNotifyMessage(false);
              setDisable(false);
              dispatch(contentUpload('create'));
              handleClose();
              dispatch(setPage('tab-2'));
              navigate('/admin/templates');
            }, 2000);
          }).catch((error) => {
            setUploading(false);
            dispatch(setErrorNotification(error?.response?.data));
          });
        }
      }
    }
  };
  useEffect(() => {
    const file = dataURLtoFile(props?.base64, value?.name);
    const imageResize = uploadImageResize(URL.createObjectURL(file));
    setImageUrl(imageResize);
  }, [props?.base64]);
  useEffect(() => {
    if (
      value?.name
      && value?.orientation
      && value?.start_transition
      && value?.end_transition
      && value?.duration
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);
  useEffect(() => () => dispatch(resetTemplateContent()), []);
  return (
    <div className="upload-media">
      <ModalBox
        status={notifyMessage}
        closeModal={setNotifyMessage}
        modalView={{ content: <Success message={t('contentCreatedSuccess')} /> }}
      />
      <div className="form">
        <div className="input-black">
          <div className="image-banner">
            <img src={props?.base64 || ''} alt="content_image" className="content-image" />
          </div>
        </div>
        <div className="form-fields">
          <div className="form-fields-row">
            <Input
              icon={' '}
              label={t('contentName')}
              type="text"
              name="name"
              required
              value={value?.name}
              change={handleFunction}
              placeholder={t('enterContentName')}
              error={
                !nameValidation(1, 100, value?.name)
                && submitButtonClicked
              }
              errorMessage={t('contentNameValidation')}
            />
            <SelectBox
              label={t('orientation')}
              name="orientation"
              lists={orientation}
              value={value?.orientation}
              onchange={handleFunction}
              placeholder={t('selectOrientation')}
              id="orientation"
            />
          </div>
          <Input
            icon={' '}
            label={t('contentDescription')}
            name="description"
            value={value?.description}
            change={handleFunction}
            placeholder={t('enterContentDescription')}
            error={
              !descriptionValidation(500, value?.description)
              && submitButtonClicked
            }
            errorMessage={t('descriptionValidation')}
          />
          <InputTags
            icon={' '}
            label={t('mediaTags')}
            name="tags"
            addData={handleFunction}
            required={false}
            value={value?.tags}
            placeholder={t('enterMediaTags')}
            error={
              !tagValidation(30, 10, value?.tags)
              && submitButtonClicked
            }
            errorMessage={t('tagsValidation')}
          />
        </div>
        <div className="advance-settings-wrap">
          <div className="advance-header">
            <h2>{t('advancedSettings')}</h2>
            <span
              role="presentation"
              onClick={() => setAdvanceSetting(!advanceSetting)}
              style={{
                transform: advanceSetting ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            >
              <RightArrow />
            </span>
          </div>
          <div
            className="settings-input"
            style={{
              height: advanceSetting ? '190px' : '0px',
            }}
          >
            <div className="single-row">
              <SelectBox
                label={t('startTransition')}
                name="start_transition"
                lists={transitions}
                value={value?.start_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="start_transitions"
              />
              <SelectBox
                label={t('endTransition')}
                name="end_transition"
                lists={transitions}
                value={value?.end_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="end_transitions"
              />
            </div>
            <div className="single-row">

              <Input
                icon={' '}
                label={t('durationSecond')}
                type="number"
                name="duration"
                required
                value={value?.duration}
                change={handleFunction}
                placeholder={t('enterDuration')}
                error={
                  !integerValidation(1, Infinity, value?.duration)
                  && submitButtonClicked
                }
                errorMessage={t('durationValidation')}
              />
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={handleClose}
              classes="default-button"
            />
          </div>

          <div className="form-button">
            <Button
              label={uploading ? t('uploading') : t('upload')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateContentUpload.propTypes = {
  setNotification: PropTypes.node.isRequired,
  base64: PropTypes.string,
  htmlContent: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    template_type: PropTypes.string,
    styles: PropTypes.shape({
      background_color: PropTypes.string,
      cate_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      desc_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      item_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      offer_price_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      price_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      sub_title_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
      title_style: PropTypes.shape({
        fontSize: PropTypes.string,
        fontFamily: PropTypes.string,
        color: PropTypes.string,
      }),
    }),
    template_title: PropTypes.string,
    template_number: PropTypes.number,
    script_url: PropTypes.string,
    html_url: PropTypes.string,
    template_contents: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        categoryHide: PropTypes.bool,
        products: PropTypes.arrayOf(PropTypes.shape({
          hide: PropTypes.bool,
          productId: PropTypes.string,
        })),
      })),
    }),
  })),
  productId: PropTypes.arrayOf(PropTypes.string),
};

TemplateContentUpload.defaultProps = {
  base64: '',
  htmlContent: '',
  data: null,
  productId: null,
};

export default TemplateContentUpload;
