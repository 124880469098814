/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setErrorNotification } from './NotificationSlice';
import templates from '../../utils/api/templates';

export const postTemplatesList = createAsyncThunk(
  'postTemplateList',
  async (data, { dispatch }) => {
    const response = await templates.getPosTemplatesList(data)
      .then((res) => ({ ...res.data, code: 200 }))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  posTemplates: [],
  status: 'succeeded',
};

export const posTemplateSlice = createSlice({
  name: 'posTemplates',
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(postTemplatesList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postTemplatesList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload.code === 200) {
          const fetchedData = action?.payload?.results;
          state.posTemplates = fetchedData;
        }
        state.status = 'succeeded';
      })
      .addCase(postTemplatesList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default posTemplateSlice.reducer;
