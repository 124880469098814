import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getTemplates: () => get(`${process.env.REACT_APP_API_URL}cms/v1/template`),
  updateSavedTemplate:
    (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/template/save-template`, data[0]),
  getSavedTemplates: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/template/my-templates`, { params });
  },
  updateImageUrl: (id, data) => {
    const body = {
      'my-template-image': data,
    };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/template/update-template-image/${id}`, body, true);
  },
  updateTemplateImage:
    (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/template/template-background-image`, data, true),
  deleteSavedTemplates:
    (id) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/template/delete-saved-template/${id}`),
  getPosTemplatesList: () => get(`${process.env.REACT_APP_API_URL}cms/v1/template/get-integration-templates`),
  addPosContent: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/template/save-integration-template`, body, true);
  },
  addContent: (fileType, data = {}) => {
    const body = { ...data };
    const endpoint = fileType === 'image'
      ? `${process.env.REACT_APP_API_URL}cms/v1/contents`
      : `${process.env.REACT_APP_API_URL}cms/v1/template/save-as-html`;
    return post(endpoint, body, true);
  },
  updateContent: (fileType, id, data = {}) => {
    const body = { ...data };
    const endpoint = fileType === 'image'
      ? `${process.env.REACT_APP_API_URL}cms/v1/contents/${id}`
      : `${process.env.REACT_APP_API_URL}cms/v1/template/save-as-html/${id}`;
    return patch(endpoint, body, true);
  },
};
