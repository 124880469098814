import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getContents: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/contents`, { params });
  },
  getTotalContentSize: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/contents/total-content-size`, { params });
  },
  getSingleContent: (data = 0) => get(`${process.env.REACT_APP_API_URL}cms/v1/contents/${data}`, {}),
  addContent: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/contents`, body, true);
  },
  updateContent: (data, id) => patch(`${process.env.REACT_APP_API_URL}cms/v1/contents/${id}`, data, true),
  deleteContent: (data = 0) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/contents/${data}`, {}),
  myContentExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/contents/export`, { ...data }),
  uploadResizedImage: (id, data) => patch(
    `${process.env.REACT_APP_API_URL}cms/v1/contents/resized-content-upload/${id}`,
    data,
    true,
  ),
};
