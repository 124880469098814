import {
  get,
  post,
  deletes,
  patch,
} from './base/index';

export default {
  getWidgets: () => get(`${process.env.REACT_APP_API_URL}cms/v1/widgets`),
  getWidgetTemplate: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/my-widgets`, { params });
  },
  createWidgetTemplate:
    (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/my-widgets`, data, true),
  deleteSavedWidget:
    (id) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/my-widgets/${id}`),
  updateWidget: (id = null, data = {}) => {
    const params = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/my-widgets/${id}`, params, true);
  },
};
