/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PieChart } from '@mui/x-charts/PieChart';

import TopContent from '../../shared/component/TopContent';

import DeleteModal from '../../shared/component/DeleteModal';
import snapshotApi from '../../utils/api/snapshot';

import {
  setErrorNotification,
} from '../../redux/slices/NotificationSlice';

import Button from '../../shared/component/Button';
import HistoryList from './components/HistoryList';
import ListRow from './components/ListRow';
import ModalBox from '../../shared/component/ModalBox';
import DevicesGroupsList from './components/DevicesGroupsList';
import DevicesList from './components/DevicesList';
import StoresList from './components/StoresList';
import SchedulesList from './components/SchedulesList';
import DotLoader from '../../shared/component/Loader';
import { checkFeatures, checkPermission } from '../../utils/helpers';

function Snapshot() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const maxRetryCount = 3;

  const displaySignage = useSelector((state) => state.snapshotDisplay.totalDataCount);
  const displayGroup = useSelector((state) => state.snapshotDisplayGroup.totalDataCount);
  const storeLocation = useSelector((state) => state.snapshotStore.totalDataCount);
  const schedules = useSelector((state) => state.snapshotSchedules.totalDataCount);

  const menus = [
    {
      name: t('displays'),
      className: 'menus active',
      key: 'displays',
      featureKey: 'create_display_snapshot',
      count: displaySignage,
      enable: checkPermission('getDisplaySnapshot'),
    },
    {
      name: t('groups'),
      className: 'menus',
      key: 'display_groups',
      featureKey: 'create_display_group_snapshot',
      count: displayGroup,
      enable: checkPermission('getGroupsSnapshot'),
    },
    {
      name: t('facilities'),
      className: 'menus',
      key: 'stores',
      featureKey: 'create_store_snapshot',
      count: storeLocation,
      enable: checkPermission('getStoreSnapshot'),
    },
    {
      name: t('schedules'),
      className: 'menus',
      key: 'schedules',
      featureKey: 'create_schedule_snapshot',
      count: schedules,
      enable: checkPermission('getScheduleSnapshot'),
    },
  ];

  const summaryDefaults = [
    { label: t('requested'), value: 0, color: 'green' },
    { label: t('received'), value: 0, color: 'blue' },
    { label: t('error'), value: 0, color: 'red' },
  ];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pageView, setPageView] = useState('displays');
  const [requestId, setRequestId] = useState('');
  const [disable, setDisable] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [summary, setSummary] = useState(summaryDefaults);
  const [createContentModal, setCreateContentModal] = useState({
    title: '',
    content: 0,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [tabs, setTabs] = useState(menus);

  let interval;

  const getCount = (name) => {
    let value = 0;
    switch (name) {
      case 'displays':
        value = displaySignage;
        break;
      case 'display_groups':
        value = displayGroup;
        break;
      case 'stores':
        value = storeLocation;
        break;
      case 'schedules':
        value = schedules;
        break;

      default:
        break;
    }
    return value;
  };

  const reset = () => {
    setRowSelection({});
    setSelectedValues([]);
    clearInterval(interval);
    setDisable(false);
    setRequestId('');
    setSummary(summaryDefaults);
  };

  const resetBeforeSubmit = () => {
    setSelectedValues([]);
    clearInterval(interval);
    setRequestId('');
    setSummary(summaryDefaults);
  };

  const changeActiveTab = (name) => {
    reset();
    setTabs(tabs.map((obj) => ({
      ...obj,
      className: obj.key === name ? 'menus active' : 'menus',
      count: getCount(obj.key),
    })));
    setPageView(name);
  };

  const retryCaptureSnapshot = (id) => {
    setSelectedValues((prevState) => prevState.map((obj) => ({
      ...obj,
      snapshots: obj.snapshots.map((elm) => {
        if (elm._id === id) {
          const expiryTimestamp = new Date();
          expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 10); // 10 minutes timer

          return {
            ...elm,
            startRetryTimer: true,
            expiryTimestamp,
          };
        }
        return { ...elm };
      }),
    })));
    snapshotApi.retrySnapshot(id).then((response) => {
      if (response.status === 200) {
        console.log('Snapshot request send successfully!');
      }
    }).catch((error) => {
      dispatch(setErrorNotification({ message: error?.response?.data?.message }));
    });
  };

  const regularInterval = (requestIds) => {
    snapshotApi.getSnapshots(requestIds, pageView).then((response) => {
      setSkeletonLoader(false);

      setSelectedValues((prevState) => prevState.map((val, index) => ({
        ...val,
        snapshots: val.snapshots.map((elm, index1) => (
          {
            ...elm,
            retry_count: response?.data?.latestSnapshotWithSameRequest[index]?.snapshots[index1]?.retry_count,
            signedUrl: response?.data?.latestSnapshotWithSameRequest[index]?.snapshots[index1]?.signedUrl,
          }
        )),
      })));
      setSummary([
        {
          key: 'requested',
          label: t('requested'),
          value: response?.data?.requestSnapshot,
          color: 'green',
        },
        {
          key: 'received',
          label: t('received'),
          value: response?.data?.receivedSnapshot,
          color: 'blue',
        },
        {
          key: 'error',
          label: t('error'),
          value: response?.data?.errorSnapshot,
          color: 'red',
        },
      ]);
    }).catch((error) => {
      setSkeletonLoader(false);
      dispatch(setErrorNotification({ message: error?.response?.data?.message }));
    });
  };

  const submit = () => {
    resetBeforeSubmit();
    setDisable(true);
    const data = Object.keys(rowSelection);
    setSkeletonLoader(true);
    snapshotApi.createCaptureSnapshot({ entityIds: data, target: pageView }).then((response) => {
      setTimeout(() => {
        setRequestId(response.data.requestId);
        setSkeletonLoader(false);

        const expiryTimestamp = new Date();
        expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 10); // 10 minutes timer

        setSelectedValues(response?.data?.latestSnapshotWithSameRequest?.map((val) => ({
          ...val,
          snapshots: val?.snapshots?.map((elm) => (
            {
              ...elm,
              startRetryTimer: true,
              expiryTimestamp,
            }
          )),
        })));
      }, 4000);
    }).catch((error) => {
      setSkeletonLoader(false);
      dispatch(setErrorNotification({ message: error?.response?.data?.message }));
    });
  };

  const viewImage = (data) => {
    setCloseModal(true);
    setCreateContentModal({
      title: data.device,
      content: <img alt="view_image" src={data} />,
    });
  };

  const stopRetryTimer = (deviceId) => {
    setSelectedValues((prevState) => prevState.map((val) => ({
      ...val,
      snapshots: val.snapshots.map((elm) => {
        if (elm.device._id === deviceId) {
          return {
            ...elm,
            startRetryTimer: false,
          };
        }
        return { ...elm };
      }),
    })));
  };

  useEffect(() => {
    if (Object.keys(rowSelection).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (requestId && !interval) {
      interval = setInterval(() => {
        regularInterval(requestId);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [requestId]);

  useEffect(() => {
    let processedData = 0;
    let requestedSnapshots = 0;
    summary.forEach((summaryObj) => {
      if (summaryObj.key === 'received' || summaryObj.key === 'error') {
        processedData += summaryObj.value;
      }

      if (summaryObj.key === 'requested') {
        requestedSnapshots = summaryObj.value;
      }
    });

    if (processedData === requestedSnapshots && requestedSnapshots) {
      clearInterval(interval);
      setRequestId('');
    }
  }, [selectedValues, summary]);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('snapshot')}
          button={false}
          buttonLabel={t('newSnapshot')}
          buttonClass="success-button"
          click={() => { }}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={() => { }}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisUser?')}
          subTitle={t('deleteUserSubMessage')}
        />
        <ModalBox
          status={closeModal}
          closeModal={setCloseModal}
          modalView={createContentModal}
          notification={false}
        />
        <div
          className="content-area"
          style={
            {
              overflow: 'scroll',
              height: '80vh',
              backgroundColor: '#f1f5f8',
              display: 'flex',
              gap: '20px',
              flexDirection: 'column',
            }
          }
        >
          <div className="my-content" style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
            <div className="tab-bar" style={{ borderBottom: '1px solid #ccc', padding: '20px 20px 0px 40px' }}>
              <ul className="tab-bar-list">
                {
                  tabs.map((e) => e.enable && (
                    <li
                      className={e.active ? e.activeClass : e.className}
                      style={{ display: checkFeatures('snapshot', e.featureKey) ? 'block' : 'none' }}
                    >
                      <div role="presentation" onClick={() => changeActiveTab(e.key)}>{e.name}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className="my-content-area">
              {
                pageView === 'displays' && checkPermission('getDisplaySnapshot') && (
                  <DevicesList rowSelection={rowSelection} setRowSelection={setRowSelection} />
                )
              }
              {
                pageView === 'display_groups' && checkPermission('getGroupsSnapshot') && (
                  <DevicesGroupsList rowSelection={rowSelection} setRowSelection={setRowSelection} />
                )
              }
              {
                pageView === 'stores' && checkPermission('getStoreSnapshot') && (
                  <StoresList rowSelection={rowSelection} setRowSelection={setRowSelection} />
                )
              }
              {
                pageView === 'schedules' && checkPermission('getScheduleSnapshot') && (
                  <SchedulesList rowSelection={rowSelection} setRowSelection={setRowSelection} />
                )
              }
              {
                pageView === 'history' && (
                  <HistoryList />
                )
              }
              {
                pageView !== 'history' && (
                  <div className="form-button-group pr-10 pb-10">
                    <div className="form-button" style={{ width: '5vw' }}>
                      <Button label={t('reset')} click={reset} classes="default-button" />
                    </div>
                    <div className="form-button">
                      <Button label={t('captureSnapshot')} click={submit} classes="success-button" disabled={disable} />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div className="my-content" style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
            {
              !skeletonLoader && selectedValues?.length === 0 && (
                <div className="my-content-area">
                  <div className="loading-process">
                    <div className="pending-text">{t('noSnapshotAtTheMoment')}</div>
                  </div>
                </div>
              )
            }
            {
              skeletonLoader && (
                <div className="my-content-area">
                  <div className="loading-process">
                    <div className="process">
                      <DotLoader
                        dotActiveColor="#30A84B"
                        dotBlurColor="#ACDCB7"
                        dotSize="10px"
                      />
                    </div>
                    <div className="process-text">{t('processing')}</div>
                    <div className="process-subtext">{t('pleaseWaitWhileTheRequestIsBeingProcessed')}</div>
                  </div>
                </div>
              )
            }
            {
              selectedValues?.length > 0 && (
                <div className="my-content-area">
                  <div className="snapshot">
                    <div className="snapshot-head">
                      <div className="title">
                        {t('capturedSnapshot')}
                      </div>
                    </div>
                    <div className="snapshot-body">
                      {
                        selectedValues.map((selectedValue) => (
                          <div>
                            {
                              pageView === 'display_groups' && (
                                <div>{selectedValue?.devicegroups?.name}</div>
                              )
                            }
                            {
                              pageView === 'stores' && (
                                <div>{selectedValue?.stores?.name}</div>
                              )
                            }
                            {
                              pageView === 'schedules' && (
                                <div>{selectedValue?.schedules?.name}</div>
                              )
                            }
                            {
                              selectedValue?.snapshots?.map((snapshot) => (
                                <>
                                  <ListRow
                                    signedUrl={snapshot.signedUrl}
                                    deviceInfo={snapshot.device}
                                    snapshotId={snapshot._id}
                                    expiryTimestamp={snapshot.expiryTimestamp}
                                    startRetryTimer={snapshot.startRetryTimer}
                                    retryCaptureSnapshot={retryCaptureSnapshot}
                                    viewImage={viewImage}
                                    stopRetryTimer={stopRetryTimer}
                                    maxRetryCount={maxRetryCount}
                                    retryCount={snapshot.retry_count}
                                    snapshot={snapshot}
                                    selectedValues={selectedValues}
                                  />
                                  <hr />
                                </>
                              ))
                            }
                          </div>

                        ))
                      }
                    </div>
                    <div className="snapshot-head">
                      <div className="title">
                        {t('snapshotSummary')}
                      </div>
                    </div>
                    {
                      summary.length > 0
                      && (
                        <div className="snapshot-body" ref={chartRef}>
                          <div className="snapshot-summary">
                            <div className="pie-chart">
                              <PieChart
                                colors={summary.map((obj) => obj.color)}
                                series={[
                                  {
                                    data: summary,
                                    cx: 150,
                                    cy: 150,
                                    innerRadius: 50,
                                    outerRadius: 100,
                                  },
                                ]}
                                height={300}
                                slotProps={{
                                  legend: { hidden: true },
                                }}
                              />
                            </div>
                            <div className="modules-list">
                              <table>
                                <tr>
                                  <th>{t('status')}</th>
                                  <th>{t('count')}</th>
                                </tr>
                                {
                                  summary.map((obj) => (
                                    <tr className="module_item">
                                      <td className="label-color">
                                        <div className="color-box" style={{ backgroundColor: obj.color }}> </div>
                                        {obj.label}
                                      </td>
                                      <td className="label">{obj.value}</td>
                                    </tr>
                                  ))
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Snapshot;
