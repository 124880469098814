import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

function Toggle(props) {
  return (
    <div className="menu-button-list table-menu-button-list">
      {
        props?.tabPages?.map((menu) => menu?.enable && (
          <Tooltip title={menu?.tooltip} placement="top">
            <div
              className={props?.active === menu.key ? 'menu-button active' : 'menu-button'}
              onClick={() => props?.button1(`../${menu.key}`)}
              role="presentation"
            >
              {props?.active === menu.key ? menu.activeIcon : menu.icon}
            </div>
          </Tooltip>
        ))
      }
    </div>
  );
}
Toggle.propTypes = {
  button1: PropTypes.func,
  tabPages: PropTypes.arrayOf().isRequired,
  active: PropTypes.oneOf(['schedule', 'calendar']),
};
Toggle.defaultProps = {
  button1: () => { },
  active: 'schedule',
};
export default Toggle;
