import {
  get,
  post,
  patch,
  // deletes,
} from './base/index';

export default {
  getPosInventories: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/pos-it-easy`, { params });
  },
  addPosToken: (data) => {
    const body = {
      posItEasy_key: data?.code,
    };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/pos-it-easy/posItEasy-key`, body);
  },
  validPosItEasyToken: () => get(`${process.env.REACT_APP_API_URL}cms/v1/pos-it-easy/check-posItEasy-key`),
  updatePosItEasyTemplate: (
    id,
    data,
  ) => patch(
    `${process.env.REACT_APP_API_URL}cms/v1/pos-it-easy/update-posItEasy-content/${id}`,
    data,
  ),
};
