import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import deleteIcon from '../../../assets/icons/Close.png';

function InputTags(props) {
  const inputFile = useRef(null);

  const handleDelete = (e) => {
    props?.addData(props?.name, props?.value.filter((chip, key) => key !== e), 13);
  };

  const onButtonClick = () => {
    inputFile.current.focus();
  };

  const onchange = (e) => {
    if (e.target.value !== '') {
      props?.addData(e.target.name, e.target.value, e.keyCode);
      if (e.keyCode === 13) {
        e.target.value = '';
      }
    }
  };

  return (
    <div className="input-black">
      <label className="label" htmlFor={props?.label}>
        {props?.label} {props?.required && <span className="text-danger">*</span>}
      </label>
      <div
        name={props?.name}
        role="presentation"
        className={props?.error ? 'input-box input-box-error' : 'input-box'}
        onClick={onButtonClick}
      >
        <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
          {
            props?.value.map((e, index) => (
              <Chip
                label={e}
                onClick={() => { }}
                onDelete={() => handleDelete(index)}
                deleteIcon={<img src={deleteIcon} alt="delete_image_icon" style={{ width: 16, height: 16 }} />}
              />
            ))
          }
          <input
            name={props?.name}
            placeholder={props?.placeholder}
            className="input-hide"
            ref={inputFile}
            autoComplete="off"
            onKeyUp={onchange}
            readOnly={props?.readonly}
          />
        </Stack>
      </div>
      {props?.error && <p className="error-message">{props?.errorMessage}</p>}
    </div>
  );
}

InputTags.propTypes = {
  addData: PropTypes.func.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool.isRequired,
  readonly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
InputTags.defaultProps = {
  value: '',
  label: '',
  placeholder: '',
  readonly: false,
  error: false,
  errorMessage: '',
};

export default InputTags;
