/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import users from '../../utils/api/users';
import { setErrorNotification } from './NotificationSlice';

export const fetchUsersList = createAsyncThunk(
  'users/fetchUsers',
  async (data, { dispatch }) => {
    const response = await users.getUsers(data)
      .then((response1) => (
        {
          ...response1.data,
          results: response1.data.results.map((e) => ({
            ...e,
            role_name: e?.role?.name,
            id: e?._id,
            location: e?.stores?.map((store) => store.name),
          })),
          code: 200,
        }
      ))
      .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  users: [],
  pageCount: 0,
  totalPageCount: 1,
  singleUsers: {},
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Reducer to add the new user to top of the user list
    createNewUser: (state, action) => {
      const newUser = action.payload; // user to be added at the top
      const userList = [
        {
          ...newUser,
          role_name: newUser?.role?.name,
          location: newUser?.stores?.map((store) => store.name),
          id: newUser._id,
        },
        ...state.users, // Existing users
      ];
      state.users = userList; // update the user list
    },
    // Reducer to update the user by ID.
    updateUser: (state, action) => {
      const updatedUser = action.payload; // user to be added at the top
      const userList = state.users; // Assign the existing user to another variable
      // Find the index of the updated user by ID
      const index = userList.findIndex((user) => user._id === updatedUser._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        userList[index] = {
          ...updatedUser,
          role_name: updatedUser?.role?.name,
          location: updatedUser?.stores?.map((store) => store.name),
          id: updatedUser._id,
        };
      }
      state.users = userList; // Finally update the user list
    },
    // Reducer to delete the user by ID
    deleteUser: (state, action) => {
      const userList = state.users;

      // Find the index to delete by ID
      const index = userList.findIndex((user) => user.id === action.payload);
      // If the index found, remove that index from list of users
      if (index !== -1) {
        userList.splice(index, 1);
      }
      state.users = userList; // Update the users state after deleted
    },
    singleUsers: (state, action) => {
      state.singleUsers = state.users.find((val) => val._id === action.payload);
    },
    singleUsersAdd: (state, action) => {
      state.singleUsers = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetUserState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUsersList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const fetchedData = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action?.payload?.page === 1) {
            state.users = fetchedData;
          } else {
            const finalData = state.users; // Existing data
            // Add fetchedData to finalData, but only if the id is not already present
            // This will useful when new data added locally
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.users = finalData; // Assign all the data without duplicates
          }
        }
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  createNewUser,
  updateUser,
  deleteUser,
  singleUsers,
  singleUsersAdd,
  setFilter,
  setTableFilter,
  resetUserState,
} = usersSlice.actions;

export default usersSlice.reducer;
