/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import schedules from '../../utils/api/schedule';

import {
  changeTimeFormat,
  getTimeDuration,
  getDatesInRange,
} from '../../utils/helpers';

export const fetchSnapshotScheduleList = createAsyncThunk(
  'schedules/fetchSnapshotSchedule',
  async (data) => {
    const response = await schedules.getSchedules(data).then((response1) => {
      const tableData = response1?.data?.results.map((val) => {
        const now = `${val?.start_only_date} ${val?.start_time}`;
        const then = `${val?.end_only_date} ${val?.end_time}`;
        let allDays = [];
        if (val.repeat) {
          allDays = getDatesInRange(
            new Date(val.recurrences.start_only_date),
            new Date(val.recurrences.end_only_date),
          );
        }
        return {
          ...val,
          scheduleDate: `
            ${val?.repeat
    ? val?.recurrences?.start_only_date
    : val?.start_only_date}
            to
            ${val?.repeat
    ? val?.recurrences?.end_only_date
    : val?.end_only_date}
          `,
          scheduledTime: `
            ${changeTimeFormat(now)}
            to
            ${changeTimeFormat(then)}
          `,
          status: val?.status,
          contents_list: val?.contents.map((values) => values.name),
          devices_list: val?.devices.map((values) => values.name),
          video_count: val?.contents.filter((values) => values.file_type === 'video/mp4').length,
          image_count: val?.contents.filter((values) => values.file_type !== 'video/mp4').length,
          contents: val?.contents.map((values) => ({ ...values, id: values._id })),
          devices: val?.devices.map((values) => ({ ...values, id: values._id })),
          device_groups: val?.device_groups.map((values) => ({ ...values, id: values._id })),
          playlists: val?.playlists.map((values) => ({ ...values, id: values._id })),
          devices_count: val?.devices.length,
          hour: getTimeDuration(now, then),
          id: val._id,
          allDays,
        };
      });
      return { ...response1?.data, results: tableData };
    }).catch((error) => error?.response?.data);
    return response;
  },
);
const initialState = {
  schedules: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeed',
  error: {},
  filter: {},
  muiFilterData: {},
};

export const snapshotScheduleSlice = createSlice({
  name: 'snapshotSchedule',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setMuiFilterData: (state, action) => {
      state.muiFilterData = action.payload;
    },
    resetSnapshotScheduleState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSnapshotScheduleList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSnapshotScheduleList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeed';
        if (action?.payload?.code) {
          state.error = action?.payload;
        } else {
          const datas = action?.payload?.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.schedules = datas;
          } else {
            state.schedules.push(...datas);
          }
        }
      })
      .addCase(fetchSnapshotScheduleList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setMuiFilterData,
  resetSnapshotScheduleState,
} = snapshotScheduleSlice.actions;

export default snapshotScheduleSlice.reducer;
