import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgressBar from '../../../shared/component/CircularProgress';

function StorageStatus(props) {
  const toShow = props?.usedStorage;
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // setPercentage(((Number(props?.companyUsedStorage) / Number(props?.subscriptionStorage)) * 100).toFixed(2));
    setPercentage(Math.round((Number(props?.companyUsedStorage) / Number(props?.subscriptionStorage)) * 100));
  }, [props]);

  return (
    <div className="progress">
      <div className="progress-chat">
        <div className="progress-text">
          <h2>{props?.title}</h2>
          <h3>{toShow || '0'} / {props?.totalStorage || 2}
          </h3>
        </div>
      </div>
      <CircularProgressBar percentage={percentage || 0} />
    </div>
  );
}
StorageStatus.propTypes = {
  usedStorage: PropTypes.string.isRequired,
  totalStorage: PropTypes.string.isRequired,
  subscriptionStorage: PropTypes.number.isRequired,
  companyUsedStorage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default StorageStatus;
