import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@mui/material';
import { changeDateTimeFormat } from '../../../../utils/helpers';
import Button from '../../../../shared/component/Button';
import wejhaImage from '../../../../assets/images/loginPage.jpg';
import { addNewSchedule } from '../../../../redux/slices/ScheduleSlice';
import { fetchSavedContentLayout } from '../../../../redux/slices/SavedContentLayoutSlice';

function SavedLayouts(props) {
  const { t } = useTranslation();
  const {
    type,
    closeModal,
  } = props;
  const {
    savedContentLayout,
    status,
    pageCount,
    totalPageCount,
    filter,
  } = useSelector((state) => state.savedContentLayout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newSchedule } = useSelector((state) => state.schedules);
  const [selectedId, setSelectedId] = useState(null);

  const handleClickSubmit = () => {
    if (type === 'scheduleAdd') {
      dispatch(addNewSchedule({
        ...newSchedule,
        type,
        schedule: {
          ...newSchedule.schedule,
          entireLayout: savedContentLayout?.find((layout) => layout?.id === selectedId),
        },
      }));
    } else if (type === 'scheduleEdit' || type === 'scheduleClone') {
      dispatch(addNewSchedule({
        ...newSchedule,
        type,
        entireLayout: savedContentLayout?.find((layout) => layout?.id === selectedId),
      }));
    }
    navigate('./layout');
  };

  const getSavedLayout = (page, filteredValues = {}) => {
    dispatch(fetchSavedContentLayout({ page, ...filteredValues }));
  };

  const handleScroll = () => {
    if (pageCount < totalPageCount) {
      getSavedLayout(pageCount + 1, filter);
    }
  };

  useEffect(() => {
    if (pageCount === 0) {
      getSavedLayout(pageCount + 1, filter);
    }
  }, []);

  return (
    <div className="schedule-saved-layout-wrap">
      <InfiniteScroll
        dataLength={savedContentLayout.length}
        next={handleScroll}
        height="50vh"
        hasMore={pageCount < totalPageCount}
        loader={status === 'loading' && (
          <div className="content-layout-loader-wrap">
            <CircularProgress color="success" />
          </div>
        )}
      >
        <div className="saved-layout-wrap">
          {savedContentLayout?.map((layout) => (
            <div
              role="presentation"
              onClick={() => setSelectedId(layout?.id)}
              className="single-layout-wrap"
            >
              <div
                className="layout-image-wrap"
                style={{ '--single-layout-border': selectedId === layout?.id ? '2px solid #212B36' : '2px solid #FFF' }}
              >
                {layout?.signedUrl
                  ? (
                    <img src={layout?.signedUrl || wejhaImage} alt="" className="layout-image" />
                  ) : (
                    <div className="card">
                      <div className="shimmerBG media"> </div>
                    </div>
                  )}
              </div>
              <div className="layout-details">
                <div className="layout-header">
                  <div className="layout-header-avatar">
                    <span>{layout?.name?.charAt(0)}</span>
                  </div>
                  <h1>{layout?.name}</h1>
                </div>
                <p>{changeDateTimeFormat(layout?.createdAt)}</p>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
      <div className="saved-layout-button-wrap">
        <Button
          label={t('back')}
          click={() => closeModal(false)}
          classes="default-button"
        />
        <Button
          label={t('continue')}
          click={handleClickSubmit}
          classes="success-button"
          disabled={!selectedId}
        />
      </div>
    </div>
  );
}

SavedLayouts.propTypes = {
  type: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SavedLayouts;
