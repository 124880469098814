import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import profile from '../../../assets/images/download.jpg';
import SelectBox from '../../../shared/component/form/SelectBox';

import userApi from '../../../utils/api/users';
import { createNewUser } from '../../../redux/slices/UsersSlice';
import { fetchRolesListAll } from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { emailValidation, nameValidation, passwordValidation } from '../../../shared/component/form/Validation';
import { fileUploadValidation } from '../../../utils/helpers';
import FacilityList from './FacilityList';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import PreviewStore from './PreviewFacility';

function UserAdd() {
  let backClick;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { rolesAllList } = useSelector((state) => state.roles);

  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [disable, setDisable] = useState(true);
  const [value, setValue] = useState({
    stores_id: [],
    stores_list: [],
  });
  const [image, setImage] = useState();
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectLocation, setSelectLocation] = useState([]);
  const [modalView, setModalView] = useState({});

  const navigateBack = () => {
    setImage('');
    navigate('../');
  };

  // Function to add new user
  const submit = () => {
    setSubmitButtonClicked(true);
    if (nameValidation(1, 100, value?.name)
      && nameValidation(1, 100, value?.user_name)
      && emailValidation(value?.email)
      && passwordValidation(value?.password)
    ) {
      setDisable(true);
      setUploading(true);
      const params = {
        email: value?.email,
        name: value?.name,
        password: value?.password,
        role: value?.role,
        user_name: value?.user_name,
        profile_image: value?.image,
      };
      if (value?.stores_id?.length > 0) {
        params.stores = value?.stores_id;
      }
      userApi.addUser(params)
        .then((res) => {
          dispatch(createNewUser(res?.data));
          setUploading(false);
          setDisable(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            navigate('../');
          }, 3000);
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
          setUploading(false);
          setDisable(false);
        });
    }
  };

  // Function to change profile image
  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const file = e.target?.files?.[0];
    const checkFileValid = fileUploadValidation(file, maxFileSizeInMB, 'image');
    if (checkFileValid === '') {
      setValue({ ...value, image: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  // Function to changes values on user form
  const handleChange = (name, changeValue) => {
    if (name === 'role' && changeValue) {
      setValue({ ...value, role: changeValue.id, role_name: changeValue.label });
    } else if (name !== 'role' && name !== 'location') {
      setValue({ ...value, [name]: changeValue });
    } else if (name === 'location') {
      setValue((prevState) => ({
        ...prevState,
        ...changeValue,
      }));
    }
  };

  const backModal = (data) => {
    backClick(data);
  };

  const viewData = (data) => {
    setModalView({
      title: t('facilities'),
      content: <PreviewStore
        data={data}
        selectData={handleChange}
        closeModal={setOpenModal}
        back={backModal}
      />,
    });
  };

  // function to change modal open
  const openModalFacility = (data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView({
      title: t('facilities'),
      content: <FacilityList
        selectData={viewData}
        closeModal={setOpenModal}
        values={oldData}
      />,
    });
    setOpenModal(true);
  };

  backClick = (data) => {
    openModalFacility(data);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const removeContentsTags = (name, key) => {
    const removeDetails = value.stores_list.filter((e) => e.id !== key.id);
    const removeId = value.stores_id.filter((e) => e !== key.id);
    setValue({ ...value, stores_list: removeDetails, stores_id: removeId });
  };

  // To handle button disable & enable status when value changes
  useEffect(() => {
    if (
      value.email
      && value.name
      && value.password
      && value.role
      && value.user_name
      && value.stores_id.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setSelectLocation(value?.stores_list);
  }, [value]);

  // Dispatch role list and store list only once
  useEffect(() => {
    dispatch(fetchRolesListAll());
  }, []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('userCreatedSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('users'), t('createNewUser')]}
          button={false}
        />
        <ModalBox
          status={openModal}
          modalView={modalView}
          closeModal={closeModal}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form-wrap">
                <div className="profile-upload">
                  <label htmlFor="file" className="upload-click">
                    <img src={image || profile} alt="profile" />
                    {!image ? <p>{t('imageFormat')}</p> : <p><span>{t('changeProfile')}</span></p>}
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={onChangePicture}
                    style={{ display: 'none' }}
                  />
                </div>
                <div className="user-form">
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('fullName')}
                      type="text"
                      name="name"
                      required
                      value={value?.name}
                      change={handleChange}
                      placeholder={t('enterName')}
                      error={!nameValidation(1, 100, value?.name) && submitButtonClicked}
                      errorMessage={t('nameValidation')}
                    />
                    <Input
                      icon={' '}
                      label={t('username')}
                      type="text"
                      name="user_name"
                      required
                      value={value?.user_name}
                      change={handleChange}
                      placeholder={t('enterUsername')}
                      error={!nameValidation(1, 100, value?.user_name) && submitButtonClicked}
                      errorMessage={t('userNameValidation')}
                    />
                  </div>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('mailID')}
                      type="text"
                      name="email"
                      required
                      value={value?.email}
                      change={handleChange}
                      placeholder={t('enterMailID')}
                      error={!emailValidation(value?.email) && submitButtonClicked}
                      errorMessage={t('invalidEmail')}
                    />
                    <Input
                      icon={' '}
                      label={t('password')}
                      type="password"
                      name="password"
                      required
                      value={value?.password}
                      change={handleChange}
                      placeholder={t('password')}
                      error={!passwordValidation(value?.password) && submitButtonClicked}
                      errorMessage={t('passwordRequirements')}
                    />
                  </div>
                  <SelectBox
                    label={t('role')}
                    name="role"
                    required
                    value={value.role_name}
                    onchange={handleChange}
                    lists={rolesAllList}
                    id="select-roles"
                    placeholder={t('selectUserRole')}
                  />
                  <MultiSelectBox
                    label={t('store')}
                    buttonLabel={t('select')}
                    clickButton={openModalFacility}
                    required
                    name="location"
                    addData={removeContentsTags}
                    placeholder={t('selectStore')}
                    value={selectLocation}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label={t('back')} click={navigateBack} classes="default-button" />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('creating') : t('create')}
              disabled={disable}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAdd;
