/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import ModalBox from '../../../../shared/component/ModalBox';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import PopupBox from '../ContentUploadConfirmation';

/* images */
import rightIceText from '../../../../assets/images/Templates/VectorTemp_3-ice-icon.png';
import arrowIcon from '../../../../assets/images/Templates/VectorArrow.png';
import TemplateContentUpload from '../TemplateContentUpload';
import FileFormat from '../FileFormat';

function HtmlTemplateThree({ templateData }) {
  const htmlRef = useRef(null);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const { t } = useTranslation();

  const cssStyles = `
.template-container-3 {
    background-color: #000;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.template-container-3 .inner-container {
    border: 1px solid #fff;
    padding: 40px 30px 30px 40px;
}

.template-container-3 .inner-container .dotted-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 10px dotted #fff;
    padding: 40px;
}

.template-container-3 .inner-container .dotted-container .grid-item {
    width: 1080px;
    margin: 0 auto;
}

.template-container-3 .inner-container .dotted-container .content-container {
    display: flex;
    justify-content: space-between;
}

.template-container-3 .inner-container .dotted-container .content-container .left-ice,
.template-container-3 .inner-container .dotted-container .content-container .right-ice {
    width: 200px;
    height: 250px;
}
img{
    border-style: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.template-container-3 .inner-container .dotted-container .content-container .top {
    width: 350px;
    text-align: center;
    position: relative;
}

.template-container-3 .inner-container .dotted-container .content-container .top .ice-text-wrapper {
    position: absolute;
    left: 50%;
    width: 355px;
    height: 120px;
    transform: translateX(-50%);
}

.template-container-3 .inner-container .dotted-container .float-end {
    display: flex;
    justify-content: flex-end;
}

.template-container-3 .inner-container .dotted-container .float-end .arrow-icon {
    width: 100px;
    height: 100px;
}
.template-container-3 .inner-container .dotted-container .bottom-ice-container {
    display: flex;
    gap: 40px;
    margin: 1rem auto 0;
    width: 1080px;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container .bottom-ice-wrapper {
    width: 18%;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container .bottom-ice-wrapper .bottom-ice {
    width: 170px;
    height: 280px;
    margin-top: 1rem;
}
.template-container-3 .inner-container .dotted-container .bottom-ice-container .menu-lists {
    display: flex;
    flex-direction: column;
    width: 40%;
}
ul {
  padding: 0;
}
.template-container-3 .inner-container .dotted-container .bottom-ice-container .menu-lists .menu-list-header {
    display: flex;
    text-align: center;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container .menu-lists ul.list-group li {
    display: table;
    padding: 0.5rem 0rem;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container .menu-lists ul.list-group li span {
    display: table-cell;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container 
.menu-lists ul.list-group li span:first-child {
    position: relative;
    overflow: hidden;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container 
.menu-lists ul.list-group li span:first-child:after {
    content: "";
    position: absolute;
    bottom: 0.5em;
    margin-left: 0.3em;
    width: 100%;
    border-bottom: 2.5px dotted #fff;
}

.template-container-3 .inner-container .dotted-container .bottom-ice-container 
.menu-lists ul.list-group li span + span {
    text-align: right;
    width: 1%;
    vertical-align: bottom;
    padding-left: 0.3em;
}
@media screen and (min-width: 1550px) {
  .template-container-3 .inner-container .dotted-container .grid-item {
    width: 70%;
  }
  .template-container-3 .inner-container .dotted-container .bottom-ice-container {
    width: 75%;
  }
}

@media screen and (max-width: 1280px) {
  .template-container-3 .inner-container .dotted-container .grid-item {
    width: 980px;
  }
  .template-container-3 .inner-container .dotted-container .bottom-ice-container {
    width: 1000px;
  }
} 
  `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
        setDisable(false);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        // Save the Base64 string in the state
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Knewave&family=Luckiest+Guy&family=McLaren&display=swap" 
            rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used setTimeout to ensure the page was fully rendered before calling the API */
      /* And then waited for the response */
      setTimeout(() => {
        convertBase64();
      }, 800);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const styleRef = data?.[0]?.styles;
  const templateContent = data?.[0]?.template_contents?.items;

  return (
    <div style={{ backgroundColor: 'white' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      {data && data?.map((item) => (
        <div className="template-container-3" ref={htmlRef}>
          <div className="inner-container">
            <div className="dotted-container">
              <div className="grid-item">
                <div className="content-container">
                  <div className="left-ice">
                    {item?.template_images?.[0]?.image !== null && (
                      <img
                        src={item?.template_images?.[0]?.image}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>

                  <div className="top">
                    <img src={rightIceText} alt="" className="ice-text-wrapper" />
                    <h2
                      style={{
                        marginBottom: '0px',
                        color: `${styleRef?.title_style?.color}`,
                        fontSize: `${styleRef?.title_style?.fontSize}`,
                        fontFamily: `${styleRef?.title_style?.fontFamily}`,
                      }}
                    >
                      {item?.template_title}
                    </h2>
                    <h1
                      style={{
                        color: `${styleRef?.sub_title_style?.color}`,
                        fontSize: `${styleRef?.sub_title_style?.fontSize}`,
                        fontFamily: `${styleRef?.sub_title_style?.fontFamily}`,
                        margin: '80px 0 20px',
                      }}
                    >
                      {item?.template_sub_title}
                    </h1>
                  </div>

                  <div className="right-ice">
                    {item?.template_images?.[1]?.image && (
                      <img
                        src={item?.template_images?.[1]?.image}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                <div className="float-end">
                  <img src={arrowIcon} alt="" className="arrow-icon" />
                </div>
              </div>

              <div className="bottom-ice-container">
                <div className="bottom-ice-wrapper">
                  <div className="bottom-ice">
                    {item?.template_images?.[2]?.image !== null && (
                      <img
                        src={item?.template_images?.[2]?.image}
                        alt=""
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                {templateContent
                  ?.filter((filterHide) => !filterHide?.hide)
                  ?.map((categoryContent) => (
                    <div key={categoryContent} className="menu-lists">
                      <div className="menu-list-header">
                        <h3
                          style={{
                            marginTop: '0px',
                            color: `${styleRef?.cate_style?.color}`,
                            fontSize: `${styleRef?.cate_style?.fontSize}`,
                            fontFamily: `${styleRef?.cate_style?.fontFamily}`,
                          }}
                        >
                          {categoryContent?.category}
                        </h3>
                      </div>

                      <ul className="list-group">
                        {categoryContent?.details?.map((detail, i) => (
                          <li key={detail?.id} className="list-group-item">
                            <span
                              className="menu-list-item"
                              style={{
                                color: `${styleRef?.item_style?.color}`,
                                fontSize: `${styleRef?.item_style?.fontSize}`,
                                fontFamily: `${styleRef?.item_style?.fontFamily}`,
                              }}
                            >
                              {detail?.name}
                            </span>
                            <span
                              className="menu-list-price"
                              style={{
                                color: `${styleRef?.price_style?.color}`,
                                fontSize: `${styleRef?.price_style?.fontSize}`,
                                fontFamily: `${styleRef?.price_style?.fontFamily}`,
                              }}
                            >
                              {categoryContent?.pricing?.[i]?.p1}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

HtmlTemplateThree.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default HtmlTemplateThree;
