import {
  get,
  post,
  patch,
  //   deletes,
} from './base/index';

export default {
  getSubscriptionDetails: () => get(`${process.env.REACT_APP_API_URL}cms/v1/subscription/fetchAccountSubscriptionData`),
  getSubscriptionHistory: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/subscription/subscription-history`, { params });
  },
  getTransactionHistory: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/subscription/transaction-history`, { params });
  },
  calculatePrice: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/subscription/calculatePrice`, { ...body });
  },
  deviceIncreaseCount: (data = {}) => {
    const body = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/subscription/edit-device`, { ...body });
  },
  deviceIncreaseCountSummary: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/subscription/edit-device-order-summary`, { ...body });
  },
  storageIncreaseCount: (data = {}) => {
    const body = { ...data };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/subscription/edit-storage`, { ...body });
  },
  storageIncreaseCountSummary: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/subscription/edit-storage-order-summary`, { ...body });
  },
  getPlanChangeSummary: (data) => post(
    `${process.env.REACT_APP_API_URL}cms/v1/subscription/plan-change-order-summary`,
    { ...data },
  ),
  planChange: (data, subscriptionStatus) => {
    const body = { ...data };
    const endpoint = subscriptionStatus === 'active' || subscriptionStatus === 'suspended'
      ? `${process.env.REACT_APP_API_URL}cms/v1/subscription/plan-change`
      : `${process.env.REACT_APP_API_URL}cms/v1/subscription/renewal-subscription`;
    return post(endpoint, body);
  },
  getBillingDetails: () => get(`${process.env.REACT_APP_API_URL}cms/v1/subscription/fetch-billing-data`),
  suspendSubscription: (data) => post(
    `${process.env.REACT_APP_API_URL}cms/v1/subscription/suspend-subscription`,
    { ...data },
  ),
};
