import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';

import TopContent from '../../../shared/component/TopContent';
import Button from '../../../shared/component/Button';
import { Input } from '../../../shared/component/form/Input';
import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import {
  fetchPlaylistContentList,
  setFilter,
  setTableFilter,
} from '../../../redux/slices/PlaylistContentSlice';
import { editPlaylist, fetchSinglePlaylist } from '../../../redux/slices/PlaylistSlice';
import DataTable from '../../Table';
import { changeDateTimeFormat } from '../../../utils/helpers';
import ContentView from '../../MyContent/components/ContentView';
import ModalBox from '../../../shared/component/ModalBox';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';
import height from '../../../utils/size-variables';
import { descriptionValidation, nameValidation } from '../../../shared/component/form/Validation';

function PlayListEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    singlePlaylist,
    newPlaylist,
  } = useSelector((state) => state.playlist);
  const {
    playlistContents,
    pageCount,
    totalPageCount,
    totalDataCount,
    status,
    filter,
    tableFilter,
  } = useSelector((state) => state.playlistContent);

  const [value, setValue] = useState({
    contents: {},
    selectedContentDetails: [], // Selected contents will be in top of the table
    selectedContentIds: [],
  });
  const [contentViewPopup, setContentViewPopup] = useState(false);
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [contentSelection, setContentSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  // Function to fetch content list
  const getContents = (counts) => {
    dispatch(fetchPlaylistContentList({ page: counts, limit: 20 }));
  };

  // Back button functionality
  const backPlaylist = () => {
    navigate('../');
  };

  // Handle input value change
  const handleChange = (name, data) => {
    setValue((prevValue) => ({
      ...prevValue,
      [name]: data,
    }));
  };

  // Submit button Functionality
  const submit = () => {
    if (nameValidation(1, 50, value?.name)
      && descriptionValidation(500, value?.description)
    ) {
      dispatch(editPlaylist(value));
      navigate('../add-view');
    }
  };

  const handleRowSelection = (selectedRows) => {
    setContentSelection(selectedRows);
    if (Object.keys(selectedRows).length === playlistContents.length) {
      setAllRowsSelected(true);
    } else {
      setAllRowsSelected(false);
    }
  };

  // Fetch next page on scroll
  const handleScroll = () => {
    getContents(pageCount + 1, filter);
  };

  // Popup content View
  const viewContent = (singleContent) => {
    setContentViewPopup(true);
    setModalView({
      title: 'Preview',
      content: <ContentView singleContent={singleContent} />,
    });
  };

  useEffect(() => {
    if (Object.keys(contentSelection).length >= 20 || allRowsSelected) {
      const newContentSelection = { ...contentSelection };
      playlistContents.forEach((content) => {
        if (!newContentSelection[content?.id]) {
          newContentSelection[content?.id] = true;
        }
      });
      setContentSelection(newContentSelection);
    }
  }, [playlistContents]);

  // Content table headers
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('contentName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original?.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original?.file_type?.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => row.original?.file_type_to_show,
    },
    {
      header: t('managedBy'),
      accessorKey: 'author',
    },
    {
      header: t('updatedBy'),
      accessorKey: 'updatedAt',
      accessorFn: (originalRow) => new Date(originalRow.updatedAt),
      filterVariant: 'date-range',
      enableColumnFilter: false,
      Cell: ({ row }) => changeDateTimeFormat(row.original.updatedAt) || '',
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableColumnFilter: false,
    },
  ];

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getContents(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (Object.keys(singlePlaylist).length === 0) {
      dispatch(fetchSinglePlaylist(params?.id));
    }
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getContents(1);
    }
  }, []);

  // Content selection changes
  useEffect(() => {
    // Update selected contents
    if (playlistContents.length > 0) {
      const selectedContents = [
        ...newPlaylist.contents,
        ...playlistContents,
      ].filter((content) => Object.keys(contentSelection).includes(content.id));
      if (selectedContents.length > 0) {
        const selectContentDetails = new Map(
          selectedContents.map((content) => [content.id, content]),
        );
        const uniqueContents = [...selectContentDetails.values()];
        setValue((prevValue) => ({
          ...prevValue,
          viewIds: contentSelection,
          selectedContentIds: uniqueContents.map((content) => content.id),
          selectedContentDetails: uniqueContents,
          contents: uniqueContents,
        }));
      }
    }
  }, [contentSelection]);

  // If a page refresh, it will run after fetchSingle playlist
  useEffect(() => {
    if (Object.keys(newPlaylist).length !== 0) {
      // Convert the selected content id's into material table readable format
      const newObj = {};
      newPlaylist?.contents?.forEach((item) => {
        newObj[item?.id] = true;
      });
      setValue({
        ...newPlaylist,
        viewIds: newObj,
        selectedContentDetails: newPlaylist?.contents,
        selectedContentIds: newPlaylist?.contents.map((view) => view.id),
        contents: newPlaylist?.contents,
      });

      setContentSelection(newObj);
    }
  }, [newPlaylist]);

  // To disable the update button
  useEffect(() => {
    // Selected playlist data from API call
    const playlistBeforeEdit = {
      name: singlePlaylist?.name,
      description: singlePlaylist?.description ? singlePlaylist?.description : '',
      contents: singlePlaylist?.contents?.map((content) => content.id),
    };
    // Edited playlist data from value
    const playlistAfterEdit = {
      name: value?.name,
      description: value?.description ? value?.description : '',
      contents: value?.selectedContentIds,
    };
    if (
      value.name
      && Object.keys(contentSelection).length > 0
      && !deepEqual(playlistBeforeEdit, playlistAfterEdit)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={contentViewPopup}
        closeModal={setContentViewPopup}
        modalView={modalView}
      />
      <div className="main-content">
        <TopContent
          label={[t('playlist'), value?.name]}
          button={false}
          buttonClass="success-button"
          buttonLabel="Back"
          click={backPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('playlistName')}
                  type="text"
                  name="name"
                  required
                  placeholder={t('enterPlaylistName')}
                  value={value.name}
                  change={handleChange}
                  error={Object.keys(value)?.includes('name') && !nameValidation(1, 50, value?.name)}
                  errorMessage={t('playlistNameValidation')}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  required={false}
                  placeholder={t('enterDescription')}
                  value={value.description}
                  change={handleChange}
                  error={!descriptionValidation(500, value?.description)}
                  errorMessage={t('descriptionValidation')}
                />
              </div>
              <DataTable
                header={columns}
                value={[
                  ...value.selectedContentDetails,
                  ...playlistContents.filter((content) => !value?.selectedContentIds?.includes(content.id)),
                ]}
                status={status}
                reduxColumnFiltersSorting={tableFilter}
                totalPageCount={totalPageCount}
                pageCount={pageCount}
                onFilterOrSortingChange={onFilterOrSortingChange}
                scrollData={handleScroll}
                totalDataCount={totalPageCount}
                rowSelection={contentSelection}
                setRowSelection={handleRowSelection}
                columnVisibility={columnVisibility}
                isFirstRender={isFirstRender}
                setColumnVisibility={setColumnVisibility}
                enable={false}
                height={height.formTableHeight}
                disableFullScreenToggle
                enableRowSelection
                toolbarLeft={(
                  <TableHeaderSelectCount
                    select={Object.keys(contentSelection)?.length}
                    total={totalDataCount}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button label="Back" click={backPlaylist} classes="default-button" />
          </div>
          <div className="form-button">
            <Button label="Continue" click={submit} classes="success-button" disabled={disable} />
          </div>
        </div>
      </div>
    </div>
  );
}
PlayListEdit.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.shape({
        px_50x50: PropTypes.string.isRequired,
      }),
      name: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
PlayListEdit.defaultProps = {
  row: null,
};

export default PlayListEdit;
