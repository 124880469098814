import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import deepEqual from 'deep-equal';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import rolesApi from '../../../utils/api/roles';

import {
  fetchPermissionList,
  fetchRolesListAll,
  updateRole,
} from '../../../redux/slices/RolesSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { nameValidation } from '../../../shared/component/form/Validation';

function RolesEdit() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions, roles } = useSelector((state) => state.roles);
  const rolesData = roles.find((val) => val.id === params?.id);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [all, setAll] = useState(false);

  const [value, setValue] = useState({
    permissions: [],
  });
  const [originalValue, setOriginalValue] = useState({});
  const [disabled, setDisabled] = useState(true);

  const getPermissions = () => {
    if (permissions.length === 0) {
      dispatch(fetchPermissionList());
    }
  };

  const addPlaylist = () => {
    localStorage.removeItem('value');
    navigate('../');
  };

  const submit = () => {
    if (nameValidation(1, 100, value?.name)) {
      setUploading(true);
      rolesApi.updateRole(value)
        .then((res) => {
          dispatch(updateRole(res?.data));
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            dispatch(fetchRolesListAll());
            navigate('../');
          }, 3000);
        })
        .catch((error) => {
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  const handleChange = (event) => {
    if (!value.permissions.includes(event.target.value)) {
      setValue({
        ...value,
        permissions: [...value.permissions, event.target.value],
      });
    } else {
      setValue((prev) => ({
        ...prev,
        permissions: prev.permissions.filter((permission) => permission !== event.target.value),
      }));
    }
  };

  const handleChangeAll = () => {
    if (!all) {
      const allValue = permissions.map((val) => val.children.map((val1) => val1.value))
        .reduceRight((accumulator, currentValue) => accumulator.concat(currentValue));
      setValue({ ...value, permissions: [...allValue] });
      setAll(true);
    } else {
      setValue({ ...value, permissions: [] });
      setAll(false);
    }
  };

  const inputHandel = (name, changValue) => {
    setValue({ ...value, [name]: changValue });
  };

  useEffect(() => {
    if (roles.length === 0) {
      navigate('../');
    }
    if (rolesData) {
      setValue(rolesData);
      setOriginalValue(rolesData);
    }
  }, [rolesData]);

  useEffect(() => {
    if (value?.name && !deepEqual(value, originalValue)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value]);

  useEffect(() => getPermissions(), []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('roleUpdatedSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('roles'), rolesData?.name]}
          button={false}
          buttonClass="success-button"
          buttonLabel="Back"
          click={addPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('role')}
                  type="text"
                  name="name"
                  required
                  value={value?.name}
                  change={inputHandel}
                  error={!nameValidation(1, 100, value?.name)}
                  errorMessage={t('roleNameValidation')}
                />
              </div>
              <div className="my-content-permissions">
                <div className="my-content-permissions-list">
                  <h3 className="title">{t('permissions')}</h3>
                  <div className="permission-list">
                    <div className="permission-lis-session">
                      <div className="my-content-permissions-all-check">
                        <FormControlLabel
                          control={(
                            <Checkbox
                              size="small"
                              sx={{
                                color: '#30A84B',
                                '&.Mui-checked': {
                                  color: '#30A84B',
                                },
                              }}
                              onChange={handleChangeAll}
                            />
                          )}
                          label="select ALL"
                        />
                      </div>
                      {
                        permissions.map((values) => values?.children.length > 0 && (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">{values.name}</FormLabel>
                            <FormGroup
                              aria-label="position"
                              row
                              sx={{
                                gap: 7,
                                rowGap: 2,
                              }}
                            >
                              {
                                values?.children.map((e) => (
                                  <FormControlLabel
                                    value={e.value}
                                    control={(
                                      <Checkbox
                                        size="small"
                                        sx={{
                                          color: '#30A84B',
                                          '&.Mui-checked': {
                                            color: '#30A84B',
                                          },
                                        }}
                                        checked={value?.permissions.includes(e.value)}
                                        onChange={handleChange}
                                      />
                                    )}
                                    label={e.name}
                                  />
                                ))
                              }
                            </FormGroup>
                          </FormControl>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={addPlaylist} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={uploading ? t('updating') : t('update')}
                  click={submit}
                  classes={uploading ? 'success-button loading-btn' : 'success-button'}
                  loading={uploading}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesEdit;
