/* eslint no-underscore-dangle: 0 */
import PropTypes from 'prop-types';
import { toPng } from 'html-to-image';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import PopupBox from '../ContentUploadConfirmation';

import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import TemplateContentUpload from '../TemplateContentUpload';
import FileFormat from '../FileFormat';

function HtmlTemplateNine({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useDispatch();

  const cssStyles = `
body {
  margin: 0;
  padding: 0;
}
ul {
  padding: 0;
  margin: 0;
}
h1, h3, h5 {
  margin: 0;
}
h2 {
  margin: 0 0 0.83em 0;
}
.template-container-9 {
  background-color: #64872b;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0 3rem;
  box-sizing: border-box;
}
.template-container-9 .template_9-inner-box {
  background-color: #fef2cc;
  width: 980px;
  padding-bottom: 10rem;
  box-shadow: #000 0px 5px 15px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.template-container-9 .template_9-inner-box .veg-salad-img {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 250px;
  height: auto;
}
.template-container-9 .template_9-inner-box .egg-img {
  position: absolute;
  top: 30px;
  right: -50px;
  width: 200px;
  height: auto;
}
.template-container-9 .template_9-inner-box .pizza-img {
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translateX(-50%);
  width: 20.5%;
  height: auto;
}
.template-container-9 .template_9-inner-box .menu-title {
  text-align: center;
  margin-bottom: 2rem;
}
.template-container-9 .template_9-inner-box .starters {
  text-align: center;
}
.common-row, .second-row, .first-row {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  padding: 1.5rem 3.6rem;
}

.common-row .list-group, .second-row .list-group, .first-row .list-group {
  margin-right: 2.5rem;
  list-style: unset;
}

.common-row .list-group .list-group-item, .second-row .list-group 
.list-group-item, .first-row .list-group .list-group-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.common-row .salads-container, .second-row .salads-container, .first-row .salads-container {
  position: absolute;
  left: -100px;
  bottom: -40px;
  display: block;
  z-index: -1;
  width: 300px;
  transform: rotate(169deg);
  height: 400px;
  background-color: #fdeaaf;
  border-radius: 50% 50% 50% 50% / 50% 50% 40% 40%;
}

.second-row {
  display: grid;
  grid-template-columns: auto auto;
}

.second-row .multiple-col {
  margin-top: 10px;
  padding: 0px 12px;
}

.first-row {
  display: flex;
}

.first-row .single-col {
  flex: 0 0 auto;
  width: 50%;
  margin-top: 10px;
  padding: 0px 12px;
}
  @media screen and (min-width: 1550px) {
    .template-container-9 .template_9-inner-box {
        width: 65%;
        padding-bottom: 12%;
    }
}
@media screen and (min-width: 1920px) {
    .template-container-9 .template_9-inner-box {
        width: 70%;
        padding-bottom: 13%;
    }
}
  `;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = () => {
    toPng(ref?.current, { cacheBust: true })
      .then((dataUrl) => {
        // Save the Base64 string in the state
        setBase64(dataUrl);
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (ref.current) {
      const content = ref.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link 
            href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap" 
            rel="stylesheet" />
            <link 
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap" 
            rel="stylesheet">
            <style>${cssStyles}</style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData, notification, openContentUploadConfirmation]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used setTimeout to ensure the page was fully rendered before calling the API */
      /* And then waited for the response */
      setTimeout(() => {
        convertBase64();
      }, 1500);
      convertHtml();
      /* This template has many images, so wait for 1.5 seconds */
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: 'content',
      content: <TemplateContentUpload
        base64={base64}
        setNotification={setNotification}
        fileType={fileType}
        htmlContent={htmlContent}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const styleRef = data?.[0]?.styles;
  const templateContent = data?.[0]?.template_contents?.items;
  const unhideCategory = templateContent?.filter((filterHide) => !filterHide?.hide);

  return (
    <div style={{ backgroundColor: 'white' }}>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />

      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      {data?.map((item) => (
        <div className="template-container-9" ref={ref}>
          <div className="template_9-inner-box">
            {item?.template_images?.[0]?.image !== null
              && (<img src={item?.template_images?.[0]?.image} alt="" className="veg-salad-img" />)}
            {item?.template_images?.[1]?.image !== null
              && (<img src={item?.template_images?.[1]?.image} alt="" className="egg-img" />)}
            {item?.template_images?.[2]?.image !== null
              && (<img src={item?.template_images?.[2]?.image} alt="" className="pizza-img" />)}
            <div className="menu-title">
              <h3
                className="mb-0"
                style={{
                  fontWeight: 'bold',
                  letterSpacing: '5px',
                  marginTop: '3rem',
                  marginBottom: '0',
                  color: `${styleRef?.title_style?.color}`,
                  fontSize: `${styleRef?.title_style?.fontSize}`,
                  fontFamily: `${styleRef?.title_style?.fontFamily}`,
                  wordWrap: 'break-word',
                }}
              >
                {item?.template_title}
              </h3>
              <h1
                style={{
                  fontWeight: 'bold',
                  color: `${styleRef?.sub_title_style?.color}`,
                  fontSize: `${styleRef?.sub_title_style?.fontSize}`,
                  fontFamily: `${styleRef?.sub_title_style?.fontFamily}`,
                  wordWrap: 'break-word',
                  fontStyle: 'italic',
                }}
              >
                {item?.template_sub_title}
              </h1>
            </div>

            {(!unhideCategory?.[0]?.hide) && (
              <>
                <div className="starters">
                  <h2
                    style={{
                      color: `${styleRef?.cate_style?.color}`,
                      fontSize: `${styleRef?.cate_style?.fontSize}`,
                      fontFamily: `${styleRef?.cate_style?.fontFamily}`,
                      marginTop: '0',
                    }}
                  >
                    {unhideCategory?.[0]?.category}
                  </h2>
                </div>

                <div className="first-row" style={{ borderTop: '3px dotted #63882B', paddingBottom: '0' }}>
                  <div className="single-col">
                    <ul className="list-group">
                      {unhideCategory?.[0]?.details.slice(0, 5).map((detail, i) => (
                        <li
                          className="list-group-item"
                          key={detail?.id}
                        >
                          <div>
                            <h5
                              style={{
                                marginTop: '0',
                                marginBottom: '.5rem',
                                textTransform: 'uppercase',
                                color: `${styleRef?.item_style?.color}`,
                                fontSize: `${styleRef?.item_style?.fontSize}`,
                                fontFamily: `${styleRef?.item_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.name}
                            </h5>
                            <span
                              style={{
                                color: `${styleRef?.desc_style?.color}`,
                                fontSize: `${styleRef?.desc_style?.fontSize}`,
                                fontFamily: `${styleRef?.desc_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.desc}
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: `${styleRef?.price_style?.color}`,
                              fontSize: `${styleRef?.price_style?.fontSize}`,
                              fontFamily: `${styleRef?.price_style?.fontFamily}`,
                            }}
                          >
                            {unhideCategory?.[0]?.pricing[i]?.p1}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="single-col">
                    <ul className="list-group">
                      {unhideCategory?.[0]?.details.slice(5, 11).map((detail, i) => (
                        <li
                          className="list-group-item"
                          key={detail?.id}
                        >
                          <div>
                            <h5
                              style={{
                                marginTop: '0',
                                marginBottom: '.5rem',
                                textTransform: 'uppercase',
                                color: `${styleRef?.item_style?.color}`,
                                fontSize: `${styleRef?.item_style?.fontSize}`,
                                fontFamily: `${styleRef?.item_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.name}
                            </h5>
                            <span
                              style={{
                                color: `${styleRef?.desc_style?.color}`,
                                fontSize: `${styleRef?.desc_style?.fontSize}`,
                                fontFamily: `${styleRef?.desc_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.desc}
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: `${styleRef?.price_style?.color}`,
                              fontSize: `${styleRef?.price_style?.fontSize}`,
                              fontFamily: `${styleRef?.price_style?.fontFamily}`,
                            }}
                          >
                            {unhideCategory?.[0]?.pricing[i]?.p1}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}

            <div className="second-row" style={{ paddingTop: '0', paddingBottom: '0', position: 'relative' }}>
              {unhideCategory?.slice(1, 5)
                ?.map((categoryContent) => (
                  <div className="multiple-col" key={categoryContent}>
                    <div className="salads-container" />
                    <div style={{ borderBottom: '3px dotted #63882B', textAlign: 'center' }}>
                      <h2
                        style={{
                          color: `${styleRef?.cate_style?.color}`,
                          fontSize: `${styleRef?.cate_style?.fontSize}`,
                          fontFamily: `${styleRef?.cate_style?.fontFamily}`,
                        }}
                      >
                        {categoryContent.category}
                      </h2>
                    </div>
                    <ul className="list-group" style={{ marginTop: '10px' }}>
                      {categoryContent?.details?.map((detail, i) => (
                        <li
                          className="list-group-item"
                          key={detail?.id}
                        >
                          <div>
                            <h5
                              style={{
                                marginTop: '0',
                                marginBottom: '.5rem',
                                textTransform: 'uppercase',
                                color: `${styleRef?.item_style?.color}`,
                                fontSize: `${styleRef?.item_style?.fontSize}`,
                                fontFamily: `${styleRef?.item_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.name}
                            </h5>
                            <span
                              style={{
                                color: `${styleRef?.desc_style?.color}`,
                                fontSize: `${styleRef?.desc_style?.fontSize}`,
                                fontFamily: `${styleRef?.desc_style?.fontFamily}`,
                                wordBreak: 'break-word',
                              }}
                            >
                              {detail?.desc}
                            </span>
                          </div>
                          <span
                            style={{
                              fontWeight: 'bold',
                              color: `${styleRef?.price_style?.color}`,
                              fontSize: `${styleRef?.price_style?.fontSize}`,
                              fontFamily: `${styleRef?.price_style?.fontFamily}`,
                            }}
                          >
                            {categoryContent?.pricing?.[i]?.p1}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

HtmlTemplateNine.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
};

export default HtmlTemplateNine;
