import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InvalidIcon } from '../../../shared/component/svgIcon';
import Button from '../../../shared/component/Button';

function PlayerAppUpdateModal(props) {
  const { t } = useTranslation();
  const submit = () => props?.close(false);
  return (
    <div className="playerApp-version-update">
      <div className="content">
        <InvalidIcon />
        <span>{t('YourPlayerVersionIsBelowThanMinimumSupportedVersion')}</span>
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('updateNow')}
            click={submit}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}

PlayerAppUpdateModal.propTypes = {
  close: PropTypes.node.isRequired,
};

export default PlayerAppUpdateModal;
