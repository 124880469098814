import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import TopContent from '../../shared/component/TopContent';
import Tabs from '../../shared/component/Tabs';
import {
  BillingActiveIcon,
  BillingIcon,
  ChangePasswordActiveIcon,
  ChangePasswordIcon,
  PreferenceActiveIcon,
  PreferenceIcon,
  ProfileActiveIcon,
  ProfileIcon,
} from '../../shared/component/svgIcon';
import Profile from './components/Profile';
import Billing from './components/Billing';
import AccountPreference from './components/AccountPreference';
import ChangePassword from './components/ChangePassword';

function Settings() {
  const { t } = useTranslation();
  const params = useParams();
  const list = [
    {
      id: 1,
      label: t('profile'),
      key: 'profile',
      icon: <ProfileIcon />,
      activeIcon: <ProfileActiveIcon />,
    },
    {
      id: 2,
      label: t('billing'),
      key: 'billing',
      icon: <BillingIcon />,
      activeIcon: <BillingActiveIcon />,
    },
    {
      id: 3,
      label: t('preferences'),
      key: 'account-preference',
      icon: <PreferenceIcon />,
      activeIcon: <PreferenceActiveIcon />,
    },
    {
      id: 4,
      label: t('changePassword'),
      key: 'change-password',
      icon: <ChangePasswordIcon />,
      activeIcon: <ChangePasswordActiveIcon />,
    },
  ];

  // const handleChange = () => {

  // };
  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('settings')}
        />
        <div className="content-area">
          <Tabs
            // change={handleChange}
            actions={list}
          />
          <div>
            {params?.tab === 'profile' && <Profile />}
            {params?.tab === 'billing' && <Billing />}
            {params?.tab === 'account-preference' && <AccountPreference />}
            {params?.tab === 'change-password' && <ChangePassword />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
