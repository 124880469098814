import {
  get,
  post,
  // patch,
  // deletes,
} from './base/index';

export default {
  getOdooInventories: (data = {}) => {
    const params = { ...data };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/odoo`, { params });
  },
  addOdooToken: (data) => {
    const body = {
      odoo_key: data.code,
      odoo_url: data.url,
    };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/odoo/odoo-key`, body);
  },
  validOdooToken: () => get(`${process.env.REACT_APP_API_URL}cms/v1/odoo/check-odoo-key`),
};
