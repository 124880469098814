/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import deviceApi from '../../utils/api/devices';
import { setErrorNotification } from './NotificationSlice';
// import { changeDateTimeFormat } from '../../utils/helpers';

export const fetchDeviceActiveLogsList = createAsyncThunk(
  'displayGroup/fetchDisplayGroup',
  async (data, { dispatch }) => {
    const response = await deviceApi.getActivityLogs(data).then((response1) => {
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        activityType: val?._source?.activityType,
        scheduleId: val?._source?.scheduleId,
        scheduleName: val?._source?.scheduleName,
        contentId: val?._source?.contentId,
        contentName: val?._source?.contentName,
        deviceId: val?._source?.deviceId,
        timestamp: val?._source?.currentTime,
        id: val?._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  deviceActiveLogs: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  filter: {},
  tableFilter: {},
};

export const deviceActiveLogsSlice = createSlice({
  name: 'deviceActiveLogs',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    resetDeviceActiveLogsState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDeviceActiveLogsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceActiveLogsList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.deviceActiveLogs = datas;
          } else {
            state.deviceActiveLogs.push(...datas);
          }
        }
      })
      .addCase(fetchDeviceActiveLogsList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setTableFilter,
  resetDeviceActiveLogsState,
} = deviceActiveLogsSlice.actions;

export default deviceActiveLogsSlice.reducer;
