/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import aiInstance from '../../utils/api/ai-instance';
import { setErrorNotification } from './NotificationSlice';

export const fetchDeviceList = createAsyncThunk(
  'Device/fetchDeviceList',
  async (data, { dispatch }) => {
    const response = await aiInstance.getAIDevices(data).then((res) => {
      const tableValues = res.data.results.map((item) => ({
        ...item,
        id: item?._id,
        ai_instance_id: item?.ai_instance?.ai_instance_id ? item?.ai_instance?.ai_instance_id : '',
        ai_instance_name: item?.ai_instance?.ai_instance_id ? item?.ai_instance?.ai_instance_name : '',
        ai_id: item?.ai_instance?.ai_instance_id ? item?.ai_instance?.ai_instance_id : '',
        ai_instance_status: item?.ai_instance?.ai_instance_status ? item?.ai_instance?.ai_instance_status : false,
      }));
      return { ...res?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);
export const fetchDeviceUsageList = createAsyncThunk(
  'DeviceUsage/fetchDeviceUsageList',
  async (data, { dispatch }) => {
    const { startDate, endDate } = data;
    const response = await aiInstance.deviceUsageReport({ startDate, endDate }).then((res) => {
      // console.log(res);
      const usageValue = res.data.map((item) => ({
        name: item.name,
        pv: (item.usage_minutes / 60).toFixed(2),
      }));
      return { ...res?.data, results: usageValue, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);
export const fetchDeviceLists = createAsyncThunk(
  'DeviceList/fetchDeviceList',
  async (data, { dispatch }) => {
    // const { startDate, endDate } = data;
    const response = await aiInstance.deviceList().then((res) => {
      const usageValue = res.data.map((item) => ({
        label: item.device_name,
        id: item.device_id,
      }));
      return { results: usageValue, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  aiDevices: [],
  instanceList: [],
  deviceUsageList: {},
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  type: '',
  filter: {},
  tableFilter: {},
  deviceList: [],
};

export const aiAnalyticsSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    getInstanceList: (state, action) => {
      state.instanceList = action.payload;
    },
    instanceTransferStatus: (state, action) => {
      state.type = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(fetchDeviceList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeeded';
        if (action.payload.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.aiDevices = fetchedData;
          }
        }
      })
      .addCase(fetchDeviceList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDeviceUsageList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceUsageList.fulfilled, (state, action) => {
        // Add user to the state array
        state.deviceUsageList = action.payload;
      })
      .addCase(fetchDeviceUsageList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDeviceLists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceLists.fulfilled, (state, action) => {
        // Add user to the state array
        state.deviceList = action.payload.results;
      })
      .addCase(fetchDeviceLists.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  getInstanceList,
  setFilter,
  setTableFilter,
  instanceTransferStatus,
} = aiAnalyticsSlice.actions;

export default aiAnalyticsSlice.reducer;
