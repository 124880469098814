import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import deepEqual from 'deep-equal';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import SelectBox from '../../../shared/component/form/SelectBox';

import devices from '../../../utils/api/devices';

import { fetchStoreLocationListAll } from '../../../redux/slices/StoreLocationSlice';
import {
  fetchDisplaySingleSignage,
  updateDisplay,
} from '../../../redux/slices/DisplaySignageSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DisplaySignageEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const storeData = useSelector((state) => state.store.storeLocationAll);
  const { displaySignage, singleDisplaySignage } = useSelector((state) => state.display);
  const dataValue = displaySignage.find((val) => val.id === params?.id);

  const [disable, setDisable] = useState(true);
  const [stores, setStores] = useState([]);
  const [value, setValue] = useState({});
  const [originalValue, setOriginalValue] = useState({});

  // For Notification
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);

  const addDisplaySignage = () => {
    navigate('../');
  };

  const submit = () => {
    setDisable(true);
    setUploading(true);
    devices.updateDevice(value).then((res) => {
      dispatch(updateDisplay(res?.data));
      setNotification(true);
      setDisable(false);
      setTimeout(() => {
        setNotification(false);
        navigate('../');
      }, 3000);
    }).catch((error) => {
      setUploading(false);
      setDisable(false);
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  const changeHandle = (name, changeValue) => {
    if (name === 'store') {
      if (changeValue) {
        setValue({ ...value, [name]: changeValue.id, store_name: changeValue.label });
      }
    } else {
      setValue({ ...value, [name]: changeValue });
    }
  };

  useEffect(() => {
    if (
      value.store
      && value.name
      && value.device_system_id
      && !deepEqual(originalValue, value)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  useEffect(() => {
    if (storeData.length === 0) {
      dispatch(fetchStoreLocationListAll());
    }
    if (stores.length === 0) {
      const store = storeData.map((val) => ({ label: val.name, id: val.id }));
      setStores(store);
      setValue({ ...dataValue, store_name: dataValue?.store?.storeName, store: dataValue?.store?.id });
      setOriginalValue({ ...dataValue, store_name: dataValue?.store?.storeName, store: dataValue?.store?.id });
    }
    if (displaySignage.length === 0 && Object.keys(singleDisplaySignage).length === 0) {
      dispatch(fetchDisplaySingleSignage(params?.id));
    }
    if (Object.keys(singleDisplaySignage).length !== 0 && displaySignage.length === 0) {
      setValue({
        ...singleDisplaySignage,
        store_name: singleDisplaySignage?.store?.name,
        store: singleDisplaySignage?.store?.id,
      });
      setOriginalValue({
        ...singleDisplaySignage,
        store_name: singleDisplaySignage?.store?.name,
        store: singleDisplaySignage?.store?.id,
      });
    }
  }, [singleDisplaySignage]);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={{ content: <Success message={t('displaySignageUpdateSuccess')} /> }}
        notification
      />
      <div className="main-content">
        <TopContent
          label={[t('signageDevices'), t('editDisplaySignage')]}
          button={false}
          buttonLabel="Back"
          buttonClass="success-button"
          click={addDisplaySignage}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area">
              <div className="signage-form">
                <div className="input-fields">
                  <SelectBox
                    label={t('storeName')}
                    name="store"
                    required
                    value={value.store_name}
                    onchange={changeHandle}
                    lists={stores}
                  />
                  <Input
                    icon={' '}
                    label={t('devicesName')}
                    type="text"
                    name="name"
                    required
                    value={value.name}
                    change={changeHandle}
                  />
                </div>
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button label={t('back')} click={addDisplaySignage} classes="default-button" />
                </div>
                <div className="form-button">
                  <Button
                    label={uploading ? t('updating') : t('update')}
                    click={submit}
                    classes={uploading ? 'success-button loading-btn' : 'success-button'}
                    disabled={disable}
                    loading={uploading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplaySignageEdit;
