/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { createNewStoreLocally } from '../../../redux/slices/StoreLocationSlice';

import TopContent from '../../../shared/component/TopContent';
import DropDownMenu from '../../../shared/component/DropDownMenu';
import { MoreMenuIcon } from '../../../shared/component/svgIcon';
import ModalBox from '../../../shared/component/ModalBox';

import AddSingleFloorMap from './AddSingleFloorMap';
import storeLocation from '../../../utils/api/store-location';

function EditFloorMap() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [storeData, setStoreData] = useState({});
  const [floorMapData, setFloorMapData] = useState([]);
  const [addNewFloor, setAddNewFloor] = useState(false);
  const [modalView, setModalView] = useState({});

  const getSingleStoreDetails = (id) => {
    storeLocation.getSingleLocation(id)
      .then((response) => {
        const formattedData = {
          ...response?.data?.[0],
          id: response?.data?.[0]?._id,
        };
        setStoreData(formattedData);
        dispatch(createNewStoreLocally(formattedData));
      }).catch((err) => (
        dispatch(setErrorNotification(err?.response?.data))
      ));
  };

  const addDevicesInFloor = (index) => {
    navigate(`../edit/floor-map-edit/${index}`);
  };

  const deleteFloorById = (id) => {
    const floorId = storeData?.floor_map?.find((floor, index) => index === id)._id;
    storeLocation.deleteFloorById(floorId)
      .then(() => {
        const updatedFloorMap = storeData?.floor_map?.filter((floor) => floor._id !== floorId);
        setFloorMapData(updatedFloorMap);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const actions = [
    {
      name: t('markDevice'),
      url: '',
      function: addDevicesInFloor,
      key: 'view',
    },
    {
      name: t('delete'),
      url: '',
      function: deleteFloorById,
      key: 'delete',
    },
  ];

  const navigateBack = () => {
    navigate(-1);
  };

  const addNewFloorMap = () => {
    setAddNewFloor(true);
    setModalView({
      title: 'New Floor',
      content: (
        <AddSingleFloorMap
          closeModal={setAddNewFloor}
          submitFloorMap={() => getSingleStoreDetails(params?.id)}
          addStoreFromEditPage
          storeId={storeData?._id}
        />
      ),
    });
  };

  useEffect(() => {
    setFloorMapData(storeData?.floor_map);
  }, [storeData]);

  useEffect(() => {
    getSingleStoreDetails(params?.id);
  }, [params]);

  return (
    <div className="content">
      <ModalBox
        status={addNewFloor}
        closeModal={setAddNewFloor}
        modalView={modalView}
      />
      <div className="main-content">
        <TopContent
          label={[t('facilities'), storeData?.name]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={navigateBack}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form-1">
              <div className="location-form">
                <div className="form-header">
                  <h2>{t('floorMap')}</h2>
                  {floorMapData?.length < 20
                    && (
                      <div className="add-button" onClick={addNewFloorMap} role="presentation">
                        {t('+addFloor')}
                      </div>
                    )}
                </div>
                <div className="floor-map-list">
                  {
                    floorMapData?.map((floorMap, index) => (
                      <div className="floor-map-item">
                        <div className="top-session">
                          <div className="thumb-image">
                            <img src={floorMap?.signedUrl} alt="floor_image" />
                          </div>
                          <div>
                            <DropDownMenu
                              action={actions}
                              value={{ id: index }}
                              icon={<MoreMenuIcon />}
                              dropdown="tableForm"
                              height="15px"
                              tooltip=""
                            />
                          </div>
                        </div>
                        <div className="middle-session">
                          {floorMap?.name}
                        </div>
                        <div className="footer-session">
                          {floorMap?.size}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFloorMap;
