import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InputRightInnerIcon } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { confirmPasswordValidation, passwordValidation } from '../../../shared/component/form/Validation';
import EyeIcon from '../../../assets/images/eye-Icon.png';
import EyeHideIcon from '../../../assets/images/eye-hide-icon.png';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import auth from '../../../utils/api/auth';

function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [type, setType] = useState('password');
  const [image, setImage] = useState(EyeIcon);
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [newPasswordImage, setNewPasswordImage] = useState(EyeIcon);
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [confirmPasswordImage, setConfirmPasswordImage] = useState(EyeIcon);
  const [value, setValue] = useState({});
  const [disable, setDisable] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const handleChange = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };
  const click = (funValue) => {
    if (funValue === 'newPassword') {
      setNewPasswordType(newPasswordType === 'password' ? 'text' : 'password');
      setNewPasswordImage(newPasswordType === 'password' ? EyeHideIcon : EyeIcon);
    } else if (funValue === 'confirmPassword') {
      setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
      setConfirmPasswordImage(confirmPasswordType === 'password' ? EyeHideIcon : EyeIcon);
    } else {
      setType(type === 'password' ? 'text' : 'password');
      setImage(type === 'password' ? EyeHideIcon : EyeIcon);
    }
  };
  const reset = () => {
    setValue({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };
  const submit = () => {
    setSubmitButtonClicked(true);
    if (passwordValidation(value?.newPassword)
      && !confirmPasswordValidation(value?.newPassword, value?.confirmPassword)) {
      setUploading(true);
      auth.changePassword(value)
        .then(() => {
          setUploading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, 3000);
        })
        .catch((err) => {
          setUploading(false);
          setDisable(false);
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (value?.oldPassword && value?.newPassword && value?.confirmPassword) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  });
  return (
    <div className="change-password">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('passwordUpdatedSuccess')} /> }}
      />
      <div className="form-fields">
        <div className="form-fields-single-row">
          <InputRightInnerIcon
            icon={image}
            type={type}
            label={t('oldPassword')}
            name="oldPassword"
            value={value?.oldPassword}
            change={handleChange}
            click={click}
            placeholder={t('enterCurrentPassword')}
          />
        </div>
        <div className="form-fields-row">
          <InputRightInnerIcon
            icon={newPasswordImage}
            type={newPasswordType}
            label={t('newPassword')}
            name="newPassword"
            value={value?.newPassword}
            change={handleChange}
            click={() => click('newPassword')}
            placeholder={t('enterNewPassword')}
            error={!passwordValidation(value?.newPassword) && submitButtonClicked}
            errorMessage={t('passwordRequirements')}
          />
          <InputRightInnerIcon
            icon={confirmPasswordImage}
            type={confirmPasswordType}
            label={t('confirmPassword')}
            name="confirmPassword"
            value={value?.confirmPassword}
            change={handleChange}
            click={() => click('confirmPassword')}
            placeholder={t('confirmNewPassword')}
            error={confirmPasswordValidation(value?.newPassword, value?.confirmPassword) && submitButtonClicked}
            errorMessage={t('passwordMismatch')}
          />
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('reset')}
              click={reset}
              classes="default-button"
            />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('changePassword...') : t('changePassword')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              loading={uploading}
              disabled={disable}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
