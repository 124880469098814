import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Input } from '../../../shared/component/form/Input';
import InputTags from '../../../shared/component/form/InputTags';

function ViewFloorMap(props) {
  const { t } = useTranslation();
  const { oldFloorMapValue } = props;
  return (
    <div className="floor-map-form">
      <div className="floor-map-fields">
        <Input
          icon={' '}
          label={t('floorName')}
          name="name"
          value={oldFloorMapValue?.name}
          readonly
          change={() => { }}
          placeholder={t('enterFloorName')}
        />
        <InputTags
          icon={' '}
          label={t('tags')}
          readonly
          name="tags"
          addData={() => { }}
          required={false}
          value={oldFloorMapValue?.tags}
          placeholder={t('enterTags')}
        />
        <div className="selected-image-session">
          <div className="thumb-image">
            <img alt="selected-images-thumb" src={oldFloorMapValue.viewImage} />
          </div>
          <div className="file-name">
            <div>{oldFloorMapValue.fileName}</div>
            <div className="subtext"><span>{oldFloorMapValue.type}</span> {oldFloorMapValue.size}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
ViewFloorMap.propTypes = {
  oldFloorMapValue: PropTypes.shape({
    name: PropTypes.number,
    tags: PropTypes.arrayOf,
    viewImage: PropTypes.string,
    fileName: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
  }),
};

ViewFloorMap.defaultProps = {
  oldFloorMapValue: {},
};
export default ViewFloorMap;
