import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InvalidIcon } from '../../../../shared/component/svgIcon';
import Button from '../../../../shared/component/Button';

function DeleteRequestPopup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const submit = () => {
    navigate('/admin/settings/billing');
  };
  return (
    <div className="deleteAccount">
      <div className="request-content">
        <InvalidIcon />
        <span className="description">
          Due to security concern please cancel your subscription to complete the account deletion process.
        </span>
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('continue')}
            click={submit}
            classes="success-button"
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteRequestPopup;
