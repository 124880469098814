/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toPng } from 'html-to-image';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import ModalBox from '../../../../shared/component/ModalBox';
import TemplateContentUpload from '../TemplateContentUpload';
import HtmlHeader from '../HtmlHeader';
import utils from '../templateFunctions';
import { createOrUpdateTemplate } from '../../../../redux/slices/TemplateSlice';
import FileFormat from '../FileFormat';
import PopupBox from '../ContentUploadConfirmation';
import { interRegular } from '../../../../assets/fonts/base64Fonts';

function ClothingTemplate({ templateData }) {
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [openContentUploadConfirmation, setOpenContentUploadConfirmation] = useState(false);
  const [base64, setBase64] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [disable, setDisable] = useState(true);
  const [modalView, setModalView] = useState({ content: '', title: '' });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const htmlRef = useRef(null);

  const cssStyles = `
.clothing-template {
    width: 100%;
    height: 100%;
    padding: 30px 25px 30px 40px;
    display: flex;
    align-items: center;
}

.list-container {
    display: flex;
    gap: 25px;
    width: 100%;
}

.list-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.items {
    display: flex;
    gap: 15px;
    align-items: center;
    text-align: center;
}

.item-image {
    width: 170px;
    height: 128px;
}

.item-price-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.logo-image {
    width: 124px;
    height: 94px;
}

img {
    width: 100%;
    height: 100%;
}
`;

  const convertImage = async (url) => {
    await utils.convertSignedUrl(url)
      .then((response) => {
        setDisable(false);
        dispatch(createOrUpdateTemplate(response));
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertBase64 = async () => {
    toPng(htmlRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        setBase64(dataUrl);
        /* converted to base64 format */
        convertImage(dataUrl);
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const convertHtml = () => {
    if (htmlRef.current) {
      const content = htmlRef.current.outerHTML;
      const fullHtml = `
        <html>
          <head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
            <style>${cssStyles}</style>
          </head>
          <body>${content}</body>
        </html>
      `;
      setHtmlContent(fullHtml); // Set the HTML content as a string
    }
  };

  useEffect(() => {
    utils?.initializer(templateData).then((res) => (setData(res)));
    utils?.reload();
  }, [templateData]);

  /* useEffect runs convertBase64 after data is updated */
  useEffect(() => {
    if (data) {
      /* used settimeout the page was fully rendered and then called the api so wait for the response */
      setTimeout(() => {
        convertBase64();
      }, 1000);
      convertHtml();
    }
  }, [data]);

  const handleFileType = (fileType) => {
    setModalView({
      title: t('content'),
      content: <TemplateContentUpload
        base64={base64}
        htmlContent={htmlContent}
        setNotification={setNotification}
        fileType={fileType}
      />,
    });
  };

  const onClickContentUpload = () => {
    setNotification(true);
    setModalView({
      title: t('fileFormat'),
      content: <FileFormat
        setNotification={setNotification}
        handleFileType={handleFileType}
        setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
        data={data}
      />,
    });
  };

  const contentDetails = data?.[0]?.template_contents?.items?.[0]?.details;
  const styles = data?.[0]?.styles?.details;
  const logoImage = data?.[0]?.template_images?.[0]?.image;
  const backgroundColor = data?.[0]?.styles?.background;

  return (
    <div>
      <HtmlHeader
        htmlData={data}
        disable={disable}
        click={onClickContentUpload}
      />
      <ModalBox
        status={notification}
        closeModal={setNotification}
        modalView={modalView}
      />
      <ModalBox
        status={openContentUploadConfirmation}
        closeModal={setOpenContentUploadConfirmation}
        modalView={{
          title: 'Confirmation',
          content: <PopupBox
            setOpenContentUploadConfirmation={setOpenContentUploadConfirmation}
            setNotification={setNotification}
          />,
        }}
      />
      <div className="header-height" />
      <style>
        {`
         @font-face {
          font-family: 'Inter';
          src: url('data:font/truetype;charset=utf-8;base64,${interRegular}') format('truetype');
          font-weight: 400;
         }
        `}
      </style>
      <div className="template-container-8">
        <div className="inner-container">
          <div
            ref={htmlRef}
            className="clothing-template"
            style={{
              backgroundColor: `${backgroundColor}`,
            }}
          >
            <div className="list-container">
              <div className="list-items">
                {contentDetails?.map((item) => (
                  <div className="items">
                    {item?.image
                      && (
                        <img src={item?.image} alt="" className="item-image" />
                      )}
                    <div className="item-price-wrapper">
                      <p style={{
                        margin: '0',
                        fontSize: `${styles?.[0]?.fontSize}`,
                        color: `${styles?.[0]?.color}`,
                        fontFamily: `${styles?.[0]?.fontFamily}`,
                      }}
                      >
                        MRP - {' '}
                        <span style={{ textDecoration: 'line-through' }}>{item?.mrp_price}</span>
                      </p>
                      <span style={{
                        fontSize: `${styles?.[1]?.fontSize}`,
                        color: `${styles?.[1]?.color}`,
                        fontFamily: `${styles?.[1]?.fontFamily}`,
                      }}
                      >
                        Off Price - {item?.offer_price}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              {logoImage
                && (
                  <div className="logo-image">
                    <img src={logoImage} alt="" />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ClothingTemplate.propTypes = {
  templateData:
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      template_title: PropTypes.string.isRequired,
    }).isRequired,
  // Ensure templateData is an array of objects with specific shape
};

export default ClothingTemplate;
