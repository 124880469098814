import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';

import displayGroupsApi from '../../../utils/api/devices-group';
import {
  singleDisplayGroupAdd,
  createNewDisplayGroup,
  updateDisplayGroup,
} from '../../../redux/slices/DisplayGroupSlice';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import height from '../../../utils/size-variables';

function DisplayGroupsAddView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newDisplayGroup } = useSelector((state) => state.displayGroup);

  // For re-ordering
  const [tableValue, setTableValues] = useState([]);

  // For notification
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [notification, setNotification] = useState(false);

  // Table column visibility
  const [columnVisibility, setColumnVisibility] = useState({});

  const addDisplayGroups = () => {
    if (newDisplayGroup.id) {
      navigate(`../edit/${newDisplayGroup.id}`);
    } else {
      navigate('../add');
    }
  };

  // Column header for display signage table
  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOS'),
      accessorKey: 'hardware',
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
  ];

  const submit = () => {
    if (newDisplayGroup.id) {
      setUploading(true);
      const deviceGroupData = {
        id: newDisplayGroup.id,
        name: newDisplayGroup?.name,
        description: newDisplayGroup?.description ? newDisplayGroup?.description : '',
        devices: tableValue.map((value) => value.id),
      };
      displayGroupsApi.updateDevicesGroup(deviceGroupData)
        .then((res) => {
          dispatch(updateDisplayGroup(res?.data));
          setUploading(false);
          setUploadMessage(t('displayGroupUpdatedSuccess'));
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
            dispatch(singleDisplayGroupAdd({ devices: [] }));
            navigate('../');
          }, 3000);
        }).catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    } else {
      setUploading(true);
      const deviceGroupData = {
        name: newDisplayGroup?.name,
        description: newDisplayGroup?.description ? newDisplayGroup.description : undefined,
        devices: tableValue.map((value) => value.id),
      };
      displayGroupsApi.addDevicesGroup(deviceGroupData)
        .then((res) => {
          dispatch(createNewDisplayGroup(res?.data));
          setUploadMessage(t('displayGroupCreatedSuccess'));
          setNotification(true);
          setUploading(false);
          setTimeout(() => {
            setNotification(false);
            dispatch(singleDisplayGroupAdd({ devices: [] }));
            navigate('../');
          }, 3000);
        })
        .catch((error) => {
          setUploading(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  useEffect(() => {
    if (Object.keys(newDisplayGroup).length === 0) {
      navigate('../add');
    } else {
      setTableValues([...newDisplayGroup.selectedDeviceDetails]);
    }
  }, []);

  return (
    <div className="content content-wrap">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={uploadMessage} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('deviceGroups'), t('createNewDisplayGroups')]}
          buttonClass="success-button"
          button={false}
          buttonLabel="Back"
          click={addDisplayGroups}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('groupName')}
                  type="text"
                  name="name"
                  readonly
                  value={newDisplayGroup?.name}
                  change={() => { }}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  value={newDisplayGroup?.description}
                  readonly
                />
              </div>
              <Table
                header={columns}
                value={tableValue}
                onFilterOrSortingChange={() => { }}
                status=""
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                rowSelection={{}}
                height={height.formTableHeight}
                disableFullScreenToggle
                enableRowOrdering
                setValue={setTableValues}
                disableFilterButton
                disableSorting
                disableFilter
              />
            </div>
            <div className="form-button-group">
              <div className="form-button">
                <Button label={t('back')} click={addDisplayGroups} classes="default-button" />
              </div>
              <div className="form-button">
                <Button
                  label={uploading ? t('creating') : t('create')}
                  click={submit}
                  classes={uploading ? 'success-button loading-btn' : 'success-button'}
                  loading={uploading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayGroupsAddView;
