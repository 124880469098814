import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecentActivities } from '../../../redux/slices/DashBoardSlice';

function RecentActivity() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    recentActivityData,
  } = useSelector((state) => state.dashboardPieCharts);

  useEffect(() => {
    dispatch(fetchRecentActivities());
  }, []);

  return (
    <div className="dashboard-recent-activity">
      <h2>{t('recentActivity')}</h2>
      <div className="activity-container">
        {recentActivityData.map((activity, index) => (
          <div
            className="single-activity"
            style={{ borderLeft: index === recentActivityData.length - 1 && 'none' }}
          >
            <div className="absolute-container">
              <div className="top-point" />
            </div>
            <h2>&quot;{activity?.event_name}&quot; {activity?.event_type}</h2>
            <span>{activity?.updatedAt}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentActivity;
