/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Template from '../../../utils/api/templates';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import {
  getAllTemplates,
  resetSignedUrl,
} from '../../../redux/slices/TemplateSlice';
import TemplateCategory from './TemplateCategory';

import SavedTemplates from './SavedTemplates';

function ViewTemplate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [listIndex, setListIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [filter, setFilter] = useState('All');
  const [category, setCategory] = useState([]);
  const [templateLoader, setTemplateLoader] = useState(true);
  const [filterValue, setFilterValue] = useState('category-list-chosen');

  const {
    allTemplates,
    tabView,
  } = useSelector((state) => state?.currentTemplate);
  const [color, setColor] = useState(tabView);
  const getTemplate = () => {
    Template?.getTemplates()
      .then((res) => {
        const payload = res?.data?.results;
        setCategory(payload);
        setTemplateLoader(false);
        dispatch(getAllTemplates(payload));
      })
      .catch((error) => {
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  const handleTabClick = (tab) => {
    setColor(tab);
    if (tab === 'tab-1') {
      setCategory(allTemplates);
    }
  };
  useEffect(() => {
    if (allTemplates?.length === 0) {
      getTemplate();
    } else {
      setTemplateLoader(false);
      setCategory(allTemplates);
    }
    dispatch(resetSignedUrl());
  }, [color]);

  const handleCategoryClick = (list, index) => {
    setListIndex(index);
    setFilterValue('category-list');
    setFilter(list?._id);
  };
  const handleCategoryHover = (index) => {
    setHoverIndex(index);
  };
  const handleFocus = (index) => {
    setHoverIndex(index);
  };
  const handleCategoryLeave = (listed) => {
    setHoverIndex(listed);
  };

  const handleAllClick = () => {
    setFilter('All');
    setListIndex(null);
    setHoverIndex(null);
    setFilterValue('category-list-chosen');
  };
  return (
    <div className="template-view">
      <div className="template-top">
        <h1 className="template-title">{t('templates')}</h1>
      </div>
      <div className="template-wrapper">
        <div style={{ overflow: 'hidden' }}>
          <div className="template_tabs-container">
            <div style={{ margin: '5px' }}>
              <p
                className={color === 'tab-1' ? 'tabs-content-chosen' : 'tabs-content'}
                role="none"
                onClick={() => handleTabClick('tab-1', 1)}
              >
                {t('templates')}
              </p>
              <div className={color === 'tab-1' ? 'template-underline-bar-chosen' : 'template-underline-bar'}> </div>
            </div>
            <div style={{ margin: '5px' }}>

              <p
                className={color === 'tab-2' ? 'tabs-content-chosen' : 'tabs-content'}
                role="none"
                onClick={() => handleTabClick('tab-2', 2)}
              >
                {t('saved')}
              </p>

              <div className={color === 'tab-2' ? 'template-underline-bar-chosen' : 'template-underline-bar'}> </div>
            </div>
          </div>
          {color === 'tab-1'
            && (
              <div className="template-scroll">
                <div className="category-container">
                  <div className={filterValue} onClick={() => handleAllClick()} role="none">
                    <p className="category-content">{t('all')}</p>
                  </div>
                  {category?.map((list, Index) => (
                    <div
                      className={(listIndex === Index || hoverIndex === Index)
                        ? 'category-list-chosen'
                        : 'category-list'}
                      key={list?.id}
                      role="none"
                      onClick={() => handleCategoryClick(list, Index)}
                      onMouseOver={() => handleCategoryHover(Index)}
                      onMouseLeave={() => handleCategoryLeave(listIndex)}
                      onFocus={() => handleFocus(Index)}
                    >
                      <p className="category-content">{t(`${list?._id}`)}</p>
                    </div>
                  ))}
                </div>
                <div className="template-container">
                  <TemplateCategory listed={filter} data={category} color={templateLoader} />
                </div>
              </div>
            )}

        </div>
        {color === 'tab-2'
          && (
            <SavedTemplates />
          )}
      </div>
    </div>

  );
}

export default ViewTemplate;
