import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import DataTable from '../Table';

import loginImage from '../../assets/images/Group.png';

import DeleteModal from '../../shared/component/DeleteModal';
import TopContent from '../../shared/component/TopContent';
import DropDownMenu from '../../shared/component/DropDownMenu';

import location from '../../utils/api/store-location';

import {
  fetchStoreLocationList,
  fetchCityStateCountry,
  setFilter,
  setTableFilter,
  deleteStore,
  createNewStoreLocally,
} from '../../redux/slices/StoreLocationSlice';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkFeatures, checkPermission } from '../../utils/helpers';
import exportCSV from '../Global/exportCSV';
import StoreGridListView from './components/StoreGridListView';
import {
  ActiveGridViewIcon,
  ActiveListMenu,
  GridViewIcon,
  ListMenuIcon,
  LocationActiveIcon,
  LocationIcon,
  MoreMenuIcon,
} from '../../shared/component/svgIcon';
import ViewStoreMap from './components/ViewStoreMap';
import height from '../../utils/size-variables';
import Toggle from '../Schedule/components/ToggleButton';

function Location() {
  const navigate = useNavigate();
  const { page } = useParams();
  const { t } = useTranslation();
  const tabPages = [
    {
      key: 'list',
      icon: <ListMenuIcon />,
      activeIcon: <ActiveListMenu />,
      enable: checkPermission('getStore'),
    },
    {
      key: 'grid',
      icon: <GridViewIcon />,
      activeIcon: <ActiveGridViewIcon />,
      enable: checkPermission('getStore'),
    },
    {
      key: 'map',
      icon: <LocationIcon />,
      activeIcon: <LocationActiveIcon />,
      enable: checkPermission('getStore'),
    },
  ];

  const dispatch = useDispatch();

  const {
    storeLocation,
    pageCount,
    totalPageCount,
    filter,
    status,
    tableFilter,
    totalDataCount,
    countryData,
  } = useSelector((state) => state.store);

  const [storeId, setId] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const getLocations = (counts, search) => {
    if (status === 'succeeded') {
      dispatch(fetchStoreLocationList({ page: counts, limit: 20, ...search }));
    }
  };

  const handleScroll = () => {
    getLocations(pageCount + 1, filter);
  };

  const getDeleteLocationId = (id) => {
    setId(id);
    setOpenDeleteModal(true);
  };

  const viewLocation = (id) => {
    navigate(`../view/${id}`);
  };

  const editLocation = (id) => {
    navigate(`../edit/${id}`);
  };

  const editFloorMap = (id) => {
    navigate(`../edit-floor-map/${id}`);
  };

  const actions = [
    {
      name: t('view'),
      url: '',
      function: viewLocation,
      key: 'view',
      disabled: !checkPermission('getStore') || !checkFeatures('store', 'view'),
    },
    {
      name: t('editStore'),
      url: '',
      function: editLocation,
      key: 'view',
      disabled: !checkPermission('editStore') || !checkFeatures('store', 'edit'),
    },
    {
      name: t('editFloorMap'),
      url: '',
      function: editFloorMap,
      key: 'view',
      disabled: !checkPermission('editStore') || !checkFeatures('store', 'edit'),
    },
    {
      name: t('delete'),
      url: '',
      function: getDeleteLocationId,
      key: 'delete',
      disabled: !checkPermission('deleteStore') || !checkFeatures('store', 'delete'),
    },
  ];

  const columns = [
    {
      header: t('sNo'),
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('storeName'),
      accessorKey: 'name',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          <img
            alt="thumbnail"
            src={
              row.original?.store_images?.[0]?.signedUrl || loginImage
            }
            loading="lazy"
            role="presentation"
          />
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('storeID'),
      accessorKey: 'store_id',
    },
    {
      header: t('areaAndCity'),
      accessorKey: 'city',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.city,
      muiFilterTextFieldProps: { placeholder: 'Filter by City' },
      Cell: ({ row }) => row.original.area_city,
      filterFn: 'commonFIlterFn',
    },
    {
      header: t('state'),
      accessorKey: 'state',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.state,
    },
    {
      header: t('country'),
      accessorKey: 'country',
      filterVariant: 'multi-select',
      filterSelectOptions: countryData?.country,
    },
    {
      header: '',
      accessorKey: 'id',
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: true,
      filterVariant: 'none',
      size: 50,
      Cell: ({ row }) => (
        <DropDownMenu
          action={actions}
          icon={<MoreMenuIcon />}
          value={row?.original}
          dropdown="tableForm"
          tooltip=""
        />
      ),
    },
  ];

  const deleteLocation = () => {
    location.deleteLocation(storeId).then(() => {
      dispatch(deleteStore(storeId));
      setId(' ');
      setOpenDeleteModal(false);
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
  };

  const navigateBack = () => {
    navigate('../add');
  };

  const calendarNavigate = (url) => {
    navigate(url);
  };

  const exportData = () => {
    const columnHeaders = ['name', 'store_id', 'area', 'country', 'state', 'city'];

    // Filter the hidden column
    const filteredHeaders = columnHeaders.filter((header) => columnVisibility[header] !== false);

    // API call for export CSV
    location.storeDataExport({ fields: filteredHeaders, ...filter })
      .then((res) => {
        // Function to export as CSV file
        exportCSV(filteredHeaders, res?.data);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
      });
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    dispatch(setTableFilter(reduxColumnFiltersSorting));
    dispatch(setFilter(filteredValues));
    getLocations(1, filteredValues);
  };

  useEffect(() => {
    setIsFirstRender(false);
    dispatch(createNewStoreLocally({}));
    if (pageCount === 0 && Object.keys(tableFilter).length === 0) {
      getLocations(1);
      dispatch(fetchCityStateCountry());
    }
  }, []);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('facilities')}
          buttonClass="success-button store-location-createbutton"
          button={checkPermission('addStore') && checkFeatures('store', 'create')}
          buttonLabel={t('newStore')}
          click={navigateBack}
        />
        <DeleteModal
          status={openDeleteModal}
          closeModal={setOpenDeleteModal}
          okFunction={deleteLocation}
          buttonLabel={t('delete')}
          title={t('areYouSureYouWantToDeleteThisFacility?')}
          subTitle=<span>{t('thisWillDeleteThisStorePermanently')} <br />{t('youCannotUndoThisAction.')}</span>
        />
        <div className="content-area">
          <div className="my-content">
            {
              page === 'grid' && (
                <div className="tab-buttons">
                  <Toggle button1={calendarNavigate} active={page} tabPages={tabPages} />
                </div>
              )
            }
            <div className="my-content-area location-table">
              {
                page === 'list' && (
                  <DataTable
                    header={columns}
                    reduxColumnFiltersSorting={tableFilter}
                    value={storeLocation}
                    status={status}
                    onFilterOrSortingChange={onFilterOrSortingChange}
                    totalPageCount={totalPageCount}
                    pageCount={pageCount}
                    scrollData={handleScroll}
                    totalDataCount={totalDataCount}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnVisibility={columnVisibility}
                    setColumnVisibility={setColumnVisibility}
                    enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                    height={height.onlyTableHeight}
                    exportButton={checkFeatures('store', 'export_csv') && checkPermission('exportStore')}
                    exportData={exportData}
                    isFirstRender={isFirstRender}
                    showToolbarLeft
                    toolbarLeft={(
                      <Toggle button1={calendarNavigate} active={page} tabPages={tabPages} />
                    )}
                  />
                )
              }
              {
                page === 'grid' && (
                  <InfiniteScroll
                    dataLength={storeLocation.length}
                    next={handleScroll}
                    height={height.onlyTableHeight}
                    hasMore={pageCount < totalPageCount}
                    loader={status === 'loading' && (
                      <h4 className="text-center">
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress color="success" />
                        </Box>
                      </h4>
                    )}
                  >
                    <StoreGridListView storeList={storeLocation} action={actions} upload={editLocation} />
                  </InfiniteScroll>
                )
              }
              {
                page === 'map' && (
                  <ViewStoreMap page={page} tabPages={tabPages} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Location.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.objectOf(),
  }),
};
Location.defaultProps = {
  row: {},
};

export default Location;
